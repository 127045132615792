import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';

import {ResponseContainer} from '../../../service/ResponseContainer';
import {BaseContainer} from '../../../service/BaseContainer';
import {RequestContainer} from '../../../service/RequestContainer'; 

declare var $ :any;
@Component({
  selector: 'app-skills-trn',
  templateUrl: 'skills-trn.component.html'
})
export class SkillsTrnComponent implements OnInit {

  public submitObj:any = {};
  public targetIndex:Object = {};
  public setPageNo:boolean = false; 
  public setPageNoNext:boolean = false;
  public pageNo:any = {};
  public routeUrl:Object = {};
  public responseData:any = {}; 
  public badgeList:any = {}; 
  public id:any = {}; 

  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;
  
  constructor(
    private router:Router,
     private httpService : HttpService, 
     private route: ActivatedRoute
   )
   {}   

   ngOnInit(): void { 
    var requestObj = new RequestContainer();
    requestObj['pageNo'] = 0; 
    this.pageNo = requestObj['pageNo'];
    requestObj['catId'] =  this.route.snapshot.paramMap.get('id');
    var currentUser = JSON.parse(sessionStorage.getItem('login_user_id'));
    requestObj['empId'] =  currentUser;
    requestObj['platform'] = "Web";
    this.routeUrl = this.router.url;
    this.id  =  this.route.snapshot.paramMap.get('id');
    if(this.routeUrl == '/trainers/skills-trn/'+this.id) 
    { 
        this.httpService.postRequest<ResponseContainer>(requestObj,'trainer/getAssignedCategorySkills_Sacaaner').subscribe(
          datas=> {
            if(datas.rsBody.result == 'success') 
            {
              this.responseData = datas.rsBody.data;
              if(this.responseData.length == 5) 
              {
                  this.setPageNoNext = true;  
              }  
              else
              {
                  this.setPageNoNext = false;
              }
              if(this.pageNo == 0)
              {
                  this.setPageNo = false;
              }
              else
              {
                  this.setPageNo = true;
              }
            } 
            else{
              console.log(datas.rsBody.error);
            }
          });
    }
  } 
  
  /* 
* pagination next
*/ 
 nextSubmit()
 {
   var  requestObj = new RequestContainer();
   requestObj['pageNo'] = this.pageNo + 1;
   this.pageNo = requestObj['pageNo'];
   requestObj['cat_id'] =  this.route.snapshot.paramMap.get('id');
   requestObj['platform'] = "Web";
   var currentUser = JSON.parse(sessionStorage.getItem('login_user_id'));
    requestObj['empId'] =  currentUser;
   this.httpService.postRequest<ResponseContainer>(requestObj,'trainer/getAssignedCategorySkills_Sacaaner').subscribe(
   datas=> {
     this.responseData = datas.rsBody.data;
     if(this.responseData.length == 6)
     {
        this.setPageNoNext = true;
        this.setPageNo = true;
     }
     else
     {
        this.setPageNoNext = false;
        this.setPageNo = true;
     }
   }); 
 }


 /* 
 * pagination previous
 */
 previousSubmit()
 {
   var  requestObj = new RequestContainer();
   if(this.pageNo == 0)
   {
     requestObj['pageNo'] = 0;
     this.pageNo = requestObj['pageNo'];
   }
   else
   {
     requestObj['pageNo'] = this.pageNo - 1; 
     this.pageNo = requestObj['pageNo'];
   }
   requestObj['platform'] = "Web";
   requestObj['cat_id'] =  this.route.snapshot.paramMap.get('id');
   var currentUser = JSON.parse(sessionStorage.getItem('login_user_id'));
   requestObj['empId'] =  currentUser;
   this.httpService.postRequest<ResponseContainer>(requestObj,'trainer/getAssignedCategorySkills_Sacaaner').subscribe(
   datas=> {
     this.responseData = datas.rsBody.data;
     if(this.responseData.length == 6)
     {
        this.setPageNoNext = true;
     }
     else
     { 
        this.setPageNoNext = false;
        this.setPageNo = true;
     } 
     if(this.pageNo == 0) 
     { 
        this.setPageNo = false;
     }
     else
     {
        this.setPageNo = true;
     }
   });
 
 }  
 
  
/* 
* back Category page
*/
backCategory(){
  this.router.navigate(['/trainers/category-trn']);  
 } 
}
