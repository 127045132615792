<!-- <p>learning-pathways-emp works!</p> -->
<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12"> 
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item active" (click)="backPathwayList();"><a href="javascript:void(0);">Pathway List</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Pathway Skill List</a></li>
                    </ol>
                </div>
            </div>
        </div>


        <!-- timeline custom -->

         
     

        <!-- timeline custom end -->

        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer"> 
                    <div class="title"> 
                        <h4>Pathway Skill</h4>
                        </div>
                        
                        
                    </div>

                    
                <!-- timeline -->
                <div class="timeline-wrapper">
                    
                    <div class="tline_h_line">
                        <ul *ngIf='responseData.length > 0'>
                            <li class="arrow_box" *ngFor="let item of responseData let i=index">
                            
                                <div class="t-card">
                                    <div class="t-head">
                                        <h3>{{item.cat_skill_name}}</h3>
                                    </div>
                                    <div class="t-body">
                                        <p>{{item.cat_skill_description}}</p>
                                    </div>
                                </div>
                                <div class="t-badge">
                                    <div class="skill-set">
                                        <div class="c_bar2 b-100" *ngIf="item.badge_name == 'Beginners'"> 
                                            <circle-progress [percent]="100" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#e5c634'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'#fff'" [class]="'pgrsbar'" [imageSrc]="item.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                                            </circle-progress>
                                        </div>
                                        <div class="c_bar2 i-100" *ngIf="item.badge_name == 'Intermediate'">
                                            <circle-progress [percent]="100" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#e5c634'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'#fff'" [class]="'pgrsbar'" [imageSrc]="item.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                                            </circle-progress>
                                        </div> 
                                        <div class="c_bar2 e-100" *ngIf="item.badge_name == 'Expert'">
                                            <circle-progress [percent]="100" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#62a006'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'#fff'" [class]="'pgrsbar'" [imageSrc]="item.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                                            </circle-progress>
                                        </div>
                                    </div>
                                </div>
                              </li>  
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>