import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';
import {ResponseContainer} from '../../../service/ResponseContainer';
import {BaseContainer} from '../../../service/BaseContainer';
import {RequestContainer} from '../../../service/RequestContainer';
declare var $ :any;

@Component({
  selector: 'app-learning-pathways-adm',
  templateUrl: 'learning-pathways-adm.component.html',
  styleUrls: ['learning-pathways-adm.component.html']
})
export class LearningPathwaysAdmComponent implements OnInit {
  
  public submitObj:Object={}; 
  public targetIndex:Object = {}; 
  public pageNo:number= 1;
  public pageSize:number= 6;

  public responseData;
  public routeUrl:Object = {};
  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;

  
  constructor(private httpService : HttpService,private router:Router,
    private route: ActivatedRoute){}  
 
  ngOnInit(): void { 
    $('.modal ').insertAfter($('body'));
    var requestObj = new RequestContainer(); 
    requestObj['pageNo'] = this.pageNo;
    requestObj['pageSize'] = this.pageSize;
    requestObj['company_id']= JSON.parse(sessionStorage.getItem('login_company'));
    this.httpService.postRequest<ResponseContainer>(requestObj, 'admin/getPathway').subscribe(datas => {
      if(datas.rsBody.result == 'success')
      {
        this.responseData = datas.rsBody.data;
      } 
     });
  }


/*
* pagination next
*/
loadpage(event) {
  $('.loader').css('display','block');
   this.pageNo= event;
   this.ngOnInit();
 }
 
 /*
* Add Skill of pathway
*/
AddSkill(id)
 {
  this.router.navigate(['/admin/add-learning-pathway-skill-adm',id]); 
 }


 /*
* Skill list of pathway
*/
showPathwaySkill(id) 
{
 this.router.navigate(['/admin/managment-adm',id]); 
}


 

/*
* delete
*/
  deleteSubmit(catId)
  {
  this.targetIndex = catId;
  $("#deletesubmit").modal('show');
  }

  deleteRow(Obj) 
  {
  this.httpService.postRequest<ResponseContainer>(Obj,'admin/deletePathway').subscribe(datas => {
  if(datas.rsBody.result == 'success')
  { 
    $("#deletesubmit").modal('hide');
    this.ngOnInit();
  } 
  });
  }

/*
* edit Category 
*/
  editSubmit(id)
  {
    this.router.navigate(['/admin/add-learning-pathway-adm',id]);
  }

}
 