import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF } from '@angular/common';
import { HttpService } from '../service/http.service';
import { ValidationHandler } from '../service/ValidationHandler';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { NgCircleProgressModule } from 'ng-circle-progress';
import {NgxPaginationModule} from 'ngx-pagination';


import { NgModule } from '@angular/core';
import { FileUploadModule } from 'ng2-file-upload';
import { FormsModule }   from '@angular/forms';

import { SuperAdminRouting } from './superadmin.routing'
import { SuperAdminComponent } from './superadmin.component';
import { SidebarSuAdmComponent } from './component/sidebar-suadm/sidebar-suadm.component';
import { HeaderModule } from '../header/header.module';
import { DashboardSuAdmComponent } from './component/dashboard-suadm/dashboard-suadm.component';
import { CompanySuAdmComponent } from './component/company-suadm/company-suadm.component';
import { SyncSuAdmComponent } from './component/sync-suadm/sync-suadm.component';
import { AddCompanySuAdmComponent } from './component/add-company-suadm/add-company-suadm.component';
import { NotificationSuAdmComponent } from './component/notification-suadm/notification-suadm.component';
import { EditProfileAdmComponent } from './component/edit-profile-adm/edit-profile-adm.component';

@NgModule({
  declarations: [
    SuperAdminComponent,
    SidebarSuAdmComponent,
    DashboardSuAdmComponent,
    AddCompanySuAdmComponent,
    CompanySuAdmComponent,
    SyncSuAdmComponent,
    NotificationSuAdmComponent,
    EditProfileAdmComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FileUploadModule,
    FormsModule,
    AutocompleteLibModule,
    SuperAdminRouting,
    HeaderModule,
    NgxPaginationModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      animationDuration: 300,
      class:'pgrsbar',
      showImage:true
    })
  ],
  providers: [{provide: APP_BASE_HREF, useValue : '' },HttpService],
  bootstrap: [SuperAdminComponent]
})
export class SuperAdminModule { }
