<app-header></app-header>
<div class="app-body">
    <div class="sidebar mob-toggle">
        <app-sidebar-adm></app-sidebar-adm>
    </div>

    <main class="main mob-toggle-main">
            <router-outlet></router-outlet>
    </main>
</div>
