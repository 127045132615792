import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stop-training-trn',
  templateUrl: 'stop-training-trn.component.html'
})
export class StopTrainingTrnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
