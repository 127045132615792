import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { FormsModule } from '@angular/forms';
// import { FileUploader } from 'ng2-file-upload';  
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { ResponseContainer } from '../../../service/ResponseContainer';
import { ValidationHandler } from '../../../service/ValidationHandler';
import * as csvtjson from 'csvtojson'
declare var $: any;
const uploadAPI = 'http://localhost:3000/imageUpload';
const csvHeaders = 'first_name,last_name,email_id,mgr_id,indirect_mgr_id,mobile_no,address,password,region'

@Component({
  selector: 'app-add-employee-adm',
  templateUrl: 'add-employee-adm.component.html',
  providers: [HttpService, ValidationHandler]
})
export class AddEmployeeAdmComponent implements OnInit {
  public responseData: any = {};
  public submitObj: any = {};
  public setCreate: boolean = true;
  public setUpdate: boolean = false;
  public routeUrl: Object = {};
  public id: any = {};
  public bulkUploadRejected: any = {};
  public bulkUploadSelected: any = {};
  csvContent: string;
  contacts: Array<any> = [];
  properties: any = "";
  flag: boolean = false;

  constructor(
    private httpService: HttpService,
    private sanitization: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private validationHandler: ValidationHandler

  ) { }

  ngOnInit(): void {
    $('.modal ').insertAfter($('body'));
    this.setCreate = true;
    this.routeUrl = this.router.url;
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.routeUrl == '/admin/add-category-adm/' + this.id) {
      this.httpService.postRequest<ResponseContainer>(this.id, 'admin/getCategoryData').subscribe(datas => {
        if (datas.rsBody.result == 'success') {
          //  this.submitObj = datas.rsBody.data;
          this.submitObj.cat_name = datas.rsBody.data[0]['cat_name'];
          this.submitObj.cat_description = datas.rsBody.data[0]['cat_description'];
          this.submitObj.imagePath = datas.rsBody.data[0]['cat_icon'];


          this.setCreate = false;
          this.setUpdate = true;
        }
      });
    }
  }

  /*
  * create submit
  */
  createSubmit() {
    let status = this.validationHandler.validateDOM('profileupdateBasic');
    if (!status) {
      this.submitObj.company_id = JSON.parse(sessionStorage.getItem('login_company'));
      this.httpService.postRequest<ResponseContainer>(this.submitObj, 'admin/createEmployee').subscribe(datas => {
        if (datas.rsBody.result == 'success') {
          this.submitObj = {};
          $("#changesubmit").modal('show');
          this.ngOnInit();
        }
        else {
          this.validationHandler.displayErrors(datas.rsBody.errors, "profileupdateBasic", null);
        }
      });
    }
  }

  /*
  *  update submit
  */
  updateSubmit() {
    let status = this.validationHandler.validateDOM('profileupdateBasic');
    if (!status) {
      this.submitObj.id = this.route.snapshot.paramMap.get('id');
      this.httpService.postRequest<ResponseContainer>(this.submitObj, 'admin/updateEmployee').subscribe(datas => {
        if (datas.rsBody.result == 'success') {
          this.submitObj = {};
          $("#changesubmit").modal('show');
          this.setUpdate = false;
          this.ngOnInit();
        }
        else {
          this.validationHandler.displayErrors(datas.rsBody.errors, "profileupdateBasic", null);
        }
      });
    }
  }
  downloadTemplate() {
    const data = csvHeaders;
    const a = document.createElement('a');
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'add_employees.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  openUploadCSVDialog() {
    $('#uploading').hide()
    $('#uploadCSV').modal('show')
  }
  dismissUpload()
  {
    $('#uploading').hide();
    $('#csvfile').show();
    $('#csvinput').val('')
    $('#uploadCSV').modal('hide')
  }
  dismissReport()
  {
    $('#report-table').empty();
    $('#report').modal('hide')
  }
  uploadCSV(files) {
    try {
      $('#csvfile').hide();
      $('#report').hide();
      $('#uploading').show();
      let fileReader = new FileReader();
      fileReader.readAsText(files[0]);
      fileReader.onload = (e) => {
        this.csvContent = fileReader.result.toString();
        csvtjson().fromString(this.csvContent).then(objArray => {
          this.submitObj.company_id = JSON.parse(sessionStorage.getItem('login_company'));
          this.submitObj.data = objArray;
          this.httpService.postRequest<ResponseContainer>(this.submitObj, 'admin/createEmployees').subscribe(datas => {
            if (datas.rsBody.result == 'success') {
              this.submitObj = {};
              this.bulkUploadRejected = datas.rsBody.rejected;
              this.bulkUploadSelected = datas.rsBody.success.length;
              this.ngOnInit();
              this.dismissUpload()
              if (this.bulkUploadRejected.length)
              {
                var table = `<tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Mobile No</th>
                <th>MGR ID</th>
                <th>Indirect MGR ID</th>
                <th>Reason</th>
            </tr>`
                this.bulkUploadRejected.forEach(element => {
                  table += `<tr>
                  <th style="margin-left : 10px;">${element.first_name}</th>
                  <th style="margin-left : 10px;">${element.last_name}</th>
                  <th style="margin-left : 10px;">${element.email_id}</th>
                  <th style="margin-left : 10px;">${element.mobile_no}</th>
                  <th style="margin-left : 10px;">${element.mgr_id}</th>
                  <th style="margin-left : 10px;">${element.indirect_mgr_id}</th>
                  <th style="margin-left : 10px;">${element.error}</th>
              </tr>`
                });
                $('#report-table').append(table)
              }
              $("#report").modal('show');

            }
            else {
              this.validationHandler.displayErrors(datas.rsBody.errors, "profileupdateBasic", null);
              this.dismissUpload()
            }
          });
        })
        
      }

    } catch (error) {

    }
  }

  onFocus() {
    this.validationHandler.hideErrors("profile-submit");
  }

  /* 
 * back Employee page
 */
  backEmployee() {
    this.router.navigate(['/admin/employee-adm']);
  }


  /**
    * Convert into base 64
   */
  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    this.submitObj['imagePath'] = reader.result;
    //console.log(this.imageSrc);
  }
  removeImage() {
    this.submitObj['imagePath'] = null;
  }

}