import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';

import {ResponseContainer} from '../../../service/ResponseContainer';
import {BaseContainer} from '../../../service/BaseContainer';
import {RequestContainer} from '../../../service/RequestContainer'; 
import {ValidationHandler} from '../../../service/ValidationHandler'; 

import * as $ from 'jquery';
declare var $ :any;  
@Component({
  selector: 'app-assign-badge-trn',
  templateUrl: 'assign-badge-trn.component.html',
  providers : [HttpService,ValidationHandler]   

})
export class AssignBadgeTrnComponent implements OnInit {


  public submitObj:any = {}; 
  public targetIndex:Object = {};
  public setPageNo:boolean = false; 
  public setPageNoNext:boolean = false;
  public pageNo:any = {};
  public routeUrl:Object = {}; 
  public responseData:any = {};  
  public id:any = {}; 
  public currentUser:any = {}; 
  public employee_id:any = {}; 



  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;
 
  constructor(
    private router:Router,
     private httpService : HttpService, 
     private route: ActivatedRoute,
     private validationHandler : ValidationHandler

   ) 
   {}  
  ngOnInit(): void { 
    $('.modal ').insertAfter($('body')); 
    $('.count').each(function () { 
      $(this).prop('Counter',0).animate({
          Counter: $(this).text() 
          }, {
              duration: 4000,
              easing: 'swing',
              step: function (now) { 
                  $(this).text(Math.ceil(now));
              }
          });
      });
    var requestObj = new RequestContainer();
    requestObj['pageNo'] = 0;
    this.pageNo = requestObj['pageNo'];   
    this.submitObj.empId = JSON.parse(sessionStorage.getItem('login_user_id')); 
    this.currentUser = JSON.parse(sessionStorage.getItem('login_user_id')); 
    this.submitObj.employee_id  =  this.route.snapshot.paramMap.get('id');
    this.employee_id  =  this.route.snapshot.paramMap.get('id'); 
  
    //this.responseData = datas.rsBody.data;  
    this.httpService.postRequest<ResponseContainer>(this.submitObj,'trainer/getAssignedBadge').subscribe(
      datas=> {
        if(datas.rsBody.result == 'success') 
        {  
          this.responseData = datas.rsBody.data;  
        } 
        else{
          console.log(datas.rsBody.error);
        } 
      });  
  }

  /* 
 * Assigne badge to employee page 
 */
  selectBadge(skill_id, badge_id){  
    this.submitObj.skillId = skill_id;
    this.submitObj.badgeId = badge_id;
    this.submitObj.employee_id =  Number(this.route.snapshot.paramMap.get('id'));
    this.httpService.postRequest<ResponseContainer>(this.submitObj,'trainer/selectBadge').subscribe(
    datas=> { 
      if(datas.rsBody.result == 'success') 
      { 
        var backcolor = '.assign_badge div'; 
        $(backcolor).on('click', function(){ 
            $(backcolor).removeClass('active-bg');
            $(this).addClass('active-bg'); 
        });  
      } 
      else{
        var backcolor = '.assign_badge div'; 
        $(backcolor).on('click', function(){ 
            $(backcolor).removeClass('active-bg');
            $(this).addClass('active-bg');
        }); 
        this.validationHandler.displayErrors(datas.rsBody.errors,"profileupdateBasic",null);
      } 
    });  
    
  }

  /*   
 * Assigne badge to employee page   
 */
assignBadge(skill_id, badge_id){ 
  this.submitObj.skillId = skill_id;
  this.submitObj.badgeId = badge_id;
  this.submitObj.empId = JSON.parse(sessionStorage.getItem('login_user_id')); 
  this.submitObj.employee_id =  Number(this.route.snapshot.paramMap.get('id'));
  this.httpService.postRequest<ResponseContainer>(this.submitObj,'trainer/assignTask').subscribe(
    datas=> { 
      if(datas.rsBody.result == 'success') 
      { 
        $("#changesubmit").modal('show');  
      } 
      else{
        this.validationHandler.displayErrors(datas.rsBody.errors,"profileupdateBasic",null);
      } 
    });  
}

  /* 
 * Assigne badge to employee page 
 */
refresh(){ 
  $("#changesubmit").modal('hide');  
  this.ngOnInit();
}

 
backEmpList(){
  this.router.navigate(['/trainers/employee-list-ass-trn']);
}

backEmpProfile(){
  this.id  =  this.route.snapshot.paramMap.get('id');
  this.router.navigate(['/trainers/employee-profile-trn',this.id]);
}
  
onFocus()
{
    this.validationHandler.hideErrors("profile-submit");
}


}
 