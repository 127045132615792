import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth.component';
import { ForgotPasswordComponent } from './component/forgotpassword/forgotpassword.component';
const routes: Routes =[
    {
                 path: 'auth',
                component: AuthComponent,
                //  children: [
                //     { path: '', component: HomeComponent },
                //  { path: 'home', component: HomeComponent },
                //     { path: 'about-us', component: AboutUsComponent }
                // ]
            },
            {
                path : 'forgot-password',
                component: ForgotPasswordComponent
            }
];

export const Authrouting = RouterModule.forRoot(routes);

