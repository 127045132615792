<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item" (click)="backEmployee();"><a href="javascript:void(0);">Employee</a>
                        </li>
                        <li class="breadcrumb-item active" *ngIf='setCreate'><a href="javascript:void(0);">Create New
                                Employee</a></li>
                        <li class="breadcrumb-item active" *ngIf='setUpdate'><a href="javascript:void(0);">Update
                                Employee</a></li>
                    </ol>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer position-relative">
                        <div class="title">
                            <h4>Add Employee</h4>
                        </div>

                        <div class="d-v-center">
                            <span class="btn btn-secondary title" (click)="downloadTemplate();">
                                <i class="fa fa-download"></i> Template
                            </span>
                            <span style="padding-left: 20px; padding-right: 10px; margin-left: 10px;" class="btn btn-secondary title"
                                (click)="openUploadCSVDialog();">
                                <i class="fa fa-upload"></i> CSV
                            </span>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="row" id="profile-submit">
            <div class="create-n-ctg col-md-12 mr-auto ml-auto">
                <div class="card mt-3">
                    <div class="card-body">
                        <form class="form" id="profileupdateBasic" role="form" autocomplete="off" #catForm="ngForm">
                            <div class="row">
                                <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">First Name *</label>
                                    <div>
                                        <input class="form-control" type="text" value=""
                                            placeholder="Enter Employee first name" name="first_name" id="first_name"
                                            [(ngModel)]="submitObj.first_name" checkMandatory="true"
                                            (focus)="onFocus()">
                                        <span class="ng-hide displayError" id="first_name_error"></span>

                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">Last Name *</label>
                                    <div>
                                        <input class="form-control" type="text" value="" name="last_name"
                                            placeholder="Enter Employee last name" id="last_name"
                                            [(ngModel)]="submitObj.last_name" checkMandatory="true" (focus)="onFocus()">
                                        <span class="ng-hide displayError" id="last_name_error"></span>

                                    </div>
                                </div>
                                <!-- <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">User ID *</label>
                                    <div>
                                        <input class="form-control" type="text" value="" maxlength="8" placeholder="0000000" name="user_id" id="user_id" [(ngModel)]="submitObj.user_id" checkMandatory="true" (focus)="onFocus()">
                                        <span class="ng-hide displayError" id="user_id_error"></span>
    
                                    </div>
                                </div>    -->
                                <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">Email-ID *</label>
                                    <div>
                                        <input class="form-control" type="text" value="" name="email_id"
                                            placeholder="Enter Employee email_id" id="email_id"
                                            [(ngModel)]="submitObj.email_id" checkMandatory="true" (focus)="onFocus()">
                                        <span class="ng-hide displayError" id="email_id_error"></span>

                                    </div>
                                </div>
                                <!-- <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">Mobile No. *</label>
                                    <div>
                                        <input class="form-control" type="text" value="" placeholder="Enter mobile no."
                                            name="mobile_no" id="mobile_no" [(ngModel)]="submitObj.mobile_no"
                                            checkMandatory="true" (focus)="onFocus()">
                                        <span class="ng-hide displayError" id="mobile_no_error"></span>

                                    </div>
                                </div> -->
                                <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">Password *</label>
                                    <div>
                                        <input class="form-control" type="text" value="" name="password"
                                            placeholder="Enter Employee password" id="password"
                                            [(ngModel)]="submitObj.password" checkMandatory="true" (focus)="onFocus()">
                                        <span class="ng-hide displayError" id="password_error"></span>

                                    </div>
                                </div>
                                <!-- <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">Team Leader unique id</label>
                                    <div>
                                        <input class="form-control" type="text" value=""
                                            placeholder="Enter manager unique id" name="mgr_id" id="mgr_id"
                                            [(ngModel)]="submitObj.mgr_id">
                                        <span class="ng-hide displayError" id="mgr_id_error"></span>

                                    </div>
                                </div> -->
                                <!-- <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">Super Team Leader unique id</label>
                                    <div>
                                        <input class="form-control" type="text" value="" name="indirect_mgr_id"
                                            placeholder="Enter Super leader unique id (indirect_mgr_id)"
                                            id="indirect_mgr_id" [(ngModel)]="submitObj.indirect_mgr_id">
                                        <span class="ng-hide displayError" id="indirect_mgr_id_error"></span>

                                    </div>
                                </div> -->
                                <!-- <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">Address *</label>
                                    <div>
                                        <input class="form-control" type="text" value="" placeholder="Enter address"
                                            name="address" id="address" [(ngModel)]="submitObj.address"
                                            checkMandatory="true" (focus)="onFocus()">
                                        <span class="ng-hide displayError" id="address_error"></span>

                                    </div>
                                </div> -->
                                <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">Job Title</label>
                                    <div>
                                        <input class="form-control" type="text" value="" name="role"
                                            placeholder="Enter Employee Job Title" id="role"
                                            [(ngModel)]="submitObj.role"  (focus)="onFocus()">
                                        <span class="ng-hide displayError" id="region_error"></span>

                                    </div>
                                </div>
                                <!-- <div class="form-group col-6" id="imagePath">
                                <label class="col-form-label form-control-label">Catgory Icon:</label>
                                <div>
                                    <div id="collapseOne" class="panel-collapse collapse show" role="tabpanel" aria-labelledby="headingOne">
                                        <div>
                                          <label for="" *ngIf="submitObj.imagePath != null">
                                            <img [src]="submitObj.imagePath" alt="" style="height: 100px !important; width: 100px !important;" /><br>
                                            <a href="javascript:void(0);" class="text-danger" (click)="removeImage();"><i class="fa fa-trash-o" aria-hidden="true"></i> Remove Employee Icon</a>
                                          </label>
                                            <label *ngIf="submitObj.imagePath == null" for="profile-image-upload" class="form-control text-primary" style="cursor: pointer;"><i class="ti-gallery mr-2"></i>Set Employee Image
                                                <input id="profile-image-upload" type="file" class="form-control btn btn-info" accept="image/*" name="profilePhoto" (change)="handleInputChange($event)" style="display: none;" />
                                            </label>
                                        </div> 
                                    </div> 
                                    <span class="ng-hide displayError" id="imagePath_error"></span>

                                </div> 
                            </div> -->
                                <div class="form-group col-6">
                                    <div class="float-right mt-5">
                                        <div class="d-v-center">
                                            <input *ngIf='setCreate' type="reset" class="btn btn-secondary mr-2"
                                                value="Cancel" (click)="backEmployee();">
                                            <input *ngIf='setCreate' type="submit" class="btn btn-warning"
                                                (click)="createSubmit();" value="Save">
                                            <input *ngIf='setUpdate' type="submit" class="btn btn-secondary"
                                                (click)="updateSubmit();" value="Update">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>




<!--upload-csv-modal-->
<div class="modal fade" id="uploadCSV" role="dialog" aria-labelledby="trash-mdl" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content">

            <div id="csvselect" class="modal-body text-center ">
                <div>
                    <div id="csvfile">

                        <div class="title">Select CSV File</div>

                        <input id="csvinput" style="margin-top: 20px;" type="file" accept=".csv"
                            (change)="uploadCSV($event.target.files)">
                    </div>
                    <div id="uploading" class="title">Adding Employees from CSV</div>
                </div>

            </div>

            <div class="mdl-footer d-v-center">
                <button class="btn btn-black btn-block m-0" (click)="dismissUpload();">Dismiss</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="report" role="dialog" aria-labelledby="trash-mdl" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div id="csvreport" class="modal-body text-center" style="width: 100%;">
                <div class="title">
                    {{this.bulkUploadSelected}} Employees Added
                </div>
                <div id="rejected">
                    <div class="title" style="margin: 10px;">{{this.bulkUploadRejected.length}} Entries Rejected</div>
                    <table id="report-table" style="width: 100%; overflow-y: scroll; max-height: 20px;">
                    </table>
                </div>
            </div>

            <div class="mdl-footer d-v-center">
                <button class="btn btn-black btn-block m-0" (click)="dismissReport();">Dismiss</button>
            </div>
        </div>
    </div>
</div>

<!--successsfull-modal-->
<div class="modal fade" id="changesubmit" role="dialog" aria-labelledby="trash-mdl" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body text-center">
                <p>Submission Successfull</p>
            </div>
            <div class="mdl-footer d-v-center">
                <button class="btn btn-black btn-block m-0" data-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>