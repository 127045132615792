<style>
.login-container {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-image: url(../../assets/img/loginbg.jpg);
    position: relative;
    background-size: cover;
    background-position: center;
    /* background-color: #0198CD; */
}
.login-container::after{
content: '';
position:absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
 background-color: rgba(255,255,255,0);
}
.login-wrap {
    width: 50rem;
    display:flex;
    border-radius: 10px;
    box-shadow: 1px 1px 14px 9px rgba(0,0,0,0.3);
    position: relative;
    z-index: 9;
    overflow: hidden;
}

.login-form {
    background: #fff;
    width: 100%;
    padding: 2rem;
}
.input-icon {
    position: relative;
}
.input-icon span {
    position: absolute;
    text-align: center;
    top: 9%;
    left: 4%;
}
.input-icon span svg {
    width: 1.2rem;
}
.input-icon .form-control{
    padding-left:40px;
}
.l_logo{
    width: 7.5rem;
} 
.bg-img{
    width: 70rem;
}
.bg-img img{
width: 100%;
height: 100%;
object-fit: contain;
background-color: #fff;
}
.txt_fadein {
    animation: fadein 2s;
    -moz-animation: fadein 2s; /* Firefox */
    -webkit-animation: fadein 2s; /* Safari and Chrome */
    -o-animation: fadein 2s; /* Opera */
    line-height: 1.15;
}
@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;  
    }
    to {
        opacity:1;                             
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}
.btn-ats{
    background-color: #17256b;
    color: #fff;
}

.plogintxt{
    font-size: 75%;
}

@media(max-width:660px) {
    .bg-img {
    display:none;
}
input[type="text"], input[type="number"], input[type="email"], input[type="tel"], input[type="password"], input.form-control, select, textarea.form-control {
    font-size:1rem;
    height: 3.5rem;
}
.input-icon span {
    top: 17%;
}
}
</style> 
<div class="login-container" id="profile-submit">
    <div class="login-wrap">
        <div class="login-form"> 
          <img src="../../assets/img/Atlas_Copco_logo.png" alt="atlas-copco" class="l_logo">
          <h4 class="txt_fadein">Welcome, <br><small class="plogintxt">Please Login to your Account.</small></h4>
          <form class="form mt-4" id="profileupdateBasic" role="form" autocomplete="off" #catForm="ngForm">
              <div class="form-group input-icon">
                <input class="form-control" type="email" value="" placeholder="Enter your email" name="email" id="email" [(ngModel)]="submitObj.email" checkMandatory="true" (focus)="onFocus()">
                <span><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" class="mt-2 w-8 h-8 stroke-current text-gray-100"><path d="M3 8L10.8906 13.2604C11.5624 13.7083 12.4376 13.7083 13.1094 13.2604L21 8M5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19Z" stroke="#4A5568" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg></span>  
              </div>
              <span class="ng-hide displayError" id="email_error"></span>
                <div class="form-group input-icon">
                  <span><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" class="mt-2 w-8 h-8 stroke-current text-gray-100"><path d="M12 15V17M6 21H18C19.1046 21 20 20.1046 20 19V13C20 11.8954 19.1046 11 18 11H6C4.89543 11 4 11.8954 4 13V19C4 20.1046 4.89543 21 6 21ZM16 11V7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7V11H16Z" stroke="#4A5568" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg> 
                  </span>
                    <input class="form-control" type="password" value="" placeholder="Enter your password" name="password" id="password" [(ngModel)]="submitObj.password" checkMandatory="true" (focus)="onFocus()">
                </div> 
                <span class="ng-hide displayError" id="password_error"></span>
               <div class="text-right">
                 <a href="/forgot-password" ><small>Forget password <i class="ti-help-alt"></i></small></a>
               </div>
              <div class="form-group">
                  <div class="d-v-center">
                      <input type="submit" class="btn btn-ats" (click)="createSubmit();" value="Login">
                  </div>
              </div>
                <!-- <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div> -->
            </form>
        </div>
        <div class="bg-img">
            <img src="../../assets/img/team.jpg">
        </div>
    </div>
</div>

 
 