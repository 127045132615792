import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF } from '@angular/common';
import { HttpService } from '../service/http.service';
import { HttpClientModule } from '@angular/common/http'; 
import { RouterModule, Routes } from '@angular/router';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { FormsModule }   from '@angular/forms';
import { NgModule } from '@angular/core'; 
import {NgxPaginationModule} from 'ngx-pagination';

import { EmployeeRouting } from './employee.routing'
import { EmployeeComponent } from './employee.component';
import { SidebarEmpComponent } from './component/sidebar-emp/sidebar-emp.component';
import { HeaderModule } from '../header/header.module';
import { DashboardEmpComponent } from './component/dashboard-emp/dashboard-emp.component';
import { HistoryEmpComponent } from './component/history-emp/history-emp.component';
import { LearningPathwaysEmpComponent } from './component/learning-pathways-emp/learning-pathways-emp.component';
import { MypathwaysEmpComponent } from './component/mypathways-emp/mypathways-emp.component';
import { AddpathwayEmpComponent } from './component/add-pathway-emp/add-pathway-emp.component';
import { NotificationEmpComponent } from './component/notification-emp/notification-emp.component';
import { Task2EmpComponent } from './component/task2-emp/task2-emp.component';
import { TasklistEmpComponent } from './component/tasklist-emp/tasklist-emp.component';
import { EditProfileAdmComponent } from './component/edit-profile-adm/edit-profile-adm.component';
import { ActivitiesComponent } from './component/activities/activities.component';


@NgModule({
  declarations: [
    EmployeeComponent,
    SidebarEmpComponent,
    DashboardEmpComponent,
    HistoryEmpComponent,
    LearningPathwaysEmpComponent,
    MypathwaysEmpComponent,
    AddpathwayEmpComponent,
    NotificationEmpComponent,
    Task2EmpComponent,
    TasklistEmpComponent,
    EditProfileAdmComponent,
    ActivitiesComponent
  ],
  imports: [
    BrowserModule,
    HeaderModule, 
    HttpClientModule,
    FormsModule,
    AutocompleteLibModule,
    NgxPaginationModule,
   // NgxQRCodeModule,
    EmployeeRouting, 
    HeaderModule,
    NgCircleProgressModule.forRoot({})
  ],
  providers: [{provide: APP_BASE_HREF, useValue : '' },HttpService],
  bootstrap: [EmployeeComponent]
})
export class EmployeeModule { }
