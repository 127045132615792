import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-task-complete-trn',
  templateUrl: 'task-complete-trn.component.html'
})
export class TaskCompleteTrnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
