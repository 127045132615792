<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Stop Training</a></li>
                    </ol>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                        <div class="title">
                            <h4>Stop Training</h4>
                        </div>
                    </div>

                    
                    <div class="st-trainig">
                    <div>
                        <img src="../../../assets/img/timer.png" alt="timer">
                        <p>Lorem ipsum dolor sit amet</p>
                        </div>
                    </div>
                </div>
                
                <div class="text-center mt-3">
                <a href="#" class="btn btn-yellow m-btn" routerLink="/trainers/training-complete-trn">Start Training</a>
                </div>
                
                
            </div>
        </div>
    </div>
</section>
