import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SuperTeamleadComponent } from './superteamlead.component';
import { DashboardSuperTldComponent } from './component/dashboard-super-tld/dashboard-super-tld.component'; 
import { EmployeeListSuperTldComponent } from './component/employee-list-super-tld/employee-list-super-tld.component';
import { HistorySuperTldComponent } from './component/history-super-tld/history-super-tld.component';
import { LearningPathwaysSuperTldComponent } from './component/learning-pathways-super-tld/learning-pathways-super-tld.component';
import { AddpathwaySuperTldComponent } from './component/add-pathway-super-tld/add-pathway-super-tld.component';
import { MyteamSuperTldComponent } from './component/myteam-super-tld/myteam-super-tld.component';
import { NotificationSuperTldComponent } from './component/notification-super-tld/notification-super-tld.component';
import { TaskListSuperTldComponent } from './component/task-list-super-tld/task-list-super-tld.component';
import { TaskSListSuperTldComponent } from './component/tasklist-super-tld/tasklist-super-tld.component';
import { Task2SuperTldComponent } from './component/task2-super-tld/task2-super-tld.component'; 
import { Task2SelfSuperTldComponent } from './component/task2-self-super-tld/task2-self-super-tld.component';
import { TechnicalSuperTldComponent } from './component/technical-super-tld/technical-super-tld.component';
import { EditProfileAdmComponent } from './component/edit-profile-adm/edit-profile-adm.component';

const routes: Routes = [
    { 
        path: 'superteamlead', 
        component: SuperTeamleadComponent,
        children: [
            { path: '', component: DashboardSuperTldComponent },
            { path: 'dashboard-tld', component: DashboardSuperTldComponent },
            { path: 'employee-list-tld/:skillId/:badgeId', component: EmployeeListSuperTldComponent },
            { path: 'history-tld', component: HistorySuperTldComponent },
            { path: 'learning-pathways-tld', component: LearningPathwaysSuperTldComponent },
            { path: 'add-pathway-tld', component: AddpathwaySuperTldComponent },
            { path: 'myteam-tld', component: MyteamSuperTldComponent },
            { path: 'notification-tld', component: NotificationSuperTldComponent },
            { path: 'task-list-tld/:skillId/:badgeId/:trainerId/:employeeId', component: TaskListSuperTldComponent },
            { path: 'tasklist-tld/:trainerId/:skillId/:badgeId', component: TaskSListSuperTldComponent },
            { path: 'task2-tld/:taskId/:employeeId', component: Task2SuperTldComponent },
            { path: 'task2-self-tld/:taskId', component: Task2SelfSuperTldComponent },
            { path: 'technical-tld/:id', component: TechnicalSuperTldComponent },
            { path: 'edit-profile-tld', component: EditProfileAdmComponent }
        ]
    }
]; 

export const SuperTeamleadRouting = RouterModule.forRoot(routes);