import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scan-employee-trn', 
  templateUrl: 'scan-employee-trn.component.html'
})
export class ScanEmployeeTrnComponent implements OnInit {
  // ngxQrcode2 = 'https://www.npmjs.com/package/ngx-qrcode2';
  // techiediaries = 'https://www.npmjs.com/package/~techiediaries';
  // letsboot = 'https://www.letsboot.com';
  constructor() { }

  ngOnInit(): void {
  }

}
