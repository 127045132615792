<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">My Training</a></li>
                    </ol>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                        <div class="title">
                            <h4>My Training</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row badge-view-wrapper assign-m-card">
            <div class="col-md-12">
            <h5 class="title">Badge List:</h5>

                <ul class="badge-overview d-v-center mt-3" *ngFor="let item of responseData let i=index">
                <li  *ngIf="item.empId_1 == currentUser">
                    <div class="card btn-brd">
                        <div class="card-body skill-set">
                            <div class="c_bar b-100">
                                <circle-progress [percent]="100" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#0078a1'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'transparent'" [class]="'pgrsbar'" [imageSrc]="item.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                                </circle-progress>
                            </div>
                            <div class="prg_content mt-3">
                                    <!-- <h5 class="mb-2">Beginner</h5> -->
                                    <span class="ctskilnm">{{item.cat_skill_name}}</span>
                                    <span class="ctnme">{{item.cat_name}}</span>
                           </div>
                        </div>
                    </div>
                </li>

                    <li *ngIf="item.empId_2 == currentUser">
                        <div class="card btn-brd">
                            <div class="card-body skill-set">
                                <div class="c_bar i-100">
                                    <circle-progress [percent]="100" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#e5c634'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'transparent'" [class]="'pgrsbar'" [imageSrc]="item.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                                    </circle-progress>
                                </div>
                                <div class="prg_content mt-3">
                                        <!-- <h5 class="mb-2">Intermediate</h5> -->
                                        <span  class="ctskilnm">{{item.cat_skill_name}}</span>
                                        <span class="ctnme">{{item.cat_name}}</span>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li *ngIf="item.empId_3 == currentUser">
                        <div class="card btn-brd">
                            <div class="card-body skill-set">
                                <div class="c_bar e-100">
                                    <circle-progress [percent]="100" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#62a006'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'transparent'" [class]="'pgrsbar'" [imageSrc]="item.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                                    </circle-progress>
                                </div>
                                <div class="prg_content mt-3">
                                        <!-- <h5 class="mb-2">Expert</h5> -->
                                        <span class="ctskilnm">{{item.cat_skill_name}}</span>
                                        <span class="ctnme">{{item.cat_name}}</span>
                                    </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <!--No data Found start-->
                  <div class="w-100" *ngIf='responseData.length == 0'>
                      <div class="text-center" role="not found">
                          <i class="fa fa-exclamation-circle fa-5x" aria-hidden="true"></i>
                          <p class="mt-2">No Badges Found</p>
                      </div>
                  </div>
               <!--No data found end--> 
            </div>
        </div>
    </div>
</section>
