<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Category</a></li>
                    </ol>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                    <div class="title">
                        <h4>Categories</h4> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="c-grid" *ngIf='responseData.length > 0'>
            <div *ngFor="let item of responseData let i=index"> 
                <a href="javascript:void(0);" (click)="listSkill(item.cat_id);" class="card cat-adm" >
                    <div class="card-body skill-set" >
                        <img src="{{item.cat_icon}}">  
                        <h5>{{item.cat_name}}</h5> 
                        <!-- <p class="m-0">10 Skill Available</p> -->
                    </div>
                </a> 
            </div>    
            <!-- <a href="#" routerLink="/trainers/skills-trn" class="card">
            <div class="card-body skill-set">
                <img src="../../../assets/img/skills.svg">
                <h5>Adavance Product Quality</h5>
                <p class="m-0">10 Skill Available</p>
                </div>  
            </a> -->
        </div> 
        <div *ngIf='responseData.length == 0'>
            <strong>No Data Found</strong>
        </div>
        <div class="mr-auto ml-auto">
            <nav class="pagination-wrap">
                <ul class="pagination">
                    <li class="page-item" (click)="previousSubmit();">
                        <a *ngIf='setPageNo' class="page-link" href="javascript:void(0);" aria-label="Previous">
                            <span aria-hidden="true">«</span>
                            <span class="sr-only">Previous</span>
                        </a>
                    </li>
                    <li class="page-item" (click)="nextSubmit();">
                        <a *ngIf='setPageNoNext' class="page-link" href="javascript:void(0);" aria-label="Next">
                            <span aria-hidden="true">»</span>
                            <span class="sr-only">Next</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
        <!-- <div class="mr-auto ml-auto">
            <nav class="pagination-wrap">
                <ul class="pagination">
                    <li class="page-item">
                        <a class="page-link" href="#" aria-label="Previous">
                            <span aria-hidden="true">«</span>
                            <span class="sr-only">Previous</span>
                        </a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item">
                        <a class="page-link" href="#" aria-label="Next">
                            <span aria-hidden="true">»</span>
                            <span class="sr-only">Next</span>
                        </a>
                    </li>
                </ul>
            </nav> 
        </div> -->
        
    </div>
</section>