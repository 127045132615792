import { Component, OnInit, Renderer2 } from '@angular/core';
import * as $ from 'jquery'
@Component({
    selector: 'app-header',
    templateUrl: 'header.component.html'
})
export class HeaderComponent implements OnInit {

  constructor(private renderer: Renderer2) { }

  addClass() {
    this.renderer.addClass(document.body, 'darkmode');
    localStorage.setItem('darkmode', 'true');
  }
  removeClass() {
    this.renderer.removeClass(document.body, 'darkmode');
    localStorage.setItem('darkmode', 'false');
  }
  
  ngOnInit(): void {
      if(localStorage.getItem('darkmode') === 'true') {
        this.renderer.addClass(document.body, 'darkmode');
      }

    $('.modal ').insertAfter($('body'));

    //toggle-desktop
    var navbar_toggler = document.querySelector('.navbar-toggler');
    var sidebar_nav = document.querySelector('.sidebar');
    var m_wrapper = document.querySelector('.main');
    var m_overlay = document.querySelector('.overlay');

    navbar_toggler.addEventListener('click', function() {
      sidebar_nav.classList.toggle('toggle-left');
      m_wrapper.classList.toggle('main-toggel');
    });

      //toggle-mobile
      var navbar_toggler_mob = document.querySelector('.navbar-toggler-mob');
    
      navbar_toggler_mob.addEventListener('click', function() {
        sidebar_nav.classList.toggle('mob-toggle');
        m_wrapper.classList.toggle('mob-toggle-main');
      });
  }

}
