<app-header></app-header>
<div class="app-body">
    <div class="sidebar mob-toggle">
        <app-sidebar-emp></app-sidebar-emp>
    </div>

    <main class="main mob-toggle-main">
        <div class="container-fluid">
            <router-outlet></router-outlet>
        </div>
    </main>
</div>
