
import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';

import {ResponseContainer} from '../../../service/ResponseContainer';
import {BaseContainer} from '../../../service/BaseContainer';
import {RequestContainer} from '../../../service/RequestContainer';
import {ValidationHandler} from '../../../service/ValidationHandler'; 

import * as $ from 'jquery'
declare var $ :any;

@Component({
  selector: 'app-myteam-tld',
  templateUrl: 'myteam-tld.component.html', 
  styleUrls: ['myteam-tld.component.html'],
  providers : [HttpService,ValidationHandler]   

})
export class MyteamTldComponent implements OnInit {

  public submitObj:any = {};
  public targetIndex:Object = {};
  public setPageNo:boolean = false;
  public setPageNoNext:boolean = false;
  public pageNo:any = {};
  public setPageNo1:boolean = false;
  public setPageNoNext1:boolean = false;
  public pageNo1:any = {}
  public routeUrl:Object = {};
  public responseData:any = {};
  public responseData1:any = {};
  public skillList:any = {};
  public keyword:any = {};  
  public badgeList:any = {};
  public keyword1:any = {};  
  public id:any = {};

  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;

  constructor(
    private router:Router,
     private httpService : HttpService,
     private validationHandler : ValidationHandler ,
     private route: ActivatedRoute
   )
  {}

  selectEvent(item) {
    this.id = item.id;
    this.submitObj.cat_skill_id = item.id;
    this.submitObj.cat_skill_name = item.cat_skill_name;
    var currentUser = JSON.parse(sessionStorage.getItem('login_user_id'));
    this.submitObj.emp_id = currentUser;  
    //start
    var requestObj = new RequestContainer();
    requestObj['pageNo'] = 0;
    this.pageNo = requestObj['pageNo'];
    requestObj['pageNo1'] = 0;
    this.pageNo1 = requestObj['pageNo1'];
    requestObj['platform'] = "Web";
    requestObj['empId'] =  currentUser;
    this.submitObj.empId =  currentUser;
      this.httpService.postRequest<ResponseContainer>(requestObj,'teamlead/getCategory').subscribe(
        datas=> {
          if(datas.rsBody.result == 'success')
          {
            this.responseData = datas.rsBody.data;
            this.responseData = datas.rsBody.data;
            if(this.responseData.length == 2)
            {
                this.setPageNoNext = true;
            }
            else
            {
                this.setPageNoNext = false;
            }
            if(this.pageNo == 0)
            {
                this.setPageNo = false;
            }
            else
            {
                this.setPageNo = true;
            }
          }
          else{
            console.log(datas.rsBody.error);
          }
        }); 
        this.httpService.postRequest<ResponseContainer>(this.submitObj,'teamlead/getSkillSeacrh').subscribe(
          datas=> { 
            if(datas.rsBody.result == 'success')
            {
              this.responseData1 = datas.rsBody.data;
            }
            else
            { 
              this.validationHandler.displayErrors(datas.rsBody.errors,"profileupdateBasic",null);
            }
          });
    //end  
  }
  
  onChangeSearch(val: string) {
  }
  
  onFocused(e){
   // this.validationHandler.hideErrors("profile-submit");
  } 

  selectEvent1(item) {
    this.id = item.id;
    this.submitObj.badge_id = item.id;
    //start
    var currentUser = JSON.parse(sessionStorage.getItem('login_user_id'));
    this.submitObj.emp_id = currentUser;  
    //start
    var requestObj = new RequestContainer();
    requestObj['pageNo'] = 0;
    this.pageNo = requestObj['pageNo'];
    requestObj['pageNo1'] = 0;
    this.pageNo1 = requestObj['pageNo1'];
    requestObj['platform'] = "Web";
    requestObj['empId'] =  currentUser;
    this.submitObj.empId =  currentUser;
    if(this.submitObj.cat_skill_id){
      console.log('cc');
      
      this.httpService.postRequest<ResponseContainer>(requestObj,'teamlead/getCategory').subscribe(
        datas=> {
          if(datas.rsBody.result == 'success')
          {
            this.responseData = datas.rsBody.data;
            this.responseData = datas.rsBody.data;
            if(this.responseData.length == 2)
            {
                this.setPageNoNext = true;
            }
            else
            {
                this.setPageNoNext = false;
            }
            if(this.pageNo == 0)
            {
                this.setPageNo = false;
            }
            else
            {
                this.setPageNo = true;
            }
          }
          else{
            console.log(datas.rsBody.error);
          }
        });
        this.httpService.postRequest<ResponseContainer>(this.submitObj,'teamlead/getEmployeeSeacrh').subscribe(
          datas=> { 
            if(datas.rsBody.result == 'success')
            {
              this.responseData1 = datas.rsBody.data;
            }
            else
            { 
              this.validationHandler.displayErrors(datas.rsBody.errors,"profileupdateBasic",null);
            }
          });
    }else{
      console.log('dd');
      
      this.httpService.postRequest<ResponseContainer>(requestObj,'teamlead/getCategory').subscribe(
        datas=> {
          if(datas.rsBody.result == 'success')
          {
            this.responseData = datas.rsBody.data;
            this.responseData = datas.rsBody.data;
            if(this.responseData.length == 2)
            {
                this.setPageNoNext = true;
            }
            else
            {
                this.setPageNoNext = false;
            }
            if(this.pageNo == 0)
            {
                this.setPageNo = false;
            }
            else
            {
                this.setPageNo = true;
            }
          }
          else{
            console.log(datas.rsBody.error);
          }
        }); 
        this.httpService.postRequest<ResponseContainer>(this.submitObj,'teamlead/getBadgeSeacrh').subscribe(
          datas=> { 
            if(datas.rsBody.result == 'success')
            {
              this.responseData1 = datas.rsBody.data;
            }
            else
            { 
              this.validationHandler.displayErrors(datas.rsBody.errors,"profileupdateBasic",null);
            }
          });
    }
      
    //end
  }
 
  onChangeSearch1(val: string) {
  }
  
  onFocused1(e){
   // this.validationHandler.hideErrors("profile-submit");
  } 


  ngOnInit(): void {

    //slider
    $(document).ready(function() {
      $('button').click(function() {
        $(this).parents('.sliderComplete').children('button').removeClass('close').fadeIn(300);

        // The button, that was visible, goes on display none.
        $(this).addClass('close').fadeOut(300);

        // We do a fluid slider with the class '.turn'.
        $(this).parents('.sliderComplete').children('.wrapper').children('.slider').toggleClass('turn');
      });
    });
    //slider
    $('.count').each(function () {
      $(this).prop('Counter',0).animate({
          Counter: $(this).text()
          }, {
              duration: 4000,
              easing: 'swing',
              step: function (now) {
                  $(this).text(Math.ceil(now));
              }
          });
      });
    var requestObj = new RequestContainer();
    requestObj['pageNo'] = 0;
    requestObj['pageNo1'] = 0;
    requestObj['platform'] = "Web";
    this.pageNo = requestObj['pageNo'];
    this.pageNo1 = requestObj['pageNo1']; 
    var currentUser = JSON.parse(sessionStorage.getItem('login_user_id'));
    requestObj['empId'] =  currentUser;
    this.httpService.postRequest<ResponseContainer>(requestObj,'teamlead/getCategory').subscribe(
      datas=> {
        if(datas.rsBody.result == 'success')
        {
          this.responseData = datas.rsBody.data;
          if(this.responseData.length == 2)
          {
              this.setPageNoNext = true;
          }
          else
          {
              this.setPageNoNext = false;
          }
          if(this.pageNo == 0)
          {
              this.setPageNo = false;
          }
          else
          {
              this.setPageNo = true;
          }
        } 
        else{
        }
      });
      this.httpService.postRequest<ResponseContainer>(requestObj,'teamlead/getEmployee').subscribe(
        datas=> {
          if(datas.rsBody.result == 'success')
          {
            this.responseData1 = datas.rsBody.data;
            if(this.responseData1.length == 5)
            {
                this.setPageNoNext1 = true;
            }
            else
            {
                this.setPageNoNext1 = false;
            }
            if(this.pageNo1 == 0)
            {
                this.setPageNo1 = false;
            }
            else
            {
                this.setPageNo1 = true;
            }
          }
          else{
            console.log(datas.rsBody.error);
          } 
        });
       this.catskillList();
       this.getBadgeList();

  }

    /* 
  * Badges List
  */
 getBadgeList() 
 {
       this.httpService.getAll<ResponseContainer>('teamlead/getBadgeList').subscribe(datas => {
       if(datas.rsBody.result == 'success')
       {
         this.keyword1 = 'badge_name';
         this.badgeList = datas.rsBody.data;
       }
      });
 } 


  catskillList(){
    this.id= JSON.parse(sessionStorage.getItem('login_company')); 
    this.httpService.postRequest<ResponseContainer>(this.id,'teamlead/getSkillList').subscribe(datas2 => {
      if(datas2.rsBody.result == 'success')
      {
        this.keyword = 'cat_skill_name';
        this.skillList = datas2.rsBody.data; 
      } 
     });
  }

 /*
* pagination next
*/
nextSubmit()
{
  var  requestObj = new RequestContainer();
  requestObj['pageNo'] = this.pageNo + 1;
  this.pageNo = requestObj['pageNo'];
  requestObj['platform'] = "Web";
  var currentUser = JSON.parse(sessionStorage.getItem('login_id'));
    requestObj['empId'] =  currentUser;
  this.httpService.postRequest<ResponseContainer>(requestObj,'admin/getCategory').subscribe(
  datas=> {
    this.responseData = datas.rsBody.data;
    if(this.responseData.length == 2)
    {
       this.setPageNoNext = true;
       this.setPageNo = true;
    }
    else
    {
       this.setPageNoNext = false;
       this.setPageNo = true;
    }
  });

}
/*
* pagination previous
*/
previousSubmit()
{
  var  requestObj = new RequestContainer();
  var currentUser = JSON.parse(sessionStorage.getItem('login_id'));
    requestObj['empId'] =  currentUser;
    requestObj['platform'] = "Web";
  if(this.pageNo == 0)
  {
    requestObj['pageNo'] = 0;
    this.pageNo = requestObj['pageNo'];
  }
  else
  {
    requestObj['pageNo'] = this.pageNo - 1;
    this.pageNo = requestObj['pageNo'];
  }

  this.httpService.postRequest<ResponseContainer>(requestObj,'admin/getCategory').subscribe(
  datas=> {
    this.responseData = datas.rsBody.data;
    if(this.responseData.length == 2)
    {
       this.setPageNoNext = true;
    }
    else
    {
       this.setPageNoNext = false;
       this.setPageNo = true;
    }
    if(this.pageNo == 0)
    {
       this.setPageNo = false;
    }
    else
    {
       this.setPageNo = true;
    }
  });

}

/*
* pagination next
*/
nextSubmit1()
{
  var  requestObj = new RequestContainer();
  requestObj['pageNo1'] = this.pageNo1 + 1;
  this.pageNo1 = requestObj['pageNo1'];
  requestObj['platform'] = "Web";
  var currentUser = JSON.parse(sessionStorage.getItem('login_user_id'));
    requestObj['empId'] =  currentUser;
  this.httpService.postRequest<ResponseContainer>(requestObj,'teamlead/getEmployee').subscribe(
  datas=> {
    this.responseData1 = datas.rsBody.data;
    if(this.responseData1.length == 5)
    {
       this.setPageNoNext1 = true;
       this.setPageNo1 = true;
    }
    else
    {
       this.setPageNoNext1 = false;
       this.setPageNo1 = true;
    }
  });

}
/*
* pagination previous
*/
previousSubmit1()
{
  var  requestObj = new RequestContainer();
  var currentUser = JSON.parse(sessionStorage.getItem('login_user_id'));
    requestObj['empId'] =  currentUser;
    requestObj['platform'] = "Web";

  if(this.pageNo == 0)
  {
    requestObj['pageNo1'] = 0;
    this.pageNo = requestObj['pageNo1'];
  }
  else
  {
    requestObj['pageNo1'] = this.pageNo1 - 1;
    this.pageNo1 = requestObj['pageNo1'];
  }

  this.httpService.postRequest<ResponseContainer>(requestObj,'teamlead/getEmployee').subscribe(
  datas=> {
    this.responseData1 = datas.rsBody.data;
    if(this.responseData1.length == 5)
    {
       this.setPageNoNext1 = true;
    }
    else
    {
       this.setPageNoNext1 = false;
       this.setPageNo1 = true;
    }
    if(this.pageNo1 == 0)
    {
       this.setPageNo1 = false;
    }
    else
    {
       this.setPageNo1 = true;
    }
  });

}
//Task list
empList(skillId, badgeId)
{
 this.router.navigate(['/teamlead/employee-list-tld', skillId,badgeId]);
}


  /*
  * search submit
  */
//  searchSubmit()
//  {
//    var requestObj = new RequestContainer();
//    requestObj['pageNo'] = 0;
//    requestObj['search_result'] = this.submitObj.search_result;
//    this.pageNo = requestObj['pageNo'];
//    var currentUser = JSON.parse(sessionStorage.getItem('login_id'));
//    requestObj['empId'] =  currentUser;
//    this.httpService.postRequest<ResponseContainer>(requestObj,'teamlead/getEmployee').subscribe(
//    datas=> {
//      this.responseData = datas.rsBody.data;
//      if(this.responseData.length == 4)
//      {
//         this.setPageNoNext = true;
//      }
//      else
//      {
//         this.setPageNoNext = false;
//      }
//      if(this.pageNo == 0)
//      {
//         this.setPageNo = false;
//      }
//      else
//      {
//         this.setPageNo = true;
//      }
//    });
//  }



searchSubmit(){
  // $(document).ready(function() {
  //   $('button').click(function() {
  //     $(this).parents('.sliderComplete').children('button').removeClass('close').fadeIn(300);

  //     // The button, that was visible, goes on display none.
  //     $(this).addClass('close').fadeOut(300);

  //     // We do a fluid slider with the class '.turn'.
  //     $(this).parents('.sliderComplete').children('.wrapper').children('.slider').toggleClass('turn');
  //   });
  // });
  // //slider
  // $('.count').each(function () {
  //   $(this).prop('Counter',0).animate({
  //       Counter: $(this).text()
  //       }, {
  //           duration: 4000,
  //           easing: 'swing',
  //           step: function (now) {
  //               $(this).text(Math.ceil(now));
  //           }
  //       });
  //   });
  var requestObj = new RequestContainer();
  requestObj['pageNo'] = 0;
  this.pageNo = requestObj['pageNo'];
  requestObj['pageNo1'] = 0;
  this.pageNo1 = requestObj['pageNo1'];
  requestObj['platform'] = "Web";
  requestObj['search_result'] = this.submitObj.search_result;
  var currentUser = JSON.parse(sessionStorage.getItem('login_user_id'));
  requestObj['empId'] =  currentUser;
  this.httpService.postRequest<ResponseContainer>(requestObj,'teamlead/getCategory').subscribe(
    datas=> {
      if(datas.rsBody.result == 'success')
      {
        this.responseData = datas.rsBody.data;
        if(this.responseData.length == 5)
        {
            this.setPageNoNext = true;
        }
        else
        {
            this.setPageNoNext = false;
        }
        if(this.pageNo == 0)
        {
            this.setPageNo = false;
        }
        else
        {
            this.setPageNo = true;
        }
      }
      else{
        console.log(datas.rsBody.error);
      }
    });
    this.httpService.postRequest<ResponseContainer>(requestObj,'teamlead/getEmployee').subscribe(
      datas=> {
        if(datas.rsBody.result == 'success')
        {
          this.responseData1 = datas.rsBody.data;
        }
        else{
          console.log(datas.rsBody.error);
        }
      });
}
 /*
 * refresh table
 */
  refreshSubmit()
  {
    this.ngOnInit();
  }

//  onFocus()
//  {
//      this.validationHandler.hideErrors("profile-submit");
//  }


searchsubmit1() {
  var currentUser = JSON.parse(sessionStorage.getItem('login_user_id'));
  this.submitObj.emp_id = currentUser;
  // start
  var requestObj = new RequestContainer();
  requestObj['pageNo'] = 0;
  this.pageNo = requestObj['pageNo'];
  requestObj['pageNo1'] = 0;
  this.pageNo1 = requestObj['pageNo1'];
  requestObj['platform'] = "Web";
  var currentUser = JSON.parse(sessionStorage.getItem('login_user_id'));
  requestObj['empId'] =  currentUser;
  this.submitObj.empId =  currentUser;
  let status = this.validationHandler.validateDOM('profileupdateBasic');
  if(!status)
  {
    this.httpService.postRequest<ResponseContainer>(requestObj,'teamlead/getCategory').subscribe(
      datas=> {
        if(datas.rsBody.result == 'success')
        {
          this.responseData = datas.rsBody.data;
          this.responseData = datas.rsBody.data;
          if(this.responseData.length == 2)
          {
              this.setPageNoNext = true;
          }
          else
          {
              this.setPageNoNext = false;
          }
          if(this.pageNo == 0)
          {
              this.setPageNo = false;
          }
          else
          {
              this.setPageNo = true;
          }
        }
        else{
          console.log(datas.rsBody.error);
        }
      });
      this.httpService.postRequest<ResponseContainer>(this.submitObj,'teamlead/getEmployeeSeacrh').subscribe(
        datas=> { 
          if(datas.rsBody.result == 'success')
          {
            this.responseData1 = datas.rsBody.data;
          }
          else
          { 
            this.validationHandler.displayErrors(datas.rsBody.errors,"profileupdateBasic",null);
          }
        });
  }

 
 
  
  //end
  
}


onFocus()
 {
     this.validationHandler.hideErrors("profile-submit");
 }

 refresh()
 {
    //  this.itemTemplate1 = [];
    // this.itemTemplate = [];
    this.badgeList = [];
    this.skillList = [];
    //this.item1.badge_name = '';
    this.ngOnInit();
 }
 
//   clearData() {
//     this.itemTemplate1 = [];
//     this.itemTemplate = [];
//     this.ngOnInit();
//   }
 
}
