<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Trainer</a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Category</a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Skills</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Employee List</a></li>
                    </ol>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                        <div class="title">
                            <h4>Trainer</h4>
                        </div>
                    </div>

                    <div class="c-grid">
                        <div class="widget-profile d-v-center">
                            <div class="widget-img-holder">
                                <div>
                                    <img src="../../../../assets/img/Profile.png">
                                </div>
                                <span><i class="fa fa-circle mr-2 text-danger"></i></span>
                            </div>
                            <h3 class="widget-content">
                                <span class="w-title">Jhon deo</span>
                                <span class="w-val">#0011</span>
                            </h3>
                        </div>
                        
                        <div class="widget-profile d-v-center">
                            <div class="widget-img-holder">
                                <div>
                                    <img src="../../../../assets/img/Profile.png">
                                </div>
                                <span><i class="fa fa-circle mr-2 text-danger"></i></span>
                            </div>
                            <h3 class="widget-content">
                                <span class="w-title">Jhon deo</span>
                                <span class="w-val">#0011</span>
                            </h3>
                        </div>
                        
                        <div class="widget-profile d-v-center">
                            <div class="widget-img-holder">
                                <div>
                                    <img src="../../../../assets/img/Profile.png">
                                </div>
                                <span><i class="fa fa-circle mr-2 text-danger"></i></span>
                            </div>
                            <h3 class="widget-content">
                                <span class="w-title">Jhon deo</span>
                                <span class="w-val">#0011</span>
                            </h3>
                        </div>
                        
                        <div class="widget-profile d-v-center">
                            <div class="widget-img-holder">
                                <div>
                                    <img src="../../../../assets/img/Profile.png">
                                </div>
                                <span><i class="fa fa-circle mr-2 text-success"></i></span>
                            </div>
                            <h3 class="widget-content">
                                <span class="w-title">Jhon deo</span>
                                <span class="w-val">#0011</span>
                            </h3>
                        </div>
                        
                        <div class="widget-profile d-v-center">
                            <div class="widget-img-holder">
                                <div>
                                    <img src="../../../../assets/img/Profile.png">
                                </div>
                                <span><i class="fa fa-circle mr-2 text-success"></i></span>
                            </div>
                            <h3 class="widget-content">
                                <span class="w-title">Jhon deo</span>
                                <span class="w-val">#0011</span>
                            </h3>
                        </div>
                        
                        <div class="widget-profile d-v-center">
                            <div class="widget-img-holder">
                                <div>
                                    <img src="../../../../assets/img/Profile.png">
                                </div>
                                <span><i class="fa fa-circle mr-2 text-danger"></i></span>
                            </div>
                            <h3 class="widget-content">
                                <span class="w-title">Jhon deo</span>
                                <span class="w-val">#0011</span>
                            </h3>
                        </div>
                        
                        <div class="widget-profile d-v-center">
                            <div class="widget-img-holder">
                                <div>
                                    <img src="../../../../assets/img/Profile.png">
                                </div>
                                <span><i class="fa fa-circle mr-2 text-success"></i></span>
                            </div>
                            <h3 class="widget-content">
                                <span class="w-title">Jhon deo</span>
                                <span class="w-val">#0011</span>
                            </h3>
                        </div>
                        
                        <div class="widget-profile d-v-center">
                            <div class="widget-img-holder">
                                <div>
                                    <img src="../../../../assets/img/Profile.png">
                                </div>
                                <span><i class="fa fa-circle mr-2 text-success"></i></span>
                            </div>
                            <h3 class="widget-content">
                                <span class="w-title">Jhon deo</span>
                                <span class="w-val">#0011</span>
                            </h3>
                        </div>
                        
                        <div class="widget-profile d-v-center">
                            <div class="widget-img-holder">
                                <div>
                                    <img src="../../../../assets/img/Profile.png">
                                </div>
                                <span><i class="fa fa-circle mr-2 text-danger"></i></span>
                            </div>
                            <h3 class="widget-content">
                                <span class="w-title">Jhon deo</span>
                                <span class="w-val">#0011</span>
                            </h3>
                        </div>
                    </div>
                </div>
                
                <div class="text-center mt-3">
                <a href="#" class="btn btn-yellow m-btn" routerLink="/trainers/stop-training-trn">Start Training</a>
                </div>
                
                
            </div>
        </div>
    </div>
</section>
