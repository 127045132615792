import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';
import {ResponseContainer} from '../../../service/ResponseContainer';
import {RequestContainer} from '../../../service/RequestContainer';
import {ValidationHandler} from '../../../service/ValidationHandler';

import * as $ from 'jquery';

declare var $ :any;

@Component({
  selector: 'app-task2-self-tld',
  templateUrl: 'task2-self-tld.component.html',
  providers : [HttpService,ValidationHandler]

})
export class Task2SelfTldComponent implements OnInit {

  public responseData:any = {};
  public targetIndex:Object = {};

  public submitObj:any = {
    optionList: [{}],
  };
  public setPageNo:boolean = false;
  public setPageNoNext:boolean = false;
  public pageNo:any = {};
  public routeUrl:Object = {};
  public id:any = {};
  public last_question_id = null; 
  constructor(
    private router:Router,
    private httpService : HttpService,
    private route: ActivatedRoute,
    private validationHandler : ValidationHandler
  )
  {
  }

  ngOnInit(): void {
    $('.modal ').insertAfter($('body'));
    var requestObj = new RequestContainer();
    requestObj['pageNo'] = 0;
    requestObj['platform'] = "Web";
    this.pageNo = requestObj['pageNo'];
    var currentUser = JSON.parse(sessionStorage.getItem('login_id'));
    requestObj['empId'] =  currentUser;
    this.submitObj.taskId = this.route.snapshot.paramMap.get('taskId');
    this.submitObj.empId = currentUser

    requestObj['taskId'] =  this.route.snapshot.paramMap.get('taskId');
    this.routeUrl = this.router.url;
    if(this.routeUrl == '/teamlead/task2-self-tld/'+requestObj['taskId'])
    {
        this.httpService.postRequest<ResponseContainer>(requestObj,'employee/getTaskQuestionsList').subscribe(
          datas=> {
            if(datas.rsBody.result == 'success')
            {
              this.responseData = datas.rsBody.data;
              this.submitObj.emp_answer = datas.rsBody.data['0']['emp_answer'];
              var last = this.responseData[this.responseData.length - 1];
              this.last_question_id = last['id'] ;
             // this.responseData.optionList = JSON.parse(datas.rsBody.data.options);
              if(this.responseData.length == 1)
              {
                  this.setPageNoNext = true;
              }
              else
              {
                  this.setPageNoNext = false;
              }
              if(this.pageNo == 0)
              {
                  this.setPageNo = false;
              }
              else
              {
                  this.setPageNo = true;
              }
            }
            else{
              console.log(datas.rsBody.error);
            }
          });
    }
  }


  /*
* pagination next
 */
nextSubmitpage()
{
  var  requestObj = new RequestContainer();
  requestObj['pageNo'] = this.pageNo + 1;
  this.pageNo = requestObj['pageNo'];
  requestObj['platform'] = "Web";
    requestObj['taskId'] =  this.route.snapshot.paramMap.get('taskId');
  this.httpService.postRequest<ResponseContainer>(requestObj,'employee/getTaskQuestionsList').subscribe(
  datas=> {
    this.responseData = datas.rsBody.data;
    if(this.responseData.length == 1)
    {
       this.setPageNoNext = true;
       this.setPageNo = true;
    }
    else
    {
       this.setPageNoNext = false;
       this.setPageNo = true;
    }
  });
}




nextSubmit(task_question_id)
{
  var  requestObj = new RequestContainer();
  requestObj['pageNo'] = this.pageNo + 1;
  this.pageNo = requestObj['pageNo'];
  requestObj['platform'] = "Web";
  requestObj['taskId'] =  this.route.snapshot.paramMap.get('taskId');
  this.submitObj.taskId =  this.route.snapshot.paramMap.get('taskId');
  var currentUser = JSON.parse(sessionStorage.getItem('login_id'));
  this.submitObj.emp_id =  currentUser;
  requestObj['empId']=  currentUser;

  this.submitObj.task_question_id = task_question_id;
 
  this.httpService.postRequest<ResponseContainer>(this.submitObj,'employee/saveAnswer').subscribe(datas => {
    if(datas.rsBody.result == 'success')
    {
      if ( this.last_question_id === task_question_id)
      {
        this.router.navigate(['/teamlead/dashboard-tld']);
      }
      this.submitObj = {};
      this.httpService.postRequest<ResponseContainer>(requestObj,'employee/getTaskQuestionsList').subscribe(
        datas=> {
          this.responseData = datas.rsBody.data;
         
          if(this.responseData.length == 1)
          {
             this.submitObj.emp_answer = datas.rsBody.data['0']['emp_answer'];
              this.setPageNoNext = true;
              this.setPageNo = true;
              $("#confirmsubmit").modal('show');

          }
          else
          {
              this.setPageNoNext = false;
              this.setPageNo = true;
              $("#confirmsubmit").modal('show');

          }
          
        });
    }
    else
    {
      // $("#confirmsubmit").modal('hide');

      // this.setPageNoNext = false;
      // this.setPageNo = true;
      // // alert(datas.rsBody.errors);
      this.validationHandler.displayErrors(datas.rsBody.errors,"profileupdateBasic",null);
    }
  });

}



/*
* pagination previous
*/
previousSubmit()
{
  var  requestObj = new RequestContainer();
  requestObj['platform'] = "Web"; 
  if(this.pageNo == 0)
  {
    requestObj['pageNo'] = 0;
    this.pageNo = requestObj['pageNo'];
  }
  else
  {
    requestObj['pageNo'] = this.pageNo - 1;
    this.pageNo = requestObj['pageNo'];
  }
    requestObj['taskId'] =  this.route.snapshot.paramMap.get('taskId');

  this.httpService.postRequest<ResponseContainer>(requestObj,'employee/getTaskQuestionsList').subscribe(
  datas=> {
    this.responseData = datas.rsBody.data;
    if(this.responseData.length == 1)
    {
       this.setPageNoNext = true;
    }
    else
    {
       this.setPageNoNext = false;
       this.setPageNo = true;
    }
    if(this.pageNo == 0)
    {
       this.setPageNo = false;
    }
    else
    {
       this.setPageNo = true;
    }
  });

}


/*
  * create submit
  */
 createSubmit()
 {
  this.httpService.postRequest<ResponseContainer>(this.submitObj,'employee/saveAnswer').subscribe(datas => {
    if(datas.rsBody.result == 'success')
    {
      this.submitObj = {};
      $("#changesubmit").modal('show');
      this.ngOnInit();
    }
    else
    {
     console.log('error');
    }
   });
 }



selectOption(option){
  this.submitObj.emp_answer = option;
  var backcolor = '.task_act a';
    $(backcolor).on('click', function(){
        $(backcolor).removeClass('active-bg');
        $(this).addClass('active-bg');
    });
}


sendRequest(){
  this.submitObj.taskId =  this.route.snapshot.paramMap.get('taskId');
  var currentUser = JSON.parse(sessionStorage.getItem('login_id'));
  this.submitObj.emp_id =  currentUser;
  this.httpService.postRequest<ResponseContainer>(this.submitObj,'employee/sendRequest').subscribe(
    datas=> {
      this.responseData = datas.rsBody.data;
      if(this.responseData.length == 1)
      {
          this.setPageNoNext = true;
          this.setPageNo = true;
      }
      else
      {
          this.setPageNoNext = false;
          this.setPageNo = true;
      }
    });
}

backBadge(){
  this.router.navigate(['/teamlead/dashboard-tld']);
}

}
