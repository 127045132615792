import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { FormsModule }   from '@angular/forms';
// import { FileUploader } from 'ng2-file-upload';  
import { DomSanitizer } from '@angular/platform-browser';
import { Router,ActivatedRoute } from '@angular/router'; 
import {ResponseContainer} from '../../../service/ResponseContainer';
import {ValidationHandler} from '../../../service/ValidationHandler'; 

  
declare var $ :any;  
const uploadAPI = 'http://localhost:3000/imageUpload';

@Component({ 
  selector: 'app-add-company-suadm',
  templateUrl: 'add-company-suadm.component.html',
  providers : [HttpService,ValidationHandler]   
}) 
export class AddCompanySuAdmComponent implements OnInit { 
  public responseData:any = {};
  public submitObj:any = {};
  public setCreate:boolean=true;
  public setUpdate:boolean = false;
  public routeUrl:Object = {};
  public id:any = {};

  constructor(
    private httpService : HttpService,
    private sanitization:DomSanitizer,
    private router:Router, 
    private route: ActivatedRoute,
    private validationHandler : ValidationHandler

  )
  { }

  ngOnInit(): void { 
    $('.modal ').insertAfter($('body'));
    this.setCreate = true; 
    this.routeUrl = this.router.url;
    this.id  =  this.route.snapshot.paramMap.get('id');
    if(this.routeUrl == '/superadmin/add-company-suadm/'+this.id)
    {
      this.httpService.postRequest<ResponseContainer>(this.id,'superadmin/getCompanyData').subscribe(datas => {
      if(datas.rsBody.result == 'success')
      {
       //  this.submitObj = datas.rsBody.data;
        this.submitObj.company_no = datas.rsBody.data[0]['company_no'];
        this.submitObj.company_name = datas.rsBody.data[0]['company_name'];
        this.submitObj.branch = datas.rsBody.data[0]['branch'];
        this.submitObj.api_url = datas.rsBody.data[0]['api_url'] || "";
        this.submitObj.email_id = datas.rsBody.admin[0]['email_id'];
        this.submitObj.first_name = datas.rsBody.admin[0]['first_name'];
        this.submitObj.last_name = datas.rsBody.admin[0]['last_name'];
        this.submitObj.mobile_no = datas.rsBody.admin[0]['mobile_no'];
        this.submitObj.address = datas.rsBody.admin[0]['address'];
        this.submitObj.region = datas.rsBody.admin[0]['region'];
        this.setCreate = false;
        this.setUpdate = true;
      }
     });
    }

  } 

  /*
  * create submit 
  */  
 createSubmit()
 {
  let status = this.validationHandler.validateDOM('profileupdateBasic');
  if(!status)
    {
      $('.loader').css('display','block');
      this.httpService.postRequest<ResponseContainer>(this.submitObj,'superadmin/createCompany').subscribe(datas => {
      if(datas.rsBody.result == 'success')
      {
        $('.loader').css('display','none');
        this.submitObj = {};
        $("#changesubmit").modal('show');
        this.ngOnInit();
      }
      else
      {
      $('.loader').css('display','none');
      this.validationHandler.displayErrors(datas.rsBody.errors,"profileupdateBasic",null);
      }
    });
  } 
 } 

  /*
  *  update submit
  */
 updateSubmit()
 {
     let status = this.validationHandler.validateDOM('profileupdateBasic');
     if(!status)
     {
      this.submitObj.id  =  this.route.snapshot.paramMap.get('id');
      this.httpService.postRequest<ResponseContainer>(this.submitObj,'superadmin/updateCompany').subscribe(datas => {
       if(datas.rsBody.result == 'success')
       {
         this.submitObj = {};
         $("#changesubmit").modal('show');
         this.setUpdate = false;
         this.ngOnInit();
       }
       else
       {
        this.validationHandler.displayErrors(datas.rsBody.errors,"profileupdateBasic",null);
       }
      });
    }
 }


 onFocus()
 {
     this.validationHandler.hideErrors("profile-submit");
 }
 
 /* 
* back Company page
*/
backCompany(){
  this.router.navigate(['/superadmin/company-suadm']);  
 } 

  /**
   * file upload
  */ 
//  public uploader:FileUploader = new FileUploader
//  (
//      {url:uploadAPI}
//  );
//  fileSelect()
//  {
//    $('#profile-image-upload').click();
//  }

//  imageUpload() 
//  {
//      this.uploader.uploadAll();
//  }

 /**
   * Convert into base 64
  */   
handleInputChange(e) {
  var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
  var pattern = /image-*/;
  var reader = new FileReader();
  if (!file.type.match(pattern)) {
    alert('invalid format');
    return;
  }
  reader.onload = this._handleReaderLoaded.bind(this);
  reader.readAsDataURL(file);
}
_handleReaderLoaded(e) {
  let reader = e.target;
  this.submitObj['imagePath'] = reader.result;
  //console.log(this.imageSrc);
}
 removeImage()
 {
    this.submitObj['imagePath'] = null;
 }

}