<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Company</a></li>
                    </ol>
                </div>
            </div>
            <div class="col-md-6">
                <div class="rightsidetxt">
                    <a class="search-wrapper btn btn-danger" routerLink="/superadmin/add-company-suadm">
                        <span>
                            <!-- <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
<g>
    <path d="M492,236H276V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v216H20c-11.046,0-20,8.954-20,20s8.954,20,20,20h216
        v216c0,11.046,8.954,20,20,20s20-8.954,20-20V276h216c11.046,0,20-8.954,20-20C512,244.954,503.046,236,492,236z"/>
</g>
                            </g></svg> -->
                            <i class="ti-plus"></i>
                        </span>
                        <p>Add Company</p>
                    </a>
                </div>
            </div>
        </div>

        <!-- <div class="row"> 
            <div class="col-md-12">
                <div class="px-3">
                    <div class="search-trainer">
                        <div class="title">
                            <h4>Companies</h4>
                        </div>
                        <a class="search-wrapper" routerLink="/superadmin/add-company-suadm">
                        <p>Add Company</p>
                            <span><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
	<g>
		<path d="M492,236H276V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v216H20c-11.046,0-20,8.954-20,20s8.954,20,20,20h216
			v216c0,11.046,8.954,20,20,20s20-8.954,20-20V276h216c11.046,0,20-8.954,20-20C512,244.954,503.046,236,492,236z"/>
	</g>
                                </g></svg></span>
                            </a>
                    </div>
                </div>  
            </div>
        </div> -->
        
        <!-- <div class="c-grid">
            <div class="cat-grid" *ngFor="let item of responseData let i=index">  
                <a href="javascript:void(0);" class="card" >
                    <div class="sticky-btn">
                        <button (click)="deleteSubmit(item.id);" class="trash"><i class="ti-trash"></i></button>
                        <button (click)="editSubmit(item.id);" class="e-btn btn-warning ml-2"><i class="fa fa-pencil"></i></button>
                    </div> 
                    
                    <div class="card-body skill-set" (click)="sync(item.id);">
                        <img src="../../../../assets/img/company.svg">
                        <h5>Branch : {{item.branch}}</h5>
                        <h5>Company No. : {{item.company_no}}</h5>
                    </div>
                </a>
            </div>           
        </div> -->
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <h4>Companies</h4>
                        <div class="table-responsive pt-3">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>S.N.</th>
                                        <th>Company</th>
                                        <!-- <th>Company No.</th> -->
                                        <!-- <th>Sync Data</th> -->
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of responseData let i=index">
                                        <td>{{i+1}}</td>
                                        <td><div class="d-flex align-items-center"><img class="comapnyimg" src="../../../../assets/img/company.svg"> {{item.company_name + " / " + item.branch}} </div></td>
                                        <!-- <td>{{item.company_no}}</td> -->
                                        <!-- <td><span (click)="sync(item.id);" class="text-info actionbtnsdf"><i class="fa fa-eye"></i></span></td> -->
                                        <td>
                                            <div>
                                                <span (click)="editSubmit(item.id);" class="text-primary actionbtnsdf"><i class="fa fa-edit"></i></span>
                                                <!-- <span (click)="sync(item.id);" class="text-info actionbtnsdf"><i class="fa fa-eye"></i></span> -->
                                                <span (click)="deleteSubmit(item.id);" class="text-danger actionbtnsdf"><i class="ti-trash"></i></span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <!-- <div class="cat-grid" *ngFor="let item of responseData let i=index">  
                                <a href="javascript:void(0);" class="card" >
                                    <div class="sticky-btn">
                                        <button (click)="deleteSubmit(item.id);" class="trash"><i class="ti-trash"></i></button>
                                        <button (click)="editSubmit(item.id);" class="e-btn btn-warning ml-2"><i class="fa fa-pencil"></i></button>
                                    </div> 
                                    
                                    <div class="card-body skill-set" (click)="sync(item.id);">
                                        <img src="../../../../assets/img/company.svg">
                                        <h5>Branch : {{item.branch}}</h5>
                                        <h5>Company No. : {{item.company_no}}</h5>
                                    </div>
                                </a>
                            </div>            -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mr-auto ml-auto">
            <nav class="pagination-wrap">
                <ul class="pagination">
                    <li class="page-item" (click)="previousSubmit();">
                        <a *ngIf='setPageNo' class="page-link" href="javascript:void(0);" aria-label="Previous">
                            <span aria-hidden="true">«</span>
                            <span class="sr-only">Previous</span>
                        </a>
                    </li>
                    <li class="page-item" (click)="nextSubmit();">
                        <a *ngIf='setPageNoNext' class="page-link" href="javascript:void(0);" aria-label="Next">
                            <span aria-hidden="true">»</span>
                            <span class="sr-only">Next</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</section> 

<!--trash-modal-->
<div class="modal fade"  id="deletesubmit" tabindex="-1"  role="dialog" aria-labelledby="trash-mdl" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>Are you sure you wanted to delete this user?</p>
        </div>
          <div class="mdl-footer d-v-center">
              <button class="btn btn-yellow btn-block" (click)='deleteRow(targetIndex);'>Yes</button>
              <button class="btn btn-black btn-block m-0" data-dismiss="modal">No</button>
          </div>
      </div>
    </div>
  </div>   