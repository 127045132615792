import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sidebar-suadm',
  templateUrl: 'sidebar-suadm.component.html'
})
export class SidebarSuAdmComponent implements OnInit {
  userDisplayName = '';
  userDisplayRole = ''; 
  userProfilePath = '';
  public login_name1:any = {};
  public login_role:any = {};
  constructor( 
    private router:Router, 
    private route: ActivatedRoute
  ) { } 
  
 
  ngOnInit(): void {
    this.userDisplayName = sessionStorage.getItem('login_name');
    this.userDisplayRole = sessionStorage.getItem('login_role');
    this.userProfilePath = JSON.parse(sessionStorage.getItem('user_data')).imagePath || null;
    // sessionStorage.setItem('login_name', JSON.stringify(this.responseData['login_name']));  
    // sessionStorage.setItem('login_role', JSON.stringify(this.responseData['login_role']));  
  }


  // logout(currentUser) {
  //   // remove user from local storage to log user out
  //   console.log(currentUser);
  //   sessionStorage.removeItem('currentUser');
  //   //this.currentUserSubject.next(null);
  // } 
 
  logout(){
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('login_id');
    this.router.navigate(['/']);
  }
}
