<section id="dashboard" class="g-100">
    <div class="container-fluid"> 
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item" (click)="backCategory();"><a href="javascript:void(0);">Category</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Skills</a></li>
                    </ol>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <div class=""> 
                    <div class="search-trainer">
                        <div class="title">
                            <h4>Skills</h4>
                        </div>
                    </div>

                    

                    <div class="b-grid" *ngIf='responseData.length > 0'>
                        <div class="widget-profile d-v-center" *ngFor="let item of responseData let i=index">
                            <div class="widget-img-holder">
                                <div>
                                    <img src="{{item.cat_skill_icon}}">
                                </div>
                            </div>
                            <h3 class="widget-content">
                                <span class="w-title">{{item.cat_skill_name}}</span>
                                <span class="w-val">{{item.cat_skill_description}}</span>
                            </h3>

                            <div class="t-menu-profile">
                                <a class="t-drp t-drp-bg dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-angle-down"></i></a>
                                
                                <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                    <li *ngIf="item.empId_1 != null">
                                        <a class="dropdown-item d-v-center" href="javascript:void(0)"> 
                                            <span class="sts text-danger mr-2">
                                                <i class="fa fa-circle"></i>
                                            </span> Beginner
                                        </a>
                                    </li>
                                    
                                    <li *ngIf="item.empId_2 != null">
                                        <a class="dropdown-item d-v-center" href="javascript:void(0)"> 
                                            <span class="sts text-warning mr-2">
                                                <i class="fa fa-circle"></i>
                                            </span> Intermediate
                                        </a>
                                    </li>
                                    <li *ngIf="item.empId_3 != null">
                                        <a class="dropdown-item d-v-center" href="javascript:void(0)"> 
                                            <span class="sts text-success mr-2">
                                                <i class="fa fa-circle"></i>
                                            </span> Expert
                                        </a>
                                    </li>
                                    <!-- <li *ngFor="let item1 of badgeList">
                                        <a class="dropdown-item d-v-center" href="javascript:void(0)"> 
                                            <span class="sts text-{{item1.badge_color}} mr-2">
                                                <i class="fa fa-circle"></i>
                                            </span> {{item1.badge_name}}
                                        </a>
                                    </li> -->
                                </ul>
                            </div>
                        </div> 
                         
                      
                    </div>
                </div>
                
                <div class="text-center mt-3">
                <div class="alert alert-warning alert-dismissible fade show mt-4">
                        <button type="button" class="close" data-dismiss="alert">&times;</button>
                        <strong>Open your mobile application to start the training.</strong>
                      </div>
                </div>
            </div>
        </div>
    </div>
</section>
