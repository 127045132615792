<section id="dashboard" class="g-100">
    <div class="container-fluid">
       <div class="row">
          <div class="col-md-12">
             <div class="page-title-box">
                <ol class="breadcrumb">
                   <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                   <li class="breadcrumb-item active" (click)="backCategory();"><a href="javascript:void(0);">Category</a></li>
                   <li class="breadcrumb-item active"><a href="javascript:void(0);">Badges</a></li>
                </ol>
             </div>
          </div>
       </div> 
       <div class="row">
          <div class="col-md-12">
             <div class=""> 
                <div class="search-trainer">
                   <div class="title">
                      <h4>Task</h4>
                   </div>
                </div>
             </div>
          </div>
       </div>
       <div class="row badge-view-wrapper trn-dash mt-3">
          <div class="col-md-12" *ngFor="let item of responseData let i=index">
             <h5 class="title">{{item.cat_skill_name}}</h5>
             <hr>
             <ul class="badge-overview flex-baseline flex-wrap d-v-center mt-3">
                <!--dynamic progress bar-->
                <li *ngIf="item.empId_1 != null">
                    <div class="card disabled-c btn-bdr" *ngIf="item.timePeriod_1 == null || item.task_cnt_1 == 0">
                        <div class="card-body skill-set">
                            <div class="c_bar b-100"  (click)="taskList(item.id, 1)">
                                <circle-progress
                                [percent]="30"
                                [radius]="100"
                                [outerStrokeWidth]="0"
                                [innerStrokeWidth]="0"
                                [space]="5"
                                [outerStrokeColor]="'transparent'"
                                [innerStrokeColor]="'transparent'"
                                [animation]="true"
                                [animationDuration]="300"
                                [backgroundGradient]="" 
                                [backgroundColor]="'transparent'" 
                                [class]="'pgrsbar'"
                                [imageSrc]="item.cat_skill_icon"
                                [imageHeight]="100"
                                [imageWidth]="100">      
                                </circle-progress>
                            </div>
                            <div class="prg_content mt-3">
                                <span class="ctskilnm mb-2">{{item.cat_skill_name}}</span>
                            </div> 
                            <p class="m-0 text-primary time_period" *ngIf="item.timePeriod_1 != null"><strong>{{item.timePeriod_1}} Days</strong>
                                <button class="btn-transparent" (click)="onSelection_timperiod(null, item.id, 1)"> <svg focusable="false" height="16px" viewBox="0 0 24 24" width="16px" xmlns="http://www.w3.org/2000/svg"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                                </button>
                            </p>
                            <ul class="calender mt-2"> 
                                <li title="1 Month" class="" (click)="onSelection_timperiod(30, item.id, 1)"><p>1M</p></li>
                                <li title="3 Month" class="" (click)="onSelection_timperiod(90, item.id, 1)"><p>3M</p></li>
                                <li title="6 Month" class="" (click)="onSelection_timperiod(180, item.id, 1)"><p>6M</p></li>
                                <li title="1 Year" class="" (click)="onSelection_timperiod(365, item.id, 1)"><p>1Y</p></li>
                            </ul>
                        </div>   
                    </div> 
 
                    <div *ngIf="item.timePeriod_1 != null && item.task_cnt_1 != 0" class="card btn-bdr disabled-g">
                        <div class="card-body skill-set">
                            <div class="c_bar b-100"  (click)="taskList(item.id, 1)">
                                <circle-progress
                                [percent]="30"
                                [radius]="100"
                                [outerStrokeWidth]="0"
                                [innerStrokeWidth]="0"
                                [space]="5"
                                [outerStrokeColor]="'transparent'"
                                [innerStrokeColor]="'transparent'"
                                [animation]="true"
                                [animationDuration]="300"
                                [backgroundGradient]=""
                                [backgroundColor]="'transparent'" 
                                [class]="'pgrsbar'"
                                [imageSrc]="item.cat_skill_icon"
                                [imageHeight]="100"
                                [imageWidth]="100">      
                                </circle-progress>
                            </div>
                            <div class="prg_content mt-3">
                                <span class="ctskilnm mb-2">{{item.cat_skill_name}}</span>
                            </div> 
                            <p class="m-0 text-primary time_period" *ngIf="item.timePeriod_1 != null"><strong>{{item.timePeriod_1}} Days</strong>
                                <button class="btn-transparent" (click)="onSelection_timperiod(null, item.id, 1)"> <svg focusable="false" height="16px" viewBox="0 0 24 24" width="16px" xmlns="http://www.w3.org/2000/svg"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                                </button>
                            </p>
                            
                            <p class="m-0 text-primary" *ngIf="item.timePeriod_1 == null" disabled-g></p>
                            <ul class="calender mt-2"> 
                                <li title="1 Month" class="active"  *ngIf="item.timePeriod_1 == 30" (click)="onSelection_timperiod(30, item.id, 1)"><p>1M</p></li>
                                <li title="1 Month" class="" *ngIf="item.timePeriod_1 != 30" (click)="onSelection_timperiod(30, item.id, 1)"><p>1M</p></li>

                                <li title="3 Month" class="active" *ngIf="item.timePeriod_1 == 90" (click)="onSelection_timperiod(90, item.id, 1)"><p>3M</p></li>
                                <li title="3 Month" class=""  *ngIf="item.timePeriod_1 != 90" (click)="onSelection_timperiod(90, item.id, 1)"><p>3M</p></li>

                                <li title="6 Month" class="active" *ngIf="item.timePeriod_1 == 180" (click)="onSelection_timperiod(180, item.id, 1)"><p>6M</p></li>
                                <li title="6 Month" class=""  *ngIf="item.timePeriod_1 != 180" (click)="onSelection_timperiod(180, item.id, 1)"><p>6M</p></li>

                                <li title="1 Year" class="active" *ngIf="item.timePeriod_1 == 365"  (click)="onSelection_timperiod(365, item.id, 1)"><p>1Y</p></li>
                                <li title="1 Year" class=""  *ngIf="item.timePeriod_1 != 365" (click)="onSelection_timperiod(365, item.id, 1)"><p>1Y</p></li>
                            </ul>
                        </div>   
                    </div> 

                </li>
                     
                <li *ngIf="item.empId_2 != null"> 
                    <div class="card disabled-c btn-bdr" *ngIf="item.timePeriod_2 == null || item.task_cnt_2 == 0">
                        <div class="card-body skill-set">  
                            <div class="c_bar i-100" (click)="taskList(item.id, 2)"> 
                                <circle-progress
                                [percent]="30"
                                [radius]="100"
                                [outerStrokeWidth]="0"
                                [innerStrokeWidth]="0" 
                                [space]="5"
                                [outerStrokeColor]="'transparent'"
                                [innerStrokeColor]="'transparent'"
                                [animation]="true"
                                [animationDuration]="300"
                                [backgroundGradient]=""
                                [backgroundColor]="'transparent'"
                                [class]="'pgrsbar'"
                                [imageSrc]="item.cat_skill_icon"
                                [imageHeight]="100"
                                [imageWidth]="100"> 
                                </circle-progress>
                            </div>
                            <div class="prg_content mt-3">
                                <span class="ctskilnm mb-2">{{item.cat_skill_name}}</span>
                            </div>
                            <p class="m-0 text-primary time_period" *ngIf="item.timePeriod_2 != null"><strong>{{item.timePeriod_2}} Days</strong>
                                <button class="btn-transparent" (click)="onSelection_timperiod(null, item.id, 2)"> <svg focusable="false" height="16px" viewBox="0 0 24 24" width="16px" xmlns="http://www.w3.org/2000/svg"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                                </button>
                            </p>
                            <ul class="calender mt-2">
                                <li title="1 Month" class="btn-warning" (click)="onSelection_timperiod(30, item.id, 2)"><p>1M</p></li>
                                <li title="3 Month" class="btn-warning" (click)="onSelection_timperiod(90, item.id, 2)"><p>3M</p></li>
                                <li title="6 Month" class="btn-warning" (click)="onSelection_timperiod(180, item.id, 2)"><p>6M</p></li>
                                <li title="1 Year" class="btn-warning" (click)="onSelection_timperiod(365, item.id, 2)"><p>1Y</p></li>
                            </ul>
                        </div>
                    </div>
                
                    <div *ngIf="item.timePeriod_2 != null && item.task_cnt_2 != 0" class="card btn-bdr">
                        <div class="card-body skill-set">  
                            <div class="c_bar i-100" (click)="taskList(item.id, 2)"> 
                                <circle-progress
                                [percent]="30"
                                [radius]="100"
                                [outerStrokeWidth]="0"
                                [innerStrokeWidth]="0" 
                                [space]="5"
                                [outerStrokeColor]="'transparent'"
                                [innerStrokeColor]="'transparent'"
                                [animation]="true"
                                [animationDuration]="300"
                                [backgroundGradient]=""
                                [backgroundColor]="'transparent'"
                                [class]="'pgrsbar'"
                                [imageSrc]="item.cat_skill_icon"
                                [imageHeight]="100"
                                [imageWidth]="100"> 
                                </circle-progress>
                            </div>
                            <div class="prg_content mt-3">
                                <span class="ctskilnm mb-2">{{item.cat_skill_name}}</span>
                            </div>
                            <p class="m-0 text-primary time_period" *ngIf="item.timePeriod_2 != null"><strong>{{item.timePeriod_2}} Days</strong>
                                <button class="btn-transparent" (click)="onSelection_timperiod(null, item.id, 2)"> <svg focusable="false" height="16px" viewBox="0 0 24 24" width="16px" xmlns="http://www.w3.org/2000/svg"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                                </button>
                            </p>
                            <ul class="calender mt-2">
                                <li title="1 Month" class="active"  *ngIf="item.timePeriod_2 == 30" (click)="onSelection_timperiod(30, item.id, 2)"><p>1M</p></li>
                                <li title="1 Month" class="" *ngIf="item.timePeriod_2 != 30" (click)="onSelection_timperiod(30, item.id, 2)"><p>1M</p></li>

                                <li title="3 Month" class="active" *ngIf="item.timePeriod_2 == 90" (click)="onSelection_timperiod(90, item.id, 2)"><p>3M</p></li>
                                <li title="3 Month" class=""  *ngIf="item.timePeriod_2 != 90" (click)="onSelection_timperiod(90, item.id, 2)"><p>3M</p></li>

                                <li title="6 Month" class="active" *ngIf="item.timePeriod_2 == 180" (click)="onSelection_timperiod(180, item.id, 2)"><p>6M</p></li>
                                <li title="6 Month" class=""  *ngIf="item.timePeriod_2 != 180" (click)="onSelection_timperiod(180, item.id, 2)"><p>6M</p></li>

                                <li title="1 Year" class="active" *ngIf="item.timePeriod_2 == 365"  (click)="onSelection_timperiod(365, item.id, 2)"><p>1Y</p></li>
                                <li title="1 Year" class=""  *ngIf="item.timePeriod_2 != 365" (click)="onSelection_timperiod(365, item.id, 2)"><p>1Y</p></li>
                            </ul>
                        </div>
                    </div>

                </li>
                    
                <li *ngIf="item.empId_3 != null">
                    <div class="card disabled-c btn-bdr" *ngIf="item.timePeriod_3 == null || item.task_cnt_3 == 0">
                         <div class="card-body skill-set">
                            <div class="c_bar e-100" (click)="taskList(item.id, 3)"> 
                                <circle-progress
                                [percent]="30"
                                [radius]="100" 
                                [outerStrokeWidth]="0"
                                [innerStrokeWidth]="0"
                                [space]="5"
                                [outerStrokeColor]="'transparent'"
                                [innerStrokeColor]="'transparent'"
                                [animation]="true"
                                [animationDuration]="300"
                                [backgroundGradient]=""
                                [backgroundColor]="'transparent'"
                                [class]="'pgrsbar'"
                                [imageSrc]="item.cat_skill_icon"
                                [imageHeight]="100"
                                [imageWidth]="100"> 
                                </circle-progress>
                            </div>
                            <div class="prg_content">
                                <span class="ctskilnm mb-2">{{item.cat_skill_name}}</span> 
                            </div>
                            <p class="m-0 text-primary time_period" *ngIf="item.timePeriod_3 != null"><strong>{{item.timePeriod_3}} Days</strong>
                                <button class="btn-transparent" (click)="onSelection_timperiod(null, item.id, 3)"> <svg focusable="false" height="16px" viewBox="0 0 24 24" width="16px" xmlns="http://www.w3.org/2000/svg"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                                </button>
                            </p>
                            <ul class="calender mt-2">
                                <li title="1 Month" class="btn-warning" (click)="onSelection_timperiod(30, item.id, 3)"><p>1M</p></li>
                                <li title="3 Month" class="btn-warning" (click)="onSelection_timperiod(90, item.id, 3)"><p>3M</p></li>
                                <li title="6 Month" class="btn-warning" (click)="onSelection_timperiod(180, item.id, 3)"><p>6M</p></li>
                                <li title="1 Year" class="btn-warning" (click)="onSelection_timperiod(365, item.id, 3)"><p>1Y</p></li>
                            </ul>
                        </div>
                    </div>
                  
                    <div *ngIf="item.timePeriod_3 != null && item.task_cnt_3 != 0" class="card btn-bdr">
                        <div class="card-body skill-set">
                           <div class="c_bar e-100" (click)="taskList(item.id, 3)"> 
                               <circle-progress
                               [percent]="30"
                               [radius]="100" 
                               [outerStrokeWidth]="0"
                               [innerStrokeWidth]="0"
                               [space]="5"
                               [outerStrokeColor]="'transparent'"
                               [innerStrokeColor]="'transparent'"
                               [animation]="true"
                               [animationDuration]="300"
                               [backgroundGradient]=""
                               [backgroundColor]="'transparent'"
                               [class]="'pgrsbar'"
                               [imageSrc]="item.cat_skill_icon"
                               [imageHeight]="100"
                               [imageWidth]="100"> 
                               </circle-progress>
                           </div>
                           <div class="prg_content mt-3">
                                <span class="ctskilnm mb-2">{{item.cat_skill_name}}</span> 
                            </div>
                            <p class="m-0 text-primary time_period" *ngIf="item.timePeriod_3 != null"><strong>{{item.timePeriod_3}} Days</strong>
                                <button class="btn-transparent" (click)="onSelection_timperiod(null, item.id, 3)"> <svg focusable="false" height="16px" viewBox="0 0 24 24" width="16px" xmlns="http://www.w3.org/2000/svg"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                                </button>
                            </p>
                            <ul class="calender mt-2">
                                <li title="1 Month" class="active"  *ngIf="item.timePeriod_3 == 30" (click)="onSelection_timperiod(30, item.id, 3)"><p>1M</p></li>
                                <li title="1 Month" class="" *ngIf="item.timePeriod_3 != 30" (click)="onSelection_timperiod(30, item.id, 3)"><p>1M</p></li>

                                <li title="3 Month" class="active" *ngIf="item.timePeriod_3 == 90" (click)="onSelection_timperiod(90, item.id, 3)"><p>3M</p></li>
                                <li title="3 Month" class=""  *ngIf="item.timePeriod_3 != 90" (click)="onSelection_timperiod(90, item.id, 3)"><p>3M</p></li>

                                <li title="6 Month" class="active" *ngIf="item.timePeriod_3 == 180" (click)="onSelection_timperiod(180, item.id, 3)"><p>6M</p></li>
                                <li title="6 Month" class=""  *ngIf="item.timePeriod_3 != 180" (click)="onSelection_timperiod(180, item.id, 3)"><p>6M</p></li>

                                <li title="1 Year" class="active" *ngIf="item.timePeriod_3 == 365"  (click)="onSelection_timperiod(365, item.id, 3)"><p>1Y</p></li>
                                <li title="1 Year" class=""  *ngIf="item.timePeriod_3 != 365" (click)="onSelection_timperiod(365, item.id, 3)"><p>1Y</p></li>
                            </ul>
                       </div>
                   </div>
                </li>
                <!--end of dynamic progress bar-->
            </ul>
        </div>
       </div>
    </div> 
 </section> 

 <!--successsfull-modal-->
<div class="modal fade"  id="changesubmit" role="dialog" aria-labelledby="trash-mdl" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>Submission Successfull</p>
        </div>
          <div class="mdl-footer d-v-center">
              <button class="btn btn-black btn-block m-0" data-dismiss="modal">Ok</button>
          </div>
      </div>
    </div>
  </div> 