import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';

import {ResponseContainer} from '../../../service/ResponseContainer';
import {BaseContainer} from '../../../service/BaseContainer';
import {RequestContainer} from '../../../service/RequestContainer'; 

@Component({
  selector: 'app-trainer-profile-adm',
   templateUrl: 'trainer-profile-adm.component.html'
})
export class TrainerProfileAdmComponent implements OnInit {
  public submitObj:any = {};
  public targetIndex:Object = {};
  public routeUrl:Object = {};
  public responseData:any = {}; 
  public badgeList:any = {};
  public id:any = {}; 

  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;
 
  constructor(
    private router:Router,
     private httpService : HttpService, 
     private route: ActivatedRoute
   ) 
  {} 
 
  ngOnInit(): void { 
    this.routeUrl = this.router.url;
    this.id  =  this.route.snapshot.paramMap.get('id');
    if(this.routeUrl == '/admin/trainer-profile-adm/'+this.id)
    { 
        this.httpService.postRequest<ResponseContainer>(this.id,'admin/getEmployeeData').subscribe(
          datas=> {
            if(datas.rsBody.result == 'success') 
            { 
              this.responseData = datas.rsBody.data; 
              this.responseData.id = datas.rsBody.data[0]['id'];
              this.responseData.user_id = datas.rsBody.data[0]['user_id'];
              this.responseData.first_name = datas.rsBody.data[0]['first_name'];
              this.responseData.last_name = datas.rsBody.data[0]['last_name']; 
              this.responseData.email_id = datas.rsBody.data[0]['email_id'];
              this.responseData.mobile_no = datas.rsBody.data[0]['mobile_no'];
              this.httpService.postRequest<ResponseContainer>(this.responseData.user_id,'employee/getEmployeeBadge').subscribe(
                datas=> {
                  if(datas.rsBody.result == 'success')  
                  {
                    this.badgeList = datas.rsBody.data;
                  }  
                  else{ 
                    console.log(datas.rsBody.error);
                  } 
                });
            } 
            else{
              console.log(datas.rsBody.error);
            }
          }); 
    }
  }

    /*
* back Category page
*/
backCategory(){
  this.router.navigate(['/admin/category-adm']);  
 } 
 

} 
