import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpService } from '../service/http.service';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { FormsModule }   from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';

import { SuperTeamleadRouting } from './superteamlead.routing'
import { SuperTeamleadComponent } from './superteamlead.component';
import { HeaderModule } from '../header/header.module';
import { DashboardSuperTldComponent } from './component/dashboard-super-tld/dashboard-super-tld.component';
import { SidebarSuperTldComponent } from './component/sidebar-super-tld/sidebar-super-tld.component';
import { EmployeeListSuperTldComponent } from './component/employee-list-super-tld/employee-list-super-tld.component';
import { HistorySuperTldComponent } from './component/history-super-tld/history-super-tld.component';
import { LearningPathwaysSuperTldComponent } from './component/learning-pathways-super-tld/learning-pathways-super-tld.component';
import { AddpathwaySuperTldComponent } from './component/add-pathway-super-tld/add-pathway-super-tld.component';
import { MyteamSuperTldComponent } from './component/myteam-super-tld/myteam-super-tld.component';
import { NotificationSuperTldComponent } from './component/notification-super-tld/notification-super-tld.component';
import { TaskListSuperTldComponent } from './component/task-list-super-tld/task-list-super-tld.component';
import { TaskSListSuperTldComponent } from './component/tasklist-super-tld/tasklist-super-tld.component';
import { Task2SuperTldComponent } from './component/task2-super-tld/task2-super-tld.component';
import { Task2SelfSuperTldComponent } from './component/task2-self-super-tld/task2-self-super-tld.component';
import { TechnicalSuperTldComponent } from './component/technical-super-tld/technical-super-tld.component';
import { EditProfileAdmComponent } from './component/edit-profile-adm/edit-profile-adm.component';


@NgModule({
  declarations: [
    SuperTeamleadComponent,
    SidebarSuperTldComponent,
    DashboardSuperTldComponent,
    EmployeeListSuperTldComponent,
    HistorySuperTldComponent,
    LearningPathwaysSuperTldComponent,
    AddpathwaySuperTldComponent,
    MyteamSuperTldComponent,
    NotificationSuperTldComponent,
    TaskListSuperTldComponent,
    TaskSListSuperTldComponent,
    Task2SuperTldComponent,
    Task2SelfSuperTldComponent,
    TechnicalSuperTldComponent,
    EditProfileAdmComponent
  ],
  imports: [
    BrowserModule,
    HeaderModule,
    HttpClientModule,
    FormsModule,
    AutocompleteLibModule,
    SuperTeamleadRouting,
    NgxPaginationModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      animationDuration: 300,
      class:'pgrsbar',
      showImage:true
    })
  ],
  providers: [{provide: APP_BASE_HREF, useValue : '' },HttpService],
  bootstrap: [SuperTeamleadComponent]
})
export class SuperTeamleadModule { }
