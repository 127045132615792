import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router,ActivatedRoute } from '@angular/router';
// import {ValidationHandler} from '../../../service/ValidationHandler';

import {ResponseContainer} from '../../../service/ResponseContainer';
import {BaseContainer} from '../../../service/BaseContainer';
import {RequestContainer} from '../../../service/RequestContainer';

declare var $ :any;

@Component({
  selector: 'app-employee-adm',
  templateUrl: 'employee-adm.component.html'
})
export class EmployeeAdmComponent implements OnInit {
    public submitObj:any = {};
    public targetIndex:Object = {};
    public pageNo:number= 1;
    public pageSize:number= 10;
    public employeeList:any = {};
    public employeeData:any = {};
    public keyword:any = {};
    public responseData;
    public routeUrl:Object = {};
    @Input() elementId: String;
    @Output() onEditorKeyup = new EventEmitter<any>();
    editor;

    constructor(
        private httpService : HttpService,
        private sanitization:DomSanitizer,
        private router:Router,
        private route: ActivatedRoute,
      )
     {
     }
    ngOnInit(): void {
      $('.modal ').insertAfter($('body'));
      var requestObj = new RequestContainer();
      requestObj['pageNo'] = this.pageNo;
      requestObj['pageSize'] = this.pageSize;
      requestObj['company_id']= JSON.parse(sessionStorage.getItem('login_company'));
      this.httpService.postRequest<ResponseContainer>(requestObj, 'admin/getAllEmployee').subscribe(datas => {
        if(datas.rsBody.result == 'success')
        {
          this.responseData = datas.rsBody.data;
        }
       });
    }


    loadpage(event) {
       $('.loader').css('display','block');
        this.pageNo= event;
        this.ngOnInit();
      }
   /*
   * delete
   */
  deleteSubmit(empId)
  {
    this.targetIndex = empId;
    $("#deletesubmit").modal('show');
  }

  deleteRow(Obj)
  {
   this.httpService.postRequest<ResponseContainer>(Obj,'admin/deleteEmployee').subscribe(datas => {
    $('.spinner').css('display','none');
    if(datas.rsBody.result == 'success')
    {
      $("#deletesubmit").modal('hide');
      this.ngOnInit();
    }
   });
  }



searchSubmit(){
  var requestObj = new RequestContainer();
  requestObj['pageNo'] = this.pageNo;
  requestObj['platform'] = "Web";
  requestObj['search_result'] = this.submitObj.search_result;
  requestObj['company_id']= JSON.parse(sessionStorage.getItem('login_company'));
  this.httpService.postRequest<ResponseContainer>(requestObj,'admin/getAllEmployee').subscribe(
    datas=> {
      if(datas.rsBody.result == 'success')
      {
        this.responseData = datas.rsBody.data;
      }
    });

}
 /*
 * refresh table
 */
  refreshSubmit()
  {
    this.ngOnInit();
  }

}
