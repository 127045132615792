import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { FormsModule }   from '@angular/forms';
// import { FileUploader } from 'ng2-file-upload'; 
import { DomSanitizer, SafeHtml, SafeUrl, SafeStyle } from '@angular/platform-browser';
import { Router,ActivatedRoute } from '@angular/router';
import {ResponseContainer} from '../../../service/ResponseContainer';
import {ValidationHandler} from '../../../service/ValidationHandler'; 
import {BaseContainer} from '../../../service/BaseContainer'; 
import {RequestContainer} from '../../../service/RequestContainer'; 
import { Location } from '@angular/common';

declare var $ :any;  
const uploadAPI = 'http://localhost:3000/imageUpload';

@Component({  
  selector: 'app-add-learning-pathway-adm',
  templateUrl: 'add-learning-pathway-adm.component.html',
  providers : [HttpService, ValidationHandler]  
}) 
export class AddLearningPathwaysAdmComponent implements OnInit {  
  public responseData:any = {};
  public submitObj:any = {};
  public setCreate:boolean=true;
  public setUpdate:boolean = false;
  public routeUrl:Object = {};
  public id:any = {};
 /* uploader:FileUploader; 
  hasBaseDropZoneOver:boolean; 
  hasAnotherDropZoneOver:boolean;
  response:string;*/
 

  constructor(
    private httpService : HttpService,
    private sanitization:DomSanitizer,
    private router:Router,
    private route: ActivatedRoute,
    private validationHandler : ValidationHandler,
    private location: Location

  )
  {
    // this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    // this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    //   var responsePath = JSON.parse(response);
    //   console.log(responsePath);// the url will be in the response
    //   this.submitObj['imagePath'] = responsePath['rsBody']['image'];
    //   this.submitObj['feature_image'] = responsePath['rsBody']['image'];
    //   this.submitObj['imagePath'] = this.sanitization.bypassSecurityTrustResourceUrl(this.submitObj['imagePath']);
    // };
  }
 
  ngOnInit(): void { 
    $('.modal ').insertAfter($('body'));
    this.setCreate = true; 
    this.routeUrl = this.router.url;
    this.id  =  this.route.snapshot.paramMap.get('id');
    if(this.routeUrl == '/admin/add-learning-pathway-adm/'+this.id)
    {
      this.httpService.postRequest<ResponseContainer>(this.id,'admin/getPathwayData').subscribe(datas => {
      if(datas.rsBody.result == 'success')
      {
        this.submitObj.pathway_name = datas.rsBody.data[0]['pathway_name'];
        this.submitObj.pathway_description = datas.rsBody.data[0]['pathway_description'];
        this.submitObj.imagePath = datas.rsBody.data[0]['pathway_icon'];
        this.setCreate = false;
        this.setUpdate = true;
      }
     });
    }
  }  

  goBack() {
    this.location.back();
  }

  /*
  * create submit
  */ 
 createSubmit()
 {
  let status = this.validationHandler.validateDOM('profileupdateBasic');
  if(!status)
    {
      this.submitObj.company_id= JSON.parse(sessionStorage.getItem('login_company'));
      this.httpService.postRequest<ResponseContainer>(this.submitObj,'admin/createPathway').subscribe(datas => {
        if(datas.rsBody.result == 'success')
        {
          this.submitObj = {};
          $("#changesubmit").modal('show');
          this.ngOnInit();
        }
      else
      {
      this.validationHandler.displayErrors(datas.rsBody.errors,"profileupdateBasic",null);
      }
    });
  }
 
 }
 

 /*
  *  update submit
  */
 updateSubmit()
 {
     let status = this.validationHandler.validateDOM('profileupdateBasic');
     if(!status)
     {
      this.submitObj.id  =  this.route.snapshot.paramMap.get('id');
      this.httpService.postRequest<ResponseContainer>(this.submitObj,'admin/updatePathway').subscribe(datas => {
       if(datas.rsBody.result == 'success')
       {
         this.submitObj = {};
         $("#changesubmit").modal('show');
         this.setUpdate = false;
         this.ngOnInit();
       }
       else
       {
        this.validationHandler.displayErrors(datas.rsBody.errors,"profileupdateBasic",null);
       }
      });
    }
 }
  
 onFocus()
 {
     this.validationHandler.hideErrors("profile-submit");
 }
  /**
   * file upload
  */
 
  /**
   * file upload
  */
//  public uploader:FileUploader = new FileUploader 
//  (
//      {url:uploadAPI}
//  );
//  fileSelect()
//  {
//    $('#profile-image-upload').click();
//  }

//  imageUpload()
//  {
//      this.uploader.uploadAll();
//  }

//  removeImage()
//  {
//     this.submitObj['imagePath'] = null;
//     this.submitObj['feature_image'] = null;
//  }

 /**
   * Convert into base 64
  */  
 handleInputChange(e) {
  var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
  var pattern = /image-*/;
  var reader = new FileReader();
  if (!file.type.match(pattern)) {
    alert('invalid format');
    return;
  }
  reader.onload = this._handleReaderLoaded.bind(this);
  reader.readAsDataURL(file);
}
_handleReaderLoaded(e) {
  let reader = e.target;
  this.submitObj['imagePath'] = reader.result;
  //console.log(this.imageSrc);
}
 removeImage() 
 {
    this.submitObj['imagePath'] = null;
 }


 backPathwayList(){
  this.router.navigate(['/admin/learning-pathways-adm']); 
 }
}