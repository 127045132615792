// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-sidebar-tld',
//   templateUrl: 'sidebar-tld.component.html'
// })
// export class SidebarTldComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }

import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sidebar-tld',
  templateUrl: 'sidebar-super-tld.component.html'
})
export class SidebarSuperTldComponent implements OnInit {
  userDisplayName = '';
  userDisplayRole = ''; 
  userProfilePath = '';
  constructor(
    private router:Router,
    private route: ActivatedRoute 
  ) { }

  ngOnInit(): void {
    this.userDisplayName = sessionStorage.getItem('login_name');
    this.userDisplayRole = sessionStorage.getItem('login_role');
    this.userProfilePath = JSON.parse(sessionStorage.getItem('user_data')).imagePath || null;
  }

  
  logout(){
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('login_id');
    sessionStorage.removeItem('login_user_id');
    sessionStorage.removeItem('login_role');
    this.router.navigate(['/']);
  }
}
