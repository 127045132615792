<section id="dashboard" class="g-100">
    <div class="container-fluid"> 
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item active" (click)="backEmpList();"><a href="javascript:void(0);">Employee List</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Employee Report</a></li>
                    </ol>
                </div>
            </div>
        </div>
 
        <div *ngFor="let item of responseData let i=index">
            <div class="e-rprt">
                <div class="e-rprt_title" id="e-rprt_title{{i}}" data-tab="item" (click)="toggleData(i)">
                    <h2 class="mt-0"><div class="d-v-center"><img src="../../../../assets/img/notification-bell.svg" width="30px;" class="mr-2" >{{item.badge_name}} Badge of under {{item.cat_skill_name}}</div> <div><span class="badge badge-light mr-2">{{item.assigned_date}}</span><i class="fa fa-chevron-down"></i></div>
                    </h2>
                </div>
                <div class="accordion-content" id="accordion-content{{i}}" id="item{{i}}" >
                    <div *ngFor="let item1 of item.task let i2=index">
                        {{item1.task_name}}
                        <div class="e-rprt_wrapper pos-rel" *ngFor="let item2 of item1.QA let i2=index">
                            <div class="notify-details">
                                <div>
                                    <h5>Question:</h5>
                                    <p>{{item2.question_title}}</p>
                                    <h5>Answer:</h5>
                                    <p>{{item2.emp_answer}}</p>{{item2.created_date}}
                                </div>
                                <div class="e_act">
                                    <button class="btn btn-success mr-2" *ngIf="item2.status == 1">Approved</button>
                                    <button class="btn btn-warning" *ngIf="item2.status == 2">Rejected</button>
                                    <button class="btn btn-success mr-2" *ngIf="item2.status == 0" (click)="answerStatusUpdate(item2.answer_id, 1)">Approve</button>
                                    <button class="btn btn-warning" *ngIf="item2.status == 0" (click)="answerStatusUpdate(item2.answer_id, 2)">Reject</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</section>
