<section id="dashboard" class="g-100">
    <div class="container-fluid">
         
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box"> 
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item" (click)="backBadge();"><a href="javascript:void(0);">Assigned Badges</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Activities</a></li>
                    </ol>
                </div>
            </div> 
        </div>


        <div class="row"> 
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                        <div class="title">
                            <h4>Activities</h4>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
 
        <!--QUESTIONS LIST-->

        <div class="row badge-view-wrapper mt-4"  *ngIf='responseData.length > 0'>

             <table class="preview_table">
                <thead>
                  <tr>
                    <th class="text-center">S.N</th>
                    <th class="text-center"> Title</th>

                    <th class="text-center"> Description</th>

                    <!-- <th>Task Document Type</th> -->

                    <th class="text-center"> Document</th>
                    
                    <th class="text-center"></th>
                  </tr>
                </thead>
                
                <tbody>
                    <tr  *ngFor="let item of responseData; let i = index">
                        <td class="text-center">{{ i + 1 }}</td>
                    <td class="text-center">{{ item.question_title }}</td>

                    <td class="text-center">{{ item.question_description }}</td>

                    <!-- <td>{{ item.task_doc_type }}</td> -->

                    <td class="text-center">
                      
                          <ng-container *ngIf="item.task_doc_type == 'image/png' || item.task_doc_type === 'image/jpeg' || item.task_doc_type === 'image/jpg'">
                            <img src="{{ item.task_doc }}" alt="" style="height: auto; width: 100px; background-size: contain;">
                          </ng-container>

                          <ng-container *ngIf="item.task_doc_type == 'video/mp4'">
                            <video controls style="width:100px ; height: 100px;">
                              <source src="{{ item.task_doc }}"  type="video/mp4">
                            </video>
                          </ng-container>

                          <ng-container *ngIf="item.task_doc_type == 'application/pdf'">
                            <iframe [src]="transform(item.task_doc)" style="width:100px ; height: 100px;"></iframe>
                          </ng-container>

                    </td>

                    <td class="text-center"> 
                        <a class="bottone5" href="{{item.task_doc}}" target="_blank" download="{{item.question_title}}">View</a>
                      </td>

                  </tr>
                </tbody>
             </table>
            
        </div>

        <div class="col-md-12"  *ngIf='responseData.length == 0'>
            <div class="task-wrapper">
                <strong>No Data Found</strong>
            </div>     
        </div>


    </div>
</section>
 


 