// import { Component } from '@angular/core';

// @Component({
//   templateUrl: 'auth.component.html'
// })
// export class AuthComponent {
// }
import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeUrl, SafeStyle } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from '../service/http.service';
import {ResponseContainer} from '../service/ResponseContainer';
import {ValidationHandler} from '../service/ValidationHandler'; 

import {BaseContainer} from '../service/BaseContainer';
import {RequestContainer} from '../service/RequestContainer';
import { ToastrService } from 'ngx-toastr';

declare var $ :any; 

@Component({
  templateUrl: 'auth.component.html',
  providers : [HttpService,ValidationHandler]   
})
export class AuthComponent {
  public submitObj:any = {};
  public responseData;
  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;

  constructor(
    private httpService : HttpService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private validationHandler : ValidationHandler
    )
  {}
  ngOnInit(): void { 
  }
  createSubmit() {
    let status = this.validationHandler.validateDOM('profileupdateBasic');
    if(!status)
      {
        this.httpService.postRequest<ResponseContainer>(this.submitObj,'login').subscribe(datas => {
          if(datas.rsBody.result == 'success')
          { 
            this.responseData = datas.rsBody.data; 
            if(this.responseData['login_role'] == 0){ 
              
              sessionStorage.setItem('currentUser', JSON.stringify(this.responseData['login_accessToken']));
              sessionStorage.setItem('login_id', JSON.stringify(this.responseData['login_id']));  
              sessionStorage.setItem('login_user_id', JSON.stringify(this.responseData['login_user_id'])); 
              sessionStorage.setItem('login_name', JSON.stringify(this.responseData['login_name']));  
              sessionStorage.setItem('login_company', JSON.stringify(this.responseData['login_company']));  
              sessionStorage.setItem('login_role', 'Super Admin');  
              sessionStorage.setItem('user_data' , JSON.stringify(this.responseData['user_data']))
              // this.toastr.success('File successfully uploaded!');
              this.responseData['login_role_name'] = 'superadmin';
              this.router.navigate(['/superadmin/dashboard-suadm']);  
            }
            if(this.responseData['login_role'] == 1){ 
              sessionStorage.setItem('currentUser', JSON.stringify(this.responseData['login_accessToken']));
              sessionStorage.setItem('login_id', JSON.stringify(this.responseData['login_id']));  
              sessionStorage.setItem('login_user_id', JSON.stringify(this.responseData['login_user_id'])); 
              sessionStorage.setItem('login_name', JSON.stringify(this.responseData['login_name']));  
              sessionStorage.setItem('login_company', JSON.stringify(this.responseData['login_company']));  
              sessionStorage.setItem('user_data' , JSON.stringify(this.responseData['user_data']))
              sessionStorage.setItem('login_role', 'HR/Admin');  
              // this.toastr.success('File successfully uploaded!');
              this.responseData['login_role_name'] = 'admin';
              this.router.navigate(['/admin/dashboard-adm']);  
            }
            if(this.responseData['login_role'] == 2){
              sessionStorage.setItem('currentUser', JSON.stringify(this.responseData['login_accessToken']));
              sessionStorage.setItem('login_id', JSON.stringify(this.responseData['login_id'])); 
              sessionStorage.setItem('login_user_id', JSON.stringify(this.responseData['login_user_id'])); 
              sessionStorage.setItem('login_name', JSON.stringify(this.responseData['login_name']));  
              sessionStorage.setItem('login_company', JSON.stringify(this.responseData['login_company']));  
              sessionStorage.setItem('user_data' , JSON.stringify(this.responseData['user_data']))
              sessionStorage.setItem('login_role', 'Trainer');  
              this.responseData['login_role_name'] = 'trainer';
              this.router.navigate(['/trainers/dashboard-trn']);  
            }
            if(this.responseData['login_role'] == 3){ 
              sessionStorage.setItem('currentUser', JSON.stringify(this.responseData['login_accessToken']));
              sessionStorage.setItem('login_id', JSON.stringify(this.responseData['login_id']));
              sessionStorage.setItem('login_user_id', JSON.stringify(this.responseData['login_user_id'])); 
              sessionStorage.setItem('login_name', JSON.stringify(this.responseData['login_name']));  
              sessionStorage.setItem('login_company', JSON.stringify(this.responseData['login_company']));  
              sessionStorage.setItem('user_data' , JSON.stringify(this.responseData['user_data']))
              sessionStorage.setItem('login_role', 'Employee');   
              this.responseData['login_role_name'] = 'employee';
              this.router.navigate(['/employee/dashboard-emp']);
            }
            if(this.responseData['login_role'] == 4){
              sessionStorage.setItem('currentUser', JSON.stringify(this.responseData['login_accessToken']));
              sessionStorage.setItem('login_id', JSON.stringify(this.responseData['login_id'])); 
              sessionStorage.setItem('login_user_id', JSON.stringify(this.responseData['login_user_id'])); 
              sessionStorage.setItem('login_name', JSON.stringify(this.responseData['login_name']));  
              sessionStorage.setItem('login_company', JSON.stringify(this.responseData['login_company']));  
              sessionStorage.setItem('user_data' , JSON.stringify(this.responseData['user_data']))
              sessionStorage.setItem('login_role', 'Team Leader');  
              this.responseData['login_role_name'] = 'teamlead'; 
              this.router.navigate(['/teamlead/dashboard-tld']);
            }  
            if(this.responseData['login_role'] == 5){
              sessionStorage.setItem('currentUser', JSON.stringify(this.responseData['login_accessToken']));
              sessionStorage.setItem('login_id', JSON.stringify(this.responseData['login_id'])); 
              sessionStorage.setItem('login_user_id', JSON.stringify(this.responseData['login_user_id'])); 
              sessionStorage.setItem('login_name', JSON.stringify(this.responseData['login_name']));  
              sessionStorage.setItem('login_company', JSON.stringify(this.responseData['login_company']));  
              sessionStorage.setItem('user_data' , JSON.stringify(this.responseData['user_data']))
              sessionStorage.setItem('login_role', 'Super Team Leader');  
              this.responseData['login_role_name'] = 'super_teamlead'; 
              this.router.navigate(['/superteamlead/dashboard-tld']);
            } 
          }
          else
          {
          this.validationHandler.displayErrors(datas.rsBody.errors,"profileupdateBasic",null);
          }
        });
      }
  }

  onFocus()
  {
      this.validationHandler.hideErrors("profile-submit");
  }
}
