import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router,ActivatedRoute } from '@angular/router';
import {ResponseContainer} from '../../../service/ResponseContainer';
import {BaseContainer} from '../../../service/BaseContainer';
import {RequestContainer} from '../../../service/RequestContainer';

declare var $ :any;
@Component({
  selector: 'app-sync-suadm',
  templateUrl: 'sync-suadm.component.html'
})
export class SyncSuAdmComponent implements OnInit {
    public submitObj:any = {};
    public id:any = {};
    public targetIndex:Object = {};
    public pageNo:number= 1;
    public pageSize:number= 10;
    public employeeList:any = {};
    public employeeData:any = {};
    public keyword:any = {};
    public responseData;
    public routeUrl:Object = {};
    public collection_size;
    pageOfItems: Array<any>;
    @Input() elementId: String;
    @Output() onEditorKeyup = new EventEmitter<any>();
    editor;

    constructor(
        private httpService : HttpService,
        private sanitization:DomSanitizer,
        private router:Router,
        private route: ActivatedRoute,
      )
     {
     }
    ngOnInit(): void {
      $('.modal ').insertAfter($('body'));
      $('#auto_search').css('display','none');
      $('#my-table-add-trainer').css('display','none');
      $('#my-table').css('display','block');
      $('#s_wrap').css('display','flex');
      $('#close-search').css('display','none');
      var requestObj = new RequestContainer();
      requestObj['pageNo'] = this.pageNo;
      requestObj['pageSize'] = this.pageSize;
      requestObj['company_id']  =  this.route.snapshot.paramMap.get('id');
      this.httpService.postRequest<ResponseContainer>(requestObj, 'superadmin/getEmployee').subscribe(datas => {
        if(datas.rsBody.result == 'success')
        {
          $('.loader').css('display','none');
          this.collection_size = datas.rsBody.data[0]['count'];
          this.responseData = datas.rsBody.data[0]['result'];
        }
       });
    }

    loadpage(event) {
       $('.loader').css('display','block');
        this.pageNo= event;
        this.ngOnInit();
      }


    selectEvent(item) {
       $('#auto_search').css('display','none');
       $('#my-table').css('display','none');
       $('#my-table-add-trainer').css('display','block');
      this.employeeData = item;
      // do something with selected item
    }

    onChangeSearch(val: string) {
      // fetch remote data from here
      // And reassign the 'data' which is binded to 'data' property.
    }

    onFocused(e){
      // do something when input is focused
    }


  /*
  * Employee List
  */
  showEmployeeList()
  {
    $('#auto_search').css('display','block');
    $('#close-search').css('display','flex');
    $('#s_wrap').css('display','none');
    // $('#my-table-add-trainer').css('display','block');
    // $('#my-table').css('display','none');
    this.submitObj.company_id  =  this.route.snapshot.paramMap.get('id');
    this.httpService.postRequest<ResponseContainer>(this.submitObj, 'superadmin/getEmployeeList').subscribe(datas => {
     if(datas.rsBody.result == 'success')
     {
       this.keyword = 'first_name';
       this.employeeList = datas.rsBody.data;
     }
   });
  }

  addTeamleader(empID)
  {
    $('#auto_search').css('display','none');
    this.submitObj.empId = empID;

    this.httpService.postRequest<ResponseContainer>(this.submitObj, 'superadmin/addTeamleader').subscribe(datas => {
     if(datas.rsBody.result == 'success')
     {
      $('#my-table-add-trainer').css('display','block');
      this.ngOnInit();
     }
     else{
       console.log(datas.rsBody.result);
     }
   });
  }


  addSuperTeamleader(empID)
  {
    $('#auto_search').css('display','none');
    this.submitObj.empId = empID;

    this.httpService.postRequest<ResponseContainer>(this.submitObj, 'superadmin/addSuperTeamleader').subscribe(datas => {
     if(datas.rsBody.result == 'success')
     {
      $('#my-table-add-trainer').css('display','block');
      this.ngOnInit();
     }
     else{
       console.log(datas.rsBody.result);
     }
   });
  }
   /*
   * delete
   */
  deleteSubmit(empId)
  {
    this.targetIndex = empId;
    $("#deletesubmit").modal('show');
  }

  deleteRow(Obj)
  {
   this.httpService.postRequest<ResponseContainer>(Obj,'superadmin/deleteEmployee').subscribe(datas => {
    $('.spinner').css('display','none');
    if(datas.rsBody.result == 'success')
    {
      $("#deletesubmit").modal('hide');
      this.ngOnInit();
    }
   });
  }

importdata()
{
  this.submitObj.clientId = "1tr9bnzomk0l8",
  this.submitObj.clientSecret = "ec4020801789c3baa10517d360f1fba934c3c87d16ed14e27203d2fef1e874d3",
  this.submitObj.grantType = "client_credentials",
  this.submitObj.scope = "all"
  this.submitObj.company_id  =  this.route.snapshot.paramMap.get('id');
  this.id  =  this.route.snapshot.paramMap.get('id');
  $('.loader').css('display','block');
  this.httpService.postRequest<ResponseContainer>(this.id, 'superadmin/getCompanyData').subscribe(datas => {
      if(datas.rsBody.result == 'success')
      {
          var company = datas.rsBody.data;

          this.submitObj.company_api = datas.rsBody.data[0]['api_url'];
          console.log(this.submitObj.company_api);
          this.httpService.postRequest<ResponseContainer>(this.submitObj, 'superadmin/callApi').subscribe(datas => {
            if(datas.rsBody.result == 'success')
            {
                // this.submitObj = '';
                this.submitObj = datas.rsBody.data;
                this.submitObj.company = company;
                this.httpService.postRequest<ResponseContainer>(this.submitObj, 'superadmin/importData1').subscribe(datass => {
                    console.log("Fgjgj");
                    if(datass.rsBody.result == 'success')
                    {
                        $('.loader').css('display','none');
                        $("#changesubmit").modal('show');
                        console.log("Done");
                    }
                    else{
                    console.log("Error");
                    }
                });
            }
            else{
            console.log("Error");
            }
        });

      }
    else{
      console.log("Error");
    }
  });
}

}
