<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row"> 
            <div class="col-md-12">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item" (click)="backCategory();"><a href="javascript:void(0);">Category</a></li>
                        <li class="breadcrumb-item active" *ngIf='setCreate'><a href="javascript:void(0);">Create New Category</a></li>
                        <li class="breadcrumb-item active" *ngIf='setUpdate'><a href="javascript:void(0);">Update Category</a></li>
                    </ol>
                </div>
            </div>
        </div> 


        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                    <div class="title">
                        <h4>Categories</h4> 
                        </div>
                        
                        <a class="search-wrapper"  routerLink="/category">
                            <p>View Category</p>
                        </a>
                    </div>
                </div>
            </div> 
        </div>
        
        <div class="row" id="profile-submit">
            <div class="create-n-ctg col-md-12 mr-auto ml-auto">
                <div class="card mt-3">
                    <div class="card-body">
                        <form class="form" id="profileupdateBasic" role="form" autocomplete="off" #catForm="ngForm">
                            <div class="row">
                            <div class="form-group col-6">
                                <label class="col-form-label form-control-label">Name:</label>
                                <div>
                                    <input class="form-control" type="text" value="" placeholder="Enter Category name" name="cat_name" id="cat_name" [(ngModel)]="submitObj.cat_name" checkMandatory="true" (focus)="onFocus()">
                                    <span class="ng-hide displayError" id="cat_name_error"></span>
 
                                </div>
                            </div>   
                            <div class="form-group col-6">
                                <label class="col-form-label form-control-label">Description</label>
                                <div>
                                    <input class="form-control" type="text" value="" name="cat_description" placeholder="Enter Category Description" id="cat_description" [(ngModel)]="submitObj.cat_description" checkMandatory="true" (focus)="onFocus()"> 
                                    <span class="ng-hide displayError" id="cat_description_error"></span>

                                </div>
                            </div> 
                            <div class="form-group col-6" id="imagePath">
                                <label class="col-form-label form-control-label">Catgory Icon:</label>
                                <div>
                                    <div id="collapseOne" class="panel-collapse collapse show" role="tabpanel" aria-labelledby="headingOne">
                                        <div>
                                          <label for="" *ngIf="submitObj.imagePath != null">
                                            <img [src]="submitObj.imagePath" alt="" style="height: 100px !important; width: 100px !important;" /><br>
                                            <a href="javascript:void(0);" class="text-danger" (click)="removeImage();"><i class="fa fa-trash-o" aria-hidden="true"></i> Remove Category Icon</a>
                                          </label>
                                            <label *ngIf="submitObj.imagePath == null" for="profile-image-upload" class="form-control text-primary" style="cursor: pointer;"><i class="ti-gallery mr-2"></i>Set Category Image
                                                <input id="profile-image-upload" type="file" class="form-control btn btn-info" accept="image/*" name="profilePhoto" (change)="handleInputChange($event)" style="display: none;" />
                                            </label>
                                        </div> 
                                    </div> 
                                    <span class="ng-hide displayError" id="imagePath_error"></span>

                                </div> 
                            </div>
                            <div class="form-group col-6">
                                <div class="float-right mt-5">
                                <div class="d-v-center">
                                    <input *ngIf='setCreate' type="reset" class="btn btn-secondary mr-2" (click)="backCategory();" value="Cancel">
                                    <input *ngIf='setCreate' type="submit" class="btn btn-warning" (click)="createSubmit();" value="Save">
                                    <input *ngIf='setUpdate' type="submit" class="btn btn-secondary" (click)="updateSubmit();" value="Update">
                                </div>
                                </div>
                            </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> 
 


<!--successsfull-modal-->
<div class="modal fade"  id="changesubmit" role="dialog" aria-labelledby="trash-mdl" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>Submission Successfull</p>
        </div>
          <div class="mdl-footer d-v-center">
              <button class="btn btn-black btn-block m-0" data-dismiss="modal">Ok</button>
          </div>
      </div>
    </div>
  </div> 