<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Training Complete</a></li>
                    </ol>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                        <div class="title">
                            <h4>Training Complete</h4>
                        </div>
                    </div>

                    
                    <div class="st-trainig">
                    <div>
                        <svg fill="green" viewBox="-21 -21 682.66669 682.66669"  xmlns="http://www.w3.org/2000/svg"><path d="m322.820312 387.933594 279.949219-307.273438 36.957031 33.671875-314.339843 345.023438-171.363281-162.902344 34.453124-36.238281zm297.492188-178.867188-38.988281 42.929688c5.660156 21.734375 8.675781 44.523437 8.675781 68.003906 0 148.875-121.125 270-270 270s-270-121.125-270-270 121.125-270 270-270c68.96875 0 131.96875 26.007812 179.746094 68.710938l33.707031-37.113282c-58.761719-52.738281-133.886719-81.597656-213.453125-81.597656-85.472656 0-165.835938 33.285156-226.273438 93.726562-60.441406 60.4375-93.726562 140.800782-93.726562 226.273438s33.285156 165.835938 93.726562 226.273438c60.4375 60.441406 140.800782 93.726562 226.273438 93.726562s165.835938-33.285156 226.273438-93.726562c60.441406-60.4375 93.726562-140.800782 93.726562-226.273438 0-38.46875-6.761719-75.890625-19.6875-110.933594zm0 0"/></svg>
                        <p>Lorem ipsum dolor sit amet</p>
                        </div>
                    </div>
                </div>
                
                <div class="text-center mt-3">
                <a href="#" class="btn btn-yellow btn-bdr m-btn" data-toggle="modal" data-target="#trnModal">Export to CSV</a>
                </div>
                
                
            </div>
        </div>
    </div>
</section>



<!--trnModal-->
<div class="modal fade"  tabindex="-1" id="trnModal" role="dialog" aria-labelledby="trnModal" aria-hidden="true">
  <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
    <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Save as</h5>
          <button type="button" class="close" data-dismiss="modal">
<svg width="14px" fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>close</title><path d="M284.29,256,506.14,34.14A20,20,0,0,0,477.86,5.86L256,227.72,34.14,5.86A20,20,0,1,0,5.86,34.14L227.71,256,5.86,477.86a20,20,0,0,0,28.29,28.29L256,284.29,477.86,506.14a20,20,0,0,0,28.29-28.29Z" transform="translate(0 0)"/></svg></button>
        </div>
      <div class="modal-body text-center">
        <select class="form-control">
          <option>project1</option>
          <option>project2</option>
          <option>project3</option>
          </select>
      </div>
        <div class="text-right p-3">
        <button class="btn btn-yellow btn-bdr" data-toggle="modal" data-target="#YnModal">Save</button>
        </div>
    </div>
  </div>
</div>

<!--YnModal-->
<div class="modal fade"  tabindex="-1" id="YnModal" role="dialog" aria-labelledby="trnModal" aria-hidden="true">
  <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
    <div class="modal-content">
        <div class="text-center pt-4">
        <svg width="60px" viewBox="-21 -21 682.66669 682.66669"xmlns="http://www.w3.org/2000/svg"><path d="m322.820312 387.933594 279.949219-307.273438 36.957031 33.671875-314.339843 345.023438-171.363281-162.902344 34.453124-36.238281zm297.492188-178.867188-38.988281 42.929688c5.660156 21.734375 8.675781 44.523437 8.675781 68.003906 0 148.875-121.125 270-270 270s-270-121.125-270-270 121.125-270 270-270c68.96875 0 131.96875 26.007812 179.746094 68.710938l33.707031-37.113282c-58.761719-52.738281-133.886719-81.597656-213.453125-81.597656-85.472656 0-165.835938 33.285156-226.273438 93.726562-60.441406 60.4375-93.726562 140.800782-93.726562 226.273438s33.285156 165.835938 93.726562 226.273438c60.4375 60.441406 140.800782 93.726562 226.273438 93.726562s165.835938-33.285156 226.273438-93.726562c60.441406-60.4375 93.726562-140.800782 93.726562-226.273438 0-38.46875-6.761719-75.890625-19.6875-110.933594zm0 0"/></svg>
        </div>
        <div class="d-v-center ml-auto mr-auto text-center pt-3 pb-3">
        <button class="btn btn-default">cancel</button>
        <button class="btn btn-yellow btn-bdr ml-2">Ok</button>
        </div>
    </div>
  </div>
</div>