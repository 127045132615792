<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Category</a></li>
                    </ol>
                </div>
            </div>
        </div>


        <div class="row"> 
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                    <div class="title">
                        <h4>Categories</h4>
                        </div>
                        
                        <a class="search-wrapper" routerLink="/admin/add-category-adm">
                        <p>Add Category</p>
                            <span><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
	<g>
		<path d="M492,236H276V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v216H20c-11.046,0-20,8.954-20,20s8.954,20,20,20h216
			v216c0,11.046,8.954,20,20,20s20-8.954,20-20V276h216c11.046,0,20-8.954,20-20C512,244.954,503.046,236,492,236z"/>
	</g>
                                </g></svg></span>
                            </a>
                    </div>
                </div>  
            </div>
        </div>
        
        <div class="c-grid"> 
            <div class="cat-grid" *ngFor="let item of responseData let i=index">  
                <a href="javascript:void(0);" class="card" >
                    <div class="sticky-btn">
                        <button (click)="deleteSubmit(item.id);" class="trash"><i class="ti-trash"></i></button>
                        <button (click)="editSubmit(item.id);" class="e-btn btn-warning ml-2"><i class="fa fa-pencil"></i></button>
                    </div>
                    
                    <div class="card-body skill-set" (click)="listSkill(item.id);" >
                        <img style="height: 80px !important; width: 80px !important;" src="{{item.cat_icon}}">

                        <!-- <img src="../../../assets/img/skills.svg"> -->
                        <h5>{{item.cat_name}}</h5>
                        <!-- <p class="m-0">10 Skill Available</p> --> 
                    </div>
                </a>
            </div>  
            <!--No data Found start-->
            <div class="w-100" *ngIf='responseData.length == 0'>  
                <div class="text-center" role="not found">
                    <i class="fa fa-exclamation-circle fa-5x" aria-hidden="true"></i>
                    <p class="mt-2">No Badges Found</p>
                </div>
            </div>
            <!--No data found end-->         
        </div> 
        
        <div class="mr-auto ml-auto">
            <nav class="pagination-wrap">
                <ul class="pagination">
                    <li class="page-item" (click)="previousSubmit();">
                        <a *ngIf='setPageNo' class="page-link" href="javascript:void(0);" aria-label="Previous">
                            <span aria-hidden="true">«</span>
                            <span class="sr-only">Previous</span>
                        </a>
                    </li>
                    <li class="page-item" (click)="nextSubmit();">
                        <a *ngIf='setPageNoNext' class="page-link" href="javascript:void(0);" aria-label="Next">
                            <span aria-hidden="true">»</span>
                            <span class="sr-only">Next</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</section> 

<!--trash-modal-->
<div class="modal fade"  id="deletesubmit" tabindex="-1"  role="dialog" aria-labelledby="trash-mdl" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>Are you sure you wanted to delete this user?</p>
        </div>
          <div class="mdl-footer d-v-center">
              <button class="btn btn-yellow btn-block" (click)='deleteRow(targetIndex);'>Yes</button>
              <button class="btn btn-black btn-block m-0" data-dismiss="modal">No</button>
          </div>
      </div>
    </div>
  </div>   