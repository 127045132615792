<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-9">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li> 
                        <li class="breadcrumb-item active" (click)="backPathwayList();"><a href="javascript:void(0);">Pathway List</a></li>
                        <li class="breadcrumb-item active" *ngIf='setCreate'><a href="javascript:void(0);">Create New Pathway</a></li>
                        <li class="breadcrumb-item active" *ngIf='setUpdate'><a href="javascript:void(0);">Update Pathway</a></li>
                    </ol>
                </div>
            </div>
            <div class="col-md-3 text-right">
                <button class="btn btn-danger" (click)="goBack()"><i class="fa fa-angle-left"></i> Back</button>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                    <div class="title">
                        <h4>Pathway</h4>
                        </div>
                        
                        <a class="search-wrapper"  routerLink="/category">
                            <p>View Pathway</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
         
        <div class="row" id="profile-submit">
            <div class="create-n-ctg col-12">
                <div class="card mt-3">
                    <div class="card-body">
                        <form class="form" id="profileupdateBasic" role="form" autocomplete="off" #catForm="ngForm">
                            <div class="form-group">
                                <label class="col-form-label form-control-label">Pathway Name :</label>
                                <div>
                                    <input class="form-control" type="text" value="" placeholder="Enter your name" name="pathway_name" id="pathway_name"  [(ngModel)]="submitObj.pathway_name" checkMandatory="true" (focus)="onFocus()">
                                    <span class="ng-hide displayError" id="pathway_name_error"></span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-form-label form-control-label">Pathway Description :</label>
                                <div>
                                    <input class="form-control" type="text" value="" name="pathway_description" placeholder="Enter Description" id="pathway_description" [(ngModel)]="submitObj.pathway_description" checkMandatory="true" (focus)="onFocus()">
                                    <span class="ng-hide displayError" id="pathway_description_error"></span>
                                </div>
                            </div>
                            <div class="form-group" id="imagePath">
                                <label class="col-form-label form-control-label">Pathway Icon:</label> 
                                <div>
                                    <label for="" *ngIf="submitObj.imagePath != null">
                                    <img [src]="submitObj.imagePath" alt="" style="height: 100px !important; width: 100px !important;" /><br>
                                    <a href="javascript:void(0);" (click)="removeImage();"><i class="fa fa-trash-o" aria-hidden="true"></i> Remove Pathway Icon</a>
                                    </label>
                                    <label *ngIf="submitObj.imagePath == null" for="profile-image-upload" class="form-control text-primary" style="cursor: pointer;">Set Category Image
                                        <input id="profile-image-upload" type="file" class="form-control btn btn-info" accept="image/*" name="profilePhoto" (change)="handleInputChange($event)" style="display: none;" />
                                    </label> 
                                    <span class="ng-hide displayError" id="imagePath_error"></span>
                                </div> 
                            </div>
                            <div class="form-group">
                                <div class="float-right">
                                <div class="d-v-center">
                                    <input *ngIf='setCreate' type="reset" class="btn btn-secondary mr-2" (click)="backPathwayList();" value="Cancel">
                                    <input *ngIf='setCreate' type="submit" class="btn btn-warning" (click)="createSubmit();" value="Save">
                                    <input *ngIf='setUpdate' type="submit" class="btn btn-secondary" (click)="updateSubmit();" value="Update">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> 



<!--successsfull-modal-->
<div class="modal fade"  id="changesubmit" role="dialog" aria-labelledby="trash-mdl" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>Submission Successfull</p>
        </div>
          <div class="mdl-footer d-v-center">
              <button class="btn btn-black btn-block m-0" data-dismiss="modal">Ok</button>
          </div>
      </div>
    </div>
  </div> 