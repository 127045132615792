
import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';

import {ResponseContainer} from '../../../service/ResponseContainer';
import {RequestContainer} from '../../../service/RequestContainer';

declare var $ :any;
@Component({
  selector: 'app-category-skills-adm',
  templateUrl: 'category-skills-adm.component.html'
})
export class CategorySkillsAdmComponent implements OnInit {
  public submitObj:any = {};
  public targetIndex:Object = {};
  public pageNo:number= 1;
  public pageSize:number= 10;
  public routeUrl:Object = {};
  public responseData:any = {};
  public badgeList:any = {};
  public id:any = {};

  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;

  constructor(
    private router:Router,
     private httpService : HttpService,
     private route: ActivatedRoute
   )
   {}

   ngOnInit(): void {
    $('.modal ').insertAfter($('body'));
    var requestObj = new RequestContainer();
    requestObj['pageNo'] = this.pageNo;
    requestObj['pageSize'] = this.pageSize;
    requestObj['cat_id'] =  this.route.snapshot.paramMap.get('id');
    this.routeUrl = this.router.url;
    this.id  =  this.route.snapshot.paramMap.get('id');
    if(this.routeUrl == '/admin/category-skills-adm/'+this.id)
    {
        this.httpService.postRequest<ResponseContainer>(requestObj,'admin/getCategorySkill').subscribe(
          datas=> {
            if(datas.rsBody.result == 'success')
            {
              this.responseData = datas.rsBody.data;
            }
            else{
              console.log(datas.rsBody.error);
            }
          });
    }
  }


  /*
  * pagination
  */
  loadpage(event) {
     $('.loader').css('display','block');
      this.pageNo= event;
      this.ngOnInit();
    }

  /*
  * Badges List
  */
 getBadgeList()
 {
       $('.spinner').css('display','block');
       this.httpService.getAll<ResponseContainer>('admin/getBadgeList').subscribe(datas => {
       $('.spinner').css('display','none');
       if(datas.rsBody.result == 'success')
       {
         this.badgeList = datas.rsBody.data;
       }
      });
 }




/*
* delete
*/
 deleteSubmit(skillId)
 {
   this.targetIndex = skillId;
   $("#deletesubmit").modal('show');
 }

 deleteRow(Obj)
 {
  this.httpService.postRequest<ResponseContainer>(Obj,'admin/deleteSkill').subscribe(datas => {
   if(datas.rsBody.result == 'success')
   {
     $("#deletesubmit").modal('hide');
     this.ngOnInit();
   }
   else{
     console.log(datas.rsBody.error);
   }
  });
 }

/*
* Assign Badge to trainer on skill_list page
*/
 assignBadgeTrianer(badgeId,skillId)
 {
   this.submitObj.employeeId = "";
   this.submitObj.badgeId = badgeId;
   this.submitObj.skillId = skillId;
   this.httpService.postRequest<ResponseContainer>(this.submitObj, 'admin/assignUpdate').subscribe(datas => {
     if(datas.rsBody.result == 'success')
     {
      this.ngOnInit();
     }
   });
 }

/*
* Assign Trsiner page
*/
 assignTrainer(skillId, badgeId){
  this.submitObj.catId  =  Number(this.route.snapshot.params.id);
  this.router.navigate(['/admin/assign-trainer-adm',this.submitObj.catId,skillId,badgeId]);
 }


 /*
* Re-Assign Trainer Skill
*/
reAssign(skillId){
  this.submitObj.skillId = skillId;
  this.httpService.postRequest<ResponseContainer>(this.submitObj, 'admin/reAssignUpdate').subscribe(datas => {
    if(datas.rsBody.result == 'success')
    {
     this.ngOnInit();
    }
  });
}


/*
* Add Skill page
*/
 addSkill(){
  this.routeUrl = this.router.url;
  this.id  =  this.route.snapshot.paramMap.get('id');
  this.router.navigate(['/admin/add-category-skill-adm',this.id]);
 }


/*
* back Category page
*/
backCategory(){
  this.router.navigate(['/admin/category-adm']);
 }



/*
  * edit Category Skill
  */
editSubmit(skillId)
{
  this.id  =  this.route.snapshot.paramMap.get('id');
  this.router.navigate(['/admin/add-category-skill-adm',this.id,skillId]);
}


}
