// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-notification',
//   templateUrl: 'notification-tld.component.html'
// })
// export class NotificationTldComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }
import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';
import {ResponseContainer} from '../../../service/ResponseContainer';
import {RequestContainer} from '../../../service/RequestContainer';  
import * as $ from 'jquery';

@Component({
  selector: 'app-notification',
  templateUrl: 'notification-tld.component.html'
})
export class NotificationTldComponent implements OnInit {

  public responseData:any = {};
  public targetIndex:Object = {}; 

  public submitObj:any = {};
  public setPageNo:boolean = false;
  public setPageNoNext:boolean = false;
  
  public pageNo:any = {};
  public routeUrl:Object = {};
  public id:any = {}; 

  constructor(
    private router:Router,
    private httpService : HttpService, 
    private route: ActivatedRoute
  )
  {
  }
 
  ngOnInit(): void {
    $('.modal ').insertAfter($('body')); 
    var requestObj = new RequestContainer(); 
    var currentUser = JSON.parse(sessionStorage.getItem('login_user_id'));  
    requestObj['empId'] =  currentUser;
    requestObj['company_id'] = JSON.parse(sessionStorage.getItem('login_company'));  
    this.httpService.postRequest<ResponseContainer>(requestObj,'teamlead/getNotification').subscribe(
      datas=> {
        if(datas.rsBody.result == 'success') 
        {
          this.responseData = datas.rsBody.data;
        } 
        else{ 
          console.log(datas.rsBody.error);
        } 
      });
  }
  answerStatusUpdate(answer_id){
    this.submitObj.answer_id = answer_id;
    // this.submitObj.status = status;

    this.httpService.postRequest<ResponseContainer>(this.submitObj,'teamlead/dismissNotification').subscribe(datas => {
      if(datas.rsBody.result == 'success')
      {
        this.submitObj = {};
       // $("#changesubmit").modal('show');
        this.ngOnInit();
      } 
      else
      { 
       console.log('error');
      }
     });
  }
  getHyperlinkedText(text : any) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;

    // Replace URLs with clickable links
    return text.replace(urlRegex, function(url) {
      return '<a target="_blank" href="' + url + '">' + url + '</a>';
    });
  }
}

