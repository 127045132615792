<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item" (click)="backEmpList();"><a href="javascript:void(0);">Employee List</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Employee Profile</a></li>
                    </ol>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                        <div class="title">
                            <h4>Profile</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4 profile-m-card">
            <div class="col-md-12">
                <div class="profile-wrapper">
                    <div class="widget-profile d-v-center">
                        <div class="widget-img-holder">
                            <div>
                                <img src="https://demo.neontheme.com/assets/images/thumb-1@2x.png">
                            </div>
                            <span><i class="fa fa-circle mr-2 text-danger"></i></span>
                        </div>
                        <h3 class="widget-content">
                            <span class="w-title"><i class="ti-user mr-1"></i> {{responseData.first_name}} {{responseData.last_name}}</span>
                            <span class="w-val"><i class="ti-id-badge mr-1"></i> #{{responseData.id}}</span>
                            <span class="w-val"><i class="ti-email mr-1"></i> employee@email.com</span>
                            <span class="w-val"><i class="ti-mobile mr-1"></i> 123-4456-789</span>
                        </h3>
                    </div>

                </div>
            </div>

            <div class="col-md-12">
                <div class="badge-view-wrapper px-0">
                    <div class="d-v-center justify-content-between pos-rel">
                        <h5 class="title">Badge Overview:</h5>
                        <a href="javascript:void(0);" class="btn btn-warning m-btn" (click)="assignBadgepage(responseData.user_id)">Assign More Badge</a>
                    </div>
                    <hr>
                    <ul class="badge-overview d-v-center flex-wrap flex-baseline mt-3">
                        <li *ngFor="let item of badgeList let i=index">
                            <div class="card btn-bdr">
                                <div class="card-body skill-set">
                                    <div class="c_bar b-100" *ngIf="item.badge_id == 1">
                                        <circle-progress
                                        [percent]="30"
                                        [radius]="100"
                                        [outerStrokeWidth]="0"
                                        [innerStrokeWidth]="0"
                                        [space]="5"
                                        [outerStrokeColor]="'transparent'"
                                        [innerStrokeColor]="'transparent'"
                                        [animation]="true"
                                        [animationDuration]="300"
                                        [backgroundGradient]=""
                                        [backgroundColor]="'transparent'"
                                        [class]="'pgrsbar'"
                                        [imageSrc]="item.cat_skill_icon"
                                        [imageHeight]="100"
                                        [imageWidth]="100">
                                        </circle-progress>
                                    </div>
                                    <div class="c_bar i-100" *ngIf="item.badge_id == 2">
                                        <circle-progress
                                        [percent]="30"
                                        [radius]="100"
                                        [outerStrokeWidth]="0"
                                        [innerStrokeWidth]="0"
                                        [space]="5"
                                        [outerStrokeColor]="'transparent'"
                                        [innerStrokeColor]="'transparent'"
                                        [animation]="true"
                                        [animationDuration]="300"
                                        [backgroundGradient]=""
                                        [backgroundColor]="'transparent'"
                                        [class]="'pgrsbar'"
                                        [imageSrc]="item.cat_skill_icon"
                                        [imageHeight]="100"
                                        [imageWidth]="100">
                                        </circle-progress>
                                    </div>
                                    <div class="c_bar e-100" *ngIf="item.badge_id == 3">
                                        <circle-progress
                                        [percent]="30"
                                        [radius]="100"
                                        [outerStrokeWidth]="0"
                                        [innerStrokeWidth]="0"
                                        [space]="5"
                                        [outerStrokeColor]="'transparent'"
                                        [innerStrokeColor]="'transparent'"
                                        [animation]="true"
                                        [animationDuration]="300"
                                        [backgroundGradient]=""
                                        [backgroundColor]="'transparent'"
                                        [class]="'pgrsbar'"
                                        [imageSrc]="item.cat_skill_icon"
                                        [imageHeight]="100"
                                        [imageWidth]="100">
                                        </circle-progress>
                                    </div>
                                    <div class="prg_content mt-3">
                                        <!-- <span class="m-0">{{item.badge_name}}</span><br> -->
                                        <span class="ctskilnm">{{item.cat_skill_name}}</span>
                                        <span class="ctnme">{{item.cat_name}}</span>
                                        <hr>
                                        <span class="expbadgeas">{{item.created_date}}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <!--No data Found start-->
                    <div class="w-100" *ngIf='badgeList.length == 0'>
                        <div class="text-center" role="not found">
                            <i class="fa fa-exclamation-circle fa-5x" aria-hidden="true"></i>
                            <p class="mt-2">No Badges Found</p>
                        </div>
                    </div>
                <!--No data found end-->
                </div>
            </div>
        </div>
    </div>
</section>
<!--successsfull-modal-->
<div class="modal fade"  id="changesubmit" role="dialog" aria-labelledby="trash-mdl" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>Assigned Successfull</p>
        </div>
          <div class="mdl-footer d-v-center">
              <button class="btn btn-black btn-block m-0" data-dismiss="modal">Ok</button>
          </div>
      </div>
    </div>
  </div>
