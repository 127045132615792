import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TeamleadComponent } from './teamlead.component';
import { DashboardTldComponent } from './component/dashboard-tld/dashboard-tld.component'; 
import { EmployeeListTldComponent } from './component/employee-list-tld/employee-list-tld.component';
import { HistoryTldComponent } from './component/history-tld/history-tld.component';
import { LearningPathwaysTldComponent } from './component/learning-pathways-tld/learning-pathways-tld.component';
import { AddpathwayTldComponent } from './component/add-pathway-tld/add-pathway-tld.component';
import { MyteamTldComponent } from './component/myteam-tld/myteam-tld.component';
import { NotificationTldComponent } from './component/notification-tld/notification-tld.component';
import { TaskListTldComponent } from './component/task-list-tld/task-list-tld.component';
import { TaskSListTldComponent } from './component/tasklist-tld/tasklist-tld.component';
import { Task2TldComponent } from './component/task2-tld/task2-tld.component'; 
import { Task2SelfTldComponent } from './component/task2-self-tld/task2-self-tld.component';
import { TechnicalTldComponent } from './component/technical-tld/technical-tld.component';
import { EditProfileAdmComponent } from './component/edit-profile-adm/edit-profile-adm.component';

const routes: Routes = [
    { 
        path: 'teamlead', 
        component: TeamleadComponent,
        children: [
            { path: '', component: DashboardTldComponent },
            { path: 'dashboard-tld', component: DashboardTldComponent },
            { path: 'employee-list-tld/:skillId/:badgeId', component: EmployeeListTldComponent },
            { path: 'history-tld', component: HistoryTldComponent },
            { path: 'learning-pathways-tld', component: LearningPathwaysTldComponent },
            { path: 'add-pathway-tld', component: AddpathwayTldComponent },
            { path: 'myteam-tld', component: MyteamTldComponent },
            { path: 'notification-tld', component: NotificationTldComponent },
            { path: 'task-list-tld/:skillId/:badgeId/:trainerId/:employeeId', component: TaskListTldComponent },
            { path: 'tasklist-tld/:trainerId/:skillId/:badgeId', component: TaskSListTldComponent },
            { path: 'task2-tld/:taskId/:employeeId', component: Task2TldComponent },
            { path: 'task2-self-tld/:taskId', component: Task2SelfTldComponent },
            { path: 'technical-tld/:id', component: TechnicalTldComponent },
            { path: 'edit-profile-tld', component: EditProfileAdmComponent }
        ]
    }
]; 

export const TeamleadRouting = RouterModule.forRoot(routes);