
import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';
import {ResponseContainer} from '../../../service/ResponseContainer';
import {BaseContainer} from '../../../service/BaseContainer';
import {RequestContainer} from '../../../service/RequestContainer';
declare var $ :any;

@Component({
  selector: 'app-learning-pathways-super-tld',
  templateUrl: 'learning-pathways-super-tld.component.html',
  styleUrls: ['learning-pathways-super-tld.component.html']
})
export class LearningPathwaysSuperTldComponent implements OnInit {

  public submitObj:Object={};
  public targetIndex:Object = {};
  public pageNo:number= 1;
  public pageSize:number= 10;

  public responseData;
  public routeUrl:Object = {};
  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;


  constructor(private httpService : HttpService,private router:Router,
    private route: ActivatedRoute){}

  ngOnInit(): void {
    $('.modal ').insertAfter($('body'));
    var requestObj = new RequestContainer();
    requestObj['platform'] = "Web"; 
    requestObj['pageNo'] = this.pageNo;
    requestObj['pageSize'] = this.pageSize;
    var currentUser = JSON.parse(sessionStorage.getItem('login_user_id'));
    requestObj['emp_id'] = currentUser;
    this.httpService.postRequest<ResponseContainer>(requestObj, 'superteamlead/getPathway').subscribe(datas => {
      if(datas.rsBody.result == 'success')
      {
        this.responseData = datas.rsBody.data;
      }
     });
  }


/*
* pagination next
*/
loadpage(event) {
   $('.loader').css('display','block');
    this.pageNo= event;
    this.ngOnInit();
  }


/*
* Skill list of pathway
*/
showPathwaySkill(id)
{
 this.router.navigate(['/superteamlead/technical-tld',id]);
}

}
