// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-learning-pathways-emp',
//   templateUrl: 'learning-pathways-emp.component.html'
// })
// export class LearningPathwaysEmpComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {  
//   }

// }

import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';

import {ResponseContainer} from '../../../service/ResponseContainer';
import {RequestContainer} from '../../../service/RequestContainer'; 
declare var $ :any; 

@Component({
  selector: 'app-learning-pathways-emp',
  templateUrl: 'learning-pathways-emp.component.html'
})
export class LearningPathwaysEmpComponent implements OnInit {

  public submitObj:any = {}; 
  public targetIndex:Object = {};
  public pageNo:number= 1;
  public pageSize:number= 100;

  public routeUrl:Object = {};
  public responseData:any = {}; 
  public badgeList:any = {}; 
  public id:any = {}; 

  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;
 
  constructor(
    private router:Router,
     private httpService : HttpService, 
     private route: ActivatedRoute
   )
   {}  

  ngOnInit(): void {  
    $('.modal ').insertAfter($('body'));
    var requestObj = new RequestContainer(); 
    requestObj['pageNo'] = this.pageNo;
    requestObj['pageSize'] = this.pageSize; 
    requestObj['platform'] = "Web"; 
    requestObj['pathway_id'] =  this.route.snapshot.paramMap.get('id');
    this.routeUrl = this.router.url; 
    this.id  =  this.route.snapshot.paramMap.get('id'); 
    if(this.routeUrl == '/employee/learning-pathways-emp/'+this.id)
    { 
        this.httpService.postRequest<ResponseContainer>(requestObj,'employee/getPathwaySkill').subscribe(
          datas=> {
            if(datas.rsBody.result == 'success') 
            {
              this.responseData = datas.rsBody.data;
            }  
            else{
              console.log(datas.rsBody.error);
            }
          });
    }
  }

/*
* pagination next
*/
loadpage(event) {
  $('.loader').css('display','block');
   this.pageNo= event;
   this.ngOnInit();
 }


 backPathwayList(){
  this.router.navigate(['/employee/mypathways-emp']); 
 }

}
