import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { FormsModule } from '@angular/forms';
// import { FileUploader } from 'ng2-file-upload';  
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { ResponseContainer } from '../../../service/ResponseContainer';
import { ValidationHandler } from '../../../service/ValidationHandler';


declare var $: any;
const uploadAPI = 'http://localhost:3000/imageUpload';

@Component({
  selector: 'app-edit-profile-adm',
  templateUrl: 'edit-profile-adm.component.html',
  providers: [HttpService, ValidationHandler]
})
export class EditProfileAdmComponent implements OnInit {
  public responseData: any = {};
  public submitObj: any = {};
  public setCreate: boolean = true;
  public setUpdate: boolean = false;
  public routeUrl: Object = {};
  public id: any = {};

  constructor(
    private httpService: HttpService,
    private sanitization: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private validationHandler: ValidationHandler

  ) { }

  ngOnInit(): void {
    $('.modal ').insertAfter($('body'));
    this.setCreate = true;
    this.routeUrl = this.router.url;
    var user_data = JSON.parse(sessionStorage.getItem('user_data'));
    console.log("user_data")
    console.log(user_data)
    this.submitObj.first_name = user_data.first_name
    this.submitObj.last_name = user_data.last_name
    this.submitObj.email_id = user_data.email_id
    this.submitObj.mobile_no = user_data.mobile_no
    this.submitObj.address = user_data.address
    this.submitObj.region = user_data.region
    this.submitObj.imagePath = user_data.imagePath


    // this.id = this.route.snapshot.paramMap.get('id');
    // if (this.routeUrl == '/admin/add-category-adm/' + this.id) {
    //   this.httpService.postRequest<ResponseContainer>(this.id, 'admin/getCategoryData').subscribe(datas => {
    //     if (datas.rsBody.result == 'success') {
    //       //  this.submitObj = datas.rsBody.data;
    //       this.submitObj.cat_name = datas.rsBody.data[0]['cat_name'];
    //       this.submitObj.cat_description = datas.rsBody.data[0]['cat_description'];
    //       this.submitObj.imagePath = datas.rsBody.data[0]['cat_icon'];
    //       this.setCreate = false;
    //       this.setUpdate = true;
    //     }
    //   });
    // }
  }

  /*
  * create submit
  */
  changePasswordSubmit() {
    let status = this.validationHandler.validateDOM('profileupdateBasic');
    if (!status) {
      if (!this.submitObj.new_password === this.submitObj.conf_password) return alert('Confirm Password should be the same as New Password')
      var user_id = JSON.parse(sessionStorage.getItem('login_user_id'));
      this.httpService.postRequest<ResponseContainer>({ old_password: this.submitObj.old_password, new_password: this.submitObj.new_password, user_id }, 'employee/changePassword').subscribe(datas => {
        if ( datas.rsBody.result == 'failed')
        {
          if (datas.rsBody.errors?.context?.key == 'password')
          {
            alert('Invalid Credentials')
          }
        }
        if (datas.rsBody.result == 'success') {
          this.submitObj = {old_password : "" , new_password : "" , conf_password : "" };
          $("#changesubmit").modal('show');
          this.ngOnInit();
        }
        else {
          this.validationHandler.displayErrors(datas.rsBody.errors, "profileupdateBasic", null);
        }
      });
    }
  }

  /*
  *  update submit
  */
  updateSubmit() {
    // let status = this.validationHandler.validateDOM('profileupdateBasic');
    // if (!status) {
      var user_id = JSON.parse(sessionStorage.getItem('login_user_id'));
      this.httpService.postRequest<ResponseContainer>({
        first_name : this.submitObj.first_name,
        last_name : this.submitObj.last_name || '',
        mobile_no : this.submitObj.mobile_no || '',
        address : this.submitObj.address || '',
        region : this.submitObj.region || '',
        imagePath : this.submitObj.imagePath || null,
        user_id 
      }, 'employee/editProfile').subscribe(datas => {
        
        if (datas.rsBody.result == 'success') {
          sessionStorage.setItem('user_data' , JSON.stringify({...datas.rsBody.user_data , email_id : this.submitObj.email_id}));
          $("#changesubmit").modal('show');
          this.ngOnInit();
          setTimeout(() => {
             window.location.reload();
          }, 2000);
        }
        else {
          this.validationHandler.displayErrors(datas.rsBody.errors, "profileupdateBasic", null);
        }
      });
    // }
  }


  onFocus() {
    this.validationHandler.hideErrors("profile-submit");
  }

  /* 
 * back Category page
 */
  backCategory() {
    this.router.navigate(['/admin/category-adm']);
  }

  /**
   * file upload
  */
  //  public uploader:FileUploader = new FileUploader
  //  (
  //      {url:uploadAPI}
  //  );
  //  fileSelect()
  //  {
  //    $('#profile-image-upload').click();
  //  }

  //  imageUpload() 
  //  {
  //      this.uploader.uploadAll();
  //  }

  /**
    * Convert into base 64
   */
  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    this.submitObj['imagePath'] = reader.result;
    //console.log(this.imageSrc);
  }
  removeImage() {
    this.submitObj['imagePath'] = null;
  }

}