<div class="sidebar-nav ps ps--active-y" role="nav">
    <div class="side-menu-container">
        <div class="user-wrap">
            <div class="user-side-wrap d-v-center">
                
                <div class="user-img">
                    <!-- <img src="../../../assets/img/user.png"> -->
                    
                    <label for="" *ngIf="userProfilePath != null">
                        <img [src]="userProfilePath" alt="" /><br>
                    </label>
                    
                    <label *ngIf="userProfilePath == null" >
                        <img src="../../../assets/img/user.png">
                    </label> 
                </div>

                <div class="user-info ml-3">
                    <h4>{{userDisplayName}}</h4>
                    <p>{{userDisplayRole}}</p>
                    <div class="profilenolog">
                        <a href="" routerLink="notification-adm"><span class="ti-bell"></span></a>
                        <a ref="#" routerLink="/admin/edit-profile-adm" routerLinkActive="active" class=""><span class="fa fa-edit"></span></a>
                        <a href="" class="" (click)="logout();"><span class="ti-power-off"></span></a>
                    </div>
                </div>
                <div *ngIf='userDisplayName == null'>
                    {{logout()}}
                </div>
            </div>

        </div>
        <!--menu-->

        <nav class="sidebar-menu">
            <ul>
                <li><a href="#" routerLink="/admin/dashboard-adm" routerLinkActive="active"><svg id="Layer_1"
                            data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 304">
                            <defs>
                                <style>
                                    .cls-1 {
                                        fill: #fff;
                                    }
                                </style>
                            </defs>
                            <title>dashboard</title>
                            <path class="cls-1"
                                d="M464,104H48A40,40,0,0,0,8,144V368a40,40,0,0,0,40,40H464a40,40,0,0,0,40-40V144A40,40,0,0,0,464,104ZM24,144a24,24,0,0,1,24-24H464a24,24,0,0,1,24,24v8H24ZM488,368a24,24,0,0,1-24,24H48a24,24,0,0,1-24-24V168H488Z"
                                transform="translate(-8 -104)" />
                            <path class="cls-1" d="M64,128H48a8,8,0,0,0,0,16H64a8,8,0,0,0,0-16Z"
                                transform="translate(-8 -104)" />
                            <path class="cls-1" d="M104,128H88a8,8,0,0,0,0,16h16a8,8,0,0,0,0-16Z"
                                transform="translate(-8 -104)" />
                            <path class="cls-1" d="M144,128H128a8,8,0,0,0,0,16h16a8,8,0,0,0,0-16Z"
                                transform="translate(-8 -104)" />
                            <path class="cls-1" d="M368,240h64a8,8,0,0,0,0-16H368a8,8,0,0,0,0,16Z"
                                transform="translate(-8 -104)" />
                            <path class="cls-1" d="M336,272h96a8,8,0,0,0,0-16H336a8,8,0,0,0,0,16Z"
                                transform="translate(-8 -104)" />
                            <path class="cls-1" d="M336,304h96a8,8,0,0,0,0-16H336a8,8,0,0,0,0,16Z"
                                transform="translate(-8 -104)" />
                            <path class="cls-1" d="M336,336h48a8,8,0,0,0,0-16H336a8,8,0,0,0,0,16Z"
                                transform="translate(-8 -104)" />
                            <path class="cls-1" d="M416,320h-8a8,8,0,0,0,0,16h8a8,8,0,0,0,0-16Z"
                                transform="translate(-8 -104)" />
                            <path class="cls-1" d="M336,240h8a8,8,0,0,0,0-16h-8a8,8,0,0,0,0,16Z"
                                transform="translate(-8 -104)" />
                            <path class="cls-1"
                                d="M64,360H288a8,8,0,0,0,0-16H256V280a8,8,0,0,0-8-8H216a8,8,0,0,0-8,8v64h-8V208a8,8,0,0,0-8-8H160a8,8,0,0,0-8,8V344h-8V240a8,8,0,0,0-8-8H104a8,8,0,0,0-8,8V344H64a8,8,0,0,0,0,16Zm160-72h16v56H224Zm-56-72h16V344H168Zm-56,32h16v96H112Z"
                                transform="translate(-8 -104)" />
                        </svg> Dashboard</a></li>
                <li><a href="#" routerLink="/admin/mybadges-adm" routerLinkActive="active"><svg id="Layer_1"
                            data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 304">
                            <defs>
                                <style>
                                    .cls-1 {
                                        fill: #fff;
                                    }
                                </style>
                            </defs>
                            <title>dashboard</title>
                            <path class="cls-1"
                                d="M464,104H48A40,40,0,0,0,8,144V368a40,40,0,0,0,40,40H464a40,40,0,0,0,40-40V144A40,40,0,0,0,464,104ZM24,144a24,24,0,0,1,24-24H464a24,24,0,0,1,24,24v8H24ZM488,368a24,24,0,0,1-24,24H48a24,24,0,0,1-24-24V168H488Z"
                                transform="translate(-8 -104)" />
                            <path class="cls-1" d="M64,128H48a8,8,0,0,0,0,16H64a8,8,0,0,0,0-16Z"
                                transform="translate(-8 -104)" />
                            <path class="cls-1" d="M104,128H88a8,8,0,0,0,0,16h16a8,8,0,0,0,0-16Z"
                                transform="translate(-8 -104)" />
                            <path class="cls-1" d="M144,128H128a8,8,0,0,0,0,16h16a8,8,0,0,0,0-16Z"
                                transform="translate(-8 -104)" />
                            <path class="cls-1" d="M368,240h64a8,8,0,0,0,0-16H368a8,8,0,0,0,0,16Z"
                                transform="translate(-8 -104)" />
                            <path class="cls-1" d="M336,272h96a8,8,0,0,0,0-16H336a8,8,0,0,0,0,16Z"
                                transform="translate(-8 -104)" />
                            <path class="cls-1" d="M336,304h96a8,8,0,0,0,0-16H336a8,8,0,0,0,0,16Z"
                                transform="translate(-8 -104)" />
                            <path class="cls-1" d="M336,336h48a8,8,0,0,0,0-16H336a8,8,0,0,0,0,16Z"
                                transform="translate(-8 -104)" />
                            <path class="cls-1" d="M416,320h-8a8,8,0,0,0,0,16h8a8,8,0,0,0,0-16Z"
                                transform="translate(-8 -104)" />
                            <path class="cls-1" d="M336,240h8a8,8,0,0,0,0-16h-8a8,8,0,0,0,0,16Z"
                                transform="translate(-8 -104)" />
                            <path class="cls-1"
                                d="M64,360H288a8,8,0,0,0,0-16H256V280a8,8,0,0,0-8-8H216a8,8,0,0,0-8,8v64h-8V208a8,8,0,0,0-8-8H160a8,8,0,0,0-8,8V344h-8V240a8,8,0,0,0-8-8H104a8,8,0,0,0-8,8V344H64a8,8,0,0,0,0,16Zm160-72h16v56H224Zm-56-72h16V344H168Zm-56,32h16v96H112Z"
                                transform="translate(-8 -104)" />
                        </svg> My Badges</a></li>
                <li><a href="#" routerLink="/admin/trainers-adm" routerLinkActive="active">
                        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 480">
                            <defs>
                                <style>
                                    .cls-1 {
                                        fill: #fff;
                                    }
                                </style>
                            </defs>
                            <title>trainer</title>
                            <path class="cls-1"
                                d="M472,0H8A8,8,0,0,0,0,8H0V168H16V16H464V192H184v16H472a8,8,0,0,0,8-8h0V8A8,8,0,0,0,472,0Z"
                                transform="translate(0 0)" />
                            <rect class="cls-1" x="264" y="40" width="160" height="16" />
                            <rect class="cls-1" x="288" y="88" width="160" height="16" />
                            <rect class="cls-1" x="256" y="136" width="160" height="16" />
                            <path class="cls-1"
                                d="M255.36,69a25.82,25.82,0,0,0-33.75-13.92l-.67.29L156.07,89A39.56,39.56,0,0,0,160,72,40,40,0,1,0,88.21,96h-.33a54.9,54.9,0,0,0-51,35.52L15.52,205.13a25.62,25.62,0,1,0,48.11,17.63L72,196.13V311.91a39.88,39.88,0,0,0-51.91,60.36A36.86,36.86,0,0,0,0,405v67a8,8,0,0,0,8,8H88a8,8,0,0,0,8-8h0V405a36.77,36.77,0,0,0-14.28-29h60.55A36.76,36.76,0,0,0,128,405v67a8,8,0,0,0,8,8h80a8,8,0,0,0,8-8h0V405a36.86,36.86,0,0,0-20.09-32.69A40,40,0,0,0,168,304.51V140.86l73.44-38.13A25.82,25.82,0,0,0,255.36,69ZM120,48A24,24,0,1,1,96,72,24,24,0,0,1,120,48ZM48,319.7a24,24,0,1,1-24,24A24,24,0,0,1,48,319.7ZM80,464H16V405a21,21,0,0,1,21-21H59a21,21,0,0,1,21,21Zm128-59v59H144V405a21,21,0,0,1,21-21H187A21,21,0,0,1,208,405ZM176,319.7a24,24,0,1,1-24,24A24,24,0,0,1,176,319.7ZM240.72,82.32a10.29,10.29,0,0,1-6,5.92L156.32,128.9A8,8,0,0,0,152,136V311.91A39.46,39.46,0,0,0,139.55,360H128V224H112V360H84.45A39.63,39.63,0,0,0,88,344h0V144a8,8,0,0,0-15.63-2.4l-23.89,76a9.62,9.62,0,0,1-13.34,5.32,9.82,9.82,0,0,1-4.47-12.7L52,136.63A38.65,38.65,0,0,1,87.87,112h56.64a8,8,0,0,0,3.68-.9l79.51-41.27a9.85,9.85,0,0,1,12.59,4.66A9.73,9.73,0,0,1,240.72,82.32Z"
                                transform="translate(0 0)" />
                            <path class="cls-1"
                                d="M331.91,372.27a40,40,0,1,0-56.56-.74l.74.74A36.86,36.86,0,0,0,256,405v67a8,8,0,0,0,8,8h80a8,8,0,0,0,8-8h0V405A36.86,36.86,0,0,0,331.91,372.27ZM304,319.7a24,24,0,1,1-24,24A24,24,0,0,1,304,319.7ZM336,464H272V405a21,21,0,0,1,21-21H315a21,21,0,0,1,21,21Z"
                                transform="translate(0 0)" />
                            <path class="cls-1"
                                d="M459.91,372.27a40,40,0,1,0-56.56-.74l.74.74A36.86,36.86,0,0,0,384,405v67a8,8,0,0,0,8,8h80a8,8,0,0,0,8-8h0V405A36.86,36.86,0,0,0,459.91,372.27ZM432,319.7a24,24,0,1,1-24,24A24,24,0,0,1,432,319.7ZM464,464H400V405a21,21,0,0,1,21-21H443a21,21,0,0,1,21,21Z"
                                transform="translate(0 0)" />
                        </svg> Trainers</a></li>
                <li><a href="#" routerLink="/admin/category-adm" routerLinkActive="active">
                        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1907.33 1142.18">
                            <defs>
                                <style>
                                    .cls-1 {
                                        fill: #fff;
                                    }
                                </style>
                            </defs>
                            <title>Category</title>
                            <path class="cls-1"
                                d="M881.83,1311.2v465.5a50,50,0,0,1-50,50H366.33a50,50,0,0,1-50-50V1311.2a50,50,0,0,1,50-50h183V972.41A49.81,49.81,0,0,1,599.47,923l1058.68,8.39V748.9a50,50,0,0,1,50-50h465.51a50,50,0,0,1,50,50v465.51a50,50,0,0,1-50,50H1708.15a50,50,0,0,1-50-50V1031l-318.43-2.52v247.1h182.76a50,50,0,0,1,50,50v465.51a50,50,0,0,1-50,50H1057a50,50,0,0,1-50-50V1325.57a50,50,0,0,1,50-50h182.75V1027.68L648.88,1023v238.2H831.83a50,50,0,0,1,50,50Zm590.65,429.88V1375.57H1107v365.51Zm651.18-576.67V798.9H1758.15v365.51ZM781.83,1726.7V1361.2H416.33v365.5Z"
                                transform="translate(-316.33 -698.9)" />
                        </svg> Category</a></li>
                <li><a href="#" routerLink="/admin/learning-pathways-adm" routerLinkActive="active">
                        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 62">
                            <defs>
                                <style>
                                    .cls-1 {
                                        fill: #fff;
                                    }
                                </style>
                            </defs>
                            <title>pathway</title>
                            <path class="cls-1"
                                d="M4.2,21.45,2.72,23.93l5.34,5.34,2.49-1.48c.32.15.64.28,1,.4l.71,2.8h7.56l.71-2.8c.32-.12.64-.25,1-.4l2.49,1.48,5.34-5.34L27.8,21.45c.15-.32.28-.64.4-1l2.8-.71V12.22l-2.8-.71c-.12-.32-.25-.64-.4-1l1.48-2.49L23.94,2.72,21.45,4.2c-.32-.15-.64-.28-1-.4L19.78,1H12.22l-.71,2.8c-.32.12-.64.25-1,.4L8.06,2.72,2.72,8.06,4.2,10.55q-.22.48-.4,1L1,12.22v7.56l2.8.71Q4,21,4.2,21.45ZM3,13.78l2.35-.6.17-.53A11,11,0,0,1,6.23,11l.26-.49L5.24,8.38,8.38,5.24l2.1,1.25L11,6.23a11,11,0,0,1,1.69-.7l.53-.17L13.78,3h4.44l.6,2.35.53.17a11,11,0,0,1,1.69.7l.49.25,2.09-1.25,3.14,3.14-1.25,2.1.25.49a11,11,0,0,1,.7,1.68l.17.53,2.35.6v4.44l-2.35.6-.17.53a11,11,0,0,1-.7,1.68l-.26.49,1.25,2.1-3.14,3.14-2.09-1.25-.49.25a11,11,0,0,1-1.69.7l-.53.17L18.22,29H13.78l-.6-2.35-.53-.17a11,11,0,0,1-1.69-.7l-.49-.25-2.1,1.25L5.24,23.62l1.25-2.1L6.23,21a11,11,0,0,1-.7-1.68l-.17-.53L3,18.22Z"
                                transform="translate(-1 -1)" />
                            <path class="cls-1"
                                d="M16,25a9,9,0,1,0-9-9A9,9,0,0,0,16,25ZM16,9a7,7,0,1,1-7,7A7,7,0,0,1,16,9Z"
                                transform="translate(-1 -1)" />
                            <path class="cls-1"
                                d="M58,53H29.76l-5.32,2.66a2.62,2.62,0,0,0,0,4.68L29.76,63H58a5,5,0,0,0,0-10ZM31,59H55v2H31Zm24-2H31V55H55ZM25,58a.62.62,0,0,1,.34-.55L29,55.62v4.76l-3.66-1.83A.62.62,0,0,1,25,58Zm33,3H57V55h1a3,3,0,0,1,0,6Z"
                                transform="translate(-1 -1)" />
                            <path class="cls-1"
                                d="M53,15h-.14a4,4,0,0,0-7.72,0H33v2H45.14a4,4,0,0,0,7.72,0H53a8,8,0,0,1,8,8v6a8,8,0,0,1-8,8H49.86a4,4,0,0,0-7.72,0H35.86a4,4,0,0,0-7.72,0H21.86a4,4,0,0,0-7.72,0H11a10,10,0,0,0,0,20H21V57H11a8,8,0,0,1,0-16h3.14a4,4,0,0,0,7.72,0h6.28a4,4,0,0,0,7.72,0h6.28a4,4,0,0,0,7.72,0H53A10,10,0,0,0,63,31V25A10,10,0,0,0,53,15Zm-4,3a2,2,0,1,1,2-2A2,2,0,0,1,49,18ZM18,42a2,2,0,1,1,2-2A2,2,0,0,1,18,42Zm14,0a2,2,0,1,1,2-2A2,2,0,0,1,32,42Zm14,0a2,2,0,1,1,2-2A2,2,0,0,1,46,42Z"
                                transform="translate(-1 -1)" />
                            <rect class="cls-1" x="32" y="21" width="14" height="2" />
                            <rect class="cls-1" x="32" y="25" width="14" height="2" />
                            <rect class="cls-1" x="40" y="29" width="2" height="2" />
                            <rect class="cls-1" x="36" y="29" width="2" height="2" />
                            <rect class="cls-1" x="44" y="29" width="2" height="2" />
                            <rect class="cls-1" x="32" y="29" width="2" height="2" />
                            <rect class="cls-1" x="16" y="47" width="2" height="2" />
                            <rect class="cls-1" x="12" y="47" width="2" height="2" />
                            <rect class="cls-1" x="4" y="47" width="2" height="2" />
                            <rect class="cls-1" x="8" y="47" width="2" height="2" />
                        </svg> Learning Pathways</a></li>
                <li><a href="#" routerLink="/admin/assign-leader" routerLinkActive="active">
                        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 62">
                            <defs>
                                <style>
                                    .cls-1 {
                                        fill: #fff;
                                    }
                                </style>
                            </defs>
                            <title>team leader</title>
                            <path class="cls-1"
                                d="M4.2,21.45,2.72,23.93l5.34,5.34,2.49-1.48c.32.15.64.28,1,.4l.71,2.8h7.56l.71-2.8c.32-.12.64-.25,1-.4l2.49,1.48,5.34-5.34L27.8,21.45c.15-.32.28-.64.4-1l2.8-.71V12.22l-2.8-.71c-.12-.32-.25-.64-.4-1l1.48-2.49L23.94,2.72,21.45,4.2c-.32-.15-.64-.28-1-.4L19.78,1H12.22l-.71,2.8c-.32.12-.64.25-1,.4L8.06,2.72,2.72,8.06,4.2,10.55q-.22.48-.4,1L1,12.22v7.56l2.8.71Q4,21,4.2,21.45ZM3,13.78l2.35-.6.17-.53A11,11,0,0,1,6.23,11l.26-.49L5.24,8.38,8.38,5.24l2.1,1.25L11,6.23a11,11,0,0,1,1.69-.7l.53-.17L13.78,3h4.44l.6,2.35.53.17a11,11,0,0,1,1.69.7l.49.25,2.09-1.25,3.14,3.14-1.25,2.1.25.49a11,11,0,0,1,.7,1.68l.17.53,2.35.6v4.44l-2.35.6-.17.53a11,11,0,0,1-.7,1.68l-.26.49,1.25,2.1-3.14,3.14-2.09-1.25-.49.25a11,11,0,0,1-1.69.7l-.53.17L18.22,29H13.78l-.6-2.35-.53-.17a11,11,0,0,1-1.69-.7l-.49-.25-2.1,1.25L5.24,23.62l1.25-2.1L6.23,21a11,11,0,0,1-.7-1.68l-.17-.53L3,18.22Z"
                                transform="translate(-1 -1)" />
                            <path class="cls-1"
                                d="M16,25a9,9,0,1,0-9-9A9,9,0,0,0,16,25ZM16,9a7,7,0,1,1-7,7A7,7,0,0,1,16,9Z"
                                transform="translate(-1 -1)" />
                            <path class="cls-1"
                                d="M58,53H29.76l-5.32,2.66a2.62,2.62,0,0,0,0,4.68L29.76,63H58a5,5,0,0,0,0-10ZM31,59H55v2H31Zm24-2H31V55H55ZM25,58a.62.62,0,0,1,.34-.55L29,55.62v4.76l-3.66-1.83A.62.62,0,0,1,25,58Zm33,3H57V55h1a3,3,0,0,1,0,6Z"
                                transform="translate(-1 -1)" />
                            <path class="cls-1"
                                d="M53,15h-.14a4,4,0,0,0-7.72,0H33v2H45.14a4,4,0,0,0,7.72,0H53a8,8,0,0,1,8,8v6a8,8,0,0,1-8,8H49.86a4,4,0,0,0-7.72,0H35.86a4,4,0,0,0-7.72,0H21.86a4,4,0,0,0-7.72,0H11a10,10,0,0,0,0,20H21V57H11a8,8,0,0,1,0-16h3.14a4,4,0,0,0,7.72,0h6.28a4,4,0,0,0,7.72,0h6.28a4,4,0,0,0,7.72,0H53A10,10,0,0,0,63,31V25A10,10,0,0,0,53,15Zm-4,3a2,2,0,1,1,2-2A2,2,0,0,1,49,18ZM18,42a2,2,0,1,1,2-2A2,2,0,0,1,18,42Zm14,0a2,2,0,1,1,2-2A2,2,0,0,1,32,42Zm14,0a2,2,0,1,1,2-2A2,2,0,0,1,46,42Z"
                                transform="translate(-1 -1)" />
                            <rect class="cls-1" x="32" y="21" width="14" height="2" />
                            <rect class="cls-1" x="32" y="25" width="14" height="2" />
                            <rect class="cls-1" x="40" y="29" width="2" height="2" />
                            <rect class="cls-1" x="36" y="29" width="2" height="2" />
                            <rect class="cls-1" x="44" y="29" width="2" height="2" />
                            <rect class="cls-1" x="32" y="29" width="2" height="2" />
                            <rect class="cls-1" x="16" y="47" width="2" height="2" />
                            <rect class="cls-1" x="12" y="47" width="2" height="2" />
                            <rect class="cls-1" x="4" y="47" width="2" height="2" />
                            <rect class="cls-1" x="8" y="47" width="2" height="2" />
                        </svg> Assign Leaders</a></li>

                <li><a href="#" routerLink="/admin/teamleaders-adm" routerLinkActive="active">
                        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 62">
                            <defs>
                                <style>
                                    .cls-1 {
                                        fill: #fff;
                                    }
                                </style>
                            </defs>
                            <title>team leader</title>
                            <path class="cls-1"
                                d="M4.2,21.45,2.72,23.93l5.34,5.34,2.49-1.48c.32.15.64.28,1,.4l.71,2.8h7.56l.71-2.8c.32-.12.64-.25,1-.4l2.49,1.48,5.34-5.34L27.8,21.45c.15-.32.28-.64.4-1l2.8-.71V12.22l-2.8-.71c-.12-.32-.25-.64-.4-1l1.48-2.49L23.94,2.72,21.45,4.2c-.32-.15-.64-.28-1-.4L19.78,1H12.22l-.71,2.8c-.32.12-.64.25-1,.4L8.06,2.72,2.72,8.06,4.2,10.55q-.22.48-.4,1L1,12.22v7.56l2.8.71Q4,21,4.2,21.45ZM3,13.78l2.35-.6.17-.53A11,11,0,0,1,6.23,11l.26-.49L5.24,8.38,8.38,5.24l2.1,1.25L11,6.23a11,11,0,0,1,1.69-.7l.53-.17L13.78,3h4.44l.6,2.35.53.17a11,11,0,0,1,1.69.7l.49.25,2.09-1.25,3.14,3.14-1.25,2.1.25.49a11,11,0,0,1,.7,1.68l.17.53,2.35.6v4.44l-2.35.6-.17.53a11,11,0,0,1-.7,1.68l-.26.49,1.25,2.1-3.14,3.14-2.09-1.25-.49.25a11,11,0,0,1-1.69.7l-.53.17L18.22,29H13.78l-.6-2.35-.53-.17a11,11,0,0,1-1.69-.7l-.49-.25-2.1,1.25L5.24,23.62l1.25-2.1L6.23,21a11,11,0,0,1-.7-1.68l-.17-.53L3,18.22Z"
                                transform="translate(-1 -1)" />
                            <path class="cls-1"
                                d="M16,25a9,9,0,1,0-9-9A9,9,0,0,0,16,25ZM16,9a7,7,0,1,1-7,7A7,7,0,0,1,16,9Z"
                                transform="translate(-1 -1)" />
                            <path class="cls-1"
                                d="M58,53H29.76l-5.32,2.66a2.62,2.62,0,0,0,0,4.68L29.76,63H58a5,5,0,0,0,0-10ZM31,59H55v2H31Zm24-2H31V55H55ZM25,58a.62.62,0,0,1,.34-.55L29,55.62v4.76l-3.66-1.83A.62.62,0,0,1,25,58Zm33,3H57V55h1a3,3,0,0,1,0,6Z"
                                transform="translate(-1 -1)" />
                            <path class="cls-1"
                                d="M53,15h-.14a4,4,0,0,0-7.72,0H33v2H45.14a4,4,0,0,0,7.72,0H53a8,8,0,0,1,8,8v6a8,8,0,0,1-8,8H49.86a4,4,0,0,0-7.72,0H35.86a4,4,0,0,0-7.72,0H21.86a4,4,0,0,0-7.72,0H11a10,10,0,0,0,0,20H21V57H11a8,8,0,0,1,0-16h3.14a4,4,0,0,0,7.72,0h6.28a4,4,0,0,0,7.72,0h6.28a4,4,0,0,0,7.72,0H53A10,10,0,0,0,63,31V25A10,10,0,0,0,53,15Zm-4,3a2,2,0,1,1,2-2A2,2,0,0,1,49,18ZM18,42a2,2,0,1,1,2-2A2,2,0,0,1,18,42Zm14,0a2,2,0,1,1,2-2A2,2,0,0,1,32,42Zm14,0a2,2,0,1,1,2-2A2,2,0,0,1,46,42Z"
                                transform="translate(-1 -1)" />
                            <rect class="cls-1" x="32" y="21" width="14" height="2" />
                            <rect class="cls-1" x="32" y="25" width="14" height="2" />
                            <rect class="cls-1" x="40" y="29" width="2" height="2" />
                            <rect class="cls-1" x="36" y="29" width="2" height="2" />
                            <rect class="cls-1" x="44" y="29" width="2" height="2" />
                            <rect class="cls-1" x="32" y="29" width="2" height="2" />
                            <rect class="cls-1" x="16" y="47" width="2" height="2" />
                            <rect class="cls-1" x="12" y="47" width="2" height="2" />
                            <rect class="cls-1" x="4" y="47" width="2" height="2" />
                            <rect class="cls-1" x="8" y="47" width="2" height="2" />
                        </svg> Team Leaders</a></li>
                <li><a href="#" routerLink="/admin/super-teamleaders-adm" routerLinkActive="active">
                        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 62">
                            <defs>
                                <style>
                                    .cls-1 {
                                        fill: #fff;
                                    }
                                </style>
                            </defs>
                            <title>team leader</title>
                            <path class="cls-1"
                                d="M4.2,21.45,2.72,23.93l5.34,5.34,2.49-1.48c.32.15.64.28,1,.4l.71,2.8h7.56l.71-2.8c.32-.12.64-.25,1-.4l2.49,1.48,5.34-5.34L27.8,21.45c.15-.32.28-.64.4-1l2.8-.71V12.22l-2.8-.71c-.12-.32-.25-.64-.4-1l1.48-2.49L23.94,2.72,21.45,4.2c-.32-.15-.64-.28-1-.4L19.78,1H12.22l-.71,2.8c-.32.12-.64.25-1,.4L8.06,2.72,2.72,8.06,4.2,10.55q-.22.48-.4,1L1,12.22v7.56l2.8.71Q4,21,4.2,21.45ZM3,13.78l2.35-.6.17-.53A11,11,0,0,1,6.23,11l.26-.49L5.24,8.38,8.38,5.24l2.1,1.25L11,6.23a11,11,0,0,1,1.69-.7l.53-.17L13.78,3h4.44l.6,2.35.53.17a11,11,0,0,1,1.69.7l.49.25,2.09-1.25,3.14,3.14-1.25,2.1.25.49a11,11,0,0,1,.7,1.68l.17.53,2.35.6v4.44l-2.35.6-.17.53a11,11,0,0,1-.7,1.68l-.26.49,1.25,2.1-3.14,3.14-2.09-1.25-.49.25a11,11,0,0,1-1.69.7l-.53.17L18.22,29H13.78l-.6-2.35-.53-.17a11,11,0,0,1-1.69-.7l-.49-.25-2.1,1.25L5.24,23.62l1.25-2.1L6.23,21a11,11,0,0,1-.7-1.68l-.17-.53L3,18.22Z"
                                transform="translate(-1 -1)" />
                            <path class="cls-1"
                                d="M16,25a9,9,0,1,0-9-9A9,9,0,0,0,16,25ZM16,9a7,7,0,1,1-7,7A7,7,0,0,1,16,9Z"
                                transform="translate(-1 -1)" />
                            <path class="cls-1"
                                d="M58,53H29.76l-5.32,2.66a2.62,2.62,0,0,0,0,4.68L29.76,63H58a5,5,0,0,0,0-10ZM31,59H55v2H31Zm24-2H31V55H55ZM25,58a.62.62,0,0,1,.34-.55L29,55.62v4.76l-3.66-1.83A.62.62,0,0,1,25,58Zm33,3H57V55h1a3,3,0,0,1,0,6Z"
                                transform="translate(-1 -1)" />
                            <path class="cls-1"
                                d="M53,15h-.14a4,4,0,0,0-7.72,0H33v2H45.14a4,4,0,0,0,7.72,0H53a8,8,0,0,1,8,8v6a8,8,0,0,1-8,8H49.86a4,4,0,0,0-7.72,0H35.86a4,4,0,0,0-7.72,0H21.86a4,4,0,0,0-7.72,0H11a10,10,0,0,0,0,20H21V57H11a8,8,0,0,1,0-16h3.14a4,4,0,0,0,7.72,0h6.28a4,4,0,0,0,7.72,0h6.28a4,4,0,0,0,7.72,0H53A10,10,0,0,0,63,31V25A10,10,0,0,0,53,15Zm-4,3a2,2,0,1,1,2-2A2,2,0,0,1,49,18ZM18,42a2,2,0,1,1,2-2A2,2,0,0,1,18,42Zm14,0a2,2,0,1,1,2-2A2,2,0,0,1,32,42Zm14,0a2,2,0,1,1,2-2A2,2,0,0,1,46,42Z"
                                transform="translate(-1 -1)" />
                            <rect class="cls-1" x="32" y="21" width="14" height="2" />
                            <rect class="cls-1" x="32" y="25" width="14" height="2" />
                            <rect class="cls-1" x="40" y="29" width="2" height="2" />
                            <rect class="cls-1" x="36" y="29" width="2" height="2" />
                            <rect class="cls-1" x="44" y="29" width="2" height="2" />
                            <rect class="cls-1" x="32" y="29" width="2" height="2" />
                            <rect class="cls-1" x="16" y="47" width="2" height="2" />
                            <rect class="cls-1" x="12" y="47" width="2" height="2" />
                            <rect class="cls-1" x="4" y="47" width="2" height="2" />
                            <rect class="cls-1" x="8" y="47" width="2" height="2" />
                        </svg> Super Team Leader</a></li>
                <li><a href="#" routerLink="/admin/employee-adm" routerLinkActive="active">
                        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 62">
                            <defs>
                                <style>
                                    .cls-1 {
                                        fill: #fff;
                                    }
                                </style>
                            </defs>
                            <title>team leader</title>
                            <path class="cls-1"
                                d="M4.2,21.45,2.72,23.93l5.34,5.34,2.49-1.48c.32.15.64.28,1,.4l.71,2.8h7.56l.71-2.8c.32-.12.64-.25,1-.4l2.49,1.48,5.34-5.34L27.8,21.45c.15-.32.28-.64.4-1l2.8-.71V12.22l-2.8-.71c-.12-.32-.25-.64-.4-1l1.48-2.49L23.94,2.72,21.45,4.2c-.32-.15-.64-.28-1-.4L19.78,1H12.22l-.71,2.8c-.32.12-.64.25-1,.4L8.06,2.72,2.72,8.06,4.2,10.55q-.22.48-.4,1L1,12.22v7.56l2.8.71Q4,21,4.2,21.45ZM3,13.78l2.35-.6.17-.53A11,11,0,0,1,6.23,11l.26-.49L5.24,8.38,8.38,5.24l2.1,1.25L11,6.23a11,11,0,0,1,1.69-.7l.53-.17L13.78,3h4.44l.6,2.35.53.17a11,11,0,0,1,1.69.7l.49.25,2.09-1.25,3.14,3.14-1.25,2.1.25.49a11,11,0,0,1,.7,1.68l.17.53,2.35.6v4.44l-2.35.6-.17.53a11,11,0,0,1-.7,1.68l-.26.49,1.25,2.1-3.14,3.14-2.09-1.25-.49.25a11,11,0,0,1-1.69.7l-.53.17L18.22,29H13.78l-.6-2.35-.53-.17a11,11,0,0,1-1.69-.7l-.49-.25-2.1,1.25L5.24,23.62l1.25-2.1L6.23,21a11,11,0,0,1-.7-1.68l-.17-.53L3,18.22Z"
                                transform="translate(-1 -1)" />
                            <path class="cls-1"
                                d="M16,25a9,9,0,1,0-9-9A9,9,0,0,0,16,25ZM16,9a7,7,0,1,1-7,7A7,7,0,0,1,16,9Z"
                                transform="translate(-1 -1)" />
                            <path class="cls-1"
                                d="M58,53H29.76l-5.32,2.66a2.62,2.62,0,0,0,0,4.68L29.76,63H58a5,5,0,0,0,0-10ZM31,59H55v2H31Zm24-2H31V55H55ZM25,58a.62.62,0,0,1,.34-.55L29,55.62v4.76l-3.66-1.83A.62.62,0,0,1,25,58Zm33,3H57V55h1a3,3,0,0,1,0,6Z"
                                transform="translate(-1 -1)" />
                            <path class="cls-1"
                                d="M53,15h-.14a4,4,0,0,0-7.72,0H33v2H45.14a4,4,0,0,0,7.72,0H53a8,8,0,0,1,8,8v6a8,8,0,0,1-8,8H49.86a4,4,0,0,0-7.72,0H35.86a4,4,0,0,0-7.72,0H21.86a4,4,0,0,0-7.72,0H11a10,10,0,0,0,0,20H21V57H11a8,8,0,0,1,0-16h3.14a4,4,0,0,0,7.72,0h6.28a4,4,0,0,0,7.72,0h6.28a4,4,0,0,0,7.72,0H53A10,10,0,0,0,63,31V25A10,10,0,0,0,53,15Zm-4,3a2,2,0,1,1,2-2A2,2,0,0,1,49,18ZM18,42a2,2,0,1,1,2-2A2,2,0,0,1,18,42Zm14,0a2,2,0,1,1,2-2A2,2,0,0,1,32,42Zm14,0a2,2,0,1,1,2-2A2,2,0,0,1,46,42Z"
                                transform="translate(-1 -1)" />
                            <rect class="cls-1" x="32" y="21" width="14" height="2" />
                            <rect class="cls-1" x="32" y="25" width="14" height="2" />
                            <rect class="cls-1" x="40" y="29" width="2" height="2" />
                            <rect class="cls-1" x="36" y="29" width="2" height="2" />
                            <rect class="cls-1" x="44" y="29" width="2" height="2" />
                            <rect class="cls-1" x="32" y="29" width="2" height="2" />
                            <rect class="cls-1" x="16" y="47" width="2" height="2" />
                            <rect class="cls-1" x="12" y="47" width="2" height="2" />
                            <rect class="cls-1" x="4" y="47" width="2" height="2" />
                            <rect class="cls-1" x="8" y="47" width="2" height="2" />
                        </svg> Employee</a></li>
            </ul>
        </nav>

    </div>
</div>