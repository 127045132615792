import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';

import {ResponseContainer} from '../../../service/ResponseContainer';
import {RequestContainer} from '../../../service/RequestContainer'; 
import * as $ from 'jquery';
declare var $ :any;  

@Component({
  selector: 'app-employee-report-trn',
  templateUrl: 'employee-report-trn.component.html'
}) 
export class EmployeeReportTrnComponent implements OnInit {
  public submitObj:any = {};
  public targetIndex:Object = {};
  public setPageNo:boolean = false;
  public setPageNoNext:boolean = false;
  public pageNo:any = {};
  public routeUrl:Object = {};
  public responseData:any = {}; 
  public id:any = {}; 
  
  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;
 
  constructor(
    private router:Router,
     private httpService : HttpService, 
     private route: ActivatedRoute
   )
   {}  
  // ngOnInit(): void {
  //   var requestObj = new RequestContainer();
  //   requestObj['pageNo'] = 0; 
  //   this.pageNo = requestObj['pageNo'];
  //   this.httpService.postRequest<ResponseContainer>(requestObj,'trainer/getEmployee').subscribe(
  //     datas=> {
  //       if(datas.rsBody.result == 'success')
  //       {
  //         this.responseData = datas.rsBody.data;
  //         if(this.responseData.length == 9) 
  //         {  
  //             this.setPageNoNext = true;  
  //         }  
  //         else
  //         {
  //             this.setPageNoNext = false;
  //         }
  //         if(this.pageNo == 0)
  //         {
  //             this.setPageNo = false;
  //         }
  //         else
  //         { 
  //             this.setPageNo = true;
  //         }
  //       } 
  //       else{
  //         console.log(datas.rsBody.error);
  //       }
  //     });    
  // }



  ngOnInit(): void {
  //   $(document).ready(function() {
  //     $(".e-rprt_title").click(function(e) {
  //         var accordionitem = $(this).attr("data-tab");
  //         $("#" + accordionitem).slideToggle().parent().siblings().find(".accordion-content").slideUp();
  //         $(this).toggleClass("active-title");
  //         $("#" + accordionitem).parent().siblings().find(".e-rprt_title").removeClass("active-title");
  //         $("i.fa-chevron-down", this).toggleClass("chevron-top");
  //         $("#" + accordionitem).parent().siblings().find(".e-rprt_title i.fa-chevron-down").removeClass("chevron-top");
  //     });
  // });
    $('.modal ').insertAfter($('body')); 
    var requestObj = new RequestContainer(); 
    // requestObj['pageNo'] = 0;  
    // this.pageNo = requestObj['pageNo'];
    var currentUser = JSON.parse(sessionStorage.getItem('login_id')); 
    requestObj['empId'] =  currentUser;  
    requestObj['employeeId']  =  this.route.snapshot.paramMap.get('id'); 
    this.httpService.postRequest<ResponseContainer>(requestObj,'trainer/getAnswersReport').subscribe(
      datas=> {
        if(datas.rsBody.result == 'success') 
        {
          this.responseData = datas.rsBody.data;
          // if(this.responseData.length == 1) 
          // {
          //     this.setPageNoNext = true;   
          // }  
          // else
          // {
          //     this.setPageNoNext = false;
          // }
          // if(this.pageNo == 0)
          // {
          //     this.setPageNo = false;
          // }
          // else
          // {
          //     this.setPageNo = true;
          // }
        } 
        else{
          console.log(datas.rsBody.error); 
        }  
      });
  }



  toggleData(item){
    var accordionitem = $('#e-rprt_title'+item).attr("data-tab")+item;
    $("#" + accordionitem).slideToggle().parent().siblings().find("#accordion-content"+item).slideUp();
    $('#e-rprt_title'+item).toggleClass("active-title");
    $("#" + accordionitem).parent().siblings().find("#e-rprt_title"+item).removeClass("active-title");
    $("i.fa-chevron-down", '#e-rprt_title'+item).toggleClass("chevron-top");
    $("#" + accordionitem).parent().siblings().find("#e-rprt_title"+item+"i.fa-chevron-down").removeClass("chevron-top");
  }
  /*
  * update status of answer (Approve or Reject) 
  */ 
 answerStatusUpdate(answer_id, status){
    this.submitObj.answer_id = answer_id;
    this.submitObj.status = status;

    this.httpService.postRequest<ResponseContainer>(this.submitObj,'trainer/answerStatusUpdate').subscribe(datas => {
      if(datas.rsBody.result == 'success')
      {
        this.submitObj = {};
       // $("#changesubmit").modal('show');
        this.ngOnInit();
      } 
      else
      { 
       console.log('error');
      }
     });
  }
  /*
 * View Profile
 */
 viewProfile(id)
 { 
  this.router.navigate(['/trainers/employee-profile-trn',id]);
 } 
 
 backEmpList()
 { 
  this.router.navigate(['/trainers/assigned-employee-list-trn']);
 } 
 
}
 