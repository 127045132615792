<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12"> 
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item" (click)="backCategory();"><a href="javascript:void(0);">Category</a></li>
                        <li class="breadcrumb-item" (click)="listSkill();"><a href="javascript:void(0);">Skill List</a></li>
                        <li class="breadcrumb-item active" *ngIf='setCreate'><a href="javascript:void(0);">Create New Skill</a></li>
                        <li class="breadcrumb-item active" *ngIf='setUpdate'><a href="javascript:void(0);">Update Skill</a></li>
                    </ol>
                </div>
            </div>
        </div> 


        <div class="row"> 
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                    <div class="title">
                        <h4>Categories</h4>
                        </div>
                        
                        <a class="search-wrapper"  routerLink="/admin/add-category-adm">
                        <p>Add Category</p>
                            <span><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
	<g>
		<path d="M492,236H276V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v216H20c-11.046,0-20,8.954-20,20s8.954,20,20,20h216
			v216c0,11.046,8.954,20,20,20s20-8.954,20-20V276h216c11.046,0,20-8.954,20-20C512,244.954,503.046,236,492,236z"/>
	</g>
                                </g></svg></span>
                            </a>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row" id="profile-submit"> 
            <div class="create-n-ctg col-md-12 mr-auto ml-auto">
                <div class="card mt-3">
                    <div class="card-body">
                        <form class="form" id="profileupdateBasic" role="form" autocomplete="off" #catForm="ngForm">
                            <div class="row">
                            <div class="form-group col-6"> 
                                <label class="col-form-label form-control-label">Name:</label>
                                <div>
                                    <input class="form-control" type="text" placeholder="Enter your name" name="cat_name" [(ngModel)]="responseData.cat_name" disabled>
                                    <input class="form-control" type="text" placeholder="Enter your name" name="cat_id"  [(ngModel)]="submitObj.cat_id" hidden>
                                </div> 
                            </div>
                            <div class="form-group col-6">
                                <label class="col-form-label form-control-label">Description</label> 
                                <div>
                                    <input class="form-control" type="text" placeholder="cat Description" name="cat_description" [(ngModel)]="responseData.cat_description" disabled>
                                </div>
                            </div>
                             
                            <div class="form-group col-6">
                                <label class="col-form-label form-control-label">Skill Name:</label> 
                                <div>
                                    <div class="d-v-center">
                                        <input class="form-control" placeholder="Skill name" name="skill_name" id="skill_name" [(ngModel)]="submitObj.skill_name" checkMandatory="true" (focus)="onFocus()">

                                        <!-- <div class="select-lvl ml-2"><a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" class="dropdown-toggle"><span>Choose Level</span><i class="fa fa-caret-down"></i></a><ul aria-labelledby="dropdownMenuLink" class="dropdown-menu"><li><a href="#" class="dropdown-item d-v-center"><span class="sts text-danger mr-2"><i class="fa fa-circle"></i></span> Beginner</a></li><li><a href="#" class="dropdown-item"><span class="sts text-warning mr-2"><i class="fa fa-circle"></i></span> Intermediate</a></li><li><a href="#" class="dropdown-item"><span class="sts text-success mr-2"><i class="fa fa-circle"></i></span> Expert</a></li></ul></div> -->
                                    </div>
                                    <span class="ng-hide displayError" id="skill_name_error"></span>

                                </div>
                            </div>

                            <div class="form-group col-6">
                                <label class="col-form-label form-control-label">Skill Description</label> 
                                <div>
                                    <input class="form-control" type="text" placeholder="Skill Description" name="skill_description" id="skill_description" [(ngModel)]="submitObj.skill_description" checkMandatory="true" (focus)="onFocus()">
                                    <span class="ng-hide displayError" id="skill_description_error"></span>

                                </div>

                            </div> 

 
                            <div class="form-group col-6" id="imagePath">
                                <label class="col-form-label form-control-label">Skill Icon:</label>
                                <div>
                                    <div id="collapseOne" class="panel-collapse collapse show" role="tabpanel" aria-labelledby="headingOne">
                                        <div>
                                          <label for="" *ngIf="submitObj.imagePath != null">
                                            <img [src]="submitObj.imagePath" alt="" style="height: 100px !important; width: 100px !important;" /><br>
                                            <a href="javascript:void(0);" class="text-danger" (click)="removeImage();"><i class="fa fa-trash-o" aria-hidden="true"></i> Remove Category Image</a>
                                          </label>
                                            <label *ngIf="submitObj.imagePath == null" for="profile-image-upload" class="form-control text-primary" style="cursor: pointer;"><i class="ti-gallery mr-2"></i>Set Category Image
                                             <input id="profile-image-upload" type="file" class="form-control btn btn-info" accept="image/*" name="profilePhoto" (change)="handleInputChange($event)" style="display: none;"/>
                                            </label>

                                        </div> 
                                    </div> 
                                    <span class="ng-hide displayError" id="imagePath_error"></span>
                                </div> 
                            </div>  
                            <div class="form-group col-6"> 
                                <div class="float-right">
                                <div class="d-v-center">
                                    <!-- <input type="reset" class="btn btn-secondary mr-2" value="Cancel"> -->
                                    <input *ngIf='setCreate' type="reset" class="btn btn-secondary mr-2" (click)="backCategory();" value="Cancel">
                                    <input *ngIf='setCreate' type="submit" class="btn btn-warning" (click)="createSubmit();" value="Save">
                                    <input *ngIf='setUpdate' type="submit" class="btn btn-secondary" (click)="updateSubmit();" value="Update">  
                                </div>
                              </div>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--successsfull-modal-->
<div class="modal fade"  id="changesubmit" tabindex="-1"  role="dialog" aria-labelledby="trash-mdl" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>Submission Successfull</p>
        </div>
          <div class="mdl-footer d-v-center">
              <button class="btn btn-black btn-block m-0" (click)="listSkill()">Ok</button>
          </div> 
      </div>
    </div>
</div> 