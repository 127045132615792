<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item" (click)="backTeamLead();"><a href="javascript:void(0);">TeamLeader List</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Assign Employee</a></li>
                    </ol>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                        <div class="title">
                            <h4>Trainer</h4>
                        </div>
                    </div>

                    <div class="c-grid" *ngIf='responseData.length > 0'>
                        <div class="widget-profile d-v-center" *ngFor="let item of responseData let i=index">
                            <div class="widget-img-holder">
                                <div>
                                    <img src="../../../../assets/img/Profile.png">
                                </div>
                                <span><i class="fa fa-circle mr-2 text-danger"></i></span>
                            </div> 
                            <h3 class="widget-content">
                                <span class="w-title">{{item.first_name}}</span> 
                                <span class="w-val">#{{item.user_id}}</span>
                            </h3>

                            <div class="t-menu-profile">
                                <a class="t-drp dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="ti-more-alt"></i></a>
                                <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                    <li><a class="dropdown-item d-v-center" href="javascript:void(0);" (click)="assignUpdate(item.user_id)"><span class="sts mr-2"><i class="fa fa-user-plus" aria-hidden="true"></i>
                                            </span> Assign</a></li>
                                    <li><a class="dropdown-item"  href="javascript:void(0);" (click)="viewProfile(item.id)"><span class="sts  mr-2"><i class="fa fa-user-circle" aria-hidden="true"></i>
                                            </span> View Profile</a></li>
                                </ul>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="mr-auto ml-auto">
    <nav class="pagination-wrap">
        <ul class="pagination">
            <li class="page-item" *ngIf='setPageNo' class="page-item" (click)="previousSubmit();">
                <a class="page-link" href="javascript:void(0);" aria-label="Previous">
                    <span aria-hidden="true">«</span>
                    <span class="sr-only">Previous</span>
                </a>
            </li>
          
            <li class="page-item" *ngIf='setPageNoNext' class="page-item" (click)="nextSubmit();">
                <a class="page-link" href="javascript:void(0);" aria-label="Next">
                    <span aria-hidden="true">»</span>
                    <span class="sr-only">Next</span>
                </a>
            </li>

        </ul> 
    </nav> 
</div> 