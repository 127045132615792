import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-employee-list-trn',
  templateUrl: 'employee-list-trn.component.html'
})
export class EmployeeListTrnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
