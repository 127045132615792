<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Assigned Badges</a></li>
                    </ol>
                </div>
            </div>
        </div>

        <!-- <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                        <div class="title">
                            <h4>Assigned Badges</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="row badge-view-wrapper mt-0 pt-0 px-0">
            <div class="col-md-12">
                <h5 class="title">All badges</h5>
                <hr>
                <ul class="badge-overview d-v-center flex-wrap flex-baseline mt-3">
                    <li *ngFor="let item of responseData let i=index">
                        <div class="badge-card" *ngIf="item.badge_id == 1" (click)="taskList(item.assign_by, item.id, item.badge_id)">
                            <div class="card btn-brd">
                                <div class="card-body skill-set" title="Task : {{item.ReprotPercent}}% and Time : {{item.remaining_days}} days left">
                                    <div class="c_bar b-{{item.ReprotPercent}}">
                                        <circle-progress [percent]="100-item.BadgeDecay_1" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#0078a1'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'transparent'" [class]="'pgrsbar'" [imageSrc]="item.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                                        </circle-progress>
                                    </div>
                                    <div class="prg_content mt-3">
                                        <!-- <h5 class="mb-2">Beginner</h5> -->
                                        <span class="ctskilnm">{{item.cat_skill_name}}</span>
                                        <span class="ctnme">{{item.cat_name}}</span>
                                        <hr>
                                        <span class="expbadgeas"><strong>Exp:</strong> {{item.expiry_1}}</span>
                                    </div>
                                </div>
                            </div>
                           <span *ngIf="item.ReprotPercent == 100?assignedBadge(item.assign_by, item.id, item.badge_id):false"></span>
                           <span *ngIf="100-item.BadgeDecay_1 == 0?updateStatus(item.assign_by, item.id, item.badge_id):false"></span>

                        </div>

                        <!--inter-->
                        <div *ngIf="item.badge_id == 2" (click)="taskList(item.assign_by, item.id, item.badge_id)">
                            <div class="card btn-brd">
                                <div class="card-body skill-set" title="Task : {{item.ReprotPercent}}% and Time : {{item.remaining_days}} days left">
                                    <div class="c_bar i-{{item.ReprotPercent}}">
                                        <circle-progress [percent]="100-item.BadgeDecay_2" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#e5c634'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'transparent'" [class]="'pgrsbar'" [imageSrc]="item.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                                        </circle-progress>
                                    </div>
                                    <div class="prg_content mt-3">
                                            <!-- <h5 class="mb-2">Intermediate</h5> -->
                                            <span class="ctskilnm">{{item.cat_skill_name}}</span>
                                            <span class="ctnme">{{item.cat_name}}</span>
                                            <hr>
                                            <span class="expbadgeas"><strong>Exp:</strong> {{item.expiry_2}}</span>
                                        </div>
                                </div>
                            </div>
                            <span *ngIf="item.ReprotPercent == 100?assignedBadge(item.assign_by, item.id, item.badge_id):false"></span>
                            <span *ngIf="100-item.BadgeDecay_2 == 0?updateStatus(item.assign_by, item.id, item.badge_id):false"></span>
                        </div>

                        <!--expert-->
                        <div *ngIf="item.badge_id == 3" (click)="taskList(item.assign_by, item.id, item.badge_id)">
                            <div class="card btn-brd">
                                <div class="card-body skill-set" title="Task : {{item.ReprotPercent}}% and Time : {{item.remaining_days}} days left">
                                    <div class="c_bar e-{{item.ReprotPercent}}">
                                        <circle-progress [percent]="100-item.BadgeDecay_3" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#62a006'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'transparent'" [class]="'pgrsbar'" [imageSrc]="item.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                                        </circle-progress>
                                    </div>
                                    <div class="prg_content mt-3">
                                            <!-- <h5 class="mb-2">Expert</h5> -->
                                            <span class="ctskilnm">{{item.cat_skill_name}}</span>
                                            <span class="ctnme">{{item.cat_name}}</span>
                                            <hr>
                                            <span class="expbadgeas"><strong>Exp:</strong> {{item.expiry_3}}</span>

                                        </div>
                                </div>
                            </div>
                            <span *ngIf="item.ReprotPercent == 100?assignedBadge(item.assign_by, item.id, item.badge_id):false"></span>
                            <span *ngIf="100-item.BadgeDecay_3 == 0?updateStatus(item.assign_by, item.id, item.badge_id):false"></span>
                        </div>
                    </li>
                </ul>
                <!--No data Found start-->
                  <div class="w-100" *ngIf='responseData.length == 0'>
                      <div class="text-center" role="not found">
                          <i class="fa fa-exclamation-circle fa-5x" aria-hidden="true"></i>
                          <p class="mt-2">No Badges Found</p>
                      </div>
                  </div>
               <!--No data found end--> 
            </div>
        </div>
    </div>
</section>
