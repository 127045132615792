<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Edit Profile</a></li>
                    </ol>
                </div>
            </div>
        </div>


        <div class="row" id="profile-submit">
            <div class="create-n-ctg col-md-12 mr-auto ml-auto">
                <div class="card mt-3">
                    <div class="card-body">
                        <h4>Profile Details</h4>
                        <br />
                        <form class="form" id="profileupdateBasic" role="form" autocomplete="off" #catForm="ngForm">
                            <div class="row">
                                <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">First Name</label>
                                    <div>
                                        <input class="form-control" type="text" value=""
                                            placeholder="Enter Employee first name" name="first_name" id="first_name"
                                            [(ngModel)]="submitObj.first_name" checkMandatory="true"
                                            (focus)="onFocus()">
                                        <span class="ng-hide displayError" id="first_name_error"></span>

                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">Last Name</label>
                                    <div>
                                        <input class="form-control" type="text" value="" name="last_name"
                                            placeholder="Enter Employee last name" id="last_name"
                                            [(ngModel)]="submitObj.last_name"  (focus)="onFocus()">
                                        <span class="ng-hide displayError" id="last_name_error"></span>

                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">Email-ID</label>
                                    <div>
                                        <input disabled class="form-control" type="text" value="" name="email_id"
                                            placeholder="Enter Employee email_id" id="email_id"
                                            [(ngModel)]="submitObj.email_id" checkMandatory="true" (focus)="onFocus()">
                                        <span class="ng-hide displayError" id="email_id_error"></span>

                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">Mobile No.</label>
                                    <div>
                                        <input class="form-control" type="text" value="" placeholder="Enter mobile no."
                                            name="mobile_no" id="mobile_no" [(ngModel)]="submitObj.mobile_no"
                                          (focus)="onFocus()">
                                        <span class="ng-hide displayError" id="mobile_no_error"></span>

                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">Address</label>
                                    <div>
                                        <input class="form-control" type="text" value="" placeholder="Enter address"
                                            name="address" id="address" [(ngModel)]="submitObj.address"
                                             (focus)="onFocus()">
                                        <span class="ng-hide displayError" id="address_error"></span>

                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">Region</label>
                                    <div>
                                        <input class="form-control" type="text" value="" name="region"
                                            placeholder="Enter Employee region" id="region"
                                            [(ngModel)]="submitObj.region"(focus)="onFocus()">
                                        <span class="ng-hide displayError" id="region_error"></span>

                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <div class="form-group" id="imagePath">
                                        <label class="col-form-label form-control-label">Profile Image:</label> 
                                        <div>
                                            <label for="" *ngIf="submitObj.imagePath != null">
                                            <img [src]="submitObj.imagePath" alt="" style="height: 100px !important; width: 100px !important;" /><br>
                                            <a href="javascript:void(0);" (click)="removeImage();"><i class="fa fa-trash-o" aria-hidden="true"></i> Remove Profile Image</a>
                                            </label>

                                            <label *ngIf="submitObj.imagePath == null" for="profile-image-upload" class="form-control text-primary" style="cursor: pointer;">Set Profile Image
                                                <input id="profile-image-upload" type="file" class="form-control btn btn-info" accept="image/*" name="profilePhoto" (change)="handleInputChange($event)" style="display: none;" />
                                            </label> 

                                            <span class="ng-hide displayError" id="imagePath_error"></span>
                                        </div> 
                                    </div>
                                </div>
                                <div class="form-group col-12">
                                    <div class="float-right mt-5">
                                        <div class="d-v-center">
                                            <input type="reset" class="btn btn-secondary mr-2" value="Cancel">
                                            <input type="submit" class="btn btn-warning"
                                                (click)="updateSubmit();" value="Save">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" id="profile-submit">
            <div class="create-n-ctg col-md-12 mr-auto ml-auto">
                <div class="card mt-3">
                    <div class="card-body">
                        <h4>Change Password</h4>
                        <br />
                        <form class="form" id="profileupdateBasic" role="form" autocomplete="off" #catForm="ngForm">
                            <div class="row">
                                <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">Old Password</label>
                                    <div>
                                        <input class="form-control" type='password' value=""
                                            placeholder="Enter Old Password" name="old_password" id="old_password"
                                            [(ngModel)]="submitObj.old_password" checkMandatory="true"
                                            (focus)="onFocus()">
                                        <span class="ng-hide displayError" id="old_password_error"></span>
                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">New Password</label>
                                    <div>
                                        <input class="form-control" type='password' value="" placeholder="New Password"
                                            name="new_password" id="new_password" [(ngModel)]="submitObj.new_password"
                                            checkMandatory="true" (focus)="onFocus()">
                                        <span class="ng-hide displayError" id="new_password_error"></span>
                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">Confirm New Password</label>
                                    <div>
                                        <input class="form-control" type='password' value=""
                                            placeholder="Confirm New Password" name="conf_password" id="conf_password"
                                            [(ngModel)]="submitObj.conf_password" checkMandatory="true"
                                            (focus)="onFocus()">
                                        <span class="ng-hide displayError" id="conf_password_error"></span>
                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <div class="float-right mt-5">
                                        <div class="d-v-center">
                                            <input type="reset" class="btn btn-secondary mr-2" value="Cancel">
                                            <input type="submit" class="btn btn-warning"
                                                (click)="changePasswordSubmit();" value="Save">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<!--successsfull-modal-->
<div class="modal fade" id="changesubmit" role="dialog" aria-labelledby="trash-mdl" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body text-center">
                <p>Submission Successfull</p>
            </div>
            <div class="mdl-footer d-v-center">
                <button class="btn btn-black btn-block m-0" data-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>