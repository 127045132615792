<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Super TeamLeader List</a></li>
                    </ol>
                </div> 
            </div>
        </div> 

 
        <div class="row">
            <div class="col-md-12"> 
                <div class="">
                    <div class="search-trainer">
                        <div class="title">
                            <h4>Super Team Leader List</h4>
                        </div>
                    </div>


                    <div class="card mt-5">
                        <div class="card-body">
                            <table class="table table-striped mb-0" id="my-table">
                                <thead> 
                                    <tr>
                                        <th>Name</th>
                                        <th>Role</th>
                                        <th>Email ID</th>
                                        <th class="text-center">Assigned Employee</th>
                                        <th class="text-center">Assign Employee</th>
                                        <th class="text-center">Re-Assign</th>
                                    </tr> 
                                </thead>  
                                <tbody *ngIf='responseData.length > 0'> 
                                    <tr *ngFor="let item of responseData let i=index">
                                        <td>{{item.first_name}}</td>
                                        <td *ngIf='item.type == 0'>Super Admin</td>
                                        <td *ngIf='item.type == 1'>Admin</td>
                                        <td *ngIf='item.type == 2'>Trainer</td>
                                        <td *ngIf='item.type == 3'>Employee</td>
                                        <td *ngIf='item.type == 4'>Team Leader</td>
                                        <td *ngIf='item.type == 5'>Super Team Leader</td>
                                        <td>{{item.email_id}}</td> 
                                        <td>
                                            <span *ngIf='item.assigned_tld.length > 0'>
        
                                                <span *ngFor="let item1 of item.assigned_tld let i1=index">
                                                    {{item1.emp_first_name}}, 
                                                </span>
                                            </span>
                                            <span *ngIf='item.assigned_tld.length == 0'>
                                               No data found
                                            </span>
        
                                        </td>
                                        <td><button class="btn btn-danger btn-block" (click)="assignTeamleader(item.user_id);">Assign</button></td>
                                        <td class="text-center"><button (click)="reAssign(item.id);" class="btn btn-warning">Re-Assign</button></td>
        
                                        <!-- <td class="text-center"><button (click)="deleteSubmit(item.id);" class="trash"><i class="ti-trash"></i></button></td> -->
                                    </tr>
        
                                </tbody>
                               
                            </table>
                            <!--No data Found start-->
                                <div class="w-100" *ngIf='responseData.length == 0'>  
                                    <div class="text-center" role="not found">
                                        <i class="fa fa-exclamation-circle fa-5x" aria-hidden="true"></i>
                                        <p class="mt-2">No Badges Found</p>
                                    </div>
                                </div>
                           <!--No data found end-->
                        </div>
                    </div>
                    
                </div>
            </div> 
        </div> 
    </div>
</section>
<div class="mr-auto ml-auto">
    <nav class="pagination-wrap">
        <ul class="pagination">
            <li class="page-item" (click)="previousSubmit();">
                <a *ngIf='setPageNo' class="page-link" href="javascript:void(0);" aria-label="Previous">
                    <span aria-hidden="true">«</span>
                    <span class="sr-only">Previous</span>
                </a>
            </li> 
            <li class="page-item" (click)="nextSubmit();">
                <a *ngIf='setPageNoNext' class="page-link" href="javascript:void(0);" aria-label="Next">
                    <span aria-hidden="true">»</span>
                    <span class="sr-only">Next</span>
                </a> 
            </li>
        </ul>
    </nav> 
</div>

<!--trash-modal-->
<div class="modal fade"  id="deletesubmit" tabindex="-1"  role="dialog" aria-labelledby="trash-mdl" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>Are you sure you wanted to delete this user?</p>
        </div>
          <div class="mdl-footer d-v-center">
              <button class="btn btn-yellow btn-block" (click)='deleteRow(targetIndex);'>Yes</button>
              <button class="btn btn-black btn-block m-0" data-dismiss="modal">No</button>
          </div>
      </div>
    </div>
  </div>  

