<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item" (click)="backPathwayList();"><a href="javascript:void(0);">Pathway list</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Create New Skill</a></li>
                    </ol>
                </div>
            </div>
            <div class="col-md-4 text-right">
                <button class="btn btn-danger" (click)="goBack()"><i class="fa fa-angle-left"></i> Back</button>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                        <div class="title">
                            <h4>Add New Skill</h4>
                        </div>
                        
                        <!-- <a class="search-wrapper"  routerLink="/admin/add-category-adm">
                        <p>Add New Skill</p>
                            <span><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
	<g>
		<path d="M492,236H276V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v216H20c-11.046,0-20,8.954-20,20s8.954,20,20,20h216
			v216c0,11.046,8.954,20,20,20s20-8.954,20-20V276h216c11.046,0,20-8.954,20-20C512,244.954,503.046,236,492,236z"/>
	</g>
                                </g></svg></span>
                            </a> -->
                    </div>
                </div>
                <!--autosearch--> 
            </div> 
        </div>
         
        <div class="row" id="profile-submit">
            <div class="create-n-ctg col-12">
                <div class="card mt-3">
                    <div class="card-body">
                        <form class="form" id="profileupdateBasic" role="form" autocomplete="off" #catForm="ngForm">
                            <div class="form-group"> 
                                <label class="col-form-label form-control-label">Pathway Name:</label>
                                <div>
                                    <input class="form-control" type="text" placeholder="Enter your name" name="pathway_name"  [(ngModel)]="submitObj.pathway_name" disabled>
                                    <input class="form-control" type="text" placeholder="Enter your name" name="pathway_id"  [(ngModel)]="submitObj.pathway_id" hidden>
                                </div> 
                            </div>
                            <div class="form-group">
                                <label class="col-form-label form-control-label">Pathway Description</label> 
                                <div>
                                    <input class="form-control" type="text" placeholder="pathway Description" name="pathway_description"  [(ngModel)]="submitObj.pathway_description" disabled>
                                </div> 
                            </div> 
                            

                            <div class="form-group">
                                <label class="col-form-label form-control-label">Pathway Skills:</label>
                                <div id="pathway_skill" > 
                                    <div class="d-v-center">
                                        <div class="ng-autocomplete" class="w-100">
                                            <ng-autocomplete  
                                            [data]="skillList"
                                            [searchKeyword]="keyword1"
                                                (selected)='selectEvent1($event)'
                                                (inputChanged)='onChangeSearch1($event)'
                                                (inputFocused)='onFocused1($event)'
                                                [itemTemplate]="itemTemplate1"
                                                [notFoundTemplate]="notFoundTemplate">                                 
                                            </ng-autocomplete>
                                                
                                            <ng-template #itemTemplate1 let-item1> 
                                            <a [innerHTML]="item1.cat_skill_name"></a>
                                            </ng-template>
                                                
                                            <ng-template #notFoundTemplate let-notFound>
                                            <div [innerHTML]="notFound"></div>
                                            </ng-template>
                                        </div>  
                                    </div>    
                                    <span class="ng-hide displayError" id="pathway_skill_error"></span>
                                
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label form-control-label">Pathway Badge:</label>
                                <div id="pathway_badge">
                                    <div class="d-v-center">
                                        <div class="ng-autocomplete" class="w-100">
                                            <ng-autocomplete 
                                            [data]="badgeList"
                                            [searchKeyword]="keyword"
                                                (selected)='selectEvent($event)'
                                                (inputChanged)='onChangeSearch($event)'
                                                (inputFocused)='onFocused($event)'
                                                [itemTemplate]="itemTemplate"
                                                [notFoundTemplate]="notFoundTemplate">                                 
                                            </ng-autocomplete>
                                                
                                            <ng-template #itemTemplate let-item> 
                                            <a [innerHTML]="item.badge_name"></a>
                                            </ng-template>
                                                
                                            <ng-template #notFoundTemplate let-notFound>
                                            <div [innerHTML]="notFound"></div>
                                            </ng-template>
                                        </div> 
                                    </div>  
                                    <span class="ng-hide displayError" id="pathway_badge_error"></span>
                                  
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="float-right"> 
                                <div class="d-v-center">
                                    <input type="reset" class="btn btn-secondary mr-2" (click)="backPathwayList();" value="Cancel">
                                    <input type="submit" class="btn btn-warning" (click)="createSubmit();" value="Save">
                                </div>
                            </div>
                            </div>
                        </form>
                    </div> 
                </div> 
            </div>
        </div>
    </div>
</section>
<!--successsfull-modal-->
<div class="modal fade"  id="changesubmit" role="dialog" aria-labelledby="trash-mdl" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>Submission Successfull</p>
        </div> 
          <div class="mdl-footer d-v-center">
              <button class="btn btn-black btn-block m-0" (click)="refreshSubmit();">Ok</button>
          </div>
      </div>
    </div>
  </div> 
<!--successsfull-modal-->
<!-- <div class="modal fade"  id="changesubmit" tabindex="-1"  role="dialog" aria-labelledby="trash-mdl" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>Submission Successfull</p>
        </div>
          <div class="mdl-footer d-v-center">
              <button class="btn btn-black btn-block m-0" (click)="listSkill()">Ok</button>
          </div> 
      </div>
    </div>
</div>  -->