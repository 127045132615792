import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { EmployeeComponent } from './employee.component';
import { DashboardEmpComponent } from './component/dashboard-emp/dashboard-emp.component';
import { HistoryEmpComponent } from './component/history-emp/history-emp.component';
import { LearningPathwaysEmpComponent } from './component/learning-pathways-emp/learning-pathways-emp.component';
import { MypathwaysEmpComponent } from './component/mypathways-emp/mypathways-emp.component';
import { AddpathwayEmpComponent } from './component/add-pathway-emp/add-pathway-emp.component';
import { NotificationEmpComponent } from './component/notification-emp/notification-emp.component';
import { Task2EmpComponent } from './component/task2-emp/task2-emp.component';
import { TasklistEmpComponent } from './component/tasklist-emp/tasklist-emp.component';
import { EditProfileAdmComponent } from './component/edit-profile-adm/edit-profile-adm.component';
import { ActivitiesComponent } from './component/activities/activities.component';

const routes: Routes = [
    {
        path: 'employee',
        component: EmployeeComponent,
        children: [
            { path: '', component: DashboardEmpComponent },
            { path: 'dashboard-emp', component: DashboardEmpComponent },
            { path: 'tasklist-emp/:trainerId/:skillId/:badgeId', component: TasklistEmpComponent },
            { path: 'task2-emp/:taskId', component: Task2EmpComponent },
            { path: 'activities/:taskId', component: ActivitiesComponent },
            { path: 'notification-emp', component: NotificationEmpComponent },
            { path: 'mypathways-emp', component: MypathwaysEmpComponent },
            { path: 'add-pathway-emp', component: AddpathwayEmpComponent },
            { path: 'learning-pathways-emp/:id', component: LearningPathwaysEmpComponent },
            { path: 'history-emp', component: HistoryEmpComponent },
            { path: 'edit-profile-emp', component: EditProfileAdmComponent }
            
        ]
    }
];

export const EmployeeRouting = RouterModule.forRoot(routes);