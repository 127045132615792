import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';
import {ResponseContainer} from '../../../service/ResponseContainer';
import {RequestContainer} from '../../../service/RequestContainer';  
import * as $ from 'jquery';

@Component({
  selector: 'app-notification-adm',
  templateUrl: 'notification-adm.component.html'
})
export class NotificationAdmComponent implements OnInit {

  public responseData:any = {};
  public targetIndex:Object = {}; 

  public submitObj:any = {};
  public setPageNo:boolean = false;
  public setPageNoNext:boolean = false;
  public pageNo:any = {};
  public routeUrl:Object = {};
  public id:any = {}; 

  constructor(
    private router:Router,
    private httpService : HttpService, 
    private route: ActivatedRoute
  )
  {
  }
  
  ngOnInit(): void {
    $('.modal ').insertAfter($('body'));  
    var requestObj = new RequestContainer();
    var currentUser = JSON.parse(sessionStorage.getItem('login_user_id')); 
    requestObj['empId'] =  currentUser;
    this.httpService.postRequest<ResponseContainer>(requestObj,'admin/getNotification').subscribe(
      datas=> {
        if(datas.rsBody.result == 'success') 
        {
          this.responseData = datas.rsBody.data;
        } 
        else{ 
          console.log(datas.rsBody.error);
        } 
      });
  }

}
