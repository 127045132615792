<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item active" (click)="backCategory();"><a href="javascript:void(0);">Category</a></li>
                        <li class="breadcrumb-item active" (click)="backBadges();"><a href="javascript:void(0);">Badges</a></li>
                        <li class="breadcrumb-item active" (click)="backTaskList();"><a href="javascript:void(0);">Task List</a></li> 
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Create New Question</a></li>
                    </ol>
                </div>
            </div>
        </div>  


        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                        <div class="title">
                            <h4>Create Task</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
      <div class="card mt-3 tab-card">
        <div class="card-header tab-card-header">
          <ul class="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="one-tab" data-toggle="tab" href="#one" role="tab" aria-controls="One" aria-selected="true">Question</a>
            </li>
            <!-- <li class="nav-item">
                <a class="nav-link" id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="Two" aria-selected="false">Activities</a>
            </li> -->
            <li class="nav-item">
                <a class="nav-link" id="three-tab" data-toggle="tab" href="#three" role="tab" aria-controls="Three" aria-selected="false">List</a>
            </li>
          </ul>
        </div>

        <div class="tab-content" id="myTabContent">
            <form id="signup" action="somewhere" method="POST"></form>
          <div class="tab-pane fade show active p-3" id="one" role="tabpanel" aria-labelledby="one-tab">
            <div class="form-group">  
                <label>Question Type</label>
                <div>
                    <div class="select-lvl">
                        <!-- <a href="javascript:void(0)" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" class="dropdown-toggle">
                            <span>Choose Level</span><i class="fa fa-caret-down"></i>
                        </a>
                       
                        <ul aria-labelledby="dropdownMenuLink" class="dropdown-menu">
                            <li><a href="javascript:void(0)" class="dropdown-item d-v-center">MCQ</a></li>
                            <li><a href="javascript:void(0)" class="dropdown-item">Q&A</a></li>
                        </ul> -->
                        <select id="question_type" class="form-control" name="question_type" [(ngModel)]="submitObj.question_type">
                            <option value="1">Q&A</option>
                            <option value="2">MCQ</option>
                        </select>
                    </div>
                    <small>Please choose the appropriate section so easily search for your question.</small>
                </div>
            </div>

            <div class="form-group"> 
                <label>Question</label>
                <textarea class="form-control" name="question"  [(ngModel)]="submitObj.question"></textarea>
                <small>Please choose the appropriate title for the question to answer it even easier.</small>
            </div>

            <div class="form-group">
                <label>Correct Answer</label>
                <textarea class="form-control" name="correct_answer"  [(ngModel)]="submitObj.correct_answer"></textarea>
                <small>Please write the corrct answer of a question.</small>
            </div>

            <div class="form-group"  *ngIf="submitObj.question_type == 2">
                <label>Option</label>
                <div *ngFor="let item of submitObj.optionList let i=index">
                    <div class="row">
                        <div class="col-10">
                            <div class="form-group">
                                <input type="text" id="{{'option'+i}}" class="form-control" placeholder="Add option" name="option{{i}}" [(ngModel)]="item.option">
                                <span class="ng-hide displayError" id="{{'option'+i}}_error"></span>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group" *ngIf="submitObj.optionList.length > 1">
                                <a href="javascript:void(0);" (click)="removeOption(i);"><i class="fa fa-times" aria-hidden="true"></i> Remove</a>
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <a href="javascript:void(0)" (click)="addOption();" class="btn btn-bdr btn-yellow mt-2"><i class="fa fa-plus"></i> Add Option</a>
                    </div>
                </div>
            </div>
            <div class="text-right m-btn">
                <!-- <a href="javascript:void(0)" class="btn btn-default">Add Next</a> -->
                <a href="javascript:void(0)" class="btn btn-warning ml-2" (click)="createSubmit();">Save</a>
            </div>
        
          </div>
          <div class="tab-pane fade p-3" id="two" role="tabpanel" aria-labelledby="two-tab">
              <!-- <div class="form-group">
                        <label>Select Activities</label>
                        <select>
                            <option>option1</option>
                            <option>option2</option> 
                            <option>option3</option>
                        </select>
                        <small>Note: Please add any supportive image/video/files.</small>
                    </div> -->
                    <div class="form-group"> 
                        <label>Activity Title</label>
                        <input type="text" class="form-control" name="activity_title"  [(ngModel)]="submitObj.activity_title">
                        <small>Please write the title of this activity.</small>
                    </div>
                    <div class="form-group">
                        <label>Activity Description</label>
                        <textarea class="form-control" name="activity_description" id="activity_description" [(ngModel)]="submitObj.activity_description"></textarea>
                        <small>Please write the description of this activity.</small>
                    </div>
                    <div class="form-group row">
                        <label class="col-lg-2 col-form-label form-control-label">Activity:</label>
                        <div class="col-lg-10">
                            <div id="collapseOne" class="panel-collapse collapse show" role="tabpanel" aria-labelledby="headingOne">
                                <div>
                                    <div *ngIf="submitObj.imagePath != null"> 
                                        <label *ngIf='submitObj.feature_type == "image/png"'>
                                            <img [src]="submitObj.imagePath" alt="" style="height: 100px !important; width: 100px !important;" />
                                            <a href="javascript:void(0);" (click)="removeImage();">Remove</a>
                                        </label>
                                        <label *ngIf='submitObj.feature_type == "image/jpg"'>
                                            <img [src]="submitObj.imagePath" alt="" style="height: 100px !important; width: 100px !important;" />
                                            <a href="javascript:void(0);" (click)="removeImage();">Remove</a>
                                        </label>
                                        <label *ngIf='submitObj.feature_type == "image/jpeg"'>
                                            <img [src]="submitObj.imagePath" alt="" style="height: 100px !important; width: 100px !important;" />
                                            <a href="javascript:void(0);" (click)="removeImage();">Remove</a>
                                        </label>
                                        <label *ngIf='submitObj.feature_type == "application/pdf"'>
                                            <!-- <object [src]="submitObj.imagePath" alt="" style="height: 100px !important; width: 100px !important;">
                                            </object> -->
                                            <iframe [src]="submitObj.imagePath" alt="" style="height: 100px !important; width: 100px !important;">
                                            </iframe>
                                            <a href="javascript:void(0);" (click)="removeImage();">Remove</a>
                                        </label>
                                        <label *ngIf='submitObj.feature_type == "video/mp4"'>
                                            <video style="height: 100px !important; width: 100px !important;" controls>
                                                <source [src]="submitObj.imagePath" type="video/mp4">
                                                Your browser does not support HTML5 video.
                                              </video>
                                            <a href="javascript:void(0);" (click)="removeImage();">Remove</a>
                                        </label>
                                    </div>
                                    <label *ngIf="submitObj.imagePath == null" for="profile-image-upload" class="text-primary" style="cursor: pointer;"><i class="ti-gallery mr-2"></i>Set Activity
                                        <input id="profile-image-upload" type="file" class="form-control btn btn-info" accept="image/*" name="profilePhoto" (change)="handleInputChange($event)" style="display: none;" />
                                    </label>
                                </div>  
                                <small>Note: Please add any supportive image/video/pdf.</small>
                            </div> 
                        </div>  
                    </div>

                    <div class="text-right m-btn"> 
                        <a href="javascript:void(0)" class="btn btn-warning ml-2" (click)="addActivity();">Save</a>
                    </div>             
          </div>
          <div class="tab-pane fade p-3" id="three" role="tabpanel" aria-labelledby="three-tab">
           <!--question list start-->
           <div class="table-responsive actvs">
            <table class="table table-striped mb-0">
                <thead>
                    <tr>
                        <th>Question/Title</th>
                        <th>Type</th>
                        <th>Options</th>
                        <th>Activity Doc</th>
                        <!-- <th class="text-center">Edit</th> -->
                        <th class="text-center">Remove</th>
                    </tr>
                </thead>
                <tbody *ngIf='responseData.length > 0'>
                    <tr *ngFor="let item of responseData let i=index">
                        <td>{{item.question_title}}</td>
                        <td *ngIf="item.question_type == 1">Q&A</td>
                        <td *ngIf="item.question_type == 2">MCQ</td> 
                        <td *ngIf="item.question_type == 3">Activity</td> 
                        <td *ngIf="item.question_type == 1">-</td>
                        <td *ngIf="item.question_type == 2">{{item.options}}</td>
                        <td *ngIf="item.question_type == 3">-</td> 
                        <td *ngIf="item.question_type == 1">-</td>
                        <td *ngIf="item.question_type == 2">-</td>
                        <td *ngIf="item.question_type == 3">
                            {{item.task_doc_type}}
                            
                            <label *ngIf="item.task_doc_type === 'image/png' || item.task_doc_type === 'image/jpeg' || item.task_doc_type === 'image/jpg'"> 
                                <img src="{{item.task_doc}}" alt="" style="height: auto; width: 100px; background-size: contain;" />
                            </label>

                            <label *ngIf='item.task_doc_type == "video/mp4"'>
                                <video controls style="width:100px ; height: 100px;">
                                    <source src="{{ item.task_doc }}"  type="video/mp4">
                                  </video>
                            </label>

                            <label *ngIf='item.task_doc_type == "application/pdf"'>
                                <iframe [src]="transform(item.task_doc)" style="width:100px ; height: 100px;"></iframe>
                                <!-- <object [src]="item.task_doc" alt="" style="height: 100px !important; width: 100px !important;">
                                </object> -->
                                <!-- <iframe src="https://www.w3docs.com/uploads/media/default/0001/01/540cb75550adf33f281f29132dddd14fded85bfc.pdf" alt="" width="100%" height="500px">
                                </iframe> -->
                            </label>

                        </td>
                        <!-- <td class="text-center"><button (click)="deleteSubmit(item.id);" class="trash">Edit</button></td> -->
                        <td class="text-center"><button (click)="deleteSubmit(item.id);" class="trash"><i class="ti-trash"></i></button></td>
                    </tr>
                </tbody>  
                <tbody *ngIf='responseData.length == 0'>
                    <td class="text-center" colspan="3"> <strong>No Data Found</strong></td>
                </tbody>
            </table>
            <div class="mr-auto ml-auto">
                <nav class="pagination-wrap">
                    <ul class="pagination">
                        <li class="page-item" *ngIf='setPageNo' class="page-item" (click)="previousSubmit();">
                            <a class="page-link" href="javascript:void(0);" aria-label="Previous">
                                <span aria-hidden="true">«</span>
                                <span class="sr-only">Previous</span>
                            </a>
                        </li>
                      
                        <li class="page-item" *ngIf='setPageNoNext' class="page-item" (click)="nextSubmit();">
                            <a class="page-link" href="javascript:void(0);" aria-label="Next">
                                <span aria-hidden="true">»</span>
                                <span class="sr-only">Next</span>
                            </a>
                        </li>
            
                    </ul>
                </nav>
            </div>
        </div>
        <!--question list end-->             
          </div>
            </div>
        </div>

    </div>
</section>
<!--successsfull-modal-->
<div class="modal fade"  id="changesubmit" role="dialog" aria-labelledby="trash-mdl" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>Submission Successfull</p>
        </div>
          <div class="mdl-footer d-v-center">
              <button class="btn btn-black btn-block m-0" data-dismiss="modal">Ok</button>
          </div>
      </div>
    </div>
</div> 


<!--trash-modal-->
<div class="modal fade"  id="deletesubmit" tabindex="-1"  role="dialog" aria-labelledby="trash-mdl" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>Are you sure you wanted to delete this user?</p>
        </div>
          <div class="mdl-footer d-v-center">
              <button class="btn btn-yellow btn-block" (click)='deleteRow(targetIndex);'>Yes</button>
              <button class="btn btn-black btn-block m-0" (click)='refresh();'>No</button>
          </div>
      </div>
    </div>
  </div>   