// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-add-category-skill-adm',
//   templateUrl: 'add-category-skill-adm.component.html'
// })
// export class AddCategorySkillAdmComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }
import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';

import {ResponseContainer} from '../../../service/ResponseContainer';
import {ValidationHandler} from '../../../service/ValidationHandler'; 
import {BaseContainer} from '../../../service/BaseContainer';
import {RequestContainer} from '../../../service/RequestContainer'; 
import { Location } from '@angular/common';

declare var $ :any;
@Component({ 
  selector: 'app-add-learning-pathways-skill-adm',
  templateUrl: 'add-learning-pathways-skill-adm.component.html',
  providers : [HttpService,ValidationHandler]   

})
export class AddLearningPathwaysSkillAdmComponent implements OnInit {
  public submitObj:any = {};
  public targetIndex:Object = {};
  public setPageNo:boolean = false;
  public setPageNoNext:boolean = false;
  public pageNo:any = {}; 
  public responseData:any = {}; 
  public badgeList:any = {}; 
  public skillList:any = {}; 
  public categoryList:any = {};
  public keyword:any = {};
  public keyword1:any = {};

  public imagePath:any = {};

 
  public id:any = {}; 
  public name:any = {};
  public routeUrl:Object = {};



  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;

  constructor(
     private httpService : HttpService,
     private router:Router,
     private route: ActivatedRoute,
     private validationHandler : ValidationHandler,
     private location: Location
   )
  {}  
 
    goBack() {
      this.location.back();
      console.log( 'goBack()...' );
    }

  selectEvent(item) {
    this.id = item.id;
    this.submitObj.pathway_badge = item.id;
  }
 
  onChangeSearch(val: string) {
  }
  
  onFocused(e){
    this.validationHandler.hideErrors("profile-submit");

  }
  
 
  ngOnInit(): void { 
    $('.modal ').insertAfter($('body'));
    this.id  =  this.route.snapshot.paramMap.get('id'); 
    this.routeUrl = this.router.url;
    if(this.routeUrl == '/admin/add-learning-pathway-skill-adm/'+this.id)
    {
        this.httpService.postRequest<ResponseContainer>(this.id,'admin/getPathwayData').subscribe(
          datas=> {
            if(datas.rsBody.result == 'success')
            {
              this.submitObj.pathway_name = datas.rsBody.data[0]['pathway_name'];
              this.submitObj.pathway_description = datas.rsBody.data[0]['pathway_description'];
              this.submitObj.pathway_id = datas.rsBody.data[0]['id'];
              
            } 
            else{ 
              console.log(datas.rsBody.error)
            }
             
          }); 
    }
    else{
      console.log('error'); 
    }
    this.getBadgeList();
  //  / this.getCategoryList();
    this.getSkillList();
  } 
  
  
  /* 
  * Badges List
  */
 getBadgeList() 
 {
       this.httpService.getAll<ResponseContainer>('admin/getBadgeList').subscribe(datas => {
       if(datas.rsBody.result == 'success')
       {
         this.keyword = 'badge_name';
         this.badgeList = datas.rsBody.data;
       }
      });
 } 


 onFocus()
 {
     this.validationHandler.hideErrors("profile-submit");
 }  
  /*
  * Catgeory List
  */
 getCategoryList() 
 {
       this.httpService.getAll<ResponseContainer>('admin/getCategoryList').subscribe(datas => {
       if(datas.rsBody.result == 'success')
       {
        // this.keyword = 'badge_name';
        this.keyword = 'cat_name'; 

         this.categoryList = datas.rsBody.data;
       }
      });
 } 
   /*
  * Skill List
  */ 
 getSkillList() 
 {
       this.id = JSON.parse(sessionStorage.getItem('login_company'));
       this.httpService.postRequest<ResponseContainer>(this.id, 'admin/getSkillList').subscribe(datas => {
       if(datas.rsBody.result == 'success')
       {
         this.keyword1 = 'cat_skill_name'; 
         this.skillList = datas.rsBody.data; 
       }
      });
 }

/*
* create submit 
*/  
 createSubmit() 
 {
  this.httpService.postRequest<ResponseContainer>(this.submitObj,'admin/createPathwaySkill').subscribe(datas => {
      if(datas.rsBody.result == 'success')
      { 
        this.submitObj = {}; 
        this.id  =  this.route.snapshot.paramMap.get('id'); 
        this.router.navigate(['/admin/managment-adm',this.id]);
      } 
      else
      { 
        this.validationHandler.displayErrors(datas.rsBody.errors,"profileupdateBasic",null);
      }
     });
 }

 
selectEvent1(item) {
  this.id = item.id;
  this.submitObj.pathway_skill = item.id;
}

onChangeSearch1(val: string) {
}

onFocused1(e){
  this.validationHandler.hideErrors("profile-submit");

}


   /*
   * sub category data
   */ 
  // toggleSubCategory(targetIndex)
  // {
  //   var idx = this.submitObj.selection_subcat.indexOf(targetIndex);
  //   if (idx > -1) {
  //       // is currently selected
  //       targetIndex['checked'] = false;
  //       this.submitObj.selection_subcat.splice(idx, 1);
  //   }
  //   else {
  //       // is newly selected
  //       var Offeredflag = true;
  //       if(this.submitObj.selection_subcat.length > 0)
  //       {
  //         for(var i=0; i < this.submitObj.selection_subcat.length; i++)
  //         {
  //              if(this.submitObj.selection_subcat[i].id == targetIndex.id)
  //              {
  //                targetIndex['checked'] = false;
  //                this.submitObj.selection_subcat.splice(i, 1);
  //                Offeredflag = false;
  //              }
  //         } 
  //       }

  //       if(Offeredflag == true)
  //       {
  //         targetIndex['checked'] = true;
  //         this.submitObj.selection_subcat.push(targetIndex.id);
  //       }

  //   }
  // }
 
  /*
  * refresh table
  */
 refreshSubmit()
 {
  $("#changesubmit").modal('hide'); 
  this.router.navigate(['/admin/learning-pathways-adm']);
 }

 backPathwayList(){ 
  this.router.navigate(['/admin/learning-pathways-adm']); 
 }

}
