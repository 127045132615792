import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sidebar-trn',
  templateUrl: 'sidebar-trn.component.html'
})
export class SidebarTrnComponent implements OnInit {
  userDisplayName = '';
  userDisplayRole = ''; 
  userProfilePath = '';
  constructor(
    private router:Router,
    private route: ActivatedRoute 
  ) { }

  ngOnInit(): void {
    this.userDisplayName = sessionStorage.getItem('login_name');
    this.userDisplayRole = sessionStorage.getItem('login_role');
    this.userProfilePath = JSON.parse(sessionStorage.getItem('user_data')).imagePath || null;
  }

  logout(){
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('login_id');
    this.router.navigate(['/']);
  }

}
 