<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12"> 
                <div class="page-title-box"> 
                    <ol class="breadcrumb"> 
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item" (click)="backCategory();"><a href="javascript:void(0);">Category</a></li>
                        <!-- <li class="breadcrumb-item" (click)="backSkillList();"><a href="javascript:void(0);">Skill List</a></li>
                        <li class="breadcrumb-item" (click)="backAssignTrainer();"><a href="javascript:void(0);">Assign Trainer</a></li> -->
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Trainer Profile</a></li>
                    </ol>
                </div> 
            </div>  
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                        <div class="title">
                            <h4>Profile</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="profile-wrapper">
                    <div class="widget-profile d-v-center">
                        <div class="widget-img-holder">
                            <div>
                                <img src="https://demo.neontheme.com/assets/images/thumb-1@2x.png">
                            </div>
                            <span><i class="fa fa-circle mr-2 text-danger"></i></span>
                        </div>
                        <h3 class="widget-content">
                            <span class="w-title"><i class="ti-user mr-1"></i> {{responseData.first_name}} {{responseData.last_name}}</span>
                            <span class="w-val"><i class="ti-id-badge mr-1"></i> #{{responseData.id}}</span> 
                            <span class="w-val"><i class="ti-email mr-1"></i> {{responseData.email_id}}</span>
                            <span class="w-val"><i class="ti-mobile mr-1"></i> {{responseData.mobile_no}}</span>
                        </h3>
                    </div>
                </div>
            </div>
        </div>

        <div class="badge-view-wrapper">
            <div class="col-md-12">
            <h5 class="title">Badge Overview:</h5> 
                
                <ul class="badge-overview flex-wrap flex-baseline d-v-center mt-3"  *ngFor="badgeList.length > 0">
                     <li *ngFor="let item of badgeList let i=index">
                        <div class="card btn-brd">
                            <div class="card-body skill-set">
                                <div class="c_bar b-100" *ngIf="item.badge_id == 1">
                                    <circle-progress
                                    [percent]="30"
                                    [radius]="100"
                                    [outerStrokeWidth]="0"
                                    [innerStrokeWidth]="0"
                                    [space]="5"
                                    [outerStrokeColor]="'transparent'"
                                    [innerStrokeColor]="'transparent'"
                                    [animation]="true"
                                    [animationDuration]="300"
                                    [backgroundGradient]=""
                                    [backgroundColor]="'transparent'"
                                    [class]="'pgrsbar'"
                                    [imageSrc]="item.cat_skill_icon"
                                    [imageHeight]="100"
                                    [imageWidth]="100">      
                                    </circle-progress>
                                </div>
                                <div class="c_bar i-100" *ngIf="item.badge_id == 2">
                                    <circle-progress
                                    [percent]="30"
                                    [radius]="100"
                                    [outerStrokeWidth]="0"
                                    [innerStrokeWidth]="0"
                                    [space]="5"
                                    [outerStrokeColor]="'transparent'"
                                    [innerStrokeColor]="'transparent'"
                                    [animation]="true"
                                    [animationDuration]="300"
                                    [backgroundGradient]=""
                                    [backgroundColor]="'transparent'"
                                    [class]="'pgrsbar'"
                                    [imageSrc]="item.cat_skill_icon"
                                    [imageHeight]="100"
                                    [imageWidth]="100">      
                                    </circle-progress>
                                </div>
                                <div class="c_bar e-100" *ngIf="item.badge_id == 3">
                                    <circle-progress
                                    [percent]="30"
                                    [radius]="100"
                                    [outerStrokeWidth]="0"
                                    [innerStrokeWidth]="0"
                                    [space]="5"
                                    [outerStrokeColor]="'transparent'"
                                    [innerStrokeColor]="'transparent'"
                                    [animation]="true"
                                    [animationDuration]="300"
                                    [backgroundGradient]=""
                                    [backgroundColor]="'transparent'"
                                    [class]="'pgrsbar'"
                                    [imageSrc]="item.cat_skill_icon"
                                    [imageHeight]="100"
                                    [imageWidth]="100">      
                                    </circle-progress>
                                </div>
                                <div class="prg_content mt-3">
                                    <span class="m-0">{{item.badge_name}}</span><br>
                                <span class="mt-1">{{item.cat_name}}</span><br>
                                <small class="m-0">{{item.created_date}}</small>
                                </div>
                            </div> 
                        </div> 
                    </li>
                </ul>
                <!--No data Found start-->
                <div class="w-100" *ngIf='badgeList.length == 0'>  
                    <div class="text-center" role="not found">
                        <i class="fa fa-exclamation-circle fa-5x" aria-hidden="true"></i>
                        <p class="mt-2">No Badges Found</p>
                    </div>
                </div>
                <!--No data found end-->
            </div>
        </div>
    </div>
</section>
