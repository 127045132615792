<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-9">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item active" (click)="backPathwayList();"><a href="javascript:void(0);">Pathway List</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Pathway Skill List</a></li>
                    </ol>
                </div>
            </div>
            <div class="col-md-3 text-md-right">
                 <button class="btn btn-danger" (click)="goBack()"><i class="fa fa-angle-left"></i> Back</button>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                    <div class="title">
                        <h4>Pathway Skill</h4>
                        </div>

                        <div class="search-wrapper" id="s_wrap" >
                        <!--<input id="search_result" name="search_result" class="form-control" style="width: 200px; margin-right: 50px;"  [(ngModel)]="submitObj.search_result" type="text" placeholder="Search by name" aria-label="Search" (ngModelChange)="searchSubmit();">-->
                        <p (click)="addPathwaySkill()">Add Pathway Skilll</p>
                        <span><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                       <g>
                           <g>
                               <path d="M492,236H276V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v216H20c-11.046,0-20,8.954-20,20s8.954,20,20,20h216
                                   v216c0,11.046,8.954,20,20,20s20-8.954,20-20V276h216c11.046,0,20-8.954,20-20C512,244.954,503.046,236,492,236z"/>
                           </g>
                                                       </g></svg></span>
                        </div>
                    </div>


                    <div id="my-table">
                        <div class="card mt-5">
                            <div class="card-body">

                                <div class="table-responsive">
                                    <table class="table table-striped mb-0">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Pathway name</th>
                                                <th>Skill name</th>
                                                <th>Badge name</th>
                                                <th class="text-center">Remove</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf='responseData.length > 0'>
                                          <tr *ngFor="let item of responseData | paginate: { itemsPerPage: pageSize, currentPage: pageNo } let i=index">
                                                <td>{{((pageNo-1)*pageSize)+(i+1)}}</td>
                                                <td>{{item.pathway_name}}</td>
                                                <td>{{item.cat_skill_name}}</td>
                                                <td>{{item.badge_name}}</td>
                                                <td class="text-center"><span (click)="deleteSubmit(item.id);" class="text-danger actionbtnsdf"><i class="ti-trash"></i></span></td>
                                            </tr>
                                        </tbody>
                                           </table>

                                           <div class="text-center mt-4">
                                           <pagination-controls  *ngIf="responseData.length !=0" (pageChange)="loadpage($event)" maxSize="10" directionLinks="true" responsive="true" previousLabel="Previous" nextLabel="Next"></pagination-controls>
                                        </div>


                                    <!--No data Found start-->
                                      <div class="w-100" *ngIf='responseData.length == 0'>
                                          <div class="text-center" role="not found">
                                              <i class="fa fa-exclamation-circle fa-5x" aria-hidden="true"></i>
                                              <p class="mt-2">No Badges Found</p>
                                          </div>
                                      </div>
                                   <!--No data found end-->
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>



<!--trash-modal-->
<div class="modal fade"  id="deletesubmit" tabindex="-1"  role="dialog" aria-labelledby="trash-mdl" aria-hidden="true">
  <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body text-center">
        <p>Are you sure you wanted to delete this user?</p>
      </div>
        <div class="mdl-footer d-v-center">
            <button class="btn btn-yellow btn-block" (click)='deleteRow(targetIndex);'>Yes</button>
            <button class="btn btn-black btn-block m-0" data-dismiss="modal">No</button>
        </div>
    </div>
  </div>
</div>

<!--trash-modal-->
<div class="modal fade"  id="deletesubmit" tabindex="-1"  role="dialog" aria-labelledby="trash-mdl" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>Are you sure you wanted to delete this user?</p>
        </div>
          <div class="mdl-footer d-v-center">
              <button class="btn btn-yellow btn-block" (click)='deleteRow(targetIndex);'>Yes</button>
              <button class="btn btn-black btn-block m-0" data-dismiss="modal">No</button>
          </div>
      </div>
    </div>
  </div>
