<section id="dashboard" class="g-100">
    <div class="container-fluid">
         <div class="row">
            <div class="col-md-6">
                <div class="page-title-box">
                    <ol class="breadcrumb px-0 mb-0">
                        <li class="breadcrumb-item text-dark"><a href="javascript:void(0);">Dashboard</a></li>
                    </ol>
                </div>
            </div>
        </div>
       <div class="row">
         <div class="col-lg-4">
             <div class="widget">
                <div class="widget-content d-v-center flex-justify-between">
                    <div class="w-75">
                        <h4>{{totalCompany}}</h4>
                        <!-- <p>Number of Companies enrolled</p> -->
                        <p>Total Companies</p>
                    </div>
                    <div class="widget-icon w-b">
                        <img src="../../../../assets/img/company.png" alt="total-employee">
                     </div>
                </div>
             </div>
          </div>
          <div class="col-lg-4">
             <div class="widget">
                <div class="widget-content d-v-center flex-justify-between">
                    <div class="w-75">
                   <h4>{{totalEmployee}}</h4>
                   <!-- <p>Employees across companies</p> -->
                   <p>Total Employees</p>
                    </div>
                    <div class="widget-icon w-b">
                        <img src="../../../../assets/img/employees.png" alt="total-employee">
                     </div>
                </div> 
             </div>
          </div>
          <div class="col-lg-4">
            <div class="widget">
               <div class="widget-content d-v-center flex-justify-between">
                   <div class="w-75">
                  <h4>{{totalTrainer}}</h4>
                  <!-- <p>Total Trainers across companies</p> -->
                  <p>Total Trainers</p>
                   </div>
                   
                   <div class="widget-icon w-b">
                    <img src="../../../../assets/img/teaching.png" alt="total-trainer">
               </div>
               </div>
            </div>
         </div>
          <div class="col-lg-4">
             <div class="widget">
                <div class="widget-content d-v-center flex-justify-between">
                    <div class="w-75">
                        <h4>{{totalLiveBadges}}</h4>
                        <p>Live Badges</p>
                     </div>
                    <div class="widget-icon w-b">
                        <img src="../../../../assets/img/medal.png" alt="live-badges">
                    </div>
                </div>
             </div>
          </div>
         </div>
    </div>
 </section>