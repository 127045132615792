import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';

import {ResponseContainer} from '../../../service/ResponseContainer';
import {RequestContainer} from '../../../service/RequestContainer'; 
declare var $ :any;

@Component({
  selector: 'app-employee-list-ass-trn',
  templateUrl: 'employee-list-ass-trn.component.html'
})
export class EmployeeListAssTrnComponent implements OnInit {
  public submitObj:any = {};
  public targetIndex:Object = {};
  public pageNo:number= 1;
  public pageSize:number= 15;
  public routeUrl:Object = {};
  public responseData:any = {};  
  public id:any = {}; 
  
  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;
 
  constructor(
    private router:Router,
     private httpService : HttpService, 
     private route: ActivatedRoute
   ) 
   {}   
  ngOnInit(): void {  
    var requestObj = new RequestContainer(); 
    requestObj['pageNo'] = this.pageNo;
    requestObj['pageSize'] = this.pageSize;
    requestObj['platform'] = "Web";
    requestObj['company_id']= JSON.parse(sessionStorage.getItem('login_company'));
    this.httpService.postRequest<ResponseContainer>(requestObj,'trainer/getEmployee').subscribe(
      datas=> {
        if(datas.rsBody.result == 'success')
        {
          this.responseData = datas.rsBody.data;
        } 
        else{
          console.log(datas.rsBody.error);
        }
      });    
  } 

 /*
  * pagination
  */
 loadpage(event) {
  $('.loader').css('display','block');
   this.pageNo= event;
   this.ngOnInit();
 }    

 
  /*
 * View Profile
 */
 viewProfile(id)
 { 
  this.router.navigate(['/trainers/employee-profile-trn',id]);
 } 


  
}
 