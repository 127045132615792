import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';
import {ResponseContainer} from '../../../service/ResponseContainer';
import {RequestContainer} from '../../../service/RequestContainer'; 
import * as $ from 'jquery';

@Component({ 
  selector: 'app-notification-trn',
  templateUrl: 'notification-trn.component.html'
})
export class NotificationTrnComponent implements OnInit {

  public responseData:any = {};
  public targetIndex:Object = {}; 

  public submitObj:any = {
    optionList: [{}],
  };
  public setPageNo:boolean = false;
  public setPageNoNext:boolean = false;
  public pageNo:any = {};
  public routeUrl:Object = {};
  public id:any = {}; 

  constructor( 
    private router:Router, 
    private httpService : HttpService, 
    private route: ActivatedRoute
  )
  {
  }

  ngOnInit(): void {  
    $('.modal ').insertAfter($('body')); 
    var requestObj = new RequestContainer();
    // requestObj['pageNo'] = 0;  
    // this.pageNo = requestObj['pageNo'];
    var currentUser = JSON.parse(sessionStorage.getItem('login_user_id')); 
    requestObj['empId'] =  currentUser;
    this.httpService.postRequest<ResponseContainer>(requestObj,'trainer/getAnswersNotification').subscribe(
      datas=> {
        if(datas.rsBody.result == 'success') 
        {
          this.responseData = datas.rsBody.data;
          // if(this.responseData.length == 1) 
          // {
          //     this.setPageNoNext = true;   
          // }  
          // else
          // {
          //     this.setPageNoNext = false;
          // }
          // if(this.pageNo == 0)
          // {
          //     this.setPageNo = false;
          // }
          // else
          // {
          //     this.setPageNo = true;
          // }
        } 
        else{
          console.log(datas.rsBody.error);
        } 
      });
  }


  /*
  * update status of answer (Approve or Reject) 
  */ 
 answerStatusUpdate(answer_id, status){
    this.submitObj.answer_id = answer_id;
    this.submitObj.status = status;

    this.httpService.postRequest<ResponseContainer>(this.submitObj,'trainer/answerStatusUpdate').subscribe(datas => {
      if(datas.rsBody.result == 'success')
      {
        this.submitObj = {};
       // $("#changesubmit").modal('show');
        this.ngOnInit();
      } 
      else
      { 
       console.log('error');
      }
     });
  }
  getHyperlinkedText(text : any) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;

    // Replace URLs with clickable links
    return text.replace(urlRegex, function(url) {
      return '<a target="_blank" href="' + url + '">' + url + '</a>';
    });
  }
}
 