import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';
import {ResponseContainer} from '../../../service/ResponseContainer';
import {RequestContainer} from '../../../service/RequestContainer';  
import * as $ from 'jquery';

@Component({
  selector: 'app-history-tld',
  templateUrl: 'history-tld.component.html'
})
export class HistoryTldComponent implements OnInit {
  public responseData:any = {};
  public badgeList:any = {};
  public submitObj:any = {};
  public id:any = {}; 

  constructor(
    private router:Router,
    private httpService : HttpService, 
    private route: ActivatedRoute 
  )
  { 
  }
  ngOnInit(): void {
    $('.modal ').insertAfter($('body')); 
    var currentUser = JSON.parse(sessionStorage.getItem('login_user_id')); 
    this.id =  currentUser;
    this.httpService.postRequest<ResponseContainer>(this.id,'teamlead/getEmployeeData').subscribe(
      datas=> {
        if(datas.rsBody.result == 'success')  
        {
          this.responseData.id = datas.rsBody.data[0]['id'];
          this.responseData.user_id = datas.rsBody.data[0]['user_id'];
          this.responseData.first_name = datas.rsBody.data[0]['first_name'];
          this.responseData.last_name = datas.rsBody.data[0]['last_name'];
          this.responseData.email_id = datas.rsBody.data[0]['email_id'];
          this.responseData.mobile_no = datas.rsBody.data[0]['mobile_no'];
          this.httpService.postRequest<ResponseContainer>(this.id,'teamlead/getEmployeeBadge').subscribe(
            datas=> {
              if(datas.rsBody.result == 'success')  
              {
                this.badgeList = datas.rsBody.data;
              }  
              else{ 
                console.log(datas.rsBody.error);
              } 
            });
        } 
        else{ 
          console.log(datas.rsBody.error);
        } 
      });
  }

} 

