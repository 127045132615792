import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-training-trn',
  templateUrl: 'training-trn.component.html'
})
export class TrainingTrnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
