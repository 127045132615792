import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';
import {ResponseContainer} from '../../../service/ResponseContainer';
import {RequestContainer} from '../../../service/RequestContainer';

@Component({
  selector: 'app-dashboard-adm',
  templateUrl: 'dashboard-adm.component.html'
})
export class DashboardAdmComponent implements OnInit {

  public submitObj:Object={};
  public totalTrainer:any={}; 
  public totalEmployee:any={};
  public totalLiveBadges:any={}; 
  public totalExpiredBadges:any={};
  public MaxAssignBadge:any={};
  public MinAssignBadge:any={};

  public routeUrl:Object = {};
  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;
 
  constructor(private httpService : HttpService,private router:Router, 
    private route: ActivatedRoute){}  
  
  ngOnInit(): void {  
    var Obj = JSON.parse(sessionStorage.getItem('login_company'));
    this.httpService.postRequest<ResponseContainer>(Obj, 'admin/getDashboard').subscribe(datas => {
      if(datas.rsBody.result == 'success')
      {
        this.totalEmployee = datas.rsBody.data[0]['totalEmployee'];
        this.totalTrainer = datas.rsBody.data[0]['totalTrainer'];
        this.totalLiveBadges = datas.rsBody.data[0]['totalLiveBadges'];
        this.totalExpiredBadges = datas.rsBody.data[0]['totalExpiredBadges'];
        this.MaxAssignBadge = datas.rsBody.data[0]['MaxAssignBadge'];
        this.MinAssignBadge = datas.rsBody.data[0]['MinAssignBadge'];

      } 
     }); 
 
}

 

}
