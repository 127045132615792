<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row"> 
            <div class="col-md-12">
                <div class="page-title-box"> 
                    <ol class="breadcrumb"> 
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item" (click)="backCategory();"><a href="javascript:void(0);">Category</a></li>
                        <li class="breadcrumb-item" (click)="backSkillList();"><a href="javascript:void(0);">Skill List</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Assign Trainer</a></li>
                    </ol>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                        <div class="title">
                            <h4>Trainer</h4>
                        </div>
                    </div>

                    <div class="c-grid" *ngIf='responseData.length > 0'>
                        <div class="widget-profile d-v-center" *ngFor="let item of responseData | paginate: { itemsPerPage: pageSize, currentPage: pageNo } let i=index">
                            <div class="widget-img-holder">
                                <div>
                                    <img src="../../../../assets/img/Profile.png">
                                </div>
                                <span><i class="fa fa-circle mr-2 text-danger"></i></span>
                            </div>
                            <h3 class="widget-content">
                                <span class="w-title">{{item.first_name}}</span>  
                                <span class="w-val">#{{item.id}}</span>
                            </h3> 

                            <div class="t-menu-profile">
                                <a class="t-drp dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="ti-more-alt"></i></a>
                                <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                    <li><a class="dropdown-item d-v-center" href="javascript:void(0);" (click)="assignUpdate(item.user_id)"><span class="sts mr-2"><i class="fa fa-user-plus" aria-hidden="true"></i>
                                            </span> Assign</a></li>
                                    <li><a class="dropdown-item"  href="javascript:void(0);" (click)="viewProfile(item.id)"><span class="sts  mr-2"><i class="fa fa-user-circle" aria-hidden="true"></i>
                                            </span> View Profile</a></li>
                                </ul>
                            </div> 
                        </div>
                        <!--start-->
                        <!--No data Found start-->
                        <div class="w-100" *ngIf='responseData.length == 0'>
                           <div class="text-center" role="not found">
                              <i class="fa fa-exclamation-circle fa-5x" aria-hidden="true"></i>
                              <p class="mt-2">No Badges Found</p>
                           </div>
                        </div>
                        <!--No data found end-->
                        <!--end-->
                    </div>
                </div>
                <div class="text-center mt-4">
                   <pagination-controls  *ngIf="responseData.length !=0" (pageChange)="loadpage($event)" maxSize="5" directionLinks="true" responsive="true" previousLabel="Previous" nextLabel="Next"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</section>
