import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';

import {ResponseContainer} from '../../../service/ResponseContainer';
import {BaseContainer} from '../../../service/BaseContainer';
import {RequestContainer} from '../../../service/RequestContainer';
declare var $ :any;

@Component({
  selector: 'app-dashboard-tld',
  templateUrl: 'dashboard-tld.component.html'
})
export class DashboardTldComponent implements OnInit {

  public submitObj:any = {};
  public targetIndex:Object = {};
  public setPageNo:boolean = false;
  public setPageNoNext:boolean = false;
  public pageNo:any = {};
  public routeUrl:Object = {};
  public responseData:any = {};
  public badgeList:any = {};
  public id:any = {};

  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;

  constructor(
    private router:Router,
     private httpService : HttpService,
     private route: ActivatedRoute
   )
  {}


  ngOnInit(): void {
    $('.count').each(function () {
      $(this).prop('Counter',0).animate({
          Counter: $(this).text()
          }, {
              duration: 4000,
              easing: 'swing',
              step: function (now) {
                  $(this).text(Math.ceil(now));
              }
          });
      });
    var requestObj = new RequestContainer();
    requestObj['pageNo'] = 0;
    requestObj['platform'] = "Web"; 
    this.pageNo = requestObj['pageNo']; 
    var currentUser = JSON.parse(sessionStorage.getItem('login_user_id'));
    requestObj['empId'] =  currentUser;
    this.httpService.postRequest<ResponseContainer>(requestObj,'employee/getAssigneTaskSkills').subscribe(
      datas=> {
        if(datas.rsBody.result == 'success')
        {
          this.responseData = datas.rsBody.data;
          if(this.responseData.length == 5)
          {
              this.setPageNoNext = true;
          }
          else
          {
              this.setPageNoNext = false;
          }
          if(this.pageNo == 0)
          {
              this.setPageNo = false;
          }
          else
          { 
              this.setPageNo = true;
          }
        }
        else{
          console.log(datas.rsBody.error);
        }
      });
  }


//Task list
taskList(trainerId, skillId, badgeId)
{
 this.router.navigate(['/teamlead/tasklist-tld',trainerId, skillId,badgeId]);
}

assignedBadge(trainerId, skillId, badgeId){
  this.submitObj.trainerId =trainerId;
  this.submitObj.empId = JSON.parse(sessionStorage.getItem('login_user_id'));
  this.submitObj.skillId = skillId;
  this.submitObj.badgeId = badgeId;
  this.httpService.postRequest<ResponseContainer>(this.submitObj,'employee/assignedBadge').subscribe(datas => {
    if(datas.rsBody.result == 'success')
    {
      this.submitObj = {};
      $("#changesubmit").modal('show');
      this.ngOnInit();
    }
  });
}


updateStatus(trainerId, skillId, badgeId){
  this.submitObj.trainerId =trainerId;
  this.submitObj.empId = JSON.parse(sessionStorage.getItem('login_user_id'));
  this.submitObj.skillId = skillId;
  this.submitObj.badgeId = badgeId;
  this.submitObj.status = 0;
  this.httpService.postRequest<ResponseContainer>(this.submitObj,'employee/updateStatus').subscribe(datas => {
    if(datas.rsBody.result == 'success')
    {
      this.submitObj = {};
      this.ngOnInit();
    }
  });
}
}
