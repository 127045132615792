<div class="sidebar-nav ps ps--active-y" role="nav">
  <div class="side-menu-container">
    <div class="user-wrap">
        <div class="user-side-wrap d-v-center mt-2">
            <div class="user-img">
                <!-- <img src="../../../assets/img/user.png"> -->
                
                <label for="" *ngIf="userProfilePath != null">
                    <img [src]="userProfilePath" alt="" /><br>
                </label>
                
                <label *ngIf="userProfilePath == null" >
                    <img src="../../../assets/img/user.png">
                </label> 
            </div>
            <div class="user-info ml-3">
                <h4>{{userDisplayName}}</h4> 
                <p>{{userDisplayRole}}</p>
                <div class="profilenolog">
                    <a href="" routerLink="/superadmin/notification-suadm"><span class="ti-bell"></span> </a>
                    <a href="" routerLink="/superadmin/edit-profile-suadm"><span class="fa fa-edit"></span> </a>
                    <a href="" class="" (click)="logout();">
                <!-- <li><a href="javascript:void(0);" (click)="logout(currentUser)" class="log-out"> -->
                    <span class="ti-power-off"></span> 
                    </a>
                </div>
            </div>
            <div *ngIf='userDisplayName == null'>
                {{logout()}}
            </div>
            
        </div>
 
    </div>
    <!--menu-->

    <nav class="sidebar-menu pt-3">
        <ul>
            <li><a href="#" routerLink="/superadmin/dashboard-suadm" routerLinkActive="active"><svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 304"><defs><style>.cls-1{fill:#fff;}</style></defs><title>dashboard</title><path class="cls-1" d="M464,104H48A40,40,0,0,0,8,144V368a40,40,0,0,0,40,40H464a40,40,0,0,0,40-40V144A40,40,0,0,0,464,104ZM24,144a24,24,0,0,1,24-24H464a24,24,0,0,1,24,24v8H24ZM488,368a24,24,0,0,1-24,24H48a24,24,0,0,1-24-24V168H488Z" transform="translate(-8 -104)"/><path class="cls-1" d="M64,128H48a8,8,0,0,0,0,16H64a8,8,0,0,0,0-16Z" transform="translate(-8 -104)"/><path class="cls-1" d="M104,128H88a8,8,0,0,0,0,16h16a8,8,0,0,0,0-16Z" transform="translate(-8 -104)"/><path class="cls-1" d="M144,128H128a8,8,0,0,0,0,16h16a8,8,0,0,0,0-16Z" transform="translate(-8 -104)"/><path class="cls-1" d="M368,240h64a8,8,0,0,0,0-16H368a8,8,0,0,0,0,16Z" transform="translate(-8 -104)"/><path class="cls-1" d="M336,272h96a8,8,0,0,0,0-16H336a8,8,0,0,0,0,16Z" transform="translate(-8 -104)"/><path class="cls-1" d="M336,304h96a8,8,0,0,0,0-16H336a8,8,0,0,0,0,16Z" transform="translate(-8 -104)"/><path class="cls-1" d="M336,336h48a8,8,0,0,0,0-16H336a8,8,0,0,0,0,16Z" transform="translate(-8 -104)"/><path class="cls-1" d="M416,320h-8a8,8,0,0,0,0,16h8a8,8,0,0,0,0-16Z" transform="translate(-8 -104)"/><path class="cls-1" d="M336,240h8a8,8,0,0,0,0-16h-8a8,8,0,0,0,0,16Z" transform="translate(-8 -104)"/><path class="cls-1" d="M64,360H288a8,8,0,0,0,0-16H256V280a8,8,0,0,0-8-8H216a8,8,0,0,0-8,8v64h-8V208a8,8,0,0,0-8-8H160a8,8,0,0,0-8,8V344h-8V240a8,8,0,0,0-8-8H104a8,8,0,0,0-8,8V344H64a8,8,0,0,0,0,16Zm160-72h16v56H224Zm-56-72h16V344H168Zm-56,32h16v96H112Z" transform="translate(-8 -104)"/></svg> Dashboard</a></li>
            <!-- <li><a href="#" routerLink="/superadmin/sync-suadm" routerLinkActive="active">
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1907.33 1142.18"><defs><style>.cls-1{fill:#fff;}</style></defs><title>Sync Data</title><path class="cls-1" d="M881.83,1311.2v465.5a50,50,0,0,1-50,50H366.33a50,50,0,0,1-50-50V1311.2a50,50,0,0,1,50-50h183V972.41A49.81,49.81,0,0,1,599.47,923l1058.68,8.39V748.9a50,50,0,0,1,50-50h465.51a50,50,0,0,1,50,50v465.51a50,50,0,0,1-50,50H1708.15a50,50,0,0,1-50-50V1031l-318.43-2.52v247.1h182.76a50,50,0,0,1,50,50v465.51a50,50,0,0,1-50,50H1057a50,50,0,0,1-50-50V1325.57a50,50,0,0,1,50-50h182.75V1027.68L648.88,1023v238.2H831.83a50,50,0,0,1,50,50Zm590.65,429.88V1375.57H1107v365.51Zm651.18-576.67V798.9H1758.15v365.51ZM781.83,1726.7V1361.2H416.33v365.5Z" transform="translate(-316.33 -698.9)"/></svg> Sync Data</a></li> -->
            <li><a href="#" routerLink="/superadmin/company-suadm" routerLinkActive="active">
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1907.33 1142.18"><defs><style>.cls-1{fill:#fff;}</style></defs><title>Company</title><path class="cls-1" d="M881.83,1311.2v465.5a50,50,0,0,1-50,50H366.33a50,50,0,0,1-50-50V1311.2a50,50,0,0,1,50-50h183V972.41A49.81,49.81,0,0,1,599.47,923l1058.68,8.39V748.9a50,50,0,0,1,50-50h465.51a50,50,0,0,1,50,50v465.51a50,50,0,0,1-50,50H1708.15a50,50,0,0,1-50-50V1031l-318.43-2.52v247.1h182.76a50,50,0,0,1,50,50v465.51a50,50,0,0,1-50,50H1057a50,50,0,0,1-50-50V1325.57a50,50,0,0,1,50-50h182.75V1027.68L648.88,1023v238.2H831.83a50,50,0,0,1,50,50Zm590.65,429.88V1375.57H1107v365.51Zm651.18-576.67V798.9H1758.15v365.51ZM781.83,1726.7V1361.2H416.33v365.5Z" transform="translate(-316.33 -698.9)"/></svg> Company</a></li>
        </ul>
    </nav>

</div>
</div>
