import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';
import {ResponseContainer} from '../../../service/ResponseContainer';
import {BaseContainer} from '../../../service/BaseContainer';
import {RequestContainer} from '../../../service/RequestContainer';

declare var $ :any;

@Component({
  selector: 'app-category-adm',
  templateUrl: 'category-adm.component.html'
})
export class CategoryAdmComponent implements OnInit {
  public submitObj:Object={};
  public targetIndex:Object = {}; 
  public setPageNo:boolean = false;
  public setPageNoNext:boolean = false;
  public pageNo:any = {}; 

  public responseData;
  public routeUrl:Object = {};
  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;
 
  constructor(private httpService : HttpService,private router:Router,
    private route: ActivatedRoute){}  
  
  ngOnInit(): void {
    $('.modal ').insertAfter($('body')); 
    var requestObj = new RequestContainer();
    requestObj['pageNo'] = 0; 
    this.pageNo = requestObj['pageNo'];
    requestObj['company_id']= JSON.parse(sessionStorage.getItem('login_company'));
    this.httpService.postRequest<ResponseContainer>(requestObj, 'admin/getCategory').subscribe(datas => {
      if(datas.rsBody.result == 'success')
      {
        this.responseData = datas.rsBody.data;
       if(this.responseData.length == 6) 
       {
          this.setPageNoNext = true;
          
       }  
       else
       {
          this.setPageNoNext = false;
       }
       if(this.pageNo == 0)
       {
          this.setPageNo = false;
       }
       else
       {
          this.setPageNo = true;
       }
      } 
     });
  }


/*
* pagination next
*/
 nextSubmit()
 {
   var  requestObj = new RequestContainer();
   requestObj['pageNo'] = this.pageNo + 1;
   this.pageNo = requestObj['pageNo'];
   requestObj['company_id']= JSON.parse(sessionStorage.getItem('login_company'));
   this.httpService.postRequest<ResponseContainer>(requestObj,'admin/getCategory').subscribe(
   datas=> {
     this.responseData = datas.rsBody.data;
     if(this.responseData.length == 6)
     {
        this.setPageNoNext = true;
        this.setPageNo = true; 
     }
     else
     {
        this.setPageNoNext = false;
        this.setPageNo = true;
     }
   });

 }
 /*
 * pagination previous
 */
 previousSubmit()
 {
   var  requestObj = new RequestContainer();
   if(this.pageNo == 0)
   {
     requestObj['pageNo'] = 0;
     this.pageNo = requestObj['pageNo'];
   }
   else
   {
     requestObj['pageNo'] = this.pageNo - 1;
     this.pageNo = requestObj['pageNo'];
   }
   requestObj['company_id']= JSON.parse(sessionStorage.getItem('login_company'));
   this.httpService.postRequest<ResponseContainer>(requestObj,'admin/getCategory').subscribe(
   datas=> {
     this.responseData = datas.rsBody.data;
     if(this.responseData.length == 6)
     {
        this.setPageNoNext = true;
     }
     else
     {
        this.setPageNoNext = false;
        this.setPageNo = true;
     } 
     if(this.pageNo == 0)
     { 
        this.setPageNo = false;
     } 
     else
     {
        this.setPageNo = true;
     } 
   });

 } 

 listSkill(id)
  {
   this.router.navigate(['/admin/category-skills-adm',id]); 
  }

 /*
 * delete
 */
deleteSubmit(catId)
{
  this.targetIndex = catId;
  $("#deletesubmit").modal('show');
}

deleteRow(Obj) 
{
 this.httpService.postRequest<ResponseContainer>(Obj,'admin/deleteCategory').subscribe(datas => {
  if(datas.rsBody.result == 'success')
  { 
    $("#deletesubmit").modal('hide');
    this.ngOnInit();
  }
 });
}

  /*
   * edit Category 
   */ 
  editSubmit(id)
  {
    this.router.navigate(['/admin/add-category-adm',id]);
  }
}
  