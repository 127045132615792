<style>
.login-container {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    /* background-image: url(../../assets/img/atlas-copco-group.jpeg); */
    position: relative;
    background-color: #0198CD;
}
.login-container::after{
content: '';
position:absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
 background-color: rgba(255,255,255,0.3);
}
.login-wrap {
    width: 50rem;
    display:flex;
    border-radius: 10px;
    box-shadow: 1px 1px 14px 9px rgba(0,0,0,0.3);
    position: relative;
    z-index: 9;
    overflow: hidden;
}

.login-form {
    background: #fff;
    width: 100%;
    padding: 2rem;
}
.input-icon {
    position: relative;
}
.input-icon span {
    position: absolute;
    text-align: center;
    top: 9%;
    left: 4%;
}
.input-icon span svg {
    width: 1.2rem;
}
.input-icon .form-control{
    padding-left:40px;
}
.l_logo{
    width: 9rem;
} 
.bg-img{
    width: 70rem;
}
.bg-img img{
width: 100%;
height: 100%;
}
.txt_fadein {
    animation: fadein 2s;
    -moz-animation: fadein 2s; /* Firefox */
    -webkit-animation: fadein 2s; /* Safari and Chrome */
    -o-animation: fadein 2s; /* Opera */
}
@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}
.btn-ats{
    background-color: #0078a1;
    color: #fff;
}
@media(max-width:660px) {
    .bg-img {
    display:none;
}
input[type="text"], input[type="number"], input[type="email"], input[type="tel"], input[type="password"], input.form-control, select, textarea.form-control {
    font-size:1rem;
    height: 3.5rem;
}
.input-icon span {
    top: 17%;
}
}
</style> 
<div class="login-container" id="profile-submit">
  <div class="login-wrap">
    <div class="login-form"> 
        <img src="../../../assets/img/Atlas_Copco_logo.png"  alt="atlas-copco" class="l_logo">
        <h4 class="txt_fadein">Reset Your Password</h4>
        <small>Enter your email, you will be sent a new temporary password if your email is valid. 
            Change your password after logging In.</small>
          <form class="form mt-4" id="profileupdateBasic" role="form" autocomplete="off" #catForm="ngForm">
              <div class="form-group input-icon">
                <input class="form-control" type="email" value="" placeholder="Enter your email" name="email_id" id="email" [(ngModel)]="submitObj.email_id" checkMandatory="true" (focus)="onFocus()">
                <span><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" class="mt-2 w-8 h-8 stroke-current text-gray-100"><path d="M3 8L10.8906 13.2604C11.5624 13.7083 12.4376 13.7083 13.1094 13.2604L21 8M5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19Z" stroke="#4A5568" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg></span>  
              </div>
              <span class="ng-hide displayError" id="email_error"></span>
              <div class="form-group">
                  <div class="d-v-center">
                      <input type="submit" class="btn btn-ats" (click)="createSubmit();" value="Submit">
                  </div>
              </div>
            </form>
        </div>
        <div class="bg-img">
            <img src="../../../assets/img/team.jpg">
        </div>
</div>
</div>

 
 