import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';

import {ResponseContainer} from '../../../service/ResponseContainer';
import {RequestContainer} from '../../../service/RequestContainer'; 
declare var $ :any;

@Component({
  selector: 'app-create-task-trn',
  templateUrl: 'create-task-trn.component.html'
})
export class CreateTaskTrnComponent implements OnInit {
  public submitObj:any = {};
  public targetIndex:Object = {};
  public setPageNo:boolean = false; 
  public setPageNoNext:boolean = false;
  public pageNo:any = {};
  public routeUrl:Object = {};
  public responseData:any = {}; 
  public badgeList:any = {}; 
  public id:any = {}; 

  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;
 
  constructor(
    private router:Router,
     private httpService : HttpService, 
     private route: ActivatedRoute
   )
   {}  

  ngOnInit(): void {
  
  }

}
