<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row"> 
            <div class="col-md-12"> 
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item" (click)="backPathway();"><a href="javascript:void(0);">Pathway</a></li>
                        <li class="breadcrumb-item active" *ngIf='setCreate'><a href="javascript:void(0);">Add New Pathway</a></li>
                        <li class="breadcrumb-item active" *ngIf='setUpdate'><a href="javascript:void(0);">Update Category</a></li>
                    </ol>
                </div>
            </div>
        </div> 


        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                    <div class="title">
                        <h4>Add Pathway</h4> 
                        </div>
                        
                        <!-- <a class="search-wrapper"  routerLink="/category">
                            <p>View Category</p>
                        </a> -->
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row" id="profile-submit">
            <div class="create-n-ctg col-md-6">
                <div class="card mt-3">
                    <div class="card-body">
                        <form class="form" id="profileupdateBasic" role="form" autocomplete="off" #catForm="ngForm">
                            <div class="row">
                            <div class="form-group col-12">
                                <label class="col-form-label form-control-label">Select Pathway:</label>
                                <div id="pathway" > 
                                    <div class="d-v-center"> 
                                        <div class="ng-autocomplete" class="w-100">
                                            <ng-autocomplete 
                                            [data]="pathwayList" 
                                            [searchKeyword]="keyword"
                                                (selected)='selectEvent($event)'
                                                (inputChanged)='onChangeSearch($event)'
                                                (inputFocused)='onFocused($event)'
                                                [itemTemplate]="itemTemplate"
                                                [notFoundTemplate]="notFoundTemplate">                                 
                                            </ng-autocomplete>
                                                
                                            <ng-template #itemTemplate let-item> 
                                            <a [innerHTML]="item.pathway_name"></a>
                                            </ng-template>
                                                
                                            <ng-template #notFoundTemplate let-notFound>
                                            <div [innerHTML]="notFound"></div>
                                            </ng-template>
                                        </div>  
                                    </div>    
                                    <span class="ng-hide displayError" id="pathway_error"></span>
                                
                                </div>
                            </div>   
                            
                            
                            <div class="form-group col-12">
                                <div class="float-right mt-1">
                                <div class="d-v-center">
                                    <input *ngIf='setCreate' type="reset" class="btn btn-secondary mr-2" (click)="backPathway();" value="Cancel">
                                    <input *ngIf='setCreate' type="submit" class="btn btn-warning" (click)="createSubmit();" value="Save">
                                    <!-- <input *ngIf='setUpdate' type="submit" class="btn btn-secondary" (click)="updateSubmit();" value="Update"> -->
                                </div>
                                </div>
                            </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> 



<!--successsfull-modal-->
<div class="modal fade"  id="changesubmit" role="dialog" aria-labelledby="trash-mdl" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>Submission Successfull</p>
        </div>
          <div class="mdl-footer d-v-center">
              <button class="btn btn-black btn-block m-0" data-dismiss="modal">Ok</button>
          </div>
      </div>
    </div>
  </div> 