import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpService } from '../service/http.service';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { FormsModule }   from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';

import { TeamleadRouting } from './teamlead.routing'
import { TeamleadComponent } from './teamlead.component';
import { HeaderModule } from '../header/header.module';
import { DashboardTldComponent } from './component/dashboard-tld/dashboard-tld.component';
import { SidebarTldComponent } from './component/sidebar-tld/sidebar-tld.component';
import { EmployeeListTldComponent } from './component/employee-list-tld/employee-list-tld.component';
import { HistoryTldComponent } from './component/history-tld/history-tld.component';
import { LearningPathwaysTldComponent } from './component/learning-pathways-tld/learning-pathways-tld.component';
import { AddpathwayTldComponent } from './component/add-pathway-tld/add-pathway-tld.component';
import { MyteamTldComponent } from './component/myteam-tld/myteam-tld.component';
import { NotificationTldComponent } from './component/notification-tld/notification-tld.component';
import { TaskListTldComponent } from './component/task-list-tld/task-list-tld.component';
import { TaskSListTldComponent } from './component/tasklist-tld/tasklist-tld.component';
import { Task2TldComponent } from './component/task2-tld/task2-tld.component';
import { Task2SelfTldComponent } from './component/task2-self-tld/task2-self-tld.component';
import { TechnicalTldComponent } from './component/technical-tld/technical-tld.component';
import { EditProfileAdmComponent } from './component/edit-profile-adm/edit-profile-adm.component';


@NgModule({
  declarations: [
    TeamleadComponent,
    SidebarTldComponent,
    DashboardTldComponent,
    EmployeeListTldComponent,
    HistoryTldComponent,
    LearningPathwaysTldComponent,
    AddpathwayTldComponent,
    MyteamTldComponent,
    NotificationTldComponent,
    TaskListTldComponent,
    TaskSListTldComponent,
    Task2TldComponent,
    Task2SelfTldComponent,
    TechnicalTldComponent,
    EditProfileAdmComponent
  ],
  imports: [
    BrowserModule,
    HeaderModule,
    HttpClientModule,
    FormsModule,
    AutocompleteLibModule,
    TeamleadRouting,
    NgxPaginationModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      animationDuration: 300,
      class:'pgrsbar',
      showImage:true
    })
  ],
  providers: [{provide: APP_BASE_HREF, useValue : '' },HttpService],
  bootstrap: [TeamleadComponent]
})
export class TeamleadModule { }
