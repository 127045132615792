import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminComponent } from './admin.component';
import { DashboardAdmComponent } from './component/dashboard-adm/dashboard-adm.component';
import { MybadgesAdmComponent } from './component/mybadges-adm/mybadges-adm.component';
import { CategoryAdmComponent } from './component/category-adm/category-adm.component';
import { AddCategoryAdmComponent } from './component/add-category-adm/add-category-adm.component'; 
import { CategorySkillsAdmComponent } from './component/category-skills-adm/category-skills-adm.component';
import { AddCategorySkillAdmComponent } from './component/add-category-skiill-adm/add-category-skill-adm.component';
import { AssignTrainerAdmComponent } from './component/assign-trainer-adm/assign-trainer-adm.component';
import { AssignEmployeeAdmComponent } from './component/assign-employee-adm/assign-employee-adm.component';

import { LearningPathwaysAdmComponent } from './component/learning-pathways-adm/learning-pathways-adm.component';
import { AddLearningPathwaysAdmComponent } from './component/add-learning-pathways-adm/add-learning-pathway-adm.component';
import { AddLearningPathwaysSkillAdmComponent } from './component/add-learning-pathways-skiill-adm/add-learning-pathways-skill-adm.component';

import { ManagmentAdmComponent } from './component/managment-adm/managment-adm.component';
import { NotificationAdmComponent } from './component/notification-adm/notification-adm.component';
import { TrainersAdmComponent } from './component/trainers-adm/trainers-adm.component';
import { TeamleadersAdmComponent } from './component/teamleaders-adm/teamleaders-adm.component'; 
import { SuperTeamleadersAdmComponent } from './component/super-teamleaders-adm/super-teamleaders-adm.component';
import { TrainerProfileAdmComponent } from './component/trainer-profile-adm/trainer-profile-adm.component';
import { Task2AdmComponent } from './component/task2-adm/task2-adm.component';
import { TasklistAdmComponent } from './component/tasklist-adm/tasklist-adm.component'; 
import { AssignTeamLeaderdmComponent } from './component/assign-teamleader-adm/assign-teamleader-adm.component'; 
import { AddEmployeeAdmComponent } from './component/add-employee-adm/add-employee-adm.component';
import { EmployeeAdmComponent } from './component/employee-adm/employee-adm.component'; 
import { AssignManagerAdmComponent } from './component/assign-manager-adm/assign-manager-adm.component'; 
import { EditProfileAdmComponent } from './component/edit-profile-adm/edit-profile-adm.component';


const routes: Routes = [ 
    {  
        path: 'admin',  
        component: AdminComponent,
        children: [
            { path: '', component: DashboardAdmComponent },
            { path: 'dashboard-adm', component: DashboardAdmComponent },  
            { path: 'edit-profile-adm', component: EditProfileAdmComponent },  
            { path: 'mybadges-adm', component: MybadgesAdmComponent },  
            { path: 'tasklist-adm/:trainerId/:skillId/:badgeId', component: TasklistAdmComponent },
            { path: 'task2-adm/:taskId', component: Task2AdmComponent },
            { path: 'trainers-adm', component: TrainersAdmComponent },
            { path: 'teamleaders-adm', component: TeamleadersAdmComponent },
            { path: 'super-teamleaders-adm', component: SuperTeamleadersAdmComponent },
            { path: 'notification-adm', component: NotificationAdmComponent }, 
            { path: 'managment-adm/:id', component: ManagmentAdmComponent },   
            { path: 'learning-pathways-adm', component: LearningPathwaysAdmComponent },
            { path: 'add-learning-pathway-adm', component: AddLearningPathwaysAdmComponent },
            { path: 'add-learning-pathway-adm/:id', component: AddLearningPathwaysAdmComponent },    
            { path: 'add-learning-pathway-skill-adm/:id', component: AddLearningPathwaysSkillAdmComponent, runGuardsAndResolvers: 'always' }, 
            { path: 'category-adm', component: CategoryAdmComponent }, 
            { path: 'add-category-adm', component: AddCategoryAdmComponent },   
            { path: 'add-category-adm/:id', component: AddCategoryAdmComponent },                
            { path: 'category-skills-adm/:id', component: CategorySkillsAdmComponent, runGuardsAndResolvers: 'always' },
            { path: 'add-category-skill-adm/:id', component: AddCategorySkillAdmComponent, runGuardsAndResolvers: 'always' }, 
            { path: 'add-category-skill-adm/:id/:skillId', component: AddCategorySkillAdmComponent, runGuardsAndResolvers: 'always' }, 
            { path: 'assign-trainer-adm/:catId/:skillId/:badgeId', component: AssignTrainerAdmComponent, runGuardsAndResolvers: 'always' }, 
            { path: 'assign-employee-adm/:teamleaderId', component: AssignEmployeeAdmComponent, runGuardsAndResolvers: 'always' }, 
            // { path: 'assign-trainer-adm/:id', component: AssignTrainerAdmComponent, runGuardsAndResolvers: 'always' }, 
            { path: 'trainer-profile-adm/:id', component: TrainerProfileAdmComponent },
            { path: 'assign-leader', component: AssignTeamLeaderdmComponent },  
            { path: 'add-employee-adm', component: AddEmployeeAdmComponent },   
            { path: 'employee-adm', component: EmployeeAdmComponent },   
            { path: 'assign-manager/:superteamleaderId', component: AssignManagerAdmComponent },  


        ]
    }
]; 

export const AdminRouting = RouterModule.forRoot(routes);