<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item active" (click)="backCategory();"><a href="javascript:void(0);">Category</a></li>
                        <li class="breadcrumb-item active" name="catId"  [(ngModel)]="submitObj.catId" (click)="backBadges();"><a href="javascript:void(0);">Badges</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Task List</a></li>
                    </ol>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                        <div class="title">
                            <h4>Task List</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row badge-view-wrapper mt-4">
            <div class="e-list" >
                <div *ngIf='responseData.length > 0'>
                    <a class="e-list-wrap" *ngFor="let item of responseData let i=index">
                        <span class="e-name"><strong>{{item.task_name}}</strong></span>
                        <span>{{item.qa_cnt}} Question/{{item.act_cnt}} Activity</span>
                          <span>{{item.task_description}}</span>
                        <div class="d-v-center">
                          <a href="javascript:void(0)" class="e-btn btn-warning" (click)="addQuestion(item.id);" title="Add"><i class="fa fa-plus" aria-hidden="true"></i></a>
                          <a href="javascript:void(0)" (click)="deleteSubmit(item.id);" class="e-btn btn-danger ml-2" title="Remove"><i class="fa fa-trash-o" aria-hidden="true"></i></a>
                        </div>
                    </a>

                    <a class="e-list-wrap-mob" *ngFor="let item of responseData let i=index">
                        <div class="e-list-float-left">
                            <h4 class="e-name"><strong>{{item.task_name}}</strong></h4>
                            <p><i class="fa fa-user" aria-hidden="true"></i>{{item.description}}</p>
                            <p>{{item.qa_cnt}} Question/{{item.act_cnt}} Activity</p>
                        </div>
                        <div class="e-list-float-right">
                            <div class="mb-2"><svg class="tick fill-danger" viewBox="0 0 448 448.55357"  xmlns="http://www.w3.org/2000/svg"><path d="m224.550781 0c-123.667969 0-224.273437 100.605469-224.273437 224.277344 0 123.667968 100.605468 224.277344 224.273437 224.277344 123.664063 0 224.269531-100.609376 224.269531-224.277344 0-123.671875-100.605468-224.277344-224.269531-224.277344zm0 430.269531c-113.582031 0-205.988281-92.414062-205.988281-205.992187 0-113.582032 92.40625-205.992188 205.988281-205.992188 113.578125 0 205.984375 92.410156 205.984375 205.992188 0 113.578125-92.40625 205.992187-205.984375 205.992187zm0 0"/><path d="m340.378906 134.847656-159.46875 159.464844-72.191406-72.1875c-3.570312-3.570312-9.355469-3.570312-12.929688 0-3.570312 3.570312-3.570312 9.355469 0 12.929688l78.65625 78.652343c1.785157 1.785157 4.125 2.675781 6.464844 2.675781s4.679688-.890624 6.464844-2.675781l165.933594-165.929687c3.570312-3.570313 3.570312-9.359375 0-12.929688-3.570313-3.570312-9.359375-3.570312-12.929688 0zm0 0"/>
                            </svg></div>
                            <p>Stress Analysis</p>
                            <a href="javascript:void(0)" class="btn btn-yellow m-btn" (click)="addQuestion(item.id);">Add</a>
                            <a href="javascript:void(0)" (click)="deleteSubmit(item.id);" class="btn btn-yellow m-btn">Remove</a>
                        </div>

                    </a>
                </div>

                <!--No data Found start-->
                  <div class="w-100" *ngIf='responseData.length == 0'>
                      <div class="text-center" role="not found">
                          <i class="fa fa-exclamation-circle fa-5x" aria-hidden="true"></i>
                          <p class="mt-2">No Badges Found</p>
                      </div>
                  </div>
               <!--No data found end-->

                <div class="text-right mt-4">
                    <a href="javascript:void(0)" class="btn btn-warning m-btn" (click)="openCreatTask();">Add Task</a>

                    <!-- <a href="javascript:void(0)" class="btn btn-warning m-btn ml-2" (click)="openAssignTask();">Assign Badge</a> -->
                </div>
            </div>
        </div>
    </div>
</section>

  <!--Create Task-->
<div class="modal fade" id="createsubmit" role="dialog" aria-hidden="true">
    <div class="modal-dialog" id="profile-submit">
         <div class="modal-content">
           <form id="profileupdateBasic" #catForm="ngForm">
             <div class="modal-body">
               <div class="text-center">
                 <div class="text-success">
                  <strong>Create New Task !!!</strong>

                 </div>
               </div>
               <label class="mt-3">Task name</label>
               <input class="form-control" type="text" value="" placeholder="Enter Task name" name="skillId"  [(ngModel)]="submitObj.skillId" hidden>
               <input class="form-control" type="text" value="" placeholder="Enter Task name" name="badgeId"  [(ngModel)]="submitObj.badgeId" hidden>
               <input class="form-control" type="text" value="" placeholder="Enter Task name" name="task_name" id="task_name" [(ngModel)]="submitObj.task_name" checkMandatory="true" (focus)="onFocus()">
               <span class="ng-hide displayError" id="task_name_error"></span>

               <label class="mt-3">Task description</label>
               <input class="form-control" type="text" value="" placeholder="Enter Task description" name="task_description" id="task_description" [(ngModel)]="submitObj.task_description" checkMandatory="true" (focus)="onFocus()">
               <span class="ng-hide displayError" id="task_description_error"></span>

            </div>
             <div class="modal-footer">
               <button type="button" class="btn btn-success btn-sm" (click)='createTask()'>Yes</button>
               <button type="button" class="btn btn-danger btn-sm" data-dismiss="modal">No</button>
             </div>
           </form>
         </div>
     </div>
 </div>



<!--Assign Task to employee-->
<div class="modal fade" id="assignsubmit" role="dialog" aria-hidden="true">
    <div class="modal-dialog" id="profile-submit">
         <div class="modal-content">
           <form id="profileupdateBasic" #catForm="ngForm">
             <div class="modal-body">
               <div class="text-center">
                 <div class="text-success">
                  <strong>Assign Task to employee !!!</strong>

                 </div>
               </div>
               <label>Select Employee</label>
               <input class="form-control" type="text" value="" placeholder="Enter Task name" name="skillId"  [(ngModel)]="submitObj.skillId" hidden>
               <input class="form-control" type="text" value="" placeholder="Enter Task name" name="badgeId"  [(ngModel)]="submitObj.badgeId" hidden>
               <div class="d-v-center">
                <div class="ng-autocomplete" class="w-100">
                    <ng-autocomplete
                    [data]="employeeList"
                    [searchKeyword]="keyword"
                        (selected)='selectEvent($event)'
                        (inputChanged)='onChangeSearch($event)'
                        (inputFocused)='onFocused($event)'
                        [itemTemplate]="itemTemplate"
                        [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>

                    <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.first_name"></a>
                    </ng-template>

                    <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                    </ng-template>
                </div>
            </div>
            </div>
             <div class="modal-footer">
               <button type="button" class="btn btn-success btn-sm" (click)='assignTask()'>Yes</button>
               <button type="button" class="btn btn-danger btn-sm" data-dismiss="modal">No</button>
             </div>
           </form>
         </div>
     </div>
 </div>

  <!--Submission-modal-->
 <div class="modal fade"  id="changesubmit" role="dialog" aria-labelledby="trash-mdl" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>Submission Successfull</p>
        </div>
          <div class="mdl-footer d-v-center">
              <button class="btn btn-black btn-block m-0" (click)="refreshSubmit();">Ok</button>
          </div>
      </div>
    </div>
  </div>

  <!--trash-modal-->
<div class="modal fade"  id="deletesubmit" tabindex="-1"  role="dialog" aria-labelledby="trash-mdl" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>Are you sure you wanted to delete this user?</p>
        </div>
          <div class="mdl-footer d-v-center">
              <button class="btn btn-yellow btn-block" (click)='deleteRow(targetIndex);'>Yes</button>
              <button class="btn btn-black btn-block m-0" data-dismiss="modal">No</button>
          </div>
      </div>
    </div>
  </div>
