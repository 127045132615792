<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box"> 
                    <ol class="breadcrumb"> 
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <!-- <li class="breadcrumb-item" (click)="backTeam();"><a href="javascript:void(0);">My Team</a></li> -->
                        <!-- <li class="breadcrumb-item" (click)="backEmployee();"><a href="javascript:void(0);">Employee List</a></li> -->
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Task List</a></li>
                    </ol>
                </div>
            </div>
        </div>

 
        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                        <div class="title">
                            <h4>task List</h4>
                        </div>
                    </div>
                </div>
            </div> 
        </div>

         
        <div class="row badge-view-wrapper mt-4">
            <div class="e-list" > 
                <div *ngIf='responseData.length > 0'> 
                    <a class="e-list-wrap" *ngFor="let item of responseData let i=index" (click)="signleTask(item.id);">
                        <span class="e-name"><strong>{{item.task_name}}</strong></span>
                        <span>{{item.qa_cnt}} Question/{{item.act_cnt}} Activity</span>
                        <span><i class="fa fa-user" aria-hidden="true"></i>
                            Given By {{item.first_name}}</span>
                        <span>
                            <svg class="tick fill-success" viewBox="0 0 448 448.55357"  xmlns="http://www.w3.org/2000/svg"><path d="m224.550781 0c-123.667969 0-224.273437 100.605469-224.273437 224.277344 0 123.667968 100.605468 224.277344 224.273437 224.277344 123.664063 0 224.269531-100.609376 224.269531-224.277344 0-123.671875-100.605468-224.277344-224.269531-224.277344zm0 430.269531c-113.582031 0-205.988281-92.414062-205.988281-205.992187 0-113.582032 92.40625-205.992188 205.988281-205.992188 113.578125 0 205.984375 92.410156 205.984375 205.992188 0 113.578125-92.40625 205.992187-205.984375 205.992187zm0 0"/><path d="m340.378906 134.847656-159.46875 159.464844-72.191406-72.1875c-3.570312-3.570312-9.355469-3.570312-12.929688 0-3.570312 3.570312-3.570312 9.355469 0 12.929688l78.65625 78.652343c1.785157 1.785157 4.125 2.675781 6.464844 2.675781s4.679688-.890624 6.464844-2.675781l165.933594-165.929687c3.570312-3.570313 3.570312-9.359375 0-12.929688-3.570313-3.570312-9.359375-3.570312-12.929688 0zm0 0"/>
                            </svg>
                        </span> 

                    </a>
    
                    <a class="e-list-wrap-mob" *ngFor="let item of responseData let i=index">
                        <div class="e-list-float-left">
                            <h4 class="e-name"><strong>{{item.task_name}}</strong></h4>
                            <p>{{item.qa_cnt}} Question/{{item.act_cnt}} Activity</p> 
                            <p><i class="fa fa-user" aria-hidden="true"></i> Given By {{item.first_name}}</p>
                        </div>
                        <div class="e-list-float-right">
                            <div class="mb-2"><svg class="tick fill-danger" viewBox="0 0 448 448.55357"  xmlns="http://www.w3.org/2000/svg"><path d="m224.550781 0c-123.667969 0-224.273437 100.605469-224.273437 224.277344 0 123.667968 100.605468 224.277344 224.273437 224.277344 123.664063 0 224.269531-100.609376 224.269531-224.277344 0-123.671875-100.605468-224.277344-224.269531-224.277344zm0 430.269531c-113.582031 0-205.988281-92.414062-205.988281-205.992187 0-113.582032 92.40625-205.992188 205.988281-205.992188 113.578125 0 205.984375 92.410156 205.984375 205.992188 0 113.578125-92.40625 205.992187-205.984375 205.992187zm0 0"/><path d="m340.378906 134.847656-159.46875 159.464844-72.191406-72.1875c-3.570312-3.570312-9.355469-3.570312-12.929688 0-3.570312 3.570312-3.570312 9.355469 0 12.929688l78.65625 78.652343c1.785157 1.785157 4.125 2.675781 6.464844 2.675781s4.679688-.890624 6.464844-2.675781l165.933594-165.929687c3.570312-3.570313 3.570312-9.359375 0-12.929688-3.570313-3.570312-9.359375-3.570312-12.929688 0zm0 0"/>
                            </svg></div>
                        </div>
                        
                    </a>
                </div>
                <div *ngIf='responseData.length == 0'>
                    <strong>No Data Found</strong>
               </div>
            </div>
        </div>
    </div>
</section>
