import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { FormsModule }   from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router,ActivatedRoute } from '@angular/router';
import {ResponseContainer} from '../../../service/ResponseContainer';
import {ValidationHandler} from '../../../service/ValidationHandler'; 
import {BaseContainer} from '../../../service/BaseContainer';  
import {RequestContainer} from '../../../service/RequestContainer'; 
declare var $ :any;  

@Component({ 
  selector: 'app-create-task-questions-trn',
  templateUrl: 'create-task-questions-trn.component.html', 
  providers : [HttpService,ValidationHandler]   

})
export class CreateTaskQuestionsTrnComponent implements OnInit {
  showDiv = {
    previous : true,
    current : false,
    next : false
  } 
  public responseData:any = {};
  public targetIndex:Object = {}; 
  public baseUrl:string = this.httpService.baseUrl;

  public submitObj:any = {
    optionList: [{}],
  };
  public setPageNo:boolean = false;
  public setPageNoNext:boolean = false;
  public pageNo:any = {};
  public routeUrl:Object = {};
  public id:any = {}; 

  constructor(
    private router:Router,
    private httpService : HttpService, 
    private route: ActivatedRoute,
    private validationHandler : ValidationHandler,
    private sanitizer: DomSanitizer

  )
  {
  }


  ngOnInit(): void {
    $('.modal ').insertAfter($('body')); 
    var requestObj = new RequestContainer();
    requestObj['pageNo'] = 0; 
    this.pageNo = requestObj['pageNo'];
    requestObj['platform'] = "Web";
    var currentUser = JSON.parse(sessionStorage.getItem('login_id'));
    requestObj['empId'] =  currentUser;
    this.submitObj.taskId = this.route.snapshot.paramMap.get('taskId');
    requestObj['catId']=  Number(this.route.snapshot.params.catId); 
    requestObj['skillId'] =  Number(this.route.snapshot.params.skillId);
    requestObj['badgeId']  =  Number(this.route.snapshot.params.badgeId); 
    requestObj['taskId'] =  this.route.snapshot.paramMap.get('taskId');
    this.routeUrl = this.router.url; 
    if(this.routeUrl == '/trainers/create-task-questions-trn/'+requestObj['catId']+'/'+requestObj['skillId']+'/'+requestObj['badgeId']+'/'+requestObj['taskId'])
    { 
        this.httpService.postRequest<ResponseContainer>(requestObj,'trainer/getTaskQuestionsList').subscribe(
          datas=> {
            if(datas.rsBody.result == 'success') 
            {

              console.log("datas.rsBody.data______Before___")
              console.log(datas.rsBody.data)

              this.responseData = datas.rsBody.data.map((el : any) =>  { return {...el , task_doc : this.baseUrl + "/uploads/" + el.task_doc}})

              // this.responseData = datas.rsBody.data;
              if(this.responseData.length == 5) 
              {
                  this.setPageNoNext = true;  
              }  
              else
              {
                  this.setPageNoNext = false;
              }
              if(this.pageNo == 0)
              {
                  this.setPageNo = false;
              }
              else
              {
                  this.setPageNo = true;
              }
            } 
            else{
              console.log(datas.rsBody.error);
            }
          });
    }
  }

  transform(link) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      link
    );
  }

/* 
* pagination next
*/ 
nextSubmit()
{
  var  requestObj = new RequestContainer();
  requestObj['pageNo'] = this.pageNo + 1;
  this.pageNo = requestObj['pageNo'];
    requestObj['taskId'] =  this.route.snapshot.paramMap.get('taskId');
    requestObj['platform'] = "Web";
  this.httpService.postRequest<ResponseContainer>(requestObj,'trainer/getTaskQuestionsList').subscribe(
  datas=> {
    // this.responseData = datas.rsBody.data;
    this.responseData = datas.rsBody.data.map((el : any) =>  { return {...el , task_doc : this.baseUrl + "/uploads/" + el.task_doc}})
    if(this.responseData.length == 5)
    {
       this.setPageNoNext = true;
       this.setPageNo = true;
    }
    else
    {
       this.setPageNoNext = false;
       this.setPageNo = true;
    }
  }); 
}


/* 
* pagination previous
*/
previousSubmit()
{
  var  requestObj = new RequestContainer();
  if(this.pageNo == 0)
  {
    requestObj['pageNo'] = 0;
    this.pageNo = requestObj['pageNo'];
  }
  else
  {
    requestObj['pageNo'] = this.pageNo - 1;
    this.pageNo = requestObj['pageNo'];
  }
    requestObj['taskId'] =  this.route.snapshot.paramMap.get('taskId');
    requestObj['platform'] = "Web";
  this.httpService.postRequest<ResponseContainer>(requestObj,'trainer/getTaskQuestionsList').subscribe(
  datas=> {
    // this.responseData = datas.rsBody.data;
    this.responseData = datas.rsBody.data.map((el : any) =>  { return {...el , task_doc : this.baseUrl + "/uploads/" + el.task_doc}})
    if(this.responseData.length == 5)
    {
       this.setPageNoNext = true;
    }
    else
    {
       this.setPageNoNext = false;
       this.setPageNo = true;
    } 
    if(this.pageNo == 0)
    { 
       this.setPageNo = false;
    }
    else
    {
       this.setPageNo = true;
    }
  });

}  
  
  /*
  * create submit 
  */ 
 createSubmit()
 { 
  this.httpService.postRequest<ResponseContainer>(this.submitObj,'trainer/addQuestion').subscribe(datas => {
    if(datas.rsBody.result == 'success')
    {

      // this.submitObj = {};
      $("#changesubmit").modal('show');
      this.ngOnInit();
    }
    else
    {
      console.log(this.submitObj)
    this.validationHandler.displayErrors(datas.rsBody.errors,"profileupdateBasic",null);
    }
  });
  // let status = this.validationHandler.validateDOM('profileupdateBasic');
  // if(!status)
  //   {
      
  // }  
 }

/*
* Add activity submit 
*/ 
 addActivity()
 { 
  this.httpService.postRequest<ResponseContainer>(this.submitObj,'trainer/addActivity').subscribe(datas => {
    if(datas.rsBody.result == 'success')
    {
      this.submitObj = {taskId : this.submitObj.taskId};
      $("#changesubmit").modal('show');
    } 
    else
    { 
     console.log('error');
    }
   });
 }

 


  /*
  * Add option
  */
 addOption()
 {
     this.submitObj.optionList.push({});
 }

 /*
 * remove option
 */
 removeOption(obj)
 {
     this.submitObj.optionList.splice(obj,1);
 }

 
 /*
 * delete
 */
deleteSubmit(empId)
{
  this.targetIndex = empId;
  //this.ModalService.open(empId);
  $("#deletesubmit").modal('show');
}

deleteRow(Obj)
{
 this.httpService.postRequest<ResponseContainer>(Obj,'trainer/deleteTaskQuestion').subscribe(datas => {
  $('.spinner').css('display','none');
  if(datas.rsBody.result == 'success')
  { 
    $("#deletesubmit").modal('hide');
    this.ngOnInit();
  }
 });
}


 /**
   * Convert into base 64
  */   
 handleInputChange(e) {
  var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
  var pattern = /image-*/;
  var reader = new FileReader();
  // if (!file.type.match(pattern)) {
  //   alert('invalid format');
  //   return;
  // }
  reader.onload = this._handleReaderLoaded.bind(this);
  this.submitObj['feature_type'] = file.type;

  reader.readAsDataURL(file);

}
_handleReaderLoaded(e) {
  let reader = e.target;
  this.submitObj['imagePath'] = reader.result;
  this.submitObj['feature_image'] = reader.result;
  //console.log(this.imageSrc);
}
 removeImage()
 {
    this.submitObj['imagePath'] = null;
    this.submitObj['feature_image'] = null;
 }

 /**
   * Refresh
  */  
 refresh(){
  $("#changesubmit").modal('hide'); 
  this.ngOnInit(); 
  // this.submitObj.taskId = this.route.snapshot.paramMap.get('taskId');
  // this.router.navigate(['/trainers/create-task-questions-trn',this.submitObj.taskId]);
 }


 /* 
* back Category page 
*/ 
backCategory(){ 
  this.router.navigate(['/trainers/assign-task-trn']);  
 } 


 backBadges(){
  var catId =  Number(this.route.snapshot.params.catId); 
  this.router.navigate(['/trainers/task-trn',catId]);  
 }

 backTaskList(){
  var catId=  Number(this.route.snapshot.params.catId); 
  var skillId =  Number(this.route.snapshot.params.skillId);
  var badgeId  =  Number(this.route.snapshot.params.badgeId); 
  this.router.navigate(['/trainers/task-list-trn',catId,skillId,badgeId]);  

 }
 
}
