<header class="app-header navbar">
    <a class="navbar-brand" href="#">
        <img class="navbar-brand-full lmodelogo" src="../../assets/img/Atlas_Copco_logo.png" alt="Logo">
        <img class="navbar-brand-full dmodelogo" src="../../assets/img/white_logo.png" alt="Logo">
        <img class="navbar-brand-minimized" src="../../assets/img/Atlas_Copco_logo-mob.svg" alt="Logo">
    </a>
    <button class="navbar-toggler d-md-down-none" type="button">
        <span class="navbar-toggler-icon ti-menu"></span>
    </button>
    <button class="navbar-toggler-mob d-md-down-none" type="button">
        <span class="navbar-toggler-icon ti-menu"></span>
    </button>
    <ul class="profile-nav ml-auto d-v-center">
        <li>
            <a class="moonf" href="javascript:;" (click)="addClass()"><i class="fa fa-moon-o"></i></a>
            <a class="sunf" href="javascript:;" (click)="removeClass()"><i class="fa fa-sun-o"></i></a>
        </li>
        <!-- <li><a href="javascript:;" class="notifycf mr-3" routerLink="notification-adm"><span class="ti-bell"></span></a></li> -->
        <li><a href="" class="btn btn-warning">Logout</a></li>
    </ul>
</header>
