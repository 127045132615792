// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-task-list',
//   templateUrl: 'task-list-tld.component.html'
// })
// export class TaskListTldComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }
import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';

import {ResponseContainer} from '../../../service/ResponseContainer';
import {RequestContainer} from '../../../service/RequestContainer';
declare var $ :any;

@Component({
  selector: 'app-task-list',
  templateUrl: 'task-list-tld.component.html'
})
export class TaskListTldComponent implements OnInit {

  public submitObj:any = {};
  public targetIndex:Object = {};
  public setPageNo:boolean = false;
  public setPageNoNext:boolean = false;
  public pageNo:any = {};
  public routeUrl:Object = {};
  public responseData:any = {};
  public badgeList:any = {};
  public id:any = {};

  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;

  constructor(
    private router:Router,
     private httpService : HttpService,
     private route: ActivatedRoute
   )
  {}


  ngOnInit(): void {
    $('.count').each(function () {
      $(this).prop('Counter',0).animate({
          Counter: $(this).text()
          }, {
              duration: 4000,
              easing: 'swing',
              step: function (now) {
                  $(this).text(Math.ceil(now));
              }
          });
      });
    var requestObj = new RequestContainer();
    requestObj['pageNo'] = 0;
    requestObj['platform'] = "Web";  
    this.pageNo = requestObj['pageNo'];
    var currentUser = JSON.parse(sessionStorage.getItem('login_user_id'));
    requestObj['empId'] =  currentUser;
    requestObj['skillId'] =  Number(this.route.snapshot.params.skillId);
    requestObj['badgeId']  =  Number(this.route.snapshot.params.badgeId);
    requestObj['trainerId']  =  Number(this.route.snapshot.params.trainerId);
    this.httpService.postRequest<ResponseContainer>(requestObj,'teamlead/getTaskList').subscribe(
      datas=> {
        if(datas.rsBody.result == 'success')
        {
          this.responseData = datas.rsBody.data;
          if(this.responseData.length == 5)
          {
              this.setPageNoNext = true;
          }
          else
          {
              this.setPageNoNext = false;
          }
          if(this.pageNo == 0)
          {
              this.setPageNo = false;
          }
          else
          {
              this.setPageNo = true;
          }
        }
        else{
          console.log(datas.rsBody.error);
        }
      });
  }



/*
* signleTask page
*/
signleTask(taskId){
  var requestObj = new RequestContainer();
  requestObj['employeeId']  =  Number(this.route.snapshot.params.employeeId);
  this.router.navigate(['/teamlead/task2-tld',taskId, requestObj['employeeId']]);
}


 /*
* back TeamLead page
*/
backTeam(){
  this.router.navigate(['/teamlead/myteam-tld']);
 }


 /*
* back Employee page
*/
backEmployee(){
  var skillId =  Number(this.route.snapshot.params.skillId);
  var badgeId  =  Number(this.route.snapshot.params.badgeId);
  this.router.navigate(['/teamlead/employee-list-tld',skillId,badgeId]);
 }


}
