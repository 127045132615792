import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF } from '@angular/common';
import { HttpService } from '../service/http.service';
import { HttpClientModule } from '@angular/common/http'; 
import { RouterModule, Routes } from '@angular/router';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { NgCircleProgressModule } from 'ng-circle-progress'; 
import { FormsModule }   from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';

//mport { NgxQRCodeModule } from 'ngx-qrcode2';
import { NgModule } from '@angular/core'; 
import { TrainersRouting } from './trainers.routing'
import { TrainersComponent } from './trainers.component';
import { SidebarTrnComponent } from './component/sidebar-trn/sidebar-trn.component';
import { DashboardTrnComponent } from './component/dashboard-trn/dashboard-trn.component';
import { AssignBadgeTrnComponent } from './component/assign-badge-trn/assign-badge-trn.component';
import { AssignedBadgesTrnComponent } from './component/assigned-badges-trn/assigned-badges-trn.component';
import { CategoryTrnComponent } from './component/category-trn/category-trn.component';
import { AssignTaskTrnComponent } from './component/assign-task-trn/assign-task-trn.component'; 
import { CreateTaskTrnComponent } from './component/create-task-trn/create-task-trn.component';
import { CreateTaskQuestionsTrnComponent } from './component/create-task-questions-trn/create-task-questions-trn.component';
import { EmployeeListAssTrnComponent } from './component/employee-list-ass-trn/employee-list-ass-trn.component';
import { AssignedEmployeeListTrnComponent } from './component/assigned-employee-list-trn/assigned-employee-list-trn.component';

import { EmployeeReportTrnComponent } from './component/employee-report-trn/employee-report-trn.component';

import { NotificationTrnComponent } from './component/notification-trn/notification-trn.component';
import { ScanEmployeeTrnComponent } from './component/scan-employee-trn/scan-employee-trn.component';
import { SkillsTrnComponent } from './component/skills-trn/skills-trn.component';
import { StopTrainingTrnComponent } from './component/stop-training-trn/stop-training-trn.component';
import { TaskCompleteTrnComponent } from './component/task-complete-trn/task-complete-trn.component';
import { TaskListTrnComponent } from './component/task-list-trn/task-list-trn.component';
import { TaskTrnComponent } from './component/task-trn/task-trn.component';
import { TrainingCompleteTrnComponent } from './component/training-complete-trn/training-complete-trn.component';
import { TrainingTrnComponent } from './component/training-trn/training-trn.component';
import { HeaderModule } from '../header/header.module';
import { ScanEmployeeListTrnComponent } from './component/scan-employee-list-trn/scan-employee-list-trn.component';
import { AssignedBadgesListTrnComponent } from './component/assigned-badges-list-trn/assigned-badges-list-trn.component';
import { EmployeeListTrnComponent } from './component/employee-list-trn/employee-list-trn.component';
import { EditProfileAdmComponent } from './component/edit-profile-adm/edit-profile-adm.component';


@NgModule({ 
  declarations: [
    TrainersComponent,
    SidebarTrnComponent,
    DashboardTrnComponent,
    AssignBadgeTrnComponent,
    AssignedBadgesTrnComponent,
    CategoryTrnComponent,
    AssignTaskTrnComponent,
    CreateTaskTrnComponent,
    CreateTaskQuestionsTrnComponent,
    EmployeeListAssTrnComponent,
    AssignedEmployeeListTrnComponent,
    EmployeeReportTrnComponent,
    NotificationTrnComponent,
    ScanEmployeeTrnComponent,
    SkillsTrnComponent,
    StopTrainingTrnComponent,
    TaskCompleteTrnComponent,
    TaskListTrnComponent,
    TaskTrnComponent,
    TrainingCompleteTrnComponent,
    TrainingTrnComponent,
    ScanEmployeeListTrnComponent,
    AssignedBadgesListTrnComponent,
    EmployeeListTrnComponent,
    EditProfileAdmComponent
    
  ],
  imports: [ 
    BrowserModule, 
    HttpClientModule,
    FormsModule,
    AutocompleteLibModule,
    NgxPaginationModule,
   // NgxQRCodeModule,
    TrainersRouting,
    HeaderModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      animationDuration: 300,
      class:'pgrsbar',
      showImage:true
    })
  ],
  providers: [{provide: APP_BASE_HREF, useValue : '' },HttpService],
  bootstrap: [TrainersComponent]
})
export class TrainersModule { }
