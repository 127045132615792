<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8">
                <div class="page-title-box">
                    <ol class="breadcrumb px-0 pb-0">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item" (click)="backCompany();"><a href="javascript:void(0);">Company</a>
                        </li>
                        <li class="breadcrumb-item active" *ngIf='setCreate'><a href="javascript:void(0);">Create New
                                Company</a></li>
                        <li class="breadcrumb-item active" *ngIf='setUpdate'><a href="javascript:void(0);">Update
                            Company</a></li>
                    </ol>
                </div>
            </div>
            <div class="col-md-4">
                <a class="search-wrapper btn btn-danger text-white float-md-right" (click)="backCompany();">
                   <i class="fa fa-list-ul"></i> Company List
                </a>
            </div>
        </div>


        <!-- <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                        <div class="title">
                            <h4>Categories</h4>
                        </div>

                        <a class="search-wrapper" (click)="backCompany();">
                            <p>View Company</p>
                        </a>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="row" id="profile-submit">
            <div class="create-n-ctg col-md-12 mr-auto ml-auto">
                <div class="card mt-2">
                    <div class="card-body">
                        <h4 class="mb-1">Categories</h4>
                        <form class="form" id="profileupdateBasic" role="form" autocomplete="off" #catForm="ngForm">
                            <div class="row">
                                <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">Company Name</label>
                                    <div>
                                        <input class="form-control" type="text" value=""
                                            placeholder="Enter Company Name" name="company_name" id="company_name"
                                            [(ngModel)]="submitObj.company_name" checkMandatory="true"
                                            (focus)="onFocus()">
                                        <span class="ng-hide displayError" id="company_name_error"></span>
                                    </div>
                                </div>
                                <!-- <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">Company Number</label>
                                    <div>
                                        <input class="form-control" type="text" value=""
                                            placeholder="Enter Company Number" name="company_no" id="company_no"
                                            [(ngModel)]="submitObj.company_no" checkMandatory="true"
                                            (focus)="onFocus()">
                                        <span class="ng-hide displayError" id="company_no_error"></span>
                                    </div>
                                </div> -->
                                <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">Branch</label>
                                    <div>
                                        <input class="form-control" type="text" value="" name="branch"
                                            placeholder="Enter Branch name" id="branch" [(ngModel)]="submitObj.branch"
                                            checkMandatory="true" (focus)="onFocus()">
                                        <span class="ng-hide displayError" id="branch_error"></span>
                                    </div>
                                </div>
                                
                                <!-- <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">API for data</label>
                                    <div>
                                        <input class="form-control" type="text" value="" name="api_url"
                                            placeholder="Enter API URL" id="api_url" [(ngModel)]="submitObj.api_url">
                                        <span class="ng-hide displayError" id="api_url_error"></span>
                                    </div>
                                </div> -->
                                <div class="form-group col-12 mb-0 mt-2">
                                    <div class="page-title-box">
                                        <h6>Admin/HR Details</h6>
                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">First Name</label>
                                    <div>
                                        <input class="form-control" type="text" value="" name="first_name"
                                            placeholder="Enter First Name" id="first_name" [(ngModel)]="submitObj.first_name"
                                            checkMandatory="true" (focus)="onFocus()">
                                        <span class="ng-hide displayError" id="first_name_error"></span>

                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">Last Name</label>
                                    <div>
                                        <input class="form-control" type="text" value="" name="last_name"
                                            placeholder="Enter Last Name" id="last_name" [(ngModel)]="submitObj.last_name"
                                             (focus)="onFocus()">
                                        <span class="ng-hide displayError" id="last_name_error"></span>

                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">Email Id</label>
                                    <div>
                                        <input class="form-control" type="text" value="" name="email_id"
                                            placeholder="Enter Email Id" id="email_id" [(ngModel)]="submitObj.email_id"
                                            checkMandatory="true" (focus)="onFocus()">
                                        <span class="ng-hide displayError" id="email_id_error"></span>

                                    </div>
                                </div>
                                <!-- <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">Phone</label>
                                    <div>
                                        <input class="form-control" type="text" value="" name="mobile_no"
                                            placeholder="Enter Phone" id="mobile_no" [(ngModel)]="submitObj.mobile_no"
                                             (focus)="onFocus()">
                                        <span class="ng-hide displayError" id="phone_no_error"></span>

                                    </div>
                                </div> -->
                                <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">Address</label>
                                    <div>
                                        <input class="form-control" type="text" value="" name="address"
                                            placeholder="Enter Address" id="address" [(ngModel)]="submitObj.address"
                                            (focus)="onFocus()">
                                        <span class="ng-hide displayError" id="address_error"></span>

                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">Region</label>
                                    <div>
                                        <input class="form-control" type="text" value="" name="region"
                                            placeholder="Enter Region" id="region" [(ngModel)]="submitObj.region"
                                            (focus)="onFocus()">
                                        <span class="ng-hide displayError" id="region_error"></span>
                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label class="col-form-label form-control-label">Password</label>
                                    <div>
                                        <input class="form-control" type="password" value="" name="password"
                                            placeholder="Enter Password" id="password" [(ngModel)]="submitObj.password"
                                          (focus)="onFocus()">
                                        <span class="ng-hide displayError" id="password_error"></span>
                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <div class="float-right mt-5">
                                        <div class="d-v-center">
                                            <input *ngIf='setCreate' type="reset" class="btn btn-secondary mr-2"
                                                (click)="backCompany();" value="Cancel">
                                            <input *ngIf='setCreate' type="submit" class="btn btn-warning"
                                                (click)="createSubmit();" value="Save">
                                            <input *ngIf='setUpdate' type="submit" class="btn btn-secondary"
                                                (click)="updateSubmit();" value="Update">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<!--successsfull-modal-->
<div class="modal fade" id="changesubmit" role="dialog" aria-labelledby="trash-mdl" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body text-center">
                <p>Submission Successfull</p>
            </div>
            <div class="mdl-footer d-v-center">
                <button class="btn btn-black btn-block m-0" data-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>