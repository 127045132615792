
import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeUrl, SafeStyle } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from '../../../service/http.service';
import {ResponseContainer} from '../../../service/ResponseContainer';
import {ValidationHandler} from '../../../service/ValidationHandler'; 

import {BaseContainer} from '../../../service/BaseContainer';
import {RequestContainer} from '../../../service/RequestContainer';
import { ToastrService } from 'ngx-toastr';

declare var $ :any; 

@Component({
  selector: 'forgotpassword',
  templateUrl: 'forgotpassword.component.html',
  providers : [HttpService,ValidationHandler]   
})
export class ForgotPasswordComponent {
  public submitObj:any = {};
  public responseData;
  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;

  constructor(
    private httpService : HttpService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private validationHandler : ValidationHandler
    )
  {}
  ngOnInit(): void { 
  }
  createSubmit ()
  {
    this.httpService.postRequest<ResponseContainer>(this.submitObj,'forget-password').subscribe(datas => {
        console.log(datas)
    });
  }
  onFocus()
  {
      this.validationHandler.hideErrors("profile-submit");
  }
}
