<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">History</a></li>
                    </ol>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                        <div class="title">
                            <h4>History</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row profile-m-card mt-4">
            <div class="col-md-12">
                <div class="profile-wrapper">
                    <div class="widget-profile d-v-center">
                        <div class="widget-img-holder">
                            <div>
                                <img src="https://demo.neontheme.com/assets/images/thumb-1@2x.png">
                            </div>
                            <span><i class="fa fa-circle mr-2 text-danger"></i></span>
                        </div>
                        <h3 class="widget-content">
                           <span class="w-title"><i class="ti-user mr-1"></i> {{responseData.first_name}} {{responseData.first_name}}</span>
                            <span class="w-val"><i class="ti-id-badge mr-1"></i> #{{responseData.id}}</span>
                            <span class="w-val"><i class="ti-email mr-1"></i> {{responseData.email_id}}</span>
                            <span class="w-val"><i class="ti-mobile mr-1"></i> {{responseData.mobile_no}}</span>
                        </h3>
                    </div>
                </div>
            </div>


        <div class="badge-view-wrapper px-0">
            <div class="col-md-12">
            <h5 class="title">Badge Overview:</h5>

                <ul class="badge-overview flex-wrap flex-baseline d-v-center mt-3" *ngIf='badgeList.length > 0'>
                     <li *ngFor="let item of badgeList let i=index">
                        <div class="card btn-brd">
                            <div class="card-body skill-set">
                                <div class="c_bar b-100" *ngIf="item.badge_id == 1">
                                    <circle-progress
                                    [percent]="30"
                                    [radius]="100"
                                    [outerStrokeWidth]="0"
                                    [innerStrokeWidth]="0"
                                    [space]="5"
                                    [outerStrokeColor]="'transparent'"
                                    [innerStrokeColor]="'transparent'"
                                    [animation]="true"
                                    [animationDuration]="300"
                                    [backgroundGradient]=""
                                    [backgroundColor]="'transparent'"
                                    [class]="'pgrsbar'"
                                    [imageSrc]="item.cat_skill_icon"
                                    [imageHeight]="100"
                                    [imageWidth]="100">
                                    </circle-progress>
                                </div>
                                <div class="c_bar i-100" *ngIf="item.badge_id == 2">
                                    <circle-progress
                                    [percent]="30"
                                    [radius]="100"
                                    [outerStrokeWidth]="0"
                                    [innerStrokeWidth]="0"
                                    [space]="5"
                                    [outerStrokeColor]="'transparent'"
                                    [innerStrokeColor]="'transparent'"
                                    [animation]="true"
                                    [animationDuration]="300"
                                    [backgroundGradient]=""
                                    [backgroundColor]="'transparent'"
                                    [class]="'pgrsbar'"
                                    [imageSrc]="item.cat_skill_icon"
                                    [imageHeight]="100"
                                    [imageWidth]="100">
                                    </circle-progress>
                                </div>
                                <div class="c_bar e-100" *ngIf="item.badge_id == 3">
                                    <circle-progress
                                    [percent]="30"
                                    [radius]="100"
                                    [outerStrokeWidth]="0"
                                    [innerStrokeWidth]="0"
                                    [space]="5"
                                    [outerStrokeColor]="'transparent'"
                                    [innerStrokeColor]="'transparent'"
                                    [animation]="true"
                                    [animationDuration]="300"
                                    [backgroundGradient]=""
                                    [backgroundColor]="'transparent'"
                                    [class]="'pgrsbar'"
                                    [imageSrc]="item.cat_skill_icon"
                                    [imageHeight]="100"
                                    [imageWidth]="100">
                                    </circle-progress>
                                </div>
                                <div class="prg_content mt-3">
                                <span class="ctskilnm">{{item.cat_skill_name}}</span>
                                <span class="ctnme">{{item.cat_name}}</span>
                                <hr>
                                <small class="expbadgeas"><strong>Exp:</strong> {{item.created_date}}</small>
                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- <li *ngIf="item.badge_id != null">
                        <div>
                            <div class="card-body skill-set">
                                <div class="c_bar b-100">
                                    <circle-progress
                                    [percent]="30"
                                    [radius]="100"
                                    [outerStrokeWidth]="0"
                                    [innerStrokeWidth]="0"
                                    [space]="5"
                                    [outerStrokeColor]="'transparent'"
                                    [innerStrokeColor]="'transparent'"
                                    [animation]="true"
                                    [animationDuration]="300"
                                    [backgroundGradient]=""
                                    [backgroundColor]="'transparent'"
                                    [class]="'pgrsbar'"
                                    [imageSrc]="item.cat_skill_icon"
                                    [imageHeight]="100"
                                    [imageWidth]="100">
                                    </circle-progress>
                                </div>
                            <p class="m-0">Beginner</p>
                            </div>
                        </div>
                    </li>

                    <li *ngIf="item.empId_2 != null">
                        <div>
                            <div class="card-body skill-set">
                                <div class="c_bar i-100">
                                    <circle-progress
                                    [percent]="30"
                                    [radius]="100"
                                    [outerStrokeWidth]="0"
                                    [innerStrokeWidth]="0"
                                    [space]="5"
                                    [outerStrokeColor]="'transparent'"
                                    [innerStrokeColor]="'transparent'"
                                    [animation]="true"
                                    [animationDuration]="300"
                                    [backgroundGradient]=""
                                    [backgroundColor]="'transparent'"
                                    [class]="'pgrsbar'"
                                    [imageSrc]="item.cat_skill_icon"
                                    [imageHeight]="100"
                                    [imageWidth]="100">
                                    </circle-progress>
                                </div>
                            <p class="m-0">Intermediate</p>
                            </div>
                        </div>
                    </li>

                    <li *ngIf="item.empId_3 != null">
                        <div>
                             <div class="card-body skill-set">
                                <div class="c_bar e-100">
                                    <circle-progress
                                    [percent]="30"
                                    [radius]="100"
                                    [outerStrokeWidth]="0"
                                    [innerStrokeWidth]="0"
                                    [space]="5"
                                    [outerStrokeColor]="'transparent'"
                                    [innerStrokeColor]="'transparent'"
                                    [animation]="true"
                                    [animationDuration]="300"
                                    [backgroundGradient]=""
                                    [backgroundColor]="'transparent'"
                                    [class]="'pgrsbar'"
                                    [imageSrc]="item.cat_skill_icon"
                                    [imageHeight]="100"
                                    [imageWidth]="100">
                                    </circle-progress>
                                </div>
                            <p class="m-0">Expert</p>
                            </div>
                        </div>
                    </li> -->

                    <!-- <li><div ng-reflect-router-link="/category-skills">
                    <div class="card-body skill-set">
                        <div>
                        <img src="../../../assets/img/Red-Beginner.png">
                    </div>
                        <h5 class="mt-1">CAD</h5>
                        <p class="m-0">05/03/2019 - 03:57 PM</p>
                    </div>
                </div></li>

                    <li><div ng-reflect-router-link="/category-skills">
                    <div class="card-body skill-set">
                        <div>
                        <img src="../../../assets/img/Yellow-Intermediate.png">
                    </div>
                        <h5 class="mt-1">Stress Analysis</h5>
                        <p class="m-0">05/03/2019 - 03:57 PM</p>
                    </div>
                </div></li>

                    <li><div ng-reflect-router-link="/category-skills">
                    <div class="card-body skill-set"><div>
                        <img src="../../../assets/img/Green-Expert.png">
                    </div>
                        <h5 class="mt-1">Design Engineering</h5>
                        <p class="m-0">05/03/2019 - 03:57 PM</p>
                    </div>
                </div></li> -->
                </ul>
                <!--No data Found start-->
                  <div class="w-100" *ngIf='badgeList.length == 0'>
                      <div class="text-center" role="not found">
                          <i class="fa fa-exclamation-circle fa-5x" aria-hidden="true"></i>
                          <p class="mt-2">No Badges Found</p>
                      </div>
                  </div>
               <!--No data found end-->
            </div>
        </div>
        </div>
    </div>
</section>
