<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Learning Pathways</a></li>
                    </ol>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                        <div class="title">
                            <h4>Learning Pathways</h4>
                        </div>
                        <a class="search-wrapper" routerLink="/employee/add-pathway-emp">
                            <p>Add Pathway</p>
                                <span><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
    <g>
        <g>
            <path d="M492,236H276V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v216H20c-11.046,0-20,8.954-20,20s8.954,20,20,20h216
                v216c0,11.046,8.954,20,20,20s20-8.954,20-20V276h216c11.046,0,20-8.954,20-20C512,244.954,503.046,236,492,236z"/>
        </g>
                                    </g></svg></span>
                         </a>
                    </div>
                </div>
            </div>
        </div> 
        <div class="b-grid pl-0" *ngIf='responseData.length != 0'>
            <a href="javascript:void(0);" *ngFor="let item of responseData | paginate: { itemsPerPage: pageSize, currentPage: pageNo } let i=index" class="widget-profile d-v-center" (click)="showPathwaySkill(item.pathway_id)">
                <div class="s-tool">
                </div>
                <div class="widget-img-holder">
                    <div>
                        <img src="{{item.pathway_icon}}">
                    </div>
                </div>
                <h3 class="widget-content">
                    <span class="w-title">{{item.pathway_name}}</span>
                    <span class="w-val">{{item.pathway_description}} </span>
                </h3>
            </a> 
        </div>
        <div class="text-center mt-4">
            <pagination-controls  *ngIf="responseData.length !=0" (pageChange)="loadpage($event)" maxSize="5" directionLinks="true" responsive="true" previousLabel="Previous" nextLabel="Next"></pagination-controls>
         </div>
        <!--No data Found start-->
          <div class="w-100" *ngIf='responseData.length == 0'>
              <div class="text-center" role="not found">
                  <i class="fa fa-exclamation-circle fa-5x" aria-hidden="true"></i>
                  <p class="mt-2">No Data Found</p>
              </div>
          </div>
       <!--No data found end--> 
    </div>
</section>
