import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';

import {ResponseContainer} from '../../../service/ResponseContainer';
import {BaseContainer} from '../../../service/BaseContainer';
import {RequestContainer} from '../../../service/RequestContainer'; 
declare var $ :any; 

@Component({
  selector: 'app-assigned-badges-list-trn',
  templateUrl: 'assigned-badges-list-trn.component.html'
})
export class AssignedBadgesListTrnComponent implements OnInit {

  public submitObj:any = {};
  public targetIndex:Object = {};
  public setPageNo:boolean = false; 
  public setPageNoNext:boolean = false;
  public pageNo:any = {};
  public routeUrl:Object = {}; 
  public responseData:any = {};  
  public id:any = {}; 
  public currentUser:any = {}; 


  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;
 
  constructor(
    private router:Router,
     private httpService : HttpService, 
     private route: ActivatedRoute
   )
   {}  
 
  ngOnInit(): void { 
    $('.count').each(function () {
      $(this).prop('Counter',0).animate({
          Counter: $(this).text()
          }, {
              duration: 4000,
              easing: 'swing',
              step: function (now) {
                  $(this).text(Math.ceil(now));
              }
          });
      });
    var requestObj = new RequestContainer();
    requestObj['pageNo'] = 0;  
    this.pageNo = requestObj['pageNo'];  
    requestObj['platform'] = "Web";
    this.submitObj.empId = JSON.parse(sessionStorage.getItem('login_user_id')); 
    this.currentUser = JSON.parse(sessionStorage.getItem('login_user_id'));
    //this.responseData = datas.rsBody.data;  
    this.httpService.postRequest<ResponseContainer>(this.submitObj,'trainer/getAssignedBadgeList').subscribe(
      datas=> {
        if(datas.rsBody.result == 'success') 
        { 
          this.responseData = datas.rsBody.data;  
        } 
        else{ 
          console.log(datas.rsBody.error);
        } 
      });  
    // var currentUser = JSON.parse(sessionStorage.getItem('login_id'));
    // requestObj['empId'] =  currentUser;
    // this.httpService.postRequest<ResponseContainer>(requestObj,'trainer/getAssignedBadge').subscribe(
    //   datas=> {
    //     if(datas.rsBody.result == 'success') 
    //     {
    //       this.responseData = datas.rsBody.data;
    //       if(this.responseData.length == 5)  
    //       {
    //           this.setPageNoNext = true;  
    //       }  
    //       else
    //       { 
    //           this.setPageNoNext = false;
    //       }  
    //       if(this.pageNo == 0)
    //       {
    //           this.setPageNo = false;
    //       }
    //       else
    //       {
    //           this.setPageNo = true;
    //       }
    //     } 
    //     else{
    //       console.log(datas.rsBody.error);
    //     }
    //   });
  }

}
