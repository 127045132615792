import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthComponent } from './auth/auth.component';
const routes: Routes =[
    {
        path: '', redirectTo: '/auth',  pathMatch: 'full',
    }
];

export const routing = RouterModule.forRoot(routes);