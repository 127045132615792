// import { Component, OnInit } from '@angular/core';

// @Component({

// })
// export class AssignTrainerAdmComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }
import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';

import {ResponseContainer} from '../../../service/ResponseContainer';
import {BaseContainer} from '../../../service/BaseContainer';
import {RequestContainer} from '../../../service/RequestContainer'; 
declare var $ :any;
@Component({
   selector: 'app-assign-trainer-adm',
  templateUrl: 'assign-trainer-adm.component.html'
}) 
export class AssignTrainerAdmComponent implements OnInit {

  public submitObj:any = {};
  public targetIndex:Object = {};
//   public setPageNo:boolean = false;
//   public setPageNoNext:boolean = false;
//   public pageNo:any = {};
  public routeUrl:Object = {};
  public responseData:any = {}; 
  public pageNo:number= 1;
  public pageSize:number= 9;

  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;
 
  constructor(
    private router:Router,
     private httpService : HttpService, 
     private route: ActivatedRoute,
   )
   {}  

   ngOnInit(): void {
    var requestObj = new RequestContainer();
    requestObj['pageNo'] = this.pageNo;
    requestObj['pageSize'] = this.pageSize;
    this.routeUrl = this.router.url;     
    requestObj['catId']  =  this.route.snapshot.params.catId; 
    requestObj['skillId']  =  this.route.snapshot.params.skillId; 
    requestObj['badgeId']  =  this.route.snapshot.params.badgeId; 
    requestObj['company_id']= JSON.parse(sessionStorage.getItem('login_company'));
    if(this.routeUrl == '/admin/assign-trainer-adm/'+requestObj['catId']+'/'+requestObj['skillId']+'/'+requestObj['badgeId'])
    { 
        this.httpService.postRequest<ResponseContainer>(requestObj,'admin/getEmployee').subscribe(
          datas=> {
            if(datas.rsBody.result == 'success')
            {
              this.responseData = datas.rsBody.data;
             
            } 
            else{
              console.log(datas.rsBody.error);
            }
          });
    }
  } 
  
   loadpage(event) {
     $('.loader').css('display','block');
      this.pageNo= event;
      this.ngOnInit();
    }

 
/*
 * Assign Trainer 
 */ 
 assignUpdate(id){ 
  this.submitObj.empId = id; 
  this.routeUrl = this.router.url; 
  this.submitObj.skillId  =  Number(this.route.snapshot.params.skillId); 
  this.submitObj.badgeId  =  Number(this.route.snapshot.params.badgeId); 
  this.httpService.postRequest<ResponseContainer>(this.submitObj,'admin/assignUpdate').subscribe(datas => {
    if(datas.rsBody.result == 'success')
    { 
      this.httpService.postRequest<ResponseContainer>(this.submitObj,'admin/getCategorySkillData').subscribe(datas => {
        if(datas.rsBody.result == 'success')
        {
          this.submitObj = {};
          this.responseData = datas.rsBody.data;
          this.responseData.id = datas.rsBody.data[0]['id'];
          this.router.navigate(['/admin/category-skills-adm',this.responseData.id]);
        }
        else
        { 
          console.log(datas.rsBody.error)
        }
       });
    }
    else
    { 
      console.log(datas.rsBody.error)
    }
   });

 }

/*
 * View Profile
 */
 viewProfile(id)
  { 
   this.router.navigate(['/admin/trainer-profile-adm',id]);
  }

  /*
* back Category page
*/
backCategory(){
  this.router.navigate(['/admin/category-adm']);  
 } 

      /* 
* back Skill List page 
*/ 
backSkillList(){
  var catId  =  this.route.snapshot.paramMap.get('catId');
  this.router.navigate(['/admin/category-skills-adm',catId]);  
 } 


}
 