// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-mypathways-emp',
//   templateUrl: 'mypathways-emp.component.html'
// })
// export class MypathwaysEmpComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// } 

import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';
import {ResponseContainer} from '../../../service/ResponseContainer';
import {ValidationHandler} from '../../../service/ValidationHandler'; 
import {BaseContainer} from '../../../service/BaseContainer';
import {RequestContainer} from '../../../service/RequestContainer';
declare var $ :any;

@Component({
  selector: 'app-add-pathway-emp',
  templateUrl: 'add-pathway-emp.component.html', 
  styleUrls: ['add-pathway-emp.component.html'],
  providers : [HttpService,ValidationHandler]   

})
export class AddpathwayEmpComponent implements OnInit {

  public submitObj:any = {};
  public targetIndex:Object = {};  
  public setPageNo:boolean = false;
  public setPageNoNext:boolean = false;
  public pageNo:any = {};
  public setCreate:boolean=true;
  public setUpdate:boolean = false;
  public pathwayList:any = {};
  public keyword:any = {};
  public id:any = {};
  public responseData; 
  public routeUrl:Object = {};
  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor; 

  
  constructor(
    private httpService : HttpService,
    private router:Router,
    private route: ActivatedRoute,
    private validationHandler : ValidationHandler
    ){}  

    selectEvent(item) {
      this.id = item.id;
      this.submitObj.pathway_id = item.id;
      var currentUser = JSON.parse(sessionStorage.getItem('login_user_id'));
      this.submitObj.emp_id = currentUser;
    }
    
    onChangeSearch(val: string) {
    }
    
    onFocused(e){
      this.validationHandler.hideErrors("profile-submit");
    
    }
  
  ngOnInit(): void {
    $('.modal ').insertAfter($('body')); 
    this.id= JSON.parse(sessionStorage.getItem('login_company')); 
    this.httpService.postRequest<ResponseContainer>(this.id,'employee/getPathwayList').subscribe(datas => { 
      if(datas.rsBody.result == 'success')
      {
        this.keyword = 'pathway_name';
        this.pathwayList = datas.rsBody.data; 
      } 
     });
  }


/*
* create submit  
*/   
createSubmit() 
{
 this.httpService.postRequest<ResponseContainer>(this.submitObj,'employee/addPathway').subscribe(datas => {
     if(datas.rsBody.result == 'success')
     { 
       this.submitObj = {}; 
       this.router.navigate(['/employee/mypathways-emp']);
     } 
     else
     { 
       this.validationHandler.displayErrors(datas.rsBody.errors,"profileupdateBasic",null);
     }
    });
}

  
/*
* Skill list of pathway 
*/
backPathway() 
{
 this.router.navigate(['/employee/mypathways-emp']); 
}

}
 
 