import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import {ResponseContainer} from '../../../service/ResponseContainer';
import {BaseContainer} from '../../../service/BaseContainer';
import {RequestContainer} from '../../../service/RequestContainer';

declare var $ :any;
@Component({
  selector: 'app-trainers-adm',
  templateUrl: 'trainers-adm.component.html'
})
export class TrainersAdmComponent implements OnInit {
  public submitObj:any = {};
  public targetIndex:Object = {};
  public pageNo:number= 1;
  public pageSize:number= 10;
  public employeeList:any = {};
  public employeeData:any = {};

  public keyword:any = {};

  public responseData; 
  public routeUrl:Object = {};
  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;

  constructor(private httpService : HttpService){}
  ngOnInit(): void {
    $('.modal ').insertAfter($('body'));
    $('#s_wrap').css('display','flex');
    $('#close-search').css('display','none');
    $('#auto_search').css('display','none');
    $('#my-table-add-trainer').css('display','none');
    $('#my-table').css('display','block');

    var requestObj = new RequestContainer();
    requestObj['pageNo'] = this.pageNo;
    requestObj['pageSize'] = this.pageSize;
    requestObj['company_id']= JSON.parse(sessionStorage.getItem('login_company'));
    this.httpService.postRequest<ResponseContainer>(requestObj, 'admin/getEmployee').subscribe(datas => {
      if(datas.rsBody.result == 'success')
      {
        $('.loader').css('display','none');
        this.responseData = datas.rsBody.data;
      }
     }); 
  }

  loadpage(event) {
     $('.loader').css('display','block');
      this.pageNo= event;
      this.ngOnInit();
    }


  selectEvent(item) {
     $('#auto_search').css('display','none');
     $('#my-table').css('display','none');
     $('#my-table-add-trainer').css('display','block');

    this.employeeData = item;
    // do something with selected item
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e){
    // do something when input is focused
  }


/*
* Employee List
*/
showEmployeeList()
{
  $('#auto_search').css('display','block');
  $('#close-search').css('display','flex');
  $('#s_wrap').css('display','none');
  // $('#my-table-add-trainer').css('display','block');
  // $('#my-table').css('display','none');
  this.submitObj.company_id= JSON.parse(sessionStorage.getItem('login_company'));
  this.httpService.postRequest<ResponseContainer>(this.submitObj, 'admin/getEmployeeList').subscribe(datas => {
   if(datas.rsBody.result == 'success')
   {
     this.keyword = 'first_name';
     this.employeeList = datas.rsBody.data;
   }
 });
}

addTrainer(empID)
{
  $('#auto_search').css('display','none');
  this.submitObj.empId = empID;

  this.httpService.postRequest<ResponseContainer>(this.submitObj, 'admin/addTrainer').subscribe(datas => {
   if(datas.rsBody.result == 'success')
   {
    $('#my-table-add-trainer').css('display','block');
    this.ngOnInit();
   }
   else{
     console.log(datas.rsBody.result);
   }
 });
}


 /*
 * delete
 */
deleteSubmit(empId)
{
  this.targetIndex = empId;
  //this.ModalService.open(empId);
  $("#deletesubmit").modal('show');
}

deleteRow(Obj)
{
 this.httpService.postRequest<ResponseContainer>(Obj,'admin/deleteEmployee').subscribe(datas => {
  $('.spinner').css('display','none');
  if(datas.rsBody.result == 'success')
  {
    $("#deletesubmit").modal('hide');
    this.ngOnInit();
  }
 });
}


}
