import { Component, OnInit } from '@angular/core';
declare var $ :any;
@Component({
  selector: 'app-training-complete-trn',
  templateUrl: 'training-complete-trn.component.html'
})
export class TrainingCompleteTrnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
      $('.modal').insertAfter($('body'));
  }

}
 