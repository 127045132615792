
import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';

import {ResponseContainer} from '../../../service/ResponseContainer';
import {RequestContainer} from '../../../service/RequestContainer'; 

declare var $ :any;
@Component({
  selector: 'app-super-teamleaders-adm',
  templateUrl: 'super-teamleaders-adm.component.html' 
})
export class SuperTeamleadersAdmComponent implements OnInit { 
  public submitObj:any = {}; 
  public targetIndex:Object = {};
  public setPageNo:boolean = false;  
  public setPageNoNext:boolean = false;
  public pageNo:any = {};
  public routeUrl:Object = {};
  public responseData:any = {};  
  public badgeList:any = {}; 
  public id:any = {}; 

  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;
 
  constructor(
    private router:Router,
     private httpService : HttpService, 
     private route: ActivatedRoute
   ) 
   {} 
  ngOnInit(): void {
    $('.modal ').insertAfter($('body'));
    var requestObj = new RequestContainer();
    requestObj['pageNo'] = 0;
    this.pageNo = requestObj['pageNo'];
    requestObj['company_id']= JSON.parse(sessionStorage.getItem('login_company'));
    this.httpService.postRequest<ResponseContainer>(requestObj,'admin/getSuperTeamLeader').subscribe(
      datas=> {
        if(datas.rsBody.result == 'success') 
        {
          this.responseData = datas.rsBody.data;
          if(this.responseData.length == 9) 
          {
              this.setPageNoNext = true;  
          }  
          else
          {
              this.setPageNoNext = false;
          }
          if(this.pageNo == 0)
          {
              this.setPageNo = false;
          }
          else
          {
              this.setPageNo = true;
          }
        } 
        else{
          console.log(datas.rsBody.error);
        }
      });
  } 
  
  
  /*
  * Badges List
  */
 getBadgeList() 
 {
       $('.spinner').css('display','block');
       this.httpService.getAll<ResponseContainer>('admin/getBadgeList').subscribe(datas => {
       $('.spinner').css('display','none');
       if(datas.rsBody.result == 'success')
       {
         this.badgeList = datas.rsBody.data;
       }
      });
 }

/* 
* pagination next
*/
 nextSubmit()
 {
   var  requestObj = new RequestContainer();
   requestObj['pageNo'] = this.pageNo + 1;
   this.pageNo = requestObj['pageNo'];
   requestObj['cat_id'] =  this.route.snapshot.paramMap.get('id');
   requestObj['company_id']= JSON.parse(sessionStorage.getItem('login_company'));
   this.httpService.postRequest<ResponseContainer>(requestObj,'admin/getSuperTeamLeader').subscribe(
   datas=> {
     this.responseData = datas.rsBody.data;
     if(this.responseData.length == 9)
     {
        this.setPageNoNext = true;
        this.setPageNo = true;
     }
     else
     {
        this.setPageNoNext = false;
        this.setPageNo = true;
     }
   }); 
 }


 /*
 * pagination previous
 */
 previousSubmit()
 {
   var  requestObj = new RequestContainer();
   if(this.pageNo == 0)
   {
     requestObj['pageNo'] = 0;
     this.pageNo = requestObj['pageNo'];
   }
   else
   { 
     requestObj['pageNo'] = this.pageNo - 1;
     this.pageNo = requestObj['pageNo'];
   }
   requestObj['cat_id'] =  this.route.snapshot.paramMap.get('id');
   requestObj['company_id']= JSON.parse(sessionStorage.getItem('login_company'));
   this.httpService.postRequest<ResponseContainer>(requestObj,'admin/getSuperTeamLeader').subscribe(
   datas=> {
     this.responseData = datas.rsBody.data;
     if(this.responseData.length == 9)
     {
        this.setPageNoNext = true;
     }
     else
     {
        this.setPageNoNext = false;
        this.setPageNo = true;
     } 
     if(this.pageNo == 0)
     { 
        this.setPageNo = false;
     }
     else
     {
        this.setPageNo = true;
     }
   });

 } 


/*
* delete
*/ 
 deleteSubmit(skillId)
 {
   this.targetIndex = skillId;
   $("#deletesubmit").modal('show');
 } 
 
 deleteRow(Obj)
 {
  this.httpService.postRequest<ResponseContainer>(Obj,'admin/deleteSkill').subscribe(datas => {
   if(datas.rsBody.result == 'success')
   {
     $("#deletesubmit").modal('hide');
     this.ngOnInit();
   }
   else{
     console.log(datas.rsBody.error);
   }
  }); 
 }

// /*
// * Assign Badge to trainer on skill_list page
// */
//  assignBadgeTrianer(badgeId,skillId) 
//  {
//    this.submitObj.employeeId = "";
//    this.submitObj.badgeId = badgeId;
//    this.submitObj.skillId = skillId;
//    this.httpService.postRequest<ResponseContainer>(this.submitObj, 'admin/assignUpdate').subscribe(datas => {
//      if(datas.rsBody.result == 'success')
//      {
//       this.ngOnInit();
//      }
//    });
//  } 
 
/* 
* Assign Trsiner page 
*/ 
assignTeamleader(superteamleaderId){ 
  this.router.navigate(['/admin/assign-manager',superteamleaderId]); 
 }

 
 /*
* Re-Assign Trainer Skill
*/
reAssign(assign_to){
  this.submitObj.assign_to = assign_to;
  this.httpService.postRequest<ResponseContainer>(this.submitObj, 'admin/reAssignEmployee').subscribe(datas => {
    if(datas.rsBody.result == 'success')
    {
     this.ngOnInit();
    }
  });
}

 
/*
* back Category page
*/
backCategory(){
  this.router.navigate(['/admin/category-adm']);  
 } 



}
