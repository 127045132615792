<section id="dashboard" class="g-100">
    <div class="container">
       <div class="row">
          <div class="col-md-12">
             <div class="page-title-box">
                <ol class="breadcrumb">
                   <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                   <li class="breadcrumb-item active"><a href="javascript:void(0);">Trainer</a></li>
                </ol>
             </div>
          </div>
       </div>
       <div class="row">
          <div class="col-md-12">
             <div class="">
                <div class="search-trainer position-relative">
                   <div class="title">
                      <h4>Trainer</h4>
                   </div>
                   <div class="search-wrapper" id="s_wrap"  (click)="showEmployeeList();" >
                      <p>Add Trainer</p>
                      <span role="search">
                         <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                            <g>
                               <g>
                                  <path d="M508.875,493.792L353.089,338.005c32.358-35.927,52.245-83.296,52.245-135.339C405.333,90.917,314.417,0,202.667,0
                                     S0,90.917,0,202.667s90.917,202.667,202.667,202.667c52.043,0,99.411-19.887,135.339-52.245l155.786,155.786
                                     c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125C513.042,504.708,513.042,497.958,508.875,493.792z
                                     M202.667,384c-99.979,0-181.333-81.344-181.333-181.333S102.688,21.333,202.667,21.333S384,102.677,384,202.667
                                     S302.646,384,202.667,384z"/>
                               </g>
                            </g>
                         </svg> 
                      </span>
                   </div>

                   <div class="search-wrapper" id="close-search" (click)="ngOnInit();" >
                    <!--<input id="search_result" name="search_result" class="form-control" style="width: 200px; margin-right: 50px;"  [(ngModel)]="submitObj.search_result" type="text" placeholder="Search by name" aria-label="Search" (ngModelChange)="searchSubmit();">-->
                    <p>{{employeeData.first_name}}</p>
                    <span role="close-search">
                       <svg focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                       </svg>
                    </span>
                   </div>
                   <!-- Search Trainer -->
                   <div class="auto-search">
                      <div class="ng-autocomplete" id="auto_search">
                         <ng-autocomplete
                         [data]="employeeList"
                         [searchKeyword]="keyword"
                         (selected)='selectEvent($event)'
                         (inputChanged)='onChangeSearch($event)'
                         (inputFocused)='onFocused($event)'
                         [itemTemplate]="itemTemplate"
                         [notFoundTemplate]="notFoundTemplate">
                         </ng-autocomplete>
                         <ng-template #itemTemplate let-item>
                            <a [innerHTML]="item.first_name"></a>
                         </ng-template>
                         <ng-template #notFoundTemplate let-notFound>
                            <div [innerHTML]="notFound"></div>
                         </ng-template>
                      </div>
                   </div>
                </div>
                <div id="my-table-add-trainer">
                   <div class="card mt-4">
                      <div class="card-body">
                         <div class="table-responsive">
                            <table class="table table-striped mb-0">
                               <thead>
                                  <tr>
                                     <th>First Name</th>
                                     <th>Last Name</th>
                                     <th>Role</th>
                                     <th>Email ID</th>
                                     <th class="text-center">Status</th>
                                  </tr>
                               </thead>
                               <tbody>
                                  <tr>
                                     <td>{{employeeData.first_name}}</td>
                                     <td>{{employeeData.last_name}}</td>
                                     <td>{{employeeData.department}}</td>
                                     <td>{{employeeData.email_id}}</td>
                                     <td class="text-center"><button (click)="addTrainer(employeeData.user_id);" class="btn btn-warning"><i class="ti-plus mr-2"></i>ADD</button></td>
                                  </tr>
                               </tbody>
                               <!-- <tbody *ngIf='responseData.length == 0'>
                                  <td class="text-center" colspan="3"> <strong>No Data Found</strong></td>
                                  </tbody> -->
                            </table>
                         </div>
                      </div>
                   </div>
                </div>
                <div id="my-table">
                   <div class="card mt-4">
                      <div class="card-body">
                         <div class="table-responsive">
                            <table class="table table-striped mb-0">
                               <thead>
                                  <tr>
                                     <th>#</th>
                                     <th>First Name</th>
                                     <th>Last Name</th>
                                     <th>Role</th>
                                     <th>Email ID</th>
                                     <th class="text-center">Remove</th>
                                  </tr>
                               </thead>
                               <tbody *ngIf='responseData.length > 0'>
                                  <tr *ngFor="let item of responseData | paginate: { itemsPerPage: pageSize, currentPage: pageNo } let i=index">
                                     <td>{{((pageNo-1)*pageSize)+(i+1)}}</td>
                                     <td>{{item.first_name}}</td>
                                     <td>{{item.last_name}}</td>
                                     <td>{{item.department}}</td>
                                     <td>{{item.email_id}}</td>
                                     <td class="text-center"><span (click)="deleteSubmit(item.user_id);" class="text-danger actionbtnsdf"><i class="ti-trash"></i></span></td>
                                  </tr>
                               </tbody>
                            </table>
                            <div class="text-center mt-4">
                               <pagination-controls  *ngIf="responseData.length !=0" (pageChange)="loadpage($event)" maxSize="5" directionLinks="true" responsive="true" previousLabel="Previous" nextLabel="Next"></pagination-controls>
                            </div>
                            <!--No data Found start-->
                            <div class="w-100" *ngIf='responseData.length == 0'>
                               <div class="text-center" role="not found">
                                  <i class="fa fa-exclamation-circle fa-5x" aria-hidden="true"></i>
                                  <p class="mt-2">No Badges Found</p>
                               </div>
                            </div>
                            <!--No data found end-->
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </section>
 <!--trash-modal-->
 <div class="modal fade"  id="deletesubmit" tabindex="-1"  role="dialog" aria-labelledby="trash-mdl" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
       <div class="modal-content">
          <div class="modal-body text-center">
             <p>Are you sure you wanted to delete this user?</p>
          </div>
          <div class="mdl-footer d-v-center">
             <button class="btn btn-yellow btn-block" (click)='deleteRow(targetIndex);'>Yes</button>
             <button class="btn btn-black btn-block m-0" data-dismiss="modal">No</button>
          </div>
       </div>
    </div>
 </div>