import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
// import { FileUploader } from 'ng2-file-upload'; 
import { DomSanitizer } from '@angular/platform-browser'; 
import { Router,ActivatedRoute } from '@angular/router';

import {ResponseContainer} from '../../../service/ResponseContainer';
import {ValidationHandler} from '../../../service/ValidationHandler'; 
import {BaseContainer} from '../../../service/BaseContainer';
import {RequestContainer} from '../../../service/RequestContainer'; 
declare var $ :any;
const uploadAPI = 'http://localhost:3000/imageUpload';

@Component({
  selector: 'app-add-category-skill-adm',
  templateUrl: 'add-category-skill-adm.component.html',
  providers : [HttpService,ValidationHandler]   

})
export class AddCategorySkillAdmComponent implements OnInit {
  public submitObj:any = {};
  public targetIndex:Object = {};
  public setPageNo:boolean = false;
  public setPageNoNext:boolean = false;
  public pageNo:any = {};
  public responseData:any = {}; 
  public id:any = {}; 
  public skillId:any = {};
  public imageSrc:any = {};
  public setCreate:boolean=true;
  public setUpdate:boolean = false;
 
   
  public routeUrl:Object = {};
  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;

  constructor(
     private httpService : HttpService,
     private sanitization:DomSanitizer,
     private router:Router,
     private route: ActivatedRoute,
     private validationHandler : ValidationHandler

   )
  {
    // this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    // this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    //   var responsePath = JSON.parse(response);
    //   console.log(responsePath);// the url will be in the response
    //   this.submitObj['imagePath'] = responsePath['rsBody']['image'];
    //   this.submitObj['feature_image'] = responsePath['rsBody']['image'];
    //   this.submitObj['imagePath'] = this.sanitization.bypassSecurityTrustResourceUrl(this.submitObj['imagePath']);
    // };
  }  

 
  ngOnInit(): void { 
    $('.modal ').insertAfter($('body'));
    this.setCreate = true; 
    this.id  =  this.route.snapshot.paramMap.get('id');
    this.skillId  =  this.route.snapshot.paramMap.get('skillId');
    this.routeUrl = this.router.url;
    if(this.routeUrl == '/admin/add-category-skill-adm/'+this.id)
    {
        this.httpService.postRequest<ResponseContainer>(this.id,'admin/getCategoryData').subscribe(
          datas=> {
            if(datas.rsBody.result == 'success')
            {
              this.responseData.cat_name = datas.rsBody.data[0]['cat_name'];
              this.responseData.cat_description = datas.rsBody.data[0]['cat_description'];
              this.submitObj.cat_id = datas.rsBody.data[0]['id'];
            } 
            else{ 
              console.log(datas.rsBody.error)
            }
            
          });
    }
    // if(this.routeUrl == '/admin/add-category-skill-adm/'+this.id+'/'+this.skillId)
    // {
    //   this.httpService.postRequest<ResponseContainer>(this.id,'admin/getCategoryData').subscribe(
    //     datas=> {
    //       if(datas.rsBody.result == 'success')
    //       {
    //         this.responseData.cat_name = datas.rsBody.data[0]['cat_name'];
    //         this.responseData.cat_description = datas.rsBody.data[0]['cat_description'];
    //         this.submitObj.cat_id = datas.rsBody.data[0]['id'];
    //         this.httpService.postRequest<ResponseContainer>(this.skillId,'admin/getCategorySkillData').subscribe(
    //           datas=> {
    //             if(datas.rsBody.result == 'success')
    //             {
    //               this.submitObj.cat_skill_name = datas.rsBody.data[0]['cat_skill_name'];
    //               this.submitObj.cat_skill_description = datas.rsBody.data[0]['cat_skill_description'];
    //               this.submitObj.imagePath = datas.rsBody.data[0]['cat_skill_icon'];
    //             } 
    //             else{ 
    //               console.log(datas.rsBody.error)
    //             }
                
    //           });
    //       } 
    //       else{ 
    //         console.log(datas.rsBody.error)
    //       }
          
    //     });
         
    // }
    if(this.routeUrl == '/admin/add-category-skill-adm/'+this.id+'/'+this.skillId)
    {
      this.httpService.postRequest<ResponseContainer>(this.skillId,'admin/getSingleCategorySkillData').subscribe(
        datas=> {
          if(datas.rsBody.result == 'success')
          {
            this.responseData.cat_name = datas.rsBody.data[0]['cat_name'];
            this.responseData.cat_description = datas.rsBody.data[0]['cat_description'];
            this.submitObj.cat_id = datas.rsBody.data[0]['id'];
            this.submitObj.skill_name = datas.rsBody.data[0]['cat_skill_name'];
            this.submitObj.skill_description = datas.rsBody.data[0]['cat_skill_description'];
            this.submitObj.imagePath = datas.rsBody.data[0]['cat_skill_icon'];
            this.setCreate = false;
            this.setUpdate = true;
          } 
          else{ 
            console.log(datas.rsBody.error)
          }
          
        });
        
    }
  } 
 
/*
* create submit
*/ 
 createSubmit()
 {
  let status = this.validationHandler.validateDOM('profileupdateBasic');
  if(!status){
    this.httpService.postRequest<ResponseContainer>(this.submitObj,'admin/createCategorySkill').subscribe(datas => {
      if(datas.rsBody.result == 'success')
      { 
        this.submitObj = {}; 
        $("#changesubmit").modal('show'); 
      }
      else
      {
      this.validationHandler.displayErrors(datas.rsBody.errors,"profileupdateBasic",null);
      }
     });
  }
 }
 

   /*
  *  update submit
  */
 updateSubmit()
 {
     let status = this.validationHandler.validateDOM('profileupdateBasic');
     if(!status)
     {
      this.submitObj.id  =  this.route.snapshot.paramMap.get('skillId');
      this.httpService.postRequest<ResponseContainer>(this.submitObj,'admin/updateSkill').subscribe(datas => {
       if(datas.rsBody.result == 'success')
       {
         this.submitObj = {};
         $("#changesubmit").modal('show');
         this.setUpdate = false;
         this.ngOnInit();
       }
       else
       {
        this.validationHandler.displayErrors(datas.rsBody.errors,"profileupdateBasic",null);
       }
      });
    }
 }


 listSkill()
  {
    $("#changesubmit").modal('hide');
    this.id  =  this.route.snapshot.paramMap.get('id');
    this.router.navigate(['/admin/category-skills-adm',this.id]); 
  
  }

/* 
* back Category page
*/
backCategory(){
  this.router.navigate(['/admin/category-adm']);  
 } 
 

  /**
  * file upload
  **/
//  public uploader:FileUploader = new FileUploader
//  (
//      {url:uploadAPI}
//  );
//  fileSelect()
//  {
//    $('#profile-image-upload').click();
//  }

//  imageUpload()
//  {
//      this.uploader.uploadAll();
//  }

onFocus()
{
    this.validationHandler.hideErrors("profile-submit");
}


  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    this.submitObj['imagePath'] = reader.result;

    //console.log(this.imageSrc);
  }
 removeImage()
 {
    this.submitObj['imagePath'] = null;
 }

}
