<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item" (click)="backEmpList();"><a href="javascript:void(0);">Employee List</a></li>
                        <li class="breadcrumb-item active" (click)="backEmpProfile();"><a href="javascript:void(0);">Employee Profile</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Assign Badge</a></li>
                    </ol>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                        <div class="title">
                            <h4>Assign Badge</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<div class="assign-m-card">
        <div class="row badge-view-wrapper" id="profile-submit">
            <div class="col-md-12" id="profileupdateBasic">
            <h5 class="title">Badge Overview:</h5>

            <ul class="badge-overview d-v-center mt-3" *ngFor="let item of responseData let i=index">
                <li class="assign_badge" *ngIf="item.empId_1 == currentUser && item.assign_to_1 != employee_id" checkMandatory="true" (focus)="onFocus()">
                    <div class="cat-adm card btn-brd" (click)="selectBadge(item.id, 1)" id="badgeId_1{{item.id}}">
                        <div class="card-body skill-set">
                            <div class="t-menu-profile">
                                <a class="t-drp t-drp-bg dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-angle-down"></i></a>
                                <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                    <li class="w-25">
                                        <a class="dropdown-item" href="javascript:void(0)" (click)="assignBadge(item.id, 1)">
                                             Assign Badge
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div class="c_bar b-100">
                                <circle-progress [percent]="100" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#0078a1'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'transparent'" [class]="'pgrsbar'" [imageSrc]="item.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                                </circle-progress>
                            </div>
                            <div class="prg_content mt-3">
                                    <!-- <h5 class="mb-2">Beginner</h5> -->
                                    <span class="ctskilnm">{{item.cat_skill_name}}</span>
                                    <span class="ctnme">{{item.cat_name}}</span>
                                </div>
                        </div>
                    </div>
                    <span class="ng-hide displayError" id="badgeId_1{{item.id}}_error"></span>
                </li>
                <li class="assign_badge" *ngIf="item.empId_2 == currentUser && item.assign_to_2 != employee_id">
                    <div class="cat-adm card btn-brd" (click)="selectBadge(item.id, 2)" id="badgeId_2{{item.id}}">
                        <div class="card-body skill-set">
                            <div class="t-menu-profile">
                                <a class="t-drp t-drp-bg dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-angle-down"></i></a>

                                <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                    <li class="w-25">
                                        <a class="dropdown-item" href="javascript:void(0)" (click)="assignBadge(item.id, 2)">
                                             Assign Badge
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="c_bar i-100">
                                <circle-progress [percent]="100" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#e5c634'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'transparent'" [class]="'pgrsbar'" [imageSrc]="item.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                                </circle-progress>
                            </div>
                            <div class="prg_content mt-2">
                                    <!-- <h5 class="mb-2">Intermediate</h5> -->
                                    <span  class="ctskilnm">{{item.cat_skill_name}}</span>
                                    <span class="ctnme">{{item.cat_name}}</span>
                                </div>
                        </div>
                    </div>
                    <span class="ng-hide displayError" id="badgeId_2{{item.id}}_error"></span>
                </li>
                <li class="assign_badge" *ngIf="item.empId_3 == currentUser && item.assign_to_3 != employee_id">
                    <div class="cat-adm card btn-brd" (click)="selectBadge(item.id, 3)" id="badgeId_3{{item.id}}">
                        <div class="card-body skill-set">
                            <div class="t-menu-profile">
                                <a class="t-drp t-drp-bg dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-angle-down"></i></a>
                                <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                    <li class="w-25">
                                        <a class="dropdown-item" href="javascript:void(0)" (click)="assignBadge(item.id, 3)">
                                             Assign Badge
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="c_bar e-100">
                                <circle-progress [percent]="100" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#62a006'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'transparent'" [class]="'pgrsbar'" [imageSrc]="item.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                                </circle-progress>
                            </div>
                            <div class="prg_content mt-3">
                                    <!-- <h5 class="mb-2">Expert</h5> -->
                                    <span class="ctskilnm">{{item.cat_skill_name}}</span>
                                    <span class="ctnme">{{item.cat_name}}</span>
                                </div>
                        </div>
                    </div>
                    <span class="ng-hide displayError" id="badgeId_3{{item.id}}_error"></span>

                </li>
            </ul>
            <!--No data Found start-->
              <div class="w-100" *ngIf='responseData.length == 0'>
                  <div class="text-center" role="not found">
                      <i class="fa fa-exclamation-circle fa-5x" aria-hidden="true"></i>
                      <p class="mt-2">No Badges Found</p>
                  </div>
              </div>
           <!--No data found end--> 
        </div>
    </div>
    </div>
    </div>
</section>

<!--successsfull-modal-->
<div class="modal fade"  id="changesubmit" role="dialog" aria-labelledby="trash-mdl" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>Assigned Successfull</p>
        </div>
          <div class="mdl-footer d-v-center">
              <button class="btn btn-black btn-block m-0" (click)="refresh();">Ok</button>
          </div>
      </div>
    </div>
  </div>
