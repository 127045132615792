import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF } from '@angular/common';
import { HttpService } from '../service/http.service';
import { ValidationHandler } from '../service/ValidationHandler';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { NgCircleProgressModule } from 'ng-circle-progress';
import {NgxPaginationModule} from 'ngx-pagination';


import { NgModule } from '@angular/core';
import { FileUploadModule } from 'ng2-file-upload';
import { FormsModule }   from '@angular/forms';

import { AdminRouting } from './admin.routing'
import { AdminComponent } from './admin.component';
import { SidebarAdmComponent } from './component/sidebar-adm/sidebar-adm.component';
import { HeaderModule } from '../header/header.module';

import { DashboardAdmComponent } from './component/dashboard-adm/dashboard-adm.component';
import { MybadgesAdmComponent } from './component/mybadges-adm/mybadges-adm.component';
import { AddCategoryAdmComponent } from './component/add-category-adm/add-category-adm.component';
import { AssignTrainerAdmComponent } from './component/assign-trainer-adm/assign-trainer-adm.component';
import { AssignEmployeeAdmComponent } from './component/assign-employee-adm/assign-employee-adm.component';

import { CategoryAdmComponent } from './component/category-adm/category-adm.component';
import { CategorySkillsAdmComponent } from './component/category-skills-adm/category-skills-adm.component';
import { LearningPathwaysAdmComponent } from './component/learning-pathways-adm/learning-pathways-adm.component';
import { AddLearningPathwaysAdmComponent } from './component/add-learning-pathways-adm/add-learning-pathway-adm.component';
import { AddLearningPathwaysSkillAdmComponent } from './component/add-learning-pathways-skiill-adm/add-learning-pathways-skill-adm.component';

import { ManagmentAdmComponent } from './component/managment-adm/managment-adm.component';
import { NotificationAdmComponent } from './component/notification-adm/notification-adm.component';
import { TrainersAdmComponent } from './component/trainers-adm/trainers-adm.component';
import { TrainerProfileAdmComponent } from './component/trainer-profile-adm/trainer-profile-adm.component';
import { TeamleadersAdmComponent } from './component/teamleaders-adm/teamleaders-adm.component';
import { SuperTeamleadersAdmComponent } from './component/super-teamleaders-adm/super-teamleaders-adm.component';
import { AddCategorySkillAdmComponent } from './component/add-category-skiill-adm/add-category-skill-adm.component';
import { Task2AdmComponent } from './component/task2-adm/task2-adm.component';
import { TasklistAdmComponent } from './component/tasklist-adm/tasklist-adm.component';
import { AssignTeamLeaderdmComponent } from './component/assign-teamleader-adm/assign-teamleader-adm.component';
import { AddEmployeeAdmComponent } from './component/add-employee-adm/add-employee-adm.component';
import { EmployeeAdmComponent } from './component/employee-adm/employee-adm.component';
import { AssignManagerAdmComponent } from './component/assign-manager-adm/assign-manager-adm.component';
import { EditProfileAdmComponent } from './component/edit-profile-adm/edit-profile-adm.component';


@NgModule({
  declarations: [
    AdminComponent,
    SidebarAdmComponent,
    DashboardAdmComponent,
    MybadgesAdmComponent,
    AddCategoryAdmComponent,
    AssignTrainerAdmComponent,
    AssignEmployeeAdmComponent,
    CategoryAdmComponent,
    CategorySkillsAdmComponent,
    LearningPathwaysAdmComponent,
    AddLearningPathwaysAdmComponent,
    AddLearningPathwaysSkillAdmComponent,
    ManagmentAdmComponent,
    NotificationAdmComponent,
    TrainersAdmComponent,
    TrainerProfileAdmComponent,
    TeamleadersAdmComponent,
    SuperTeamleadersAdmComponent,
    AddCategorySkillAdmComponent,
    Task2AdmComponent,
    TasklistAdmComponent,
    AssignTeamLeaderdmComponent,
    AddEmployeeAdmComponent,
    EmployeeAdmComponent,
    AssignManagerAdmComponent,
    EditProfileAdmComponent


  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FileUploadModule,
    FormsModule,
    AutocompleteLibModule,
    AdminRouting,
    HeaderModule,
    NgxPaginationModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      animationDuration: 300,
      class:'pgrsbar',
      showImage:true
    })
  ],
  providers: [{provide: APP_BASE_HREF, useValue : '' },HttpService],
  bootstrap: [AdminComponent]
})
export class AdminModule { }
