<section id="dashboard" class="g-100" (focus)="onFocus()">
   <div class="container-fluid">
      <div class="row">
         <div class="col-md-12">
            <div class="page-title-box">
               <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                  <li class="breadcrumb-item active"><a href="javascript:void(0);">My Team</a></li>
               </ol>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-md-12">
            <div class="">
               <div class="search-trainer">
                  <div class="title">
                     <h4>My Team</h4>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="row badge-view-wrapper mt-4">
         <div class="col-12">
            <div class="card mt-3 tab-card">
               <div class="card-header tab-card-header d-flex justify-content-between pb-0">
                  <ul class="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
                     <li class="nav-item">
                        <a class="nav-link active" id="one-tab" data-toggle="tab" href="#one" role="tab" aria-controls="One" aria-selected="true">Employee</a>
                     </li>
                     <li class="nav-item">
                        <a class="nav-link" id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="Two" aria-selected="false">Catgeory</a>
                     </li>
                  </ul>
                  <div class="d-flex justify-content-end">
                     <div id="search" class="mb-1">
                        <label for="search-input">
                           <span role="search">
                              <svg focusable="false" height="24px" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg">
                                 <path d="M20.49,19l-5.73-5.73C15.53,12.2,16,10.91,16,9.5C16,5.91,13.09,3,9.5,3S3,5.91,3,9.5C3,13.09,5.91,16,9.5,16 c1.41,0,2.7-0.47,3.77-1.24L19,20.49L20.49,19z M5,9.5C5,7.01,7.01,5,9.5,5S14,7.01,14,9.5S11.99,14,9.5,14S5,11.99,5,9.5z"></path>
                                 <path d="M0,0h24v24H0V0z" fill="none"></path>
                              </svg>
                           </span>
                           <span role="close-search" style="display: none;">
                              <svg focusable="false" height="24px" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg">
                                 <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                 <path d="M0 0h24v24H0z" fill="none"></path>
                              </svg>
                           </span>
                        </label>
                        <input class="form-control input-lg" id="search_result" name="search_result" [(ngModel)]="submitObj.search_result" type="text" placeholder="Search by Employee name" aria-label="Search" (ngModelChange)="searchSubmit();" tabindex="1">
                     </div>
                  </div>
               </div>
               <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active p-3" id="one" role="tabpanel" aria-labelledby="one-tab">
                     <section class="mb-4" id="profile-submit">
                        <div class="row" id="profileupdateBasic">
                           <div class="col-12">
                              <div class="input-group">
                                 <div id="skill" class="flex-fill">
                                    <div class="ng-autocomplete ng_custom-search">
                                       <ng-autocomplete 
                                       [data]="skillList" 
                                       [searchKeyword]="keyword"
                                       (selected)='selectEvent($event)'
                                       (inputChanged)='onChangeSearch($event)'
                                       (inputFocused)='onFocused($event)'
                                       [itemTemplate]="itemTemplate"
                                       placeHolder="Select Category Skill"
                                       [notFoundTemplate]="notFoundTemplate">                                 
                                       </ng-autocomplete>
                                       <ng-template #itemTemplate let-item> 
                                          <a [innerHTML]="item.cat_skill_name"></a>
                                       </ng-template>
                                       <ng-template #notFoundTemplate let-notFound>
                                          <div [innerHTML]="notFound"></div>
                                       </ng-template>
                                    </div>
                                 </div> 
                                 <div id="badge_name" class="flex-fill">
                                    <div class="d-v-center">
                                       <div class="ng-autocomplete ng_custom-search">
                                          <ng-autocomplete 
                                          [data]="badgeList"  
                                          [searchKeyword]="keyword1"
                                          (selected)='selectEvent1($event)'
                                          (inputChanged)='onChangeSearch1($event)'
                                          (inputFocused)='onFocused1($event)'
                                          [itemTemplate]="itemTemplate1"
                                          placeHolder="Select Level"
                                          [notFoundTemplate]="notFoundTemplate">                                
                                          </ng-autocomplete>
                                          <ng-template #itemTemplate1 let-item1> 
                                             <a [innerHTML]="item1.badge_name"></a>
                                          </ng-template>
                                          <ng-template #notFoundTemplate let-notFound>
                                             <div [innerHTML]="notFound"></div>
                                          </ng-template>
                                       </div>
                                    </div>
                                    <span class="ng-hide displayError" id="badge_name_error"></span>
                                 </div>
                                 <div class="input-group-append">
                                    <input class="btn btn-yellow" type="submit" (click)="refresh();" value="Refresh">
                                 </div>
                              </div>
                           </div>
                        </div>
                     </section>
                     <section class="slider-wrapper" *ngFor="let item of responseData1 let i=index">
                        <div class="inner-wrapper">
                           <h3 class="badge badge-dark">{{item.first_name}}</h3>
                           <ul class="slider">
                              <li *ngFor="let item1 of item.badges let i1=index">
                                 <div class="skill-set">
                                    <div class="c_bar3 e-100" *ngIf="item1.badge_id == 3" title="{{item1.cat_skill_name}}">
                                       <circle-progress [percent]="100" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#62a006'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'transparent'" [class]="'pgrsbar'" [imageSrc]="item1.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                                       </circle-progress>
                                    </div>
                                 </div>
                                 <div class="skill-set" *ngIf="item1.badge_id == 2" title="{{item1.cat_skill_name}}">
                                    <div class="c_bar3 i-100">
                                       <circle-progress [percent]="100" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#e5c634'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'transparent'" [class]="'pgrsbar'" [imageSrc]="item1.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                                       </circle-progress>
                                    </div>
                                 </div>
                                 <div class="skill-set" *ngIf="item1.badge_id == 1" title="{{item1.cat_skill_name}}">
                                    <div class="c_bar3 b-100">
                                       <circle-progress [percent]="100" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#0078a1'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'transparent'" [class]="'pgrsbar'" [imageSrc]="item1.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                                       </circle-progress>
                                    </div>
                                 </div>
                              </li>
                              <li *ngIf="item.badges.length == 0">
                                 <strong>No data Found</strong>
                              </li>
                           </ul>
                        </div>
                        <hr>
                     </section>
                     <section class="slider-wrapper"  *ngIf='responseData1.length == 0'>
                        <strong>No Data Found</strong>
                     </section>
                     <div class="mr-auto ml-auto">
                        <nav class="pagination-wrap">
                           <ul class="pagination">
                              <li class="page-item" (click)="previousSubmit1();">
                                 <a *ngIf='setPageNo1' class="page-link" href="javascript:void(0);" aria-label="Previous">
                                 <span aria-hidden="true">«</span>
                                 <span class="sr-only">Previous</span>
                                 </a>
                              </li>
                              <li class="page-item" (click)="nextSubmit1();">
                                 <a *ngIf='setPageNoNext1' class="page-link" href="javascript:void(0);" aria-label="Next">
                                 <span aria-hidden="true">»</span>
                                 <span class="sr-only">Next</span>
                                 </a>
                              </li>
                           </ul> 
                        </nav>
                     </div>
                  </div>
                  <div class="tab-pane fade p-3" id="two" role="tabpanel" aria-labelledby="two-tab">
                     <ul class="badge-overview d-v-center mt-3" *ngFor="let item of responseData let i=index">
                        <li *ngFor="let item1 of item.task let i=index">
                           <div class="card btn-brd" *ngIf="item1.badge_id == 1" (click)="empList(item.cat_skill_id, item1.badge_id)">
                              <div class="card-body skill-set">
                                 <div class="c_bar b-100">
                                    <circle-progress [percent]="100" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#0078a1'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'transparent'" [class]="'pgrsbar'" [imageSrc]="item.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                                    </circle-progress>
                                 </div>
                                 <div class="prg_content mt-3">
                                    <span>{{item.cat_name}}</span><br>
                                    <span>{{item.cat_skill_name}}</span><br>
                                    <span> {{item1.emp_count}} Employee</span>
                                 </div>
                              </div>
                           </div>
                           <div class="card btn-brd" *ngIf="item1.badge_id == 2" (click)="empList(item.cat_skill_id, item1.badge_id)">
                              <div class="card-body skill-set">
                                 <div class="c_bar i-100">
                                    <circle-progress [percent]="100" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#e5c634'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'transparent'" [class]="'pgrsbar'" [imageSrc]="item.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                                    </circle-progress>
                                 </div>
                                 <div class="prg_content mt-3">
                                    <span>{{item.cat_name}}</span><br>
                                    <span>{{item.cat_skill_name}}</span><br>
                                    <span> {{item1.emp_count}} Employee</span>
                                 </div>
                              </div>
                           </div>
                           <div class="card btn-brd" *ngIf="item1.badge_id == 3" (click)="empList(item.cat_skill_id, item1.badge_id)">
                              <div class="card-body skill-set">
                                 <div class="c_bar e-100">
                                    <circle-progress [percent]="100" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#62a006'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'transparent'" [class]="'pgrsbar'" [imageSrc]="item.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                                    </circle-progress>
                                 </div>
                                 <div class="prg_content mt-3">
                                    <span>{{item.cat_name}}</span><br>
                                    <span>{{item.cat_skill_name}}</span><br>
                                    <span> {{item1.emp_count}} Employee</span>
                                 </div>
                              </div>
                           </div>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>