<section id="dashboard" class="g-100">
   <div class="container-fluid">
      <div class="row">
         <div class="col-lg-4">
            <div class="widget">
               <div class="widget-content d-v-center flex-justify-between">
                   <div class="w-75">
                   <h4>{{totalEmployee}}</h4>
                   <p>Total Employees</p>
                    </div>
                    <div class="widget-icon w-b">
                        <img src="../../../../assets/img/total-employee.png" alt="total-employee">
                    </div>
               </div>
            </div>
          </div>
         <div class="col-lg-4">
            <div class="widget">
               <div class="widget-content d-v-center flex-justify-between">
                 <div class="w-75">
                   <h4>{{totalLiveBadges}}</h4>
                   <p>Live Badges</p>
                        </div>
                    <div class="widget-icon w-b">
                     <img src="../../../../assets/img/live-badges.png" alt="live-badges">
                </div>
                </div>
            </div>
         </div>
         <div class="col-lg-4">
            <div class="widget">
               <div class="widget-content d-v-center flex-justify-between">
                    <div class="w-75">
                  <h4>{{totalExpiredBadges}}</h4>
                  <p>Expired Badges</p>
                   </div>
                   
                   <div class="widget-icon w-b">
                    <img src="../../../../assets/img/expired-badges.png" alt="expired-badges">
               </div>
               </div>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-md-12">
            <div class="dash-card-grid">
               <!-- 1 -->
               <div class="widget" *ngFor="let item of MaxAssignBadge let i=index">
                  <div class="widget-icon badge-icon p-2" title="{{item.cat_skill_name}} skill of {{item.badge_name}} Badge">
                     <div class="skill-set" title="{{item.cat_skill_name}} skill of {{item.badge_name}} Badge">
                        <!--beginner-->
                        <div class="c_bar2 b-100" *ngIf="item.badge_id == 1">
                           <circle-progress [percent]="100" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#0078a1'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'transparent'" [class]="'pgrsbar'" [imageSrc]="item.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                           </circle-progress>
                       </div>
                        <!--intermediate-->
                       <div class="c_bar2 i-100" *ngIf="item.badge_id == 2">
                           <circle-progress [percent]="100" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#e5c634'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'transparent'" [class]="'pgrsbar'" [imageSrc]="item.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                           </circle-progress>
                       </div>
                        <!--expert-->
                        <div class="c_bar2 e-100" *ngIf="item.badge_id == 3">
                           <circle-progress [percent]="100" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#62a006'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'transparent'" [class]="'pgrsbar'" [imageSrc]="item.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                           </circle-progress>
                        </div> 
                     </div>
                  </div>
                  <div class="widget-content"> 
                     <h5 class="w-title">Maximum Assigned Badge</h5>
                  </div>
               </div>
                
               <!-- 2 -->
               <div class="widget" *ngFor="let item of MinAssignBadge let i=index">
                  <div class="widget-icon badge-icon">
                     <div class="skill-set" title="{{item.cat_skill_name}} skill of {{item.badge_name}} Badge">
                        <!--beginner-->
                        <div class="c_bar2 b-100" *ngIf="item.badge_id == 1">
                           <circle-progress [percent]="100" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#0078a1'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'transparent'" [class]="'pgrsbar'" [imageSrc]="item.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                           </circle-progress>
                       </div>
                        <!--intermediate-->
                       <div class="c_bar2 i-100" *ngIf="item.badge_id == 2">
                           <circle-progress [percent]="100" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#e5c634'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'transparent'" [class]="'pgrsbar'" [imageSrc]="item.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                           </circle-progress>
                       </div>
                        <!--expert-->
                        <div class="c_bar2 e-100" *ngIf="item.badge_id == 3">
                           <circle-progress [percent]="100" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#62a006'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'transparent'" [class]="'pgrsbar'" [imageSrc]="item.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                           </circle-progress>
                        </div> 
                     </div>
                  </div>
                  <div class="widget-content">
                     <h5 class="w-title">Minimum Assigned Badge</h5>
                  </div>
               </div>
                
               <!-- 3 --> 
               <div class="widget">
                  <div class="widget-icon badge-icon p-2">
                     <!-- <img src="../../../../assets/img/Employee-beginner.png" alt="Employee-beginner">
                         <img src="../../../../assets/img/Employee-expert.png" alt="Employee-expert"> -->
                         <img src="../../../../assets/img/Employee-intermediate.png" alt="Employee-intermediate"> 
                  </div>
                  <div class="widget-content">
                     <h5 class="w-title">Employee with maximum badge</h5>
                     <h6 *ngFor="let item of MaxEmpBadge let i=index">{{item.first_name}}</h6>
                  </div>
               </div>
                
               <!-- 4 -->
               <div class="widget">
                  <div class="widget-icon badge-icon p-2">
                    <img src="../../../../assets/img/Employee-beginner.png" alt="Employee-beginner">
                  </div>
                  <div class="widget-content">
                     <h5 class="w-title">Employee with maximum number of beginner badges</h5>
                     <h6 *ngFor="let item of MaxBegBadge let i=index">{{item.first_name}}</h6>
                  </div>
               </div>
                
               <!-- 5 -->
               <div class="widget">
                  <div class="widget-icon badge-icon p-2">
                         <img src="../../../../assets/img/Employee-intermediate.png" alt="Employee-intermediate"> 
                  </div>
                  <div class="widget-content">
                     <h5 class="w-title">Employee with maximum number of Intermediate badges</h5>
                     <h6 *ngFor="let item of MaxIntBadge let i=index">{{item.first_name}}</h6>
                  </div>
               </div>
                
               <!-- 6 -->
               <div class="widget">
                  <div class="widget-icon badge-icon p-2">
                         <img src="../../../../assets/img/Employee-expert.png" alt="Employee-expert">
                  </div>
                  <div class="widget-content">
                     <h5 class="w-title">Employee with maximum number of expert badges</h5>
                     <h6 *ngFor="let item of MaxExpBadge let i=index">{{item.first_name}}</h6>
                  </div>
               </div>
                
            </div>
         </div>
      </div>
   </div>
</section>