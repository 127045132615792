<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Pathway List</a></li>
                    </ol>
                </div>
            </div>
        </div> 

        <div class="row">
            <div class="col-md-12">
                <div class=""> 
                    <div class="search-trainer">
                    <div class="title">
                        <h4>Learning Pathways</h4>
                        </div>

                        <a class="search-wrapper"  routerLink="/admin/add-learning-pathway-adm">
                        <p>Create New</p>
                            <span><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
	<g>
		<path d="M492,236H276V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v216H20c-11.046,0-20,8.954-20,20s8.954,20,20,20h216
			v216c0,11.046,8.954,20,20,20s20-8.954,20-20V276h216c11.046,0,20-8.954,20-20C512,244.954,503.046,236,492,236z"/>
	</g>
                                </g></svg></span>
                            </a>
                    </div>
                </div>
            </div> 
        </div>
  
        <!-- <div class="b-grid">
            <a href="javascript:void(0);" *ngFor="let item of responseData | paginate: { itemsPerPage: pageSize, currentPage: pageNo } let i=index" class="widget-profile d-v-center">
                <div class="s-tool">
                    <button (click)="AddSkill(item.id);" class="trash ml-1" title="Add"><i class="ti-plus"></i></button>
                    <button (click)="editSubmit(item.id);" class="trash ml-2" title="Edit"><i class="ti-pencil"></i></button>
                    <button (click)="deleteSubmit(item.id);" class="trash ml-2" title="Delete"><i class="ti-trash"></i></button>
                </div>
                <div class="widget-img-holder" (click)="showPathwaySkill(item.id)"> 
                    <div>
                        <img style="height: 80px !important; width: 80px !important;" src="{{item.pathway_icon}}">
                    </div>  
                </div>
                <h3 class="widget-content" (click)="showPathwaySkill(item.id)">
                    <span class="w-title">{{item.pathway_name}}</span>
                    <span class="w-val">{{item.pathway_description}} </span>
                </h3>
            </a>
        </div>  -->

        <div class="card mt-4">
            <div class="card-body">
                <div class="responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Image</th>
                                <th>Pathway Name </th>
                                <th>Pathway Description</th>
                                <!-- <th>Pathway Skill</th>
                                <th>Add</th> -->
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of responseData | paginate: { itemsPerPage: pageSize, currentPage: pageNo } let i=index">
                                <td>{{i+1}} </td>
                                <td><img class="pathvicvg" src="{{item.pathway_icon}}" alt=""></td>
                                <td>{{item.pathway_name}}</td>
                                <td>{{item.pathway_description}}</td>
                                <!-- <td> <span (click)="showPathwaySkill(item.id)" class="fa fa-eye"></span> </td>
                                <td><span class="fa fa-plus"></span> </td> -->
                                <td class="d-flex">                    
                                    <span (click)="AddSkill(item.id);" class="text-info actionbtnsdf"> <i class="ti-plus"></i> </span>
                                    <span (click)="editSubmit(item.id);" class="text-primary actionbtnsdf" title="Edit"><i class="ti-pencil"></i></span>
                                    <span (click)="showPathwaySkill(item.id)" class="actionbtnsdf"><i class="fa fa-eye"></i> </span> 
                                    <span (click)="deleteSubmit(item.id);" class="text-danger actionbtnsdf" title="Delete"><i class="ti-trash"></i></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="text-center mt-4">
                    <pagination-controls  *ngIf="responseData.length !=0" (pageChange)="loadpage($event)" maxSize="5" directionLinks="true" responsive="true" previousLabel="Previous" nextLabel="Next"></pagination-controls>
                </div>
                <!--No data Found start-->
                <div class="w-100" *ngIf='responseData.length == 0'>
                    <div class="text-center" role="not found">
                        <i class="fa fa-exclamation-circle fa-5x" aria-hidden="true"></i>
                        <p class="mt-2">No Data Found</p>
                    </div>
                </div>
            <!--No data found end--> 

            </div>
        </div>
    </div>
</section>
 <!--trash-modal-->
<div class="modal fade"  id="deletesubmit" tabindex="-1"  role="dialog" aria-labelledby="trash-mdl" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>Are you sure you wanted to delete this user?</p>
        </div>
        <div class="mdl-footer d-v-center">
            <button class="btn btn-yellow btn-block" (click)='deleteRow(targetIndex);'>Yes</button>
            <button class="btn btn-black btn-block m-0" data-dismiss="modal">No</button>
        </div>
      </div>
    </div>
  </div>   