import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SuperAdminComponent } from './superadmin.component';
import { DashboardSuAdmComponent } from './component/dashboard-suadm/dashboard-suadm.component';
import { CompanySuAdmComponent } from './component/company-suadm/company-suadm.component';
import { SyncSuAdmComponent } from './component/sync-suadm/sync-suadm.component';
import { AddCompanySuAdmComponent } from './component/add-company-suadm/add-company-suadm.component'; 
import { NotificationSuAdmComponent } from './component/notification-suadm/notification-suadm.component';
import { EditProfileAdmComponent } from './component/edit-profile-adm/edit-profile-adm.component';

const routes: Routes = [
    {  
        path: 'superadmin',  
        component: SuperAdminComponent,
        children: [
            { path: '', component: DashboardSuAdmComponent },
            { path: 'dashboard-suadm', component: DashboardSuAdmComponent },  
            { path: 'notification-suadm', component: NotificationSuAdmComponent }, 
            { path: 'company-suadm', component: CompanySuAdmComponent }, 
            { path: 'sync-suadm/:id', component: SyncSuAdmComponent }, 
            { path: 'add-company-suadm', component: AddCompanySuAdmComponent },   
            { path: 'add-company-suadm/:id', component: AddCompanySuAdmComponent } ,
            { path: 'edit-profile-suadm', component: EditProfileAdmComponent }
        ]
    }
]; 

export const SuperAdminRouting = RouterModule.forRoot(routes);