// import { Component, OnInit } from '@angular/core';

// @Component({

// })
// export class AssignTrainerAdmComponent implements OnInit {

//   constructor() { } 

//   ngOnInit(): void {
//   }

// }
import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';

import {ResponseContainer} from '../../../service/ResponseContainer';
import {BaseContainer} from '../../../service/BaseContainer';
import {RequestContainer} from '../../../service/RequestContainer'; 
import {ValidationHandler} from '../../../service/ValidationHandler'; 

declare var $ :any;
@Component({
   selector: 'app-assign-employee-adm', 
  templateUrl: 'assign-employee-adm.component.html',
  providers : [HttpService,ValidationHandler]   

}) 
export class AssignEmployeeAdmComponent implements OnInit {

  public submitObj:any = {};
  public targetIndex:Object = {};
  public setPageNo:boolean = false;
  public setPageNoNext:boolean = false;
  public pageNo:any = {};
  public routeUrl:Object = {};
  public responseData:any = {}; 


  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;
 
  constructor(
    private router:Router,
     private httpService : HttpService, 
     private route: ActivatedRoute,
     private validationHandler : ValidationHandler
   )
   {}  

   ngOnInit(): void { 
    var requestObj = new RequestContainer(); 
    requestObj['pageNo'] = 0;
    this.pageNo = requestObj['pageNo'];
    this.routeUrl = this.router.url;     
    requestObj['teamleaderId']  =  this.route.snapshot.params.teamleaderId; 
    requestObj['company_id']= JSON.parse(sessionStorage.getItem('login_company'));
    if(this.routeUrl == '/admin/assign-employee-adm/'+requestObj['teamleaderId'])
    { 
        this.httpService.postRequest<ResponseContainer>(requestObj,'admin/getEmployeesList').subscribe(
          datas=> {
            if(datas.rsBody.result == 'success')
            {
              this.responseData = datas.rsBody.data;
              if(this.responseData.length == 9) 
              {
                  this.setPageNoNext = true;  
              }  
              else
              { 
                  this.setPageNoNext = false;
              }
              if(this.pageNo == 0)
              {
                  this.setPageNo = false;
              }
              else
              { 
                  this.setPageNo = true;
              }
            } 
            else{
              console.log(datas.rsBody.error);
            }
          });
    }
  } 

  
/*
* pagination next
*/
 nextSubmit()
 {
   var  requestObj = new RequestContainer();
   requestObj['pageNo'] = this.pageNo + 1;
   this.pageNo = requestObj['pageNo'];
   requestObj['company_id']= JSON.parse(sessionStorage.getItem('login_company'));
   this.httpService.postRequest<ResponseContainer>(requestObj,'admin/getEmployeesList').subscribe(
   datas=> {
     this.responseData = datas.rsBody.data;
     if(this.responseData.length == 9)
     {
        this.setPageNoNext = true;
        this.setPageNo = true;
     }
     else
     {
        this.setPageNoNext = false;
        this.setPageNo = true;
     }
   });

 }
 /*
 * pagination previous
 */
 previousSubmit()
 {
   var  requestObj = new RequestContainer();
   if(this.pageNo == 0)
   {
     requestObj['pageNo'] = 0;
     this.pageNo = requestObj['pageNo'];
   }
   else
   {
     requestObj['pageNo'] = this.pageNo - 1; 
     this.pageNo = requestObj['pageNo'];
   }
   requestObj['company_id']= JSON.parse(sessionStorage.getItem('login_company'));
   this.httpService.postRequest<ResponseContainer>(requestObj,'admin/getEmployeesList').subscribe(
   datas=> {
     this.responseData = datas.rsBody.data;
     if(this.responseData.length == 9)
     {
        this.setPageNoNext = true;
     }
     else
     { 
        this.setPageNoNext = false;
        this.setPageNo = true;
     } 
     if(this.pageNo == 0) 
     { 
        this.setPageNo = false;
     }
     else
     {
        this.setPageNo = true;
     }
   }); 

 } 
 
 
/*
 * Assign Trainer
 */
 assignUpdate(id){ 
  this.submitObj.empId = id; 
  this.routeUrl = this.router.url; 
  this.submitObj.teamleaderId  =  Number(this.route.snapshot.params.teamleaderId); 
  this.httpService.postRequest<ResponseContainer>(this.submitObj,'admin/assignTeamLeader').subscribe(datas => {
    if(datas.rsBody.result == 'success')
    { 
      this.router.navigate(['/admin/teamleaders-adm']);
    }
    else
    { 
      this.validationHandler.displayErrors(datas.rsBody.errors,"profileupdateBasic",null);
    }
   });

 }

/*
 * Team Lead Page
 */
 backTeamLead(){
  this.router.navigate(['/admin/teamleaders-adm']);
 }

/*
 * View Profile
 */
 viewProfile(id)
  { 
   this.router.navigate(['/admin/trainer-profile-adm',id]);
  }

}
 