import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';
import {ValidationHandler} from '../../../service/ValidationHandler'; 

import {ResponseContainer} from '../../../service/ResponseContainer';
import {RequestContainer} from '../../../service/RequestContainer'; 
declare var $ :any;

@Component({
  selector: 'app-task-list-trn',
  templateUrl: 'task-list-trn.component.html',
  providers : [HttpService,ValidationHandler]   

})
export class TaskListTrnComponent implements OnInit {

  public submitObj:any = {};
  public employeeList:any = {}; 
  public targetIndex:Object = {};
  public setPageNo:boolean = false;  
  public setPageNoNext:boolean = false;
  public pageNo:any = {}; 
  public routeUrl:Object = {};
  public responseData:any = {}; 
  public badgeList:any = {}; 
  public id:any = {}; 
  public keyword:any = {};

  @Input() elementId: String; 
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;
 
  constructor(
    private router:Router, 
     private httpService : HttpService, 
     private route: ActivatedRoute,
     private validationHandler : ValidationHandler

   )
   {}  
 
  ngOnInit(): void {
    $('.modal ').insertAfter($('body'));
    var requestObj = new RequestContainer();
    requestObj['pageNo'] = 0;
    this.pageNo = requestObj['pageNo'];
    var currentUser = JSON.parse(sessionStorage.getItem('login_user_id'));
    this.submitObj.skillId  =  Number(this.route.snapshot.params.skillId); 
    this.submitObj.badgeId  =  Number(this.route.snapshot.params.badgeId); 
    this.submitObj.empId  =  currentUser;
    requestObj['empId'] =  currentUser;
    requestObj['catId']=  Number(this.route.snapshot.params.catId); 
    requestObj['skillId'] =  Number(this.route.snapshot.params.skillId);
    requestObj['badgeId']  =  Number(this.route.snapshot.params.badgeId); 
    requestObj['platform'] = "Web"; 
    this.routeUrl = this.router.url;  
    this.id  =  this.route.snapshot.paramMap.get('id'); 
    if(this.routeUrl == '/trainers/task-list-trn/'+requestObj['catId']+'/'+requestObj['skillId']+'/'+requestObj['badgeId'])
    { 
        this.httpService.postRequest<ResponseContainer>(requestObj,'trainer/getTaskList').subscribe(
          datas=> { 
            if(datas.rsBody.result == 'success') 
            {
              this.responseData = datas.rsBody.data;
              if(this.responseData.length == 5) 
              {
                  this.setPageNoNext = true;  
              }  
              else
              {
                  this.setPageNoNext = false;
              }
              if(this.pageNo == 0)
              {
                  this.setPageNo = false;
              }
              else
              {
                  this.setPageNo = true;
              } 
            } 
            else{ 
              console.log(datas.rsBody.error);
            }
          });
    }
    // this.getEmployee();
  }


/* 
* get-Emp-List
*/
  getEmployee(){
    this.httpService.getAll<ResponseContainer>('trainer/getEmployeeList').subscribe(datas => {
      if(datas.rsBody.result == 'success')
      {
       this.keyword = 'first_name'; 
        this.employeeList = datas.rsBody.data;
      }
     });
  } 

  
// on selection of emp
selectEvent(item) {
  this.submitObj.employee_id= item.id;
}

onChangeSearch(val: string) {
  // fetch remote data from here
  // And reassign the 'data' which is binded to 'data' property.
}

onFocused(e){
  // do something when input is focused
}


/* 
* open create-task modal
*/
openCreatTask(){
  $("#createsubmit").modal('show');
}  

/* 
* open create-task modal
*/
openAssignTask(){
  $("#assignsubmit").modal('show'); 
}  


/* 
* create-task
*/ 
  createTask(){ 
    let status = this.validationHandler.validateDOM('profileupdateBasic');
    if(!status)
      {
        this.httpService.postRequest<ResponseContainer>(this.submitObj,'trainer/createTask').subscribe(datas => {
          if(datas.rsBody.result == 'success')
            {
              this.submitObj = {};
              $("#createsubmit").modal('hide');
              $("#changesubmit").modal('show');
              this.ngOnInit();
            }
            else
            {
            this.validationHandler.displayErrors(datas.rsBody.errors,"profileupdateBasic",null);
            }
      });
    }
    // this.httpService.postRequest<ResponseContainer>(this.submitObj,'trainer/createTask').subscribe(datas => {
    //   if(datas.rsBody.result == 'success')
    //   {
    //     this.submitObj = {};
    //     $("#createsubmit").modal('hide');
    //     $("#changesubmit").modal('show');
    //     this.ngOnInit();
    //   }
    //   else
    //   { 
    //    console.log('error');
    //   } 
    //  });
  }


  onFocus()
  {
      this.validationHandler.hideErrors("profile-submit");
  }
 /*  
* create-task  
*/ 
assignTask(){
  this.httpService.postRequest<ResponseContainer>(this.submitObj,'trainer/assignTask').subscribe(datas => {
    if(datas.rsBody.result == 'success')
    {
      this.submitObj = {};
      $("#assignsubmit").modal('hide');
      $("#changesubmit").modal('show');
      this.ngOnInit();
    }
    else
    { 
     console.log('error');
    } 
   });
}
 
 /* 
* create-task-questions-trn page
*/ 
addQuestion(taskId){ 
  var catId=  Number(this.route.snapshot.params.catId); 
  var skillId =  Number(this.route.snapshot.params.skillId);
  var badgeId  =  Number(this.route.snapshot.params.badgeId);
  this.router.navigate(['/trainers/create-task-questions-trn',catId,skillId,badgeId,taskId]);
}

 
/* 
* refresh table
*/
 refreshSubmit()
 {
  $("#changesubmit").modal('hide'); 
  this.ngOnInit();
}

 /*
 * delete
 */
deleteSubmit(taskId) 
{
  this.targetIndex = taskId;
  //this.ModalService.open(empId);
  $("#deletesubmit").modal('show'); 
} 
 
deleteRow(Obj)
{
 this.httpService.postRequest<ResponseContainer>(Obj,'trainer/deleteTask').subscribe(datas => {
  $('.spinner').css('display','none');
  if(datas.rsBody.result == 'success')
  {
    $("#deletesubmit").modal('hide'); 
    this.ngOnInit();
  }
 });
}

/* 
* back Category page 
*/
backCategory(){
  this.router.navigate(['/trainers/assign-task-trn']);  
 } 


 backBadges(){
  var catId =  Number(this.route.snapshot.params.catId); 

  this.router.navigate(['/trainers/task-trn',catId]);  
 }
}
