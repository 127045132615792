import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule }   from '@angular/forms';
import { Authrouting } from './auth.routing'
import { AuthComponent } from './auth.component';
import { AdminModule } from './../admin/admin.module';
import { SuperAdminModule } from './../superadmin/superadmin.module'; 
import { TrainersModule } from '../trainers/trainers.module';
import { EmployeeModule } from '../employee/employee.module';
import { TeamleadModule } from '../teamlead/teamlead.module';
import { SuperTeamleadModule } from '../superteamlead/superteamlead.module';
import { ForgotPasswordComponent } from './component/forgotpassword/forgotpassword.component';

@NgModule({
  declarations: [
    AuthComponent,
    ForgotPasswordComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    Authrouting,
    SuperAdminModule,
    AdminModule,
    TrainersModule,
    EmployeeModule,
    TeamleadModule,
    SuperTeamleadModule
  ],
  providers: [],
  bootstrap: [AuthComponent]
})
export class AuthModule { }
