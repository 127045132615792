import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';

import {ResponseContainer} from '../../../service/ResponseContainer';
import {RequestContainer} from '../../../service/RequestContainer'; 
declare var $ :any;

@Component({
  selector: 'app-tasklist-adm',
  templateUrl: 'tasklist-adm.component.html'
})
export class TasklistAdmComponent implements OnInit {

  public submitObj:any = {};
  public employeeList:any = {};
  public targetIndex:Object = {};
  public setPageNo:boolean = false;  
  public setPageNoNext:boolean = false;
  public pageNo:any = {}; 
  public routeUrl:Object = {};
  public responseData:any = {}; 
  public badgeList:any = {}; 
  public id:any = {}; 
  public keyword:any = {};

  @Input() elementId: String; 
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;
 
  constructor(
    private router:Router, 
     private httpService : HttpService, 
     private route: ActivatedRoute
   )
   {}  
 
  
   ngOnInit(): void {
    $('.modal ').insertAfter($('body'));
    var requestObj = new RequestContainer();
    requestObj['pageNo'] = 0;
    this.pageNo = requestObj['pageNo'];
    var currentUser = JSON.parse(sessionStorage.getItem('login_id')); 
    // this.submitObj.skillId  =  Number(this.route.snapshot.params.skillId); 
    // this.submitObj.badgeId  =  Number(this.route.snapshot.params.badgeId); 
    this.submitObj.empId  =  currentUser;
    requestObj['empId'] =  currentUser;
    requestObj['skillId'] =  Number(this.route.snapshot.params.skillId);
    requestObj['badgeId']  =  Number(this.route.snapshot.params.badgeId); 
    requestObj['trainerId']  =  Number(this.route.snapshot.params.trainerId); 

 
    this.routeUrl = this.router.url;  
    this.id  =  this.route.snapshot.paramMap.get('id'); 
    if(this.routeUrl == '/admin/tasklist-adm/'+requestObj['trainerId']+'/'+requestObj['skillId']+'/'+requestObj['badgeId'])
    { 
        this.httpService.postRequest<ResponseContainer>(requestObj,'employee/getTaskList').subscribe(
          datas=> {
            if(datas.rsBody.result == 'success') 
            {
              this.responseData = datas.rsBody.data;
              if(this.responseData.length == 5) 
              {
                  this.setPageNoNext = true;  
              }  
              else
              {
                  this.setPageNoNext = false;
              }
              if(this.pageNo == 0)
              {
                  this.setPageNo = false;
              }
              else
              {
                  this.setPageNo = true;
              } 
            } 
            else{
              console.log(datas.rsBody.error);
            }
          });
    }
  }


/* 
* signleTask page
*/  
signleTask(taskId){
  this.router.navigate(['/admin/task2-adm',taskId]); 
}

backBadge(){
  this.router.navigate(['/admin/mybadges-adm']); 
}

}
 