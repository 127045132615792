import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse, HttpEvent,HttpResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
//import { catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { catchError, map, tap } from 'rxjs/operators';
import {BaseContainer} from './BaseContainer';
import {HttpRequestObj} from './HttpRequestObj';
import {HttpResponseObj} from './HttpResponseObj';

declare var $ :any;

@Injectable()
export class HttpService {
  private  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  };
 public baseUrl: string = 'https://api.skilltracer.com';
 public trackObj:any={};
 public location:any = {};
 public ipInfoToken:any = '7c8b8f2eab6adf';
 //public basepath =

  constructor(private http: HttpClient) {

  }

  //GENRIC POST request
  public postRequest<T extends BaseContainer>(requestObj, url)
  :Observable<T>
  {
    var parentRef = this;
    var httpRequestObj = new HttpRequestObj(requestObj);
    var apiUrl = this.baseUrl+'/'+url;
    return Observable.create(function subscribe(observer) {
        parentRef.http.post<HttpResponseObj>(apiUrl, httpRequestObj,parentRef.httpOptions)
        .pipe(
              catchError(this.error)
             )
        .subscribe((httpEvent:HttpResponse<HttpResponseObj>) => {
          if(httpEvent['errors'] == null)
          {
          observer.next(httpEvent);
          observer.complete();
          }
          else
          {
              observer.complete();
          }
        });
      });
  }

//REST API GET ALL RECORDS
public getAll<T extends BaseContainer>(url)
:Observable<T>
{
  var parentRef = this;
  var apiUrl = this.baseUrl+'/'+url;
  return Observable.create(function subscribe(observer) {
      parentRef.http.get<HttpResponseObj>(apiUrl,parentRef.httpOptions)
      .pipe(
        catchError(this.error)
        )
      .subscribe((httpEvent:HttpResponse<HttpResponseObj>) => {
        if(httpEvent['errors'] == null)
        {
        observer.next(httpEvent);
        observer.complete();
        }
        else
        {
            observer.complete();
        }
      });
    });
}


//REST API GET SPECIFIC RECORD BASED ON ID
public find<T extends BaseContainer>(id : any , url)
:Observable<T>
{
  var parentRef = this;
  var apiUrl = this.baseUrl+'/'+url;

  return Observable.create(function subscribe(observer) {
      parentRef.http.get<HttpResponseObj>(apiUrl+'/'+id)
      .pipe(
            catchError(this.error)
           )
      .subscribe((httpEvent:HttpResponse<HttpResponseObj>) => {
        if(httpEvent['errors'] == null)
        {
        observer.next(httpEvent);
        observer.complete();
        }
        else
        {
            observer.complete();
        }
      });
    });
}


//CREATE A NEW RECORD
public save<T extends BaseContainer>(requestObj , url)
:Observable<T>
{
  var parentRef = this;
  var httpRequestObj = new HttpRequestObj(requestObj);
  var apiUrl = this.baseUrl+'/'+url;

  return Observable.create(function subscribe(observer) {
      parentRef.http.post<HttpResponseObj>(apiUrl, httpRequestObj,parentRef.httpOptions)
      .pipe(
            catchError(this.error)
           )
      .subscribe((httpEvent:HttpResponse<HttpResponseObj>) => {
        if(httpEvent['errors'] == null)
        {
        observer.next(httpEvent);
        observer.complete();
        }
        else
        {
            observer.complete();
        }
      });
    });
}


//UPDATE AN EXISTING RECORD
public update<T extends BaseContainer>(requestObj ,url,id )
:Observable<T>
{
  var parentRef = this;
  var httpRequestObj = new HttpRequestObj(requestObj);
  var apiUrl = this.baseUrl+'/'+url;

  return Observable.create(function subscribe(observer) {
      parentRef.http.put<HttpResponseObj>(apiUrl+'/'+id, httpRequestObj,parentRef.httpOptions)
      .pipe(
            catchError(this.error)
           )
      .subscribe((httpEvent:HttpResponse<HttpResponseObj>) => {
        if(httpEvent['errors'] == null)
        {
        observer.next(httpEvent);
        observer.complete();
        }
        else
        {
            observer.complete();
        }
      });
    });
}


//DELETE AN EXISTING RECORD
public delete<T extends BaseContainer>(url,id )
:Observable<T>
{
  var parentRef = this;
  var apiUrl = this.baseUrl+'/'+url;

  return Observable.create(function subscribe(observer) {
      parentRef.http.delete<HttpResponseObj>(apiUrl+'/'+id)
      .pipe(
            catchError(this.error)
           )
      .subscribe((httpEvent:HttpResponse<HttpResponseObj>) => {
        if(httpEvent['errors'] == null)
        {
        observer.next(httpEvent);
        observer.complete();
        }
        else
        {
            observer.complete();
        }
      });
    });
}

//Start by shivangi
//GENRIC POST request
public postRequest1<T extends BaseContainer>(requestObj, url)
:Observable<T>
{
  var parentRef = this;
  return Observable.create(function subscribe(observer) {
      parentRef.http.post<HttpResponseObj>(url, requestObj)
      .pipe(
            catchError(this.error)
           )
      .subscribe((httpEvent:HttpResponse<HttpResponseObj>) => {
        observer.next(httpEvent);
        observer.complete();
      });
    });
}
//End by shivangi


  error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
   // console.log(errorMessage);
    return throwError(errorMessage);
  }

}
