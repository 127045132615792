<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Training</a></li>
                    </ol>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                        <div class="title">
                            <h4>Training</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row badge-view-wrapper mt-4">
            <div class="col-md-12">
                <ul class="badge-overview d-v-center mt-3">
                <li><div class="card" routerLink="/task-list-trn">
                    <div class="card-body skill-set">
                        <div>
                        <img src="../../../assets/img/Red-Beginner.png">
                    </div>
                        <p class="m-0">Beginner</p>
                    </div>
                </div></li>
                    
                    <li><div class="card" routerLink="/task-list-trn">
                    <div class="card-body skill-set">
                        <div>
                        <img src="../../../assets/img/Yellow-Intermediate.png">
                    </div>
                        <p class="m-0">Intermediate</p>
                    </div>
                </div></li>
                    
                    <li><div class="card" routerLink="/task-list-trn">
                    <div class="card-body skill-set"><div>
                        <img src="../../../assets/img/Green-Expert.png">
                    </div>
                        <p class="m-0">Expert</p>
                    </div>
                </div></li>
                </ul>
            </div>
        </div>
    </div>
</section>


