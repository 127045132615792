<div class="loader"><div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>

<app-header></app-header>
<div class="app-body">
    <div class="sidebar mob-toggle">
        <app-sidebar-suadm></app-sidebar-suadm>
    </div>
    <!--changes by shivangi start-->
    <!-- changes by shivangi end-->
    <main class="main mob-toggle-main">
            <router-outlet></router-outlet>
    </main>
</div> 
