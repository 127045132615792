<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Employee List</a></li>
                    </ol>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                        <div class="title">
                            <h4>Trainer</h4>
                        </div>
                    </div> 

                    <div class="dash-card-grid mt-4" *ngIf='responseData.length > 0'>
                        <a class="widget-profile d-v-center" *ngFor="let item of responseData | paginate: { itemsPerPage: pageSize, currentPage: pageNo } let i=index" href="javascript:void(0);"  (click)="viewProfile(item.user_id)">
                            
                            <!-- <div class="widget-img-holder">
                                <div>
                                    <img src="../../../../assets/img/Profile.png">
                                </div>
                                <span><i class="fa fa-circle mr-2 text-danger"></i></span>
                            </div>
                            <h3 class="widget-content">
                                <span class="w-title">{{item.first_name}}</span> 
                                <span class="w-val">#{{item.id}}</span>  
                            </h3> 

                            <div class="t-menu-profile">
                                <a class="t-drp dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="ti-more-alt"></i></a>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <li><a class="dropdown-item d-v-center" href="javascript:void(0);" (click)="showReport(item.id)"><span class="sts mr-2"><i class="fa fa-user-circle" aria-hidden="true"></i>
                                            </span> View Reoprt</a></li>
                                    <li><a class="dropdown-item"  href="javascript:void(0);" (click)="viewProfile(item.id)"><span class="sts  mr-2"><i class="fa fa-user-plus" aria-hidden="true"></i>
                                            </span> View Profile</a></li>
                                </ul>
                            </div> -->
                         
                            <div class="widget-img-holder">
                                <div>
                                    <img src="../../../../assets/img/Profile.png">
                                </div>
                            </div> 
                            <h3 class="widget-content">
                                <span class="w-title">{{item.first_name}}</span>
                                <span class="w-val">#{{item.id}}</span>
                            </h3>
                        </a>  
                    </div> 
                    <!--No data Found start-->
                    <div class="w-100" *ngIf='responseData.length == 0'>
                        <div class="text-center" role="not found">
                            <i class="fa fa-exclamation-circle fa-5x" aria-hidden="true"></i>
                            <p class="mt-2">No Badges Found</p>
                        </div>
                    </div>
                    <!--No data found end--> 
                </div>
            </div>
        </div>
    </div>
</section>
<div class="text-center mt-4">
    <pagination-controls  *ngIf="responseData.length !=0" (pageChange)="loadpage($event)" maxSize="5" directionLinks="true" responsive="true" previousLabel="Previous" nextLabel="Next"></pagination-controls>
</div>
