
import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';

import {ResponseContainer} from '../../../service/ResponseContainer';
import {RequestContainer} from '../../../service/RequestContainer';
import { Location } from '@angular/common';
declare var $ :any;

@Component({
  selector: 'app-managment-adm',
  templateUrl: 'managment-adm.component.html'
})
export class ManagmentAdmComponent implements OnInit {

  public submitObj:any = {};
  public targetIndex:Object = {};
  public pageNo:number= 1;
  public pageSize:number= 10;
  public routeUrl:Object = {};
  public responseData:any = {};
  public badgeList:any = {};
  public id:any = {};

  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;

  constructor(
    private router:Router,
     private httpService : HttpService,
     private route: ActivatedRoute,
     private location: Location
   )
   {}

  ngOnInit(): void {
    $('.modal ').insertAfter($('body'));
    var requestObj = new RequestContainer();
    requestObj['pageNo'] = this.pageNo;
    requestObj['pageSize'] = this.pageSize;
    requestObj['pathway_id'] =  this.route.snapshot.paramMap.get('id');
    requestObj['platform'] = "Web";
    this.routeUrl = this.router.url;
    this.id  =  this.route.snapshot.paramMap.get('id');
    if(this.routeUrl == '/admin/managment-adm/'+this.id)
    {
        this.httpService.postRequest<ResponseContainer>(requestObj,'admin/getPathwaySkill').subscribe(
          datas=> {
            if(datas.rsBody.result == 'success')
            {
              this.responseData = datas.rsBody.data;
            }
            else{
              console.log(datas.rsBody.error);
            }
          });
    }
  }

  goBack() {
    this.location.back();
  }


/*
* pagination next
*/

loadpage(event) {
   $('.loader').css('display','block');
    this.pageNo= event;
    this.ngOnInit();
  }


/*
* delete
*/
 deleteSubmit(pathway_skill_id)
 {
   this.targetIndex = pathway_skill_id;
   $("#deletesubmit").modal('show');
 }

 deleteRow(Obj)
 {
  this.httpService.postRequest<ResponseContainer>(Obj,'admin/deletePathwaySkill').subscribe(datas => {
   if(datas.rsBody.result == 'success')
   {
     $("#deletesubmit").modal('hide');
     this.ngOnInit();
   }
   else{
     console.log(datas.rsBody.error);
   }
  });
 }


 /*
* Add Pathwau Skill page
*/
addPathwaySkill(){
  this.routeUrl = this.router.url;
  this.id  =  this.route.snapshot.paramMap.get('id');
  this.router.navigate(['/admin/add-learning-pathway-skill-adm',this.id]);
 }





 backPathwayList(){
  this.router.navigate(['/admin/learning-pathways-adm']);
 }

}
