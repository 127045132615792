<section id="dashboard" class="g-100">
    <div class="container-fluid">
         
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box"> 
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item" (click)="backTeam();"><a href="javascript:void(0);">My Team</a></li>
                        <!-- <li class="breadcrumb-item" (click)="backEmployee();"><a href="javascript:void(0);">Employee List</a></li>
                        <li class="breadcrumb-item active" (click)="backTaskList();"><a href="javascript:void(0);">Task List</a></li> -->
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Task No.2</a></li>
                    </ol>
                </div>
            </div> 
        </div>


        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                        <div class="title">
                            <h4>Task No.2</h4>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
 
        <!--QUESTIONS LIST-->
        <div class="row badge-view-wrapper mt-4"  *ngIf='responseData.length > 0'>
            <div class="col-md-12" *ngFor="let item of responseData let i=index"> 
                <div class="task-wrapper">
                    <h3 class="text-center">{{item.question_title}}</h3>
                    <div *ngIf='item.question_type == 0'> 
                        <textarea value="fgdfg" class="text-area mt-4" placeholder="Write your answer here" rows="20" name="emp_answer" [(ngModel)]="submitObj.emp_answer" id="emp_answer" cols="40" autocomplete="off" role="textbox" aria-autocomplete="list" aria-haspopup="true" disabled></textarea>
                        <h3 class="text-center" *ngIf='item.submission_title != null'>{{item.submission_title}}</h3>
                    </div>
                    <div *ngIf='item.question_type == 1'> 
                        <textarea value="fgdfg" class="text-area mt-4" placeholder="Write your answer here" rows="20" name="emp_answer" [(ngModel)]="submitObj.emp_answer" id="emp_answer" cols="40" autocomplete="off" role="textbox" aria-autocomplete="list" aria-haspopup="true"></textarea>
                    </div>
                    <div class="b-grid task_act" *ngIf='item.question_type == 2'> 
                        <a href="javascript:void(0);" class="widget-profile d-v-center" *ngFor="let item1 of item.optionList let i1=index" (click)="selectOption(item1.option);">
                            <div class="widget-img-holder">
                                <div><span class="q-alf">A </span></div>
                            </div>
                            <h3 class="widget-content"><span class="w-val">{{item1.option}}</span></h3>
                        </a>
                    </div> 
                    <div *ngIf='item.question_type == 3'>  
                        <label *ngIf='item.task_doc_type == "image/png"'> 
                            {{item.task_doc_type}}
                            <img [src]="item.task_doc" alt="" />
                        </label>
                        <label *ngIf='item.task_doc_type == "image/jpeg"'>
                            {{item.task_doc_type}}
                            <img [src]="item.task_doc" alt="" />
                        </label>
                        <label *ngIf='item.task_doc_type == "video/mp4"'>
                            {{item.task_doc_type}}

                            <video controls>
                                <source [src]="item.task_doc" type="video/mp4">
                            </video>
                        </label>
                        <label *ngIf='item.task_doc_type == "application/pdf"'>
                            {{item.task_doc_type}}
                            <iframe src="https://www.w3docs.com/uploads/media/default/0001/01/540cb75550adf33f281f29132dddd14fded85bfc.pdf" alt="" width="100%" height="500px">
                            </iframe>
                        </label>
                    </div>
                </div>
                <!--Question pagination start-->
                <div class="text-right mt-4">
                    <a href="javascript:void(0);" class="btn btn-secondary" *ngIf='setPageNoNext' (click)="nextSubmit(item.id);">Save & Next</a>
                </div>

                <div class="text-right mt-4" *ngIf='item.last_question_id == item.id'>
                    <a href="javascript:void(0);" class="btn btn-secondary" *ngIf='setPageNoNext' (click)="sendRequest();">Send for Approval</a>
                </div>                
            </div>
        </div>
        <div class="col-md-12"  *ngIf='responseData.length == 0'>
            <div class="task-wrapper">
                <strong>No Data Found</strong>
            </div>     
        </div>
    </div>
</section>
 



<!--Confirmation-->
<div class="modal fade" id="confrt" tabindex="-1" role="dialog" aria-labelledby="confrt" aria-hidden="true">
  <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body text-center">
          <img src="../../../assets/img/warning.svg" width="50px" alt="warning">
        <p><strong>Sent for approval?</strong></p>
      </div>
        <div class="mdl-footer d-v-center">
            <a href="javascript:void(0);" class="btn btn-default btn-block">Cancel</a>
            <a href="javascript:void(0);" class="btn btn-yellow btn-block m-0">Ok</a>
        </div>
    </div>
  </div>
</div>