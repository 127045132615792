import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';

import {ResponseContainer} from '../../../service/ResponseContainer';
import {RequestContainer} from '../../../service/RequestContainer'; 
@Component({
  selector: 'app-assigned-employee-list-trn',
  templateUrl: 'assigned-employee-list-trn.component.html'
})
export class AssignedEmployeeListTrnComponent implements OnInit {
  public submitObj:any = {}; 
  public targetIndex:Object = {};
  public setPageNo:boolean = false;
  public setPageNoNext:boolean = false;
  public pageNo:any = {};
  public routeUrl:Object = {};
  public responseData:any = {}; 
  public id:any = {}; 
  
  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;
 
  constructor(
    private router:Router,
     private httpService : HttpService, 
     private route: ActivatedRoute
   ) 
   {}  
  ngOnInit(): void { 
    var requestObj = new RequestContainer();
    requestObj['pageNo'] = 0;   
    this.pageNo = requestObj['pageNo'];
    requestObj['empId'] = JSON.parse(sessionStorage.getItem('login_id'));
    requestObj['platform'] = "Web";
    this.httpService.postRequest<ResponseContainer>(requestObj,'trainer/getAssignedEmployee').subscribe(
      datas=> {
        if(datas.rsBody.result == 'success')
        {
          this.responseData = datas.rsBody.data;
          if(this.responseData.length == 9) 
          { 
              this.setPageNoNext = true;  
          }  
          else
          {
              this.setPageNoNext = false;
          }
          if(this.pageNo == 0)
          {
              this.setPageNo = false;
          }
          else
          { 
              this.setPageNo = true;
          }
        } 
        else{
          console.log(datas.rsBody.error);
        }
      });    
  }

  /*
 * View Profile
 */
 viewProfile(id)
 { 
  this.router.navigate(['/trainers/employee-profile-trn',id]);
 } 

/*
 * View Profile
 */
showReport(id)
{ 
 this.router.navigate(['/trainers/employee-report-trn',id]);  
} 
  
}
 