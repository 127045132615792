<section id="dashboard" class="g-100">
   <div class="container-fluid">
      <div class="row">
         <div class="col-md-12">
            <div class="page-title-box">
               <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                  <li class="breadcrumb-item active"><a href="javascript:void(0);">Employee</a></li>
               </ol>
            </div>
         </div>
      </div>
      <!-- Start Employee list by shivangi-->
      <div class="row">
         <div class="col-md-12">
            <div class="">
               <div class="search-trainer">
                  <div class="title">
                     <h4>Employee Data</h4>
                  </div>
                  <a class="search-wrapper" routerLink="/admin/add-employee-adm">
                     <p>Add Employee</p>
                     <span>
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                           viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                           <g>
                              <g>
                                 <path d="M492,236H276V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v216H20c-11.046,0-20,8.954-20,20s8.954,20,20,20h216
                                    v216c0,11.046,8.954,20,20,20s20-8.954,20-20V276h216c11.046,0,20-8.954,20-20C512,244.954,503.046,236,492,236z"/>
                              </g>
                           </g>
                        </svg>
                     </span>
                  </a>
               </div>
               <!-- --- -->
               <div id="my-table1">
                  <div class="card mt-4">
                     <div class="card-body">
                        <!-- Search Trainer -->
                        <div class="d-flex justify-content-end">
                           <div id="search">
                              <label for="search-input"><i class="fa fa-search" aria-hidden="true"></i><span class="sr-only">Search icons</span></label>
                              <input id="search_result" name="search_result" class="form-control"  [(ngModel)]="submitObj.search_result" type="text" placeholder="Search by Employee name" aria-label="Search" (ngModelChange)="searchSubmit();">
                           </div>
                        </div> 
                        <div class="table-responsive mt-3">
                           <table class="table table-striped mb-0">
                              <thead>
                                 <tr>
                                    <th>#</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Role</th>
                                    <th>Email ID</th>
                                    <th>Job Title</th>
                                    <th class="text-center">Remove</th>
                                 </tr>
                              </thead>
                              <tbody *ngIf='responseData.length > 0'>
                                 <tr *ngFor="let item of responseData | paginate: { itemsPerPage: pageSize, currentPage: pageNo } let i=index">
                                    <td>{{((pageNo-1)*pageSize)+(i+1)}}</td>
                                    <td>{{item.first_name}}</td>
                                    <td>{{item.last_name}}</td>
                                    <td *ngIf='item.type == 0'>Super Admin</td>
                                    <td *ngIf='item.type == 1'>Admin</td>
                                    <td *ngIf='item.type == 2'>Trainer</td>
                                    <td *ngIf='item.type == 3'>Employee</td>
                                    <td *ngIf='item.type == 4'>Team Leader</td>
                                    <td *ngIf='item.type == 5'>Super Team Leader</td>
                                    <td>{{item.email_id}}</td>
                                    <td>{{item.role}}</td>
                                    <td class="text-center"><span (click)="deleteSubmit(item.user_id);" class="text-danger actionbtnsdf"><i class="ti-trash"></i></span></td>
                                 </tr>
                              </tbody>
                           </table>
                           <div class="text-center mt-4">
                              <pagination-controls  *ngIf="responseData.length !=0" (pageChange)="loadpage($event)" maxSize="5" directionLinks="true" responsive="true" previousLabel="Previous" nextLabel="Next"></pagination-controls>
                           </div>
                           <!--No data Found start-->
                           <div class="w-100" *ngIf='responseData.length == 0'>
                              <div class="text-center" role="not found">
                                 <i class="fa fa-exclamation-circle fa-5x" aria-hidden="true"></i>
                                 <p class="mt-2">No Badges Found</p>
                              </div>
                           </div>
                           <!--No data found end-->
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<!--successsfull-modal-->
<div class="modal fade"  id="changesubmit" role="dialog" aria-labelledby="trash-mdl" aria-hidden="true">
   <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
         <div class="modal-body text-center">
            <p>Submission Successfull</p>
         </div>
         <div class="mdl-footer d-v-center">
            <button class="btn btn-black btn-block m-0" data-dismiss="modal">Ok</button>
         </div>
      </div>
   </div>
</div>
