<section id="dashboard" class="g-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0);">Notification</a></li>
                    </ol>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="search-trainer">
                        <div class="title">
                            <h4>Notification</h4>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12">
                <div class="notify mt-4">
                    <ul *ngIf='responseData.length > 0'>
                        <li *ngFor="let item of responseData let i=index"> 
                            <div  class="widget">
                                <div class="notify-icon d-v-center flex-justify-between" >
                                    <img src="../../../assets/img/notification-bell.svg">
                                    <span class="badge badge-secondary">{{item.created_date}}</span>
                                </div>
                                <div class="notify-details mt-3">
                                    <!-- <div class="skill-set">
                                        <div class="c_bar i-100">
                                            <circle-progress [percent]="100" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#e5c634'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'transparent'" [class]="'pgrsbar'" [imageSrc]="" [imageHeight]="100" [imageWidth]="100">
                                            </circle-progress>
                                        </div> 
                                    </div> -->
                                    <div class="card-body skill-set" *ngIf="item.badge_id == 1">
                                        <div class="c_bar2 b-100">
                                            <circle-progress [percent]="100" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#0078a1'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'transparent'" [class]="'pgrsbar'" [imageSrc]="item.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                                            </circle-progress>
                                        </div>
                                     </div>
                                     <div class="card-body skill-set" *ngIf="item.badge_id == 2">
                                        <div class="c_bar2 i-100">
                                            <circle-progress [percent]="100" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#e5c634'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'transparent'" [class]="'pgrsbar'" [imageSrc]="item.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                                            </circle-progress>
                                        </div>
                                     </div>
                                     <div class="card-body skill-set" *ngIf="item.badge_id == 3">
                                        <div class="c_bar2 e-100">
                                            <circle-progress [percent]="100" [radius]="100" [outerStrokeWidth]="15" [innerStrokeWidth]="23" [space]="-20" [outerStrokeColor]="'#62a006'" [innerStrokeColor]="'#e7e8ea'" [animation]="true" [animationDuration]="300" [backgroundGradient]="" [responsive]="true" [backgroundColor]="'transparent'" [class]="'pgrsbar'" [imageSrc]="item.cat_skill_icon" [imageHeight]="100" [imageWidth]="100">
                                            </circle-progress>
                                        </div>
                                     </div>
                                    <div>
                                    <h6><strong>Notification</strong></h6>
                                    <p class="mt-0">{{item.first_name}} assigned you the {{item.badge_name}} Badge under {{item.cat_name}}</p>

                                    <!-- <p class="mt-0">{{item.first_name}} {{item.last_name}} assigned you the {{item.badge_name}} Badge of {{item.cat_skill_name} under {{item.cat_name}}}</p> -->
                                </div>
                            </div>
                                 <!-- <div class="text-right">
                                    <div class="mt-2">
                                        <button class="btn btn-success" (click)="answerStatusUpdate(item.answer_id, 1)">Approve</button>
                                        <button class="btn btn-warning ml-2" (click)="answerStatusUpdate(item.answer_id, 2)">Reject</button>
                                    </div>
                                    
                                </div> -->
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div> 