import { NgModule } from '@angular/core'; 
import { Routes, RouterModule } from '@angular/router';

import { TrainersComponent } from './trainers.component';
import { DashboardTrnComponent } from './component/dashboard-trn/dashboard-trn.component';
import { AssignBadgeTrnComponent } from './component/assign-badge-trn/assign-badge-trn.component';
import { AssignedBadgesTrnComponent } from './component/assigned-badges-trn/assigned-badges-trn.component';
import { CategoryTrnComponent } from './component/category-trn/category-trn.component';
import { AssignTaskTrnComponent } from './component/assign-task-trn/assign-task-trn.component';
import { CreateTaskTrnComponent } from './component/create-task-trn/create-task-trn.component'; 
import { CreateTaskQuestionsTrnComponent } from './component/create-task-questions-trn/create-task-questions-trn.component';
import { EmployeeListAssTrnComponent } from './component/employee-list-ass-trn/employee-list-ass-trn.component';
import { AssignedEmployeeListTrnComponent } from './component/assigned-employee-list-trn/assigned-employee-list-trn.component';
import { EmployeeReportTrnComponent } from './component/employee-report-trn/employee-report-trn.component';

import { NotificationTrnComponent } from './component/notification-trn/notification-trn.component';
import { ScanEmployeeTrnComponent } from './component/scan-employee-trn/scan-employee-trn.component';
import { SkillsTrnComponent } from './component/skills-trn/skills-trn.component';
import { StopTrainingTrnComponent } from './component/stop-training-trn/stop-training-trn.component';
import { TaskCompleteTrnComponent } from './component/task-complete-trn/task-complete-trn.component';
import { TaskListTrnComponent } from './component/task-list-trn/task-list-trn.component';
import { TaskTrnComponent } from './component/task-trn/task-trn.component';
import { TrainingCompleteTrnComponent } from './component/training-complete-trn/training-complete-trn.component';
import { TrainingTrnComponent } from './component/training-trn/training-trn.component';
import { ScanEmployeeListTrnComponent } from './component/scan-employee-list-trn/scan-employee-list-trn.component';
import { AssignedBadgesListTrnComponent } from './component/assigned-badges-list-trn/assigned-badges-list-trn.component';
import { EmployeeListTrnComponent } from './component/employee-list-trn/employee-list-trn.component';
import { EditProfileAdmComponent } from './component/edit-profile-adm/edit-profile-adm.component';


const routes: Routes = [
    {
        path: 'trainers',
        component: TrainersComponent, 
        children: [
            { path: '', component: DashboardTrnComponent },
            { path: 'dashboard-trn', component: DashboardTrnComponent },
            // { path: 'assigned-badges-trn', component: AssignedBadgesTrnComponent},
            { path: 'employee-profile-trn/:id', component: AssignedBadgesTrnComponent },
            { path: 'assign-badge-trn/:id', component: AssignBadgeTrnComponent },
            { path: 'category-trn', component: CategoryTrnComponent },
            { path: 'assign-task-trn', component: AssignTaskTrnComponent }, 
            { path: 'create-task-trn', component: CreateTaskTrnComponent  }, 
            { path: 'create-task-questions-trn/:catId/:skillId/:badgeId/:taskId', component: CreateTaskQuestionsTrnComponent  },
            { path: 'employee-list-ass-trn', component: EmployeeListAssTrnComponent }, 
            { path: 'assigned-employee-list-trn', component: AssignedEmployeeListTrnComponent }, 
            { path: 'employee-report-trn/:id', component: EmployeeReportTrnComponent }, 

            { path: 'notification-trn', component: NotificationTrnComponent },
            { path: 'scan-employee-trn', component: ScanEmployeeTrnComponent },
            { path: 'skills-trn/:id', component: SkillsTrnComponent, runGuardsAndResolvers: 'always' },
           // { path: 'skills-trn', component: SkillsTrnComponent }, 
            { path: 'stop-training-trn', component: StopTrainingTrnComponent },
            { path: 'task-complete-trn', component: TaskCompleteTrnComponent },
            { path: 'task-list-trn/:catId/:skillId/:badgeId', component: TaskListTrnComponent },
            { path: 'task-trn/:id', component: TaskTrnComponent },
            { path: 'training-complete-trn', component: TrainingCompleteTrnComponent }, 
            { path: 'training-trn', component: TrainingTrnComponent },
            { path: 'scan-employee-list-trn', component: ScanEmployeeListTrnComponent },
            { path: 'assigned-badges-list-trn', component: AssignedBadgesListTrnComponent },
            { path: 'employee-list-trn', component: EmployeeListTrnComponent },
            { path: 'edit-profile-trn', component: EditProfileAdmComponent }
            
        ] 
    }
]; 

export const TrainersRouting = RouterModule.forRoot(routes);