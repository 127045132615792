// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-technical',
//   templateUrl: 'technical-tld.component.html'
// })
// export class TechnicalTldComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }

import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';

import {ResponseContainer} from '../../../service/ResponseContainer';
import {RequestContainer} from '../../../service/RequestContainer';
declare var $ :any;

@Component({
  selector: 'app-technical-tld',
  templateUrl: 'technical-tld.component.html'
})
export class TechnicalTldComponent implements OnInit {

  public submitObj:any = {};
  public targetIndex:Object = {};
  public setPageNo:boolean = false;
  public setPageNoNext:boolean = false;
  public pageNo:any = {};
  public routeUrl:Object = {};
  public responseData:any = {};
  public badgeList:any = {};
  public id:any = {};

  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;

  constructor(
    private router:Router,
     private httpService : HttpService,
     private route: ActivatedRoute
   )
   {}

  ngOnInit(): void {
    $('.modal ').insertAfter($('body'));
    var requestObj = new RequestContainer();
    requestObj['pageNo'] = 0;
    requestObj['platform'] = "Web";
    this.pageNo = requestObj['pageNo'];
    requestObj['pathway_id'] =  this.route.snapshot.paramMap.get('id');
    this.routeUrl = this.router.url;
    this.id  =  this.route.snapshot.paramMap.get('id');
    if(this.routeUrl == '/teamlead/technical-tld/'+this.id)
    {
        this.httpService.postRequest<ResponseContainer>(requestObj,'teamlead/getPathwaySkill').subscribe(
          datas=> {
            if(datas.rsBody.result == 'success')
            {
              this.responseData = datas.rsBody.data;
              if(this.responseData.length == 5)
              {
                  this.setPageNoNext = true;
              }
              else
              {
                  this.setPageNoNext = false;
              }
              if(this.pageNo == 0)
              {
                  this.setPageNo = false;
              }
              else
              {
                  this.setPageNo = true;
              }
            }
            else{
              console.log(datas.rsBody.error);
            }
          });
    }
  }


/*
* pagination next
*/
 nextSubmit()
 {
   var  requestObj = new RequestContainer();
   requestObj['pageNo'] = this.pageNo + 1;
   requestObj['platform'] = "Web";
   this.pageNo = requestObj['pageNo'];
   requestObj['pathway_id'] =  this.route.snapshot.paramMap.get('id');
   this.httpService.postRequest<ResponseContainer>(requestObj,'teamlead/getPathwaySkill').subscribe(
   datas=> {
     this.responseData = datas.rsBody.data;
     if(this.responseData.length == 5)
     {
        this.setPageNoNext = true;
        this.setPageNo = true;
     }
     else
     {
        this.setPageNoNext = false;
        this.setPageNo = true;
     }
   });
 }


 /*
 * pagination previous
 */
 previousSubmit()
 {
   var  requestObj = new RequestContainer();
   requestObj['platform'] = "Web"; 
   if(this.pageNo == 0)
   {
     requestObj['pageNo'] = 0;
     this.pageNo = requestObj['pageNo'];
   }
   else
   {
     requestObj['pageNo'] = this.pageNo - 1;
     this.pageNo = requestObj['pageNo'];
   }
   requestObj['pathway_id'] =  this.route.snapshot.paramMap.get('id');

   this.httpService.postRequest<ResponseContainer>(requestObj,'teamlead/getPathwaySkill').subscribe(
   datas=> {
     this.responseData = datas.rsBody.data;
     if(this.responseData.length == 5)
     {
        this.setPageNoNext = true;
     }
     else
     {
        this.setPageNoNext = false;
        this.setPageNo = true;
     }
     if(this.pageNo == 0)
     {
        this.setPageNo = false;
     }
     else
     {
        this.setPageNo = true;
     }
   });

 }



 backPathwayList(){
  this.router.navigate(['/teamlead/learning-pathways-tld']);
 }

}
