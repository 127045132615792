import { Component, OnDestroy,OnInit,EventEmitter,Input,Output } from '@angular/core';
import { HttpService } from '../../../service/http.service';
import { Router,ActivatedRoute } from '@angular/router';

import {ResponseContainer} from '../../../service/ResponseContainer';
import {BaseContainer} from '../../../service/BaseContainer';
import {RequestContainer} from '../../../service/RequestContainer'; 
declare var $ :any; 
@Component({ 
  selector: 'app-task-trn',
  templateUrl: 'task-trn.component.html'
})
export class TaskTrnComponent implements OnInit {
  public submitObj:any = {};
  public targetIndex:Object = {};
  public setPageNo:boolean = false; 
  public setPageNoNext:boolean = false;
  public pageNo:any = {};
  public routeUrl:Object = {};
  public responseData:any = {};  
  public badgeList:any = {}; 
  public id:any = {};  

  @Input() elementId: String;
  @Output() onEditorKeyup = new EventEmitter<any>();
  editor;
 
  constructor(
    private router:Router,
     private httpService : HttpService, 
     private route: ActivatedRoute
   )
   {}  

  
  ngOnInit(): void { 
    $('.modal ').insertAfter($('body')); 
    $('.count').each(function () {
      $(this).prop('Counter',0).animate({
          Counter: $(this).text()
          }, {
              duration: 4000,
              easing: 'swing',
              step: function (now) {
                  $(this).text(Math.ceil(now));
              } 
          });
      });
    var requestObj = new RequestContainer();
    requestObj['pageNo'] = 0;
    this.pageNo = requestObj['pageNo']; 
    requestObj['catId'] =  this.route.snapshot.paramMap.get('id'); 
    var currentUser = JSON.parse(sessionStorage.getItem('login_user_id'));
    requestObj['empId'] =  currentUser;
    requestObj['platform'] = "Web"; 
    this.routeUrl = this.router.url; 
    this.id  =  this.route.snapshot.paramMap.get('id');  
    if(this.routeUrl == '/trainers/task-trn/'+this.id)  
    { 
        this.httpService.postRequest<ResponseContainer>(requestObj,'trainer/getAssignedCategorySkills').subscribe(
          datas=> {
            if(datas.rsBody.result == 'success') 
            {
              this.responseData = datas.rsBody.data; 
              
              if(this.responseData.timePeriod_1 == null){

              } 
              var backcolor = '.task_act a';
              $(backcolor).on('click', function(){
                  $(backcolor).removeClass('active-bg');
                  $(this).addClass('active-bg');
              });
              if(this.responseData.length == 5)   
              {
                  this.setPageNoNext = true;  
              }  
              else
              { 
                  this.setPageNoNext = false;
              } 
              if(this.pageNo == 0)
              {
                  this.setPageNo = false;
              }
              else
              {
                  this.setPageNo = true;
              }
            } 
            else{
              console.log(datas.rsBody.error);
            }
          });
    }
  } 

//Task list
  taskList(skillId, badgeId)  
  
  {
   var catId = this.route.snapshot.paramMap.get('id');
   this.router.navigate(['/trainers/task-list-trn',catId,skillId,badgeId]);    
  } 

//Time Period
onSelection_timperiod(time_period, skillId, badgeId){
  this.submitObj.time_period = time_period;
  this.submitObj.skillId = skillId;
  this.submitObj.badgeId = badgeId;
  this.httpService.postRequest<ResponseContainer>(this.submitObj,'trainer/addTimePeriod').subscribe(datas => {
    if(datas.rsBody.result == 'success')
    {
      this.submitObj = {};
      $("#changesubmit").modal('show');
      this.ngOnInit();
    }
    else
    { 
     console.log('error');
    }
   });
 
  } 
 
/* 
* back Category page
*/
backCategory(){
  this.router.navigate(['/trainers/assign-task-trn']);  
 } 

}
